import SolutionManifest from '../../solution-manifest';

const ENDPOINTS = {
    UserResendEmailCode: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.ResendUserEmailCode}`,
    AdditionalUser: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.AdditionalUser}`,
    FreeTrial: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.FreeTrial}`,
    ExtentFreeTrial: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.ExtentFreeTrial}`,
    VerifyTenantEmail: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.VerifyTenantEmail}`,
    StripeListInvoices: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.StripeListInvoices}`,
    StripeGetId: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.StripeGetId}`,
    StripePaidPlan: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.StripePaidPlan}`,
    StripeListProductPlan: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.StripeListProductPlan}`,
    StripePaymentMethods: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.StripePaymentMethods}`,
    SubDomain: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.SubDomain}`,
    MetaData: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.MetaData}`,
    UserSettings: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserSettings}`,
    UserGroup: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserGroup}`,
    UserApprover: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserApprover}`,
    UserVerifyEmail: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserVerifyEmail}`,
    UserRegistration: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserRegistration}`,
    MapAssets: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.MapAssets}`,
    Collections: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Collections}`,
    Bookmarks: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Bookmarks}`,
    Asset: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Assets}`,
    Analysis: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Analysis}`,
    Search: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Search}`,
    LoadAssets : `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.LoadAssets}`,
    FilterAssets : `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.FilterAssets}`,
    Execution: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Execution}`,
    AttachIot: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.AttachPolicy}`,
    FaceCollections: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.FaceCollections}`,
    FaceCollection: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.FaceCollection}`,
    Faces: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Faces}`,
    Face: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Face}`,
    CustomLabelModels: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.CustomLabelModels}`,
    CustomVocabularies: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.CustomVocabularies}`,
    CustomLanguageModels: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.CustomLanguageModels}`,
    CustomEntityRecognizers: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.CustomEntityRecognizers}`,
    Stats: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Stats}`,
    Users: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Users}`,
    userProfile: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserProfile}`,
    AIOptionsSettings: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.AIOptionsSettings}`,
    DeleteProxyAssets: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.DeleteProxyAssets}`,
    UserListing: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserListing}`,
    AuditTrail: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.AuditTrail}`,
    UserBackupCodes: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.UserBackupCodes}`,
    Reset2FA: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Reset2FA}`,
    VerifyUserBackupCodes: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.VerifyUserBackupCodes}`,
    Notification: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Notification}`,
    TextSearch: `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.TextSearch}`,
    ImageSimilar: `${SolutionManifest.GoogleApiEndpoint}/${SolutionManifest.ApiOps.ImageSimilar}`
};

export default ENDPOINTS;