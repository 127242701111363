import { Placeholder } from "react-bootstrap";
import SolutionManifest from "../../solution-manifest";

export default class Localization {
    static get isoCode() {
        return 'en-US';
    }

    static get MainTab() {
        return Localization.Languages[Localization.isoCode].MainTab;
    }

    static get Search() {
        return Localization.Languages[Localization.isoCode].Search;
    }

    static get Upload() {
        return Localization.Languages[Localization.isoCode].Upload;
    }

    static get Edit() {
        return Localization.Languages[Localization.isoCode].Edit;
    }

    static get Modal() {
        return Localization.Languages[Localization.isoCode].Modal;
    }

    static get Alerts() {
        return Localization.Languages[Localization.isoCode].Alerts;
    }

    static get Tag() {
        return Localization.Languages[Localization.isoCode].Tag;
    }

    static get Collection() {
        return Localization.Languages[Localization.isoCode].Collection;
    }

    static get Login() {
        return Localization.Languages[Localization.isoCode].Login;
    }

    static get Registration() {
        return Localization.Languages[Localization.isoCode].Registration;
    }

    static get UserVerifyEmail() {
        return Localization.Languages[Localization.isoCode].UserVerifyEmail;
    }

    static get Profile() {
        return Localization.Languages[Localization.isoCode].Profile;
    }

    static get ForgotPassword() {
        return Localization.Languages[Localization.isoCode].ForgotPassword;
    }

    static get Settings() {
        return Localization.Languages[Localization.isoCode].Settings;
    }

    static get Welcome() {
        return Localization.Languages[Localization.isoCode].Welcome;
    }

    static get TenantVerifyEmail() {
        return Localization.Languages[Localization.isoCode].TenantVerifyEmail;
    }

    static get Plan() {
        return Localization.Languages[Localization.isoCode].Plan;
    }

    static get Error() {
        return Localization.Languages[Localization.isoCode].Error;
    }

    static get AuditTrail() {
        return Localization.Languages[Localization.isoCode].AuditTrail;
    }

    static get BookMark() {
        return Localization.Languages[Localization.isoCode].BookMark;
    }

    static get Languages() {
        return {
            'en-US': {
                Error: {
                    Title: "Page not found",
                    Desc: "elko is a digital asset management tool designed to help you store and manage files across your team effortlessly. Start your elko journey today.",
                    Button: {
                        Primary: "Register",
                        PrimaryUrl: SolutionManifest.Plan.Url,
                        Secondary: "Start free trial",
                        SecondaryUrl: SolutionManifest.Plan.FreeTrialUrl
                    },
                    Elko: "Go to elko workspace",
                    ElkoHomePage: "Go to elko homepage"
                },
                MainTab: {
                    Search: "Explore",
                    Upload: "Upload",
                    Collections: "Collections",
                    Settings: "Settings",
                    Account: "Account",
                    Bookmarks: "Bookmarks",
                    Profile: "Profile",
                    Analytics: "Analytics",
                    Navigation: {
                        UserManagement: {
                            Title: "User Management",
                            SubPage: {
                                UserList: "User List",
                                UserRequest: "User Request"
                            }
                        },
                        AssetManagement: {
                            Title: "Asset Management",
                            SubPage: {
                                MyAssets: "My Assets"
                            }
                        },
                        PlanManagement: {
                            Title: "Plan Management",
                            SubPage: {
                                Plan: "Plan",
                                Invoices: "Invoices",
                                AuditTrail: "Audit Trail"
                            }
                        }
                    }
                },
                Search: {
                    SearchTitle: "What are you searching for?",
                    AssetList: {
                        title: "Recently Added",
                        NoAssets: "No assets yet",
                        NoAssetsDesc: "Get started by adding your images, videos, documents and audio files",
                        NoAssetsBtn: "Upload Files"
                    },
                    Edit: {
                        Title: "Edit",
                        AssetUploadedBy: "Uploaded By",
                        AssetDate: "Date Uploaded",
                        AssetType: "Type",
                        AssetFormal: "Format",
                        AssetSize: "Size",
                        AssetDimensions: "Dimensions",
                        AssetFrameRate: "Frame Rate",
                        AssetFormat: "Format",
                        AssetOwner: "Owner",
                        PrimaryBtn: "Next",
                        SecondaryBtn: "Cancel",
                        Tags: "Tags",
                        Suggested: "AI Smart Tags",
                        Collections: "Collections",
                        Comments: "Comments",
                        ViewMore: "+ more",
                        ViewLess: "- less",
                        MetaData: "Asset Information",
                        Dimensions: "Dimensions"
                    },
                    View: {
                        Return: "Return",
                        Next: "Next",
                        Previous: "Previous",
                        TagDesc: "File has the following tag(s):",
                        AiSmartTag: "AI Smart Tag(s):"
                    }
                },
                Upload: {
                    Title: "Upload",
                    Dropzone: "Drag and drop your files here<br/>or <span>browse</span> from your device",
                    ImageDropzone: "Drag and drop an image<br/>or <span>browse</span> from your device",
                    ImageDropzoneInfo: "JPG/PNG files up to 50MB only",
                    ImageDropzoneInfo2: "Right-click on an image, click Copy Image Address and paste link above",
                    MobileDropzone: "<span>Browse</span> from your device",
                    MobileImageDropzone: "<span>Browse</span> from your device",
                    MaxStorage: "Unable to upload as your storage is full",
                    UploadGuide: "Upload Guide",
                    Uploading: "Uploading",
                    Processing: "Processing",
                    Analysing: "Analysing",
                    Completed: "Uploaded",
                    Error: "Failed to upload",
                    StorageExceeded: "Upload failed: Storage space exceeded",
                    ErrorImageUpload: "Only JPG/PNG files up to 50MB allowed",
                    ImageUploadErrorInvalidUrl: "No image found. Try a different link",
                    Offcanvas: {
                        Title: "Review your files",
                        Description1: "<span>(Optional)</span> Select one or more file(s) and perform the following actions ",
                        Description2: "Click on “<span>Done</span>” when you are ready to store your files in the system",
                        Progress: {
                            Title: "Upload Progress",
                            CircularContent: {
                                Total: "Total Files",
                                Uploaded: "Uploaded",
                                Fail: "Failed to Upload"
                            },
                            Success: "All file(s) uploaded successfully",
                            SuccessDesc: "Your files are now stored in the system.",
                            Error: "Some file(s) failed to upload",
                            ErrorDesc: "You can still proceed to review, add or edit information on uploaded file(s) as shown on the left panel.",
                            Button: "Done",
                            CharLimitReach: "Character limit reached"
                        },
                        Error: {
                            Title: "Error detected in some file(s)",
                            Desc: "You can still proceed to add new files or edit existing files.",
                            Desc2: "Please try to upload your file(s) again.",
                            Desc3: "You can still proceed to add new files or edit existing files.",
                            Issues: {
                                Title: "Errors may arise due to",
                                Extension: "Invalid file extension",
                                Size: "File size exceeds maximum 50MB limit",
                                Same: "Same file name & extension",
                                Name: "Filename exceeds 256-character count."
                            },
                            Button: "Proceed"
                        }
                    },
                    Type: {
                        Image: "Image",
                        Gif: "Image (GIF)",
                        Doc: "Document",
                        Audio: "Audio",
                        Video: "Video"
                    },
                    Guide: {
                        Title: "Upload Guide",
                        Desc: "Follow this guide and upload your files in 3 simple steps",
                        Steps_1: {
                            Id: "select-file",
                            Header: "Step 1: Select",
                            Desc: "drag and drop your files into the upload box. Alternatively, click <span class='font-bold'>Browse</span> to select files from your device. There is no limit to the number of files that you can upload at a time so long as you keep within your storage limit.",
                            Img: {
                                src: "./images/upload-guide/001-upload.png",
                                alt: "Upload Step 1 Select",
                                desc: "Add one or more files in the upload box"
                            },
                            FileRequirementsId: "file-requirements",
                            FileRequirementsTitle: "File requirements",
                            FileRequirements: ["Individual file size should not exceed 50 MB; and",
                            "File extension should be compatible with the system requirements as listed below."],
                            FileRequirementsType: [
                                {
                                    name: "Images",
                                    type: [
                                        "JPG",
                                        "JPEG",
                                        "PNG",
                                        "GIF",
                                        "SVG",
                                        "TIFF"
                                    ]
                                }, {
                                    name: "Videos",
                                    type: [
                                        "MP4",
                                        //"AVI",
                                        "MOV"
                                    ]
                                }, {
                                    name: "Documents",
                                    type: [
                                        "PDF",
                                        "DOC",
                                        "DOCX",
                                        "XLS",
                                        "XLSX",
                                        "PPT",
                                        "PPTX"
                                    ]
                                }, {
                                    name: "Audio",
                                    type: [
                                        "MP3",
                                        "WAV"
                                    ]
                                }
                            ],
                            WorkspaceDesc: "Below the upload box is your workspace, where the file setttings panel and your files will appear. You can also choose to add more files by repeating the above step.",
                            WorkspaceImg: {
                                src: "./images/upload-guide/002-workspace.png",
                                alt: "Workspace Image",
                                desc: "Files will be displayed in your workspace below the upload box"
                            },
                            CommonErrorId: "file-errors",
                            CommonError: "Common upload errors",
                            CommonErrorDesc: "Errors may arise during upload. Refer to the table below on how to resolve the different kinds of error:",
                            CommonErrorTable: [
                                {
                                    name: "Error encountered",
                                    error: ["Invalid file extension",
                                            "Ensure your file extension conforms with those listed under the file requirements",
                                            "File size exceeds maximum 50 MB limit"]
                                }, {
                                    name: "Solution",
                                    error: ["Same filename and extension",
                                            "To prevent duplicate files, files with the same filename and extension cannot be added within the same upload batch. If you have a different file, rename and add it into the system again.",
                                            "Resize your file and keep it within 50 MB."]
                                }
                            ],
                            UploadError: "You will be notified on any upload errors. Files with errors will not be added into your workspace and the individual filenames will be listed. Click <span class='font-bold'>Proceed</span> to continue reviewing other files that have been added successfully.",
                            UploadErrorImg: {
                                src: "./images/upload-guide/003-error.png",
                                alt: "Upload Error Image",
                                desc: "Error message will be displayed on the right-side panel"
                            }
                        },
                        Steps_2: {
                            Id: "review-file",
                            Header: "Step 2: Review",
                            Desc: "You can now preview files, add or edit file information and delete unwanted files.",
                            AlertHeader: "Quick Tip",
                            AlertContent: "Navigating to other pages will not affect the files that you have added. You can return to the <span class='font-bold'>Upload</span> page and review your files at any time unless you log off and exit the current session. However, if you refresh the page, your files will be removed and you have to upload them again.",
                            SettingId: "file-setting",
                            SettingHeader: "Settings panel",
                            SettingDesc1: "The settings panel comprises of various functions that you can make use of when reviewing your files. Select at least one file to perform the actions (i.e. Edit, Collection and Delete).",
                            SettingDesc2: "The active view mode can be identified by the icon in black on the far-right of the settings panel. By default, files are displayed as cards in a grid view. Click the <span class='font-bold'>List View</span> icon if you prefer to view the files in a list or table format.",
                            SettingImg: {
                                src: "./images/upload-guide/004-settings-panel.png",
                                alt: "Setting Images",
                                desc: "Switch between view modes via the settings panel"
                            },
                            PreviewId: "preview-file",
                            PreviewHeader: "Preview & select files",
                            GridView: {
                                header: "Grid view",
                                desc: "Each file is shown as a card in your workspace. The card contains the file’s thumbnail, and icons for various functions (i.e. checkbox, enlarge view and delete) at the bottom. A generic icon will replace the thumbnail for audio files.",
                                img: {
                                    src: "./images/upload-guide/005-workspace-in-grid-view.png",
                                    alt: "Workspace Grid View",
                                    desc: "Workspace in grid view"
                                },
                                thumbnailDesc: "Hover over a file’s thumbnail to reveal the filename.",
                                thumbnailList: {
                                    header: "For video and audio,",
                                    list: ["The file will play automatically on mute when your cursor hovers over the thumbnail.",
                                            "Toggle the mute/unmute icon to switch between your preferred sound settings.",
                                            "The video/audio length on the bottom-right of the thumbnail will start counting down as the file plays.",
                                            "The file will reset once your cursor leaves the thumbnail."],
                                    img: {
                                        src: "./images/upload-guide/006-card-features.png",
                                        alt: "Card features Image",
                                        desc: "Common features and functions of a card"
                                    },
                                    select: {
                                        desc: "To select a file, you can either click the checkbox on the bottom-left corner of the card or its thumbnail. Selected files have a black border and a checked checkbox (tick symbol) within the card. Simply click the checkbox or thumbnail again to de-select the file.",
                                        img: {
                                            src: "./images/upload-guide/007-selected-file(grid-view).png",
                                            alt: "Selected file Image",
                                            desc: "Example of a selected file or card in grid view"
                                        },
                                    }
                                },

                            },
                            ListView: {
                                header: "List view",
                                desc: "Each file is shown in a row in your workspace. The row contains a checkbox, the file’s thumbnail, the filename along with its extension, and file type. A generic icon will replace the thumbnail for audio files. Icons for various functions (i.e. enlarge view and delete) are located under the Actions column. Unlike in grid view, there are no interactions when you hover over the thumbnail.",
                                img: {
                                    src: "./images/upload-guide/008-workspace-in-list-view.png",
                                    alt: "Workspace List View",
                                    desc: "Workspace in list view"
                                },
                                sortDesc1: "To select a file, you can either click the checkbox or its thumbnail. Selected files have a checked checkbox (tick symbol). Simply click the checkbox or thumbnail again to de-select the file.",
                                sortDesc2: "You can click the <span class='font-bold'>Sort</span> icon (up and down arrow) to re-arrange files by filename or file type (Image/Video/Document/Audio) in ascending alphabetical order. Click again to re-arrange the files in descending alphabetical order.",
                                sortImg: {
                                    src: "./images/upload-guide/009-selected-file(list-view).png",
                                    alt: "Selected file",
                                    desc: "Example of a selected file or row in list view"
                                },
                                alertHeader: "Quick Tip",
                                alertContent: "Select multiple files by clicking the respective checkboxes or thumbnails, or via the <span class='font-bold'>Select all</span> function. This allows you to perform bulk actions such as adding descriptions to multiple files quickly."
                            },
                            SelectAll: {
                                header: "Select all",
                                id: "select-all-file",
                                desc1: "Be default, no files are selected and the <span class='font-bold'>Select all</span> checkbox is unchecked. Click the box once to select all files. The <span class='font-bold'>Select all</span> checkbox becomes checked (tick symbol) and the checkboxes of all the files in your workspace become checked too. Click again to de-select all files and return to <span class='font-bold'>Default</span> mode.",
                                desc2: "If you have only selected some of your files, the checkbox shows a dash (-) symbol to indicate  <span class='font-bold'>Partial Selection</span>. The number of files selected is listed next to the checkbox. Click the checkbox again to switch to the <span class='font-bold'>All Selected</span> mode where all files are selected.",
                                img: {
                                    src: "./images/upload-guide/010-select-all.png",
                                    alt: "Select All Image",
                                    desc: "Various selection modes depending on the number of files selected"
                                }
                            },
                            Edit: {
                                header: "Edit",
                                id: "edit-file",
                                desc1: "Select at least one file and click <span class='font-bold'>Edit</span> in the file settings panel.",
                                filenameList: {
                                    header: "Filename",
                                    list: ["Click in the field to edit the filename.",
                                            /*`Filename cannot contain the following special characters: <span class='font-bold'><>'":;[\]{}|\\+=/?.,</span>`,*/
                                            "Filename cannot exceed 256 characters. You can refer to the character counter on the bottom-right of the field to view the number of characters used.",
                                            "Filename cannot be empty. The system will automatically revert to the last input if you exit an empty field.",
                                            "Bulk editing of filenames across multiple files is not allowed."]
                                },
                                descriptionList: {
                                    header: "Description",
                                    list: ["Description is optional. You may leave the field empty.",
                                            "Click in the field to add a description.",
                                            "Description cannot exceed 300 characters. You can refer to the character counter on the bottom-right of the field to view the number of characters used.",
                                            "Bulk editing of description is allowed. You can add replace or remove descriptions across multiple files."]
                                },
                                img: {
                                    src: "./images/upload-guide/011-edit-filename-and-description.jpg",
                                    alt: "File name description",
                                    desc: "Click into a field and start typing. A character counter will automatically appear on the bottom-right of the field."
                                }
                            },
                            Tag: {
                                header: "Tag",
                                id: "tag-file",
                                desc: "Tags allow you to label and organise your files. Applying relevant and concise keywords makes it easier for users to find these files in the system later. Our Artificial Intelligence (AI) system will automatically detect and tag your files after upload is complete. Nevertheless, you can enhance tagging by manually tagging your files.",
                                individual: {
                                    header: "Individual file",
                                    list: [
                                            "Tag is optional. You may leave the field empty.",
                                            "Click in the field to add a tag.",
                                            "Press the <span class='font-bold'>comma</span> button or <span class='font-bold'>Enter</span> button to add a tag to the selected file.",
                                            `Tag cannot contain the following special characters: <span class='font-bold'><>'":;[\]{}|\\+=/?.,</span>`,
                                            "Tag cannot exceed 50 characters. You can refer to the character counter on the bottom-right of the field to view the number of characters used.",
                                            "Tag is not case-sensitive. You can add “Coffee”, “COFFEE” or “coffee” as tags. However, duplicate tags will be replaced. For example, if you have added “Coffee” as a tag and try to add “coffee” as another tag, the system will recognise “coffee” as the latest tag and remove “Coffee”.",
                                            "Once a tag is successfully added, it will appear under the <span class='font-bold'>File has the following tag(s)</span> section. You can click the <span class='font-bold'>X</span> icon to individually remove tags or the <span class='font-bold'>Clear All</span> button to remove all tags added to the file."
                                        ]
                                },
                                multiple: {
                                    header: "Multiple files",
                                    list: [
                                            "Bulk tagging is allowed. You can add or remove tags across multiple files.",
                                            "Tags that are added to all selected files will appear under the <span class='font-bold'>All files have the following tag(s)</span> section.",
                                            "Tags that are added to some of the selected files will appear under the <span class='font-bold'>Some files have the following tag(s)</span> section."
                                        ],
                                    nested: [
                                        "You can distinguish the files that each tag is added to. When your cursor hovers over a tag, a tooltip appears above the tag to show the number of file(s) this tag has been added to. Click the tag if you wish to add the tag to all selected files.",
                                        "Grid view: Upon hovering over the tag, a file with this tag has a thick purple border around its card in the workspace.",
                                        "List view: Upon hovering over the tag, a file with this tag is enclosed within a light purple rectangle in the workspace.",
                                        "You can remove the tag(s) by clicking the <span class='font-bold'>X</span> icon or <span class='font-bold'>Clear All</span> button."
                                    ],
                                },
                                img: {
                                    grid: {
                                        header: "Grid view",
                                        src: "./images/upload-guide/012a-bulk-tagging(grid-view).png",
                                        alt: "Grid View",
                                        desc: "In this example, both DSCF_0130.jpg and DSCF_0110.mp4 have the tag “Sky”. However, only DSCF_0110.mp4 has the tag “Greenery”. Upon hovering over “Greenery”, the file has a thick purple border around its card in the workspace.",
                                    },
                                    list: {
                                        header: "List view",
                                        src: "./images/upload-guide/012b-bulk-tagging(list-view).png",
                                        alt: "List View",
                                        desc: "In this example, both DSCF_0130.jpg and DSCF_0110.mp4 have the tag “Sky”. However, only DSCF_0110.mp4 has the tag “Greenery”. Upon hovering over “Greenery”, the file is enclosed within a light purple rectangle in the workspace.",
                                    }
                                },
                                alertHeader: "Quick Tip",
                                alertContent: "Use simple and specific terms in your tags to generate meaningful search results. For example, use “Merlion” instead of “statue” as a tag for an image of the popular tourist landmark in Singapore."
                            },
                            Collection: {
                                header: "Collection",
                                id: "file-collection",
                                desc: "Collections are a great way for you to group and store related assets. For instance, you may wish to create a collection centred on a particular theme (e.g. Travel) or a collection containing all files related to a specific work project. You can either add file(s) to existing collections or create a new collection during the review phase.<br/><br/>Select at least one file and click Collection in the file settings panel.",
                                individual: {
                                    header: "Individual file",
                                    list: [
                                            "Collection is optional. You may leave the field empty.",
                                            "Click in the field or on the dropdown arrow to open the collection dropdown list. The dropdown list shows existing collections in alphabetical order.",
                                            "Alternatively, you can directly type in the field to search for an existing collection.",
                                            "You can add a file to a collection by clicking the checkbox next to the collection name or anywhere within the collection label.",
                                            "Once the checkbox is checked (tick symbol), the file is added to that collection and the collection appears under <span class='font-bold'>File is in the following collection(s)</span> section. You can click the <span class='font-bold'>X</span> icon to individually remove collections or the <span class='font-bold'>Clear All</span> button to remove all collections the file was added to.",
                                            "You can also remove a file from a particular collection by un-checking the collection’s checkbox."
                                        ],
                                    img: {
                                        src: "./images/upload-guide/012-collection.jpg",
                                        alt: "Collection individual file image",
                                        desc: "Search or choose from the list of existing collections and add your files to these collections"
                                    }
                                },
                                multiple: {
                                    header: "Multiple files",
                                    list: [
                                        "Bulk action is allowed. You can add or remove collections across multiple files.",
                                        "Collections that all selected files are added to will appear under the <span class='font-bold'>All files are in the following collection(s)</span> section. Under the dropdown list, the checkboxes of these collections will be checked (tick symbol).",
                                        "Collections that some of the selected files are added to will appear under the <span class='font-bold'>Some files are in the following collection(s)</span> section."
                                    ],
                                    nested: [
                                        "You can distinguish the files added to each collection. When your cursor hovers over a collection, a tooltip appears above the collection to show the number of file(s) added to this collection. Click the collection if you wish to add all selected files to this collection.",
                                        "Grid view: Upon hovering over the collection, a file in this collection has a thick orange border around its card in the workspace.",
                                        "List view: Upon hovering over the collection, a file in this collection is enclosed within a light orange rectangle in the workspace.",
                                        "Under the dropdown list, the checkbox shows a dash (-) symbol. Click the checkbox to add selected files to the collection.",
                                        "You can remove the collection(s) by clicking the <span class='font-bold'>X</span> icon or <span class='font-bold'>Clear All</span> button."
                                    ],
                                    img: {
                                        grid: {
                                            header: "Grid view",
                                            src: "./images/upload-guide/013a-bulk-collection-grid-view.jpg",
                                            alt: "Collection multiple file grid image",
                                            desc: "In this example, both files are in the “Travel” collection. However, only the second file is in the “Greenery” collection. Upon hovering over “Greenery”, the file has a thick orange border around its card in the workspace."
                                        },
                                        list: {
                                            header: "List view",
                                            src: "./images/upload-guide/013b-bulk-collection-list-view.jpg",
                                            alt: "Collection multiple file list image",
                                            desc: "In this example, both files are in the “Travel” collection. However, only the second file is in the “Greenery” collection. Upon hovering over “Greenery”, the file is enclosed within a light orange rectangle in the workspace."
                                        },
                                    }
                                },
                                create: {
                                    header: "Create a new collection",
                                    list: [
                                        "Click in the field or on the dropdown arrow to open the dropdown list. Next, click <span class='font-bold'>Create New Collection</span> found at the bottom of the dropdown list.",
                                        "A field appears and you can type and name the new collection.",
                                        `Collection name cannot contain the following special characters: <span class='font-bold'><>'":;[\]{}|\\+=/?.,</span>`,
                                        "Collection name cannot exceed 50 characters. You can refer to the character counter on the bottom-right of the field to view the number of characters used.",
                                        "Click <span class='font-bold'>Create</span> once you are satisfied with the collection name. If you do not wish to create a new collection, click <span class='font-bold'>Cancel</span> instead.",
                                        "A new collection will be created and your selected file(s) will be automatically added to the new collection. Under the dropdown list, the checkbox for the new collection will be checked (tick symbol).",
                                        "Collection names are unique. If a collection name already exists, you will be prompted to choose a different name when creating a new collection."
                                    ],
                                    img: {
                                        src: "./images/upload-guide/014-create-new-collection.png",
                                        alt: "Create Collection image",
                                        desc: "Create new collections whenever you need them"
                                    }
                                }
                            },
                            Delete: {
                                header: "Delete",
                                id: "delete-file",
                                desc1: "You can delete and remove unwanted files that you no longer wish to store in the system by:",
                                desc2: "A confirmation pop-up will appear. Click <span class='font-bold'>Delete</span> to proceed with deletion. Click <span class='font-bold'>Cancel</span> to close the pop-up without removing any files.",
                                list: [
                                    "Clicking the bin icon on the cards in grid view; or",
                                    "Clicking the bin icon under the far-right <span class='font-bold'>Actions</span> column in list view; or",
                                    "Selecting one or more files and click the <span class='font-bold'>Delete</span> button in the settings panel."
                                ]
                            }
                        },
                        Steps_3: {
                            Header: "Step 3: Upload",
                            Id: "upload-file",
                            Desc: "Once you are done reviewing your files, click <span class='font-bold'>Upload Files</span> to begin transferring the files and information to the system. All files within your workspace, regardless whether they are selected or not, will be uploaded. The total number of files is indicated in the upload button.",
                            Img: {
                                src: "./images/upload-guide/015-upload-button.png",
                                alt: "Upload button",
                                desc: "Upload the entire batch of files to the system with just a single click"
                            },
                            Status: {
                                desc1: "You can view the upload status of each file. The progress circle icon turns into a green tick icon when the file is successfully uploaded.",
                                desc2: "The right-side panel shows the overall upload progress for the entire batch of files including the number and percentage of files uploaded and those that failed to upload.",
                                list: [
                                    "Grid view: The progress circle can be found on the bottom-left of the card.",
                                    "List view: The progress circle can be found under the far-right <span class='font-bold'>Status</span> column."
                                ],
                                img: {
                                    grid: {
                                        header: "Grid view",
                                        src: "./images/upload-guide/016a-upload-progress-grid-view.png",
                                        alt: "Upload Status Grid View",
                                        desc: "Check the progress circle in the card to determine the upload status of each file"
                                    },
                                    list: {
                                        header: "List view",
                                        src: "./images/upload-guide/016b-upload-progress-list-view.png",
                                        alt: "Upload Status List View",
                                        desc: "Check the progress circle in the last column to determine the upload status of each file"
                                    },
                                }
                            },
                            Done: {
                                desc: "Once the entire batch has been processed and uploaded, click <span class='font-bold'>Done</span> to return to the <span class='font-bold'>Explore</span> page. Your files are now stored and ready to be viewed by other users.",
                                img: {
                                    src: "./images/upload-guide/017-upload-success.png",
                                    alt: "Done upload image",
                                    desc: "Overall upload success"
                                }
                            },
                            Error: {
                                desc: "In the unlikely event of a processing error, files may fail to upload and an error icon is shown in place of the green tick icon. You will be notified on the list of files that failed to upload. Check and ensure your files have not been moved, renamed or changed, and try to upload them again.",
                                img: {
                                    src: "./images/upload-guide/018-upload-failure.png",
                                    alt: "Error upload image",
                                    desc: "Processing error may occur during the final upload stage"
                                }
                            }
                        },
                        TableContent: {
                            header: "Table of Content",
                            steps_1: [
                                {
                                    name: "Step 1: Select",
                                    id: "select-file",
                                    bold: true
                                },
                                {
                                    name: "File requirements",
                                    id: "file-requirements"
                                },
                                {
                                    name: "Common upload errors",
                                    id: "file-errors"
                                }
                            ],
                            steps_2: [
                                {
                                    name: "Step 2: Review",
                                    id: "review-file",
                                    bold: true
                                },
                                {
                                    name: "Settings panel",
                                    id: "file-setting"
                                },
                                {
                                    name: "Preview & select files",
                                    id: "preview-file"
                                },
                                {
                                    name: "Select all",
                                    id: "select-all-file"
                                },
                                {
                                    name: "Edit",
                                    id: "edit-file"
                                },
                                // {
                                //     name: "Tag",
                                //     id: "tag-file"
                                // },
                                {
                                    name: "Collection",
                                    id: "file-collection"
                                },
                                {
                                    name: "Delete",
                                    id: "delete-file"
                                }
                            ],
                            steps_3: {
                                name: "Step 3: Upload",
                                id: "upload-file",
                                bold: true
                            },
                        }
                    }
                },
                Edit: {
                    Title: "Edit",
                    FileDescPlaceholder: 'Add or replace all descriptions',
                    Restricted: "Restrictedzeld Use",
                    Comments: "Allow User Comments",
                    NoFilesSelected: "No files selected",
                    SelectAFile: "Select a file to begin editing"
                },
                Tag: {
                    Title: "Tag",
                    TagPlaceholder: 'Separate tags with commas or press Enter',
                    SelectAFile: "Select a file to begin adding and editing tag(s)",
                    TagsDesc: 'File has the following tag(s): ',
                    TagsAllDesc: 'All files have the following tag(s):',
                    NoTags: 'No tags yet',
                    NoTagsDesc: 'Add a tag in the field above',
                    StartAddingTags: 'Add a Tags in box above',
                    SomeFileTags: "Some files have the following tag(s):",
                    ClearAll: "Clear All",
                    AddAll: "Add All",
                    AISystem: "Our AI system will automatically detect and tag your files after upload is complete. You can enhance tagging by adding your preferred tags below.",
                    AISmartTagTitle: 'AI Smart Tag(s):'
                },
                Collection: {
                    Title: "Collections",
                    Name: "Collection",
                    NoCollections: "No collections yet",
                    NoCollectionsDesc: "Get started by adding your assets into Collections during Upload!",
                    NoCollectionsBtn: "Upload Files",
                    SelectAFile: "Select a file to begin adding to collection(s)",
                    NoFilesSelected: "No files selected",
                    CollectionHeader: "All files are in the following collection(s):",
                    NoCollectionDesc: ["No collections yet", "Search an existing collection or create a new collection below"],
                    CollectionPlaceholder: 'Collection',
                    CreateNewCollection: 'Create New Collection',
                    NewCollectionName: 'Collection Name',
                    NewCollectionPrimaryBtn: 'Create',
                    NewCollectionSecondaryBtn: 'Cancel',
                    SomeFileCollection: 'Some files are in the following collection(s):',
                    ClearAll: "Clear All",
                    AddAll: "Add All",
                    NoAssets: "No Assets",
                },
                Modal: {
                    Delete: {
                        Title: "Delete",
                        Desc: "Are you sure you want to delete",
                        Desc1: "Are you sure you want to delete the asset listed below? Once deleted, it will be removed from your workspace and users can no longer view or download it. This action cannot be undone.",
                        DescPaymentMethod: "Are you sure you want to delete this payment method?",
                        ConfirmButton: "Delete",
                        CancelButton: "Cancel"
                    },
                    Logout: {
                        Title: "Logout",
                        Desc: "Are you sure you want to logout",
                        ConfirmButton: "Log Out",
                        CancelButton: "Cancel"
                    },
                    DeleteCollection: {
                        Title: "Delete collection",
                        Desc1: "Are you sure you want to delete this collection",
                        Desc2: "Users can still view or download the assets from the workspace.",
                        Desc3: "This action cannot be undone."
                    },
                    VerifyIdentity: {
                        Title: "Verify identity",
                        Desc1: "For security purposes, we need to verify your identity first. Enter your password below."
                    },
                    Reset2fa: {
                        Title: "Reset 2FA",
                        Desc1: "Are you sure you want to reset the two-factor authentication (2FA) credentials for ${username}? The user will be prompted to set up 2FA again the next time they log in. This action cannot be undone."
                    }
                },
                Alerts: {
                    UploadS3Error: 'fail to upload file to S3 bucket...',
                    DeleteS3Error: 'fail to delete file to S3 bucket...',
                    ProcessError: 'fail to process & analzye file...',
                    CreateUuidError: 'fail to create an unique uuid...',
                    ComputeChecksumError: 'fail to compute MD5 checksum...',
                    NoValidFile: 'No valid file has been imported.',
                    FileCannotImported: 'A few files cannot be imported.',
                    FileNameError: 'Bulk editing of filenames is not allowed. Select a file and edit individually.',
                    NewCollectionNameError: 'Field cannot be empty',
                    NewCollectionNameDuplication: 'Collection already exists',
                    NoCollectionFound: 'No Collection Found',
                    noExistingCollection: 'No existing collections',
                    MixedSetting: "Mixed settings detected. Click to change terms of use across all selected files.",
                    MixedOwner: "Multiple owners detected. Hover over a name to identify files under the owner. Click on a name to assign all selected files to the same owner.",
                    MixedTerms: "Multiple terms of use detected. Hover to identify files under the terms of use. Click to assign all selected files to the same terms of use.",
                },
                Login: {
                    LoginResponse: {
                        NotAuthorizedException: "Invalid username or password",
                        UserNotFoundException: "Your credentials are invalid. Please register with your administrator.",
                        PasswordAttemptsExceeded: "Account temporarily locked due to multiple failed login attempts. Please try again later.",
                    },
                    Guide2fa: {
                        Setup2fa: {
                            step_0: {
                                Title:"Two-Factor Authentication (2FA)",
                                Desc1:"For security purposes, all users are required to set up Two-Factor Authentication (2FA).",
                                Button1:"Set up 2FA",
                            },
                            step_1: {
                                Title:"Download and open an authentication app",
                                Desc1:"Open an authentication app of your choice (e.g. Google Authenticator, Microsoft Authenticator).",
                                Button1:"Next",
                            },
                            step_2: {
                                Title:"Scan QR code",
                                Desc1:"Can’t scan QR code? Key the code below in your authentication app.",
                                Button1:"Next",
                            },
                            step_3: {
                                Title:"Enter verification code",
                                Button1:"Next",
                            },
                            step_4: {
                                Title:"2FA Setup Success",
                                Desc1:"Your two-factor authentication (2FA) was set up successfully.",
                                Desc2:"Store your backup codes safely",
                                Desc3:"If you lose your device or have issues generating 2FA codes, you can use a backup code to access your account. Each code can only be used once.",
                                Button1:"Done",
                            }
                        },
                        Verification2fa: {
                            verify_code: {
                                Title: "Verify your identity",
                                Desc1: "Enter the code shown in your authentication app below.",
                                Button1: "Next"
                            },
                            backup_code: {
                                Title: "Enter backup code",
                                Desc1: "Enter one of your unique backup codes below.",
                                Button1: "Verify"
                            }
                        }
                    }
                },
                Profile: {
                    Title: "Profile",
                    TwoFA: {
                        Title: "Two-Factor Authentication (2FA)",
                        Desc: "If you lose your device or have issues generating 2FA codes, you can use a backup code to access your account.",
                        Button: "See Backup Codes",
                        Modal: {
                            Title: "Verify identity",
                            Desc: "For security purposes, we need to verify your identity first. Enter your password below.",
                            VerifyButton: "Verify",
                            CancelButton: "Cancel",
                            BackupCodes: {
                                Title: "Backup codes",
                                Desc: "Each code can only be used once. Keep these codes somewhere safe.",
                                GenerateNewCodesButton: "Generate new codes",
                                CloseButton: "Close"
                            }
                        }
                    },
                    ChangePasswordRespose: {
                        Successful: "You have successfully changed your password.",
                        NotAuthorizedException: "Incorrect username or password",
                        LimitExceededException: "Attempt limit exceeded, please try after some time.",
                        InvalidParameterException: "1 validation error detected",
                        NotAuthorizedExceptionForChangePassword: "Current Password not correct",
                    },
                },
                ForgotPassword: {
                    ForgotPasswordMessage: "Enter your username below. An email containing instructions to reset your password will be sent to the email address linked to your username shortly.",
                    SuccessfulPasswordChangeMessage: "Please log in to the system with your username and new password.",
                    VerificationMeassge: "Check your inbox for a 6-digit verification code and enter it below. Make sure to check your spam or junk folder as well.",
                    ForgotPasswordRespose: {
                        InvalidParameterException: "Cannot reset password for the user as there is no registered/verified email.",
                        UserNotFoundException: "This username is not registered in the system.",
                        LimitExceededException: "Attempt limit exceeded, please try after some time.",
                        VerificationCodeInvalidMeassge: "Invalid verification code",
                    },
                    ConfirmPasswordResponse: {
                        CodeMismatchException: "Invalid verification code",
                        LimitExceededException: "Attempt limit exceeded, please try after some time.",
                    },
                    ResendCodeResponse : {
                        Successful: "New verification code sent",
                    }
                },
                Registration: {
                    Title: "Register",
                    SuccessTitle: "Registration Completed",
                    SuccessDesc: "Thank you for registering. An acknowledgment email with more information will be sent to you shortly. If you did not receive the email within 10 minutes, check your spam folder.",
                    ErrorEmail: {
                        name: "",
                        error: "Invalid email address format"
                    },
                    ExistUserName: {
                        name: "UsernameExistsException",
                        error: "Username already exists"
                    },
                    ExistEmail_UserName: {
                        name: "UsernameEmailExistsException",
                        error: "Email address and username already exist"
                    },
                    ExistEmail: {
                        name: "EmailExistsException",
                        error: "Email address already exists"
                    },
                    ErrorEmailFormat: "Invalid email address format",
                    ErrorUserName: {
                        email: "Email format not permitted",
                        specialChar: "Special characters not permitted",
                    },
                    ErrorEmailVerification: {
                        name: "EmailVerificationException",
                        error: "Email address already exists"
                    },
                    ErrorInvalid: {
                        name: "InvalidParameterException",
                        error: "The provided input contains one or more invalid parameters. Please check your input and try again."
                    },
                    ErrorReenterPassword: "Passwords do not match",
                    ErrorUsernameLimit: "Character limit reached"
                },
                UserVerifyEmail: {
                    ErrorVerifyEmail: {
                        name: "EmailVerifiedException",
                        error: "Invalid verification code"
                    },
                    ErrorEmailCode: {
                        name: "InvalidEmailCodeException",
                        error: "Invalid verification code"
                    },
                    ErrorInvalidSubscriptionPlan: {
                        name: "InvalidSubscriptionPlanException",
                        error: "Invalid verification code"
                    },
                    ErrorCode: "Invalid verification code",
                    SuccessResendCode: "New verification code sent to your inbox",
                },
                Settings: {
                    UserRequest: {
                        Offcanvas: {
                            ApproverUserRequest: {
                                Title: "Approve user request",
                                SingleUserDesc: "Are you sure you want to approve the user request listed below? Additional users will be charged.",
                                MultiUserDesc: "Are you sure you want to approve the ${totalUserRequest} user requests as listed below? Additional users will be charged.",
                                SmallDesc: "Don’t worry, you will not be charged yet!",
                                Button: "Approve user ${request}",
                                //MultipleUserButton: "Approve user requests"
                            },
                            OrderSummary: {
                                Title: "Order Summary",
                                DescSG: "A pro-rated amount will be charged for each additional user. Thereafter, you will be charged <b>US$10 per user per month (incl. GST)</b> from the next billing month.",
                                DescOverseas: "A pro-rated amount will be charged for each additional user. Thereafter, you will be charged <b>US$10 per user per month</b> from the next billing month.",
                                SmallDesc: "The amount will be directly deducted from your default paying card.",
                                Button: "Make payment",
                                Success: {
                                    Title: "Successful payment",
                                    SingleUserDesc: "Your payment has been processed. ${totalUserRequest} user has been added to your elko workspace.",
                                    MultipleUsersDesc: "Your payment has been processed. ${totalUserRequest} users have been added to your elko workspace.",
                                    Button: "Done"
                                },
                                Error: {
                                    Title: "Unable to process payment",
                                    Desc: "Check and ensure your default payment card is valid. You can update your payment method under Plans.",
                                    Button: "Done"
                                }
                            }
                        },
                        NoUserRequest: "No new user requests",
                        NoUserRequestDesc: "New user requests will appear here for your review",
                        NoUsersSelected: "Select at least one user to reject",
                        NoUsersSelectedApprove: "Select at least one user to approve",
                        NoRolesSelected: "Select a role for the user(s)",
                        NoUserRequestApprovedDesc: "User requests approved in the last 30 days will appear here",
                        NoUserRequestRejectedDesc: "User requests rejected in the last 30 days will appear here",
                        AlertNotificationApproved: "Only requests approved in the last 30 days will appear below",
                        AlertNotificationRejected: "Only requests rejected in the last 30 days will appear below",
                        CustomerAdmin: "Note: Customer Admin has all rights to the system.",
                        AssetOwner: "Note: Asset Owner automatically has rights of Asset Approver.",
                        NoResult: "No users found",
                        NoResultFilter: "No results found",
                        NoResultDesc: [
                            "Check and ensure your spelling is correct",
                            "Try a different search"],
                        NoResultDescDate: [
                            "Check and ensure your inputs and selections are valid",
                            "Try inputting and/or selecting other valid Filters options"]
                    },
                    UserList: {
                        DeleteSingleUser: "Are you sure you want to delete the user listed below?<br/>The user will no longer be able to access the system and the action cannot be undone.",
                        MobileDeleteSingleUser: "Are you sure you want to delete ${NameDeleted}? The user will no longer be able to access the system and the action cannot be undone.",
                        MobileDeleteBulkUser: "Are you sure you want to delete ${totalDeleted} users? The user will no longer be able to access the system and the action cannot be undone.",
                        DeleteBulkUsers: "Are you sure you want to delete the ${totalDeleted} users listed below?<br/>The users will no longer be able to access the system and the action cannot be undone.",
                        DeleteSingleUserPayment: "Are you sure you want to delete the user listed below?<br/>The user will no longer be able to access the system and the action cannot be undone. Any unused time will be pro-rated and credited towards your next bill.",
                        MobileDeleteSingleUserPayment: "Are you sure you want to delete ${NameDeleted}? The user will no longer be able to access the system and the action cannot be undone. Any unused time will be pro-rated and credited towards your next bill.",
                        DeleteBulkUserPayment: "Are you sure you want to delete the ${totalDeleted} users listed below?<br/>The users will no longer be able to access the system and the action cannot be undone. Any unused time will be pro-rated and credited towards your next bill.",
                        MobileDeleteBulkUserPayment: "Are you sure you want to delete ${totalDeleted} users? The users will no longer be able to access the system and the action cannot be undone. Any unused time will be pro-rated and credited towards your next bill.",
                    },
                    Roles: {
                        CustomerAdmin: "Customer Admin has all rights to the system.",
                        AssetOwner: "Asset Owner can upload, edit and delete assets.",
                        EndUser: "End User can only view and download assets."
                    }
                },
                Welcome: {
                    Title: "Welcome to elko",
                    Desc: "Start uploading your digital assets and inviting your teammates to your workspace",
                    LogIn: "Log in to workspace"
                },
                TenantVerifyEmail: {
                    Title: "Email verification success",
                    Desc: "Start uploading your digital assets and inviting your teammates to your workspace",
                    LogIn: "Log in to workspace"
                },
                Plan: {
                    FreeTrial: {
                        Title: "Free Trial",
                        Desc: "Need more time to explore elko? Extend your trial by another 7 days for free. <br /> All data and files will be removed at the end of the free trial and are not transferrable to any paid plan.",
                        MobileDesc: "Need more time to explore elko? Extend your trial by another 7 days for free. <br /> <br /> All data and files will be removed at the end of the free trial and are not transferrable to any paid plan.",
                        TrialDesc: "Sign up for a paid plan below to enjoy full access to elko throughout your subscription period. <br /> All data and files will be removed at the end of the free trial and are not transferrable to any paid plan.",
                        MobileTrialDesc: "Sign up for a paid plan below to enjoy full access to elko throughout your subscription period. <br /><br /> All data and files will be removed at the end of the free trial and are not transferrable to any paid plan.",
                        Btn: "Extend free trial",
                        StartDate: "Start date",
                        EndDate: "End date",
                        BillingAmount: "Billing amount",
                        NumberUsers: "Number of users",
                        DaysRemaining: "Days remaining",
                        TimeRemaining: "Time remaining",
                        TrialExtension: "Trial extension remaining",
                        FreeTrialAmount: "US$0 (Free)",
                        AlertContent: "All data and files will be removed at the end of the free trial and are not transferrable to any paid plan",
                        PricingTitle: "Pricing",
                        ProductPriceBtn: "Register",
                        TrialExtensionSuccess: "Free trial extended successfully",
                        TrialExtensionError: {
                            name: 'TrialExtensionLimitedException',
                            error: 'You have reach the limit of free trial extension.'
                        },
                        NotificationBanner: {
                            CustomerAdmin: "Your free trial ends in ${trial_days_remaining}. Register a paid plan or extend your free trial today.",
                            Extended: "Your free trial ends in ${trial_days_remaining}. Register a paid plan to enjoy full access to elko.",
                            User: "Your free trial ends in ${trial_days_remaining}",
                        }
                    },
                    PaidPlan: {
                        NumberUsers: "Number of users",
                        SubscriptionTerm: "Subscription term",
                        MonthlyTerm: "Monthly",
                        YearlyTerm: "Annual (1-year)",
                        StartDate: "Start date",
                        ExpiryDate: "Expiry date",
                        RenewalDate: "Renewal date",
                        Credit: "Credit",
                        AmountDue: "Amount Due",
                        AmountDueModal: "Amount due",
                        BillingAmount: "Billing amount",
                        CancellationDate: "Cancellation date",
                        SubscriptionDesc: "Your monthly subscription will automatically renew unless you cancel your subscription before <span>${renewalDate}</span>. The subscription amount will be automatically deducted from your default payment card.",
                        SubscriptionExpiryDesc: "Your monthly subscription has been cancelled. You will have access to your workspace until your subscription expires on <span>${expiryDate}</span>.",
                        PaymentMethod: "Payment method",
                        AddPaymentMethod: "Add payment method",
                        Cancel: "Cancel",
                        SetAsDefault: "Set as default",
                        CardNumber: "Card number",
                        ExpirationDate: "Expiration date",
                        CVC: "CVC",
                        DefaultPaymentMethod: "Default payment method updated",
                        NewPaymentMethod: "New payment method added",
                        PaymentMethodError: "Your card cannot be processed",
                        PaymentMethodDeleted: "Payment method deleted",
                        CancelSubscription: {
                            Title: "Cancel subscription",
                            Desc: "Upon cancellation, you will continue to have access to your workspace until your subscription expires on <span class='font-bold'>${expiryDate}</span>. You may wish to transfer and store your files elsewhere before your workspace expires. Any remaining credits will be forfeited.",
                            CancelMySubscription: "To proceed with cancellation, type <span class='font-bold'>Cancel my subscription</span> in the field below.",
                            Input: {
                                Label: "Cancel subscription?",
                                Placeholder: "Cancel my subscription"
                            },
                            Button: {
                                Primary: "Cancel subscription",
                                Secondary: "Keep subscription"
                            }
                        },
                        Tooltip: {
                            Credit: "Credit for unused time will be used to offset next billing amount",
                            AmountDue: "Amount due = \nNext billing amount - Credit",
                            MobileBtn: "Got it"
                        }
                    },
                    Storage: {
                        Title: "Storage",
                        Desc: "You have used up <b>${storagePercentage}</b> of your <b>${maxStorageSize}</b> storage space",
                        Used: "Used",
                        Remaining: "Remaining"
                    }
                },
                AuditTrail: {
                    Category: {
                        ActivityTypes: {
                            plan: {
                                add: "Added payment method",
                                delete: "Deleted payment method",
                                change: "Changed default payment method to",
                                cancel: "Cancelled subscription plan",
                                extend: "Extended free trial"
                            },
                            collection: {
                                create: "Created",
                                edit: "Edited collection name from",
                                delete: "Deleted"
                            },
                            asset: {
                                upload: "Uploaded",
                                delete: "Deleted",
                                edit: "Edited",
                                download: "Downloaded"
                            },
                            user: {
                                approve: "Approved",
                                reject: "Rejected",
                                edit: "Edited role of",
                                delete: "Deleted"
                            },
                            account: {
                                login: "Logged in to workspace",
                                logout: "Logged out of workspace",
                                edit: "Edited profile",
                                changePassword: "Changed password successfully",
                                resetPassword: "Reset password successfully",
                                setup2fa: "Set up 2<span class='text-uppercase'>FA</span> successfully",
                                reset2fa: "Reset 2<span class='text-uppercase'>FA</span> of"
                            }
                        }
                    },
                    UserRoles: {
                        customer_admin: "Customer Admin",
                        asset_owner: "Asset Owner",
                        end_user: "End User"
                    }
                },
                BookMark: {
                    Main: {
                        Title: "Bookmarks",
                        NoBookmarks: "No bookmarks yet",
                        NoBookmarksDesc: "Discover and save assets that inspire you"
                    },
                    Modal: {
                        Title: "Bookmark to...",
                        Button: {
                            Cancel: "Cancel",
                            Create: "Create",
                            Creating: "Creating",
                            Done: "Done"
                        },
                        Input: {
                            Label: "Folder",
                            Placeholder: "Enter folder name"
                        }, 
                        Alert: {
                            TitleExist: "Folder already exists"
                        },
                        DeleteModal: {
                            Title: "Delete folder",
                            Desc1: "Are you sure you want to delete this folder",
                            Desc2: "This action cannot be undone."
                        },
                    },
                    AssetList: {
                        NoAssetsTitle: "No assets",
                        NoSavedAssets: "No assets yet",
                        NoAssetDesc: "Discover and save assets that inspire you"
                    }
                }
            }
        }
    }
}