import { useEffect, useContext } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { SessionContext } from "./components/shared/context/session-provider";
import path from "./components/shared/routePaths";
import SubDomainService from "./services/api/subDomainService";
import SolutionManifest from "./solution-manifest";

function CheckSubdomain() {
    // Session Context
    const { refreshSession,
        handleRefreshError,
        setHasSubdomain,
        setCheckAuthenticated,
        setIsAuthenticated,
        setUserPoolID,
        setClientID,
        setTenantUuid,
        setProductUrl,
        setSubdomainName,
        setUserPool,
        setGroupsRoles,
        setIdentityPoolID,
        setCompanyName
    } = useContext(SessionContext);

    const checkAndSetSubDomain = async () => {
        try {
            const currentHOST = window.location.host;
            const subDomain = currentHOST.split('.')[0];
            const checkDomain = await SubDomainService.checkSubDomain({subDomain: subDomain});
            const currentURL = window.location.href;

            if (checkDomain?.response) {
                const CognitoUsers = {
                    UserPoolId: checkDomain.response.userpool_id,
                    ClientId: checkDomain.response.client_id,
                };

                const cognitoUserPool = new CognitoUserPool(CognitoUsers);
                const groupRolesData = SolutionManifest.groupRoles;

                const arrGroupRoles = groupRolesData?.map((groupRole) => ({
                    value: groupRole.code,
                    label: groupRole.name,
                    color: groupRole.color,
                }));

                const cognitoData = {
                    id: checkDomain.response.userpool_id,
                    pool: cognitoUserPool,
                    tenantUuid: checkDomain.response.uuid
                }

                const sessionParams = {
                    userpool_id: checkDomain.response.userpool_id,
                    client_id: checkDomain.response.client_id,
                    uuid: checkDomain.response.uuid,
                    identitypool_id: checkDomain.response.identitypool_id,
                    groups_roles: arrGroupRoles,
                    subdomain_name: subDomain,
                    cognito_userpool: cognitoUserPool,
                    company_name: checkDomain.response.company_name
                };

                const refreshSessionIfNeeded = async (cognitoData) => {
                    const cognitoUser = cognitoData?.pool.getCurrentUser();

                    if (cognitoUser && localStorage.getItem('refreshToken')) {
                        try {
                            await refreshSession(cognitoUser, cognitoData, sessionParams);
                        } catch (err) {
                            handleRefreshError(err, sessionParams);
                        }
                    } else {
                        setGroupsRoles(sessionParams.groups_roles);
                        setSubdomainName(sessionParams.subdomain_name);
                        setUserPoolID(sessionParams.userpool_id);
                        setClientID(sessionParams.client_id);
                        setTenantUuid(sessionParams.uuid);
                        setUserPool(sessionParams.cognito_userpool);
                        setIdentityPoolID(sessionParams.identitypool_id);
                        setCompanyName(sessionParams.company_name);
                        setIsAuthenticated(false);
                        setCheckAuthenticated(false);
                        localStorage.removeItem('newPasswordRequired');
                    };
                };

                refreshSessionIfNeeded(cognitoData);
            }

            if (currentURL.includes(path.VERIFYTENANTEMAIL)) {
                setHasSubdomain(true);
                setProductUrl(checkDomain.productUrl);
            } else {
                setHasSubdomain(checkDomain.response);
                setProductUrl(checkDomain.productUrl);
            }
        } catch (err) {
        // Handle error if needed
        console.log(`Check Subdomain Error: ${err}`);
        }
    };

    useEffect(() => {
        checkAndSetSubDomain();
    }, []);
};

export default CheckSubdomain;
