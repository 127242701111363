import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Pagination, Input } from 'antd';
import { SessionContext } from '../../shared/context/session-provider';
import { GlobalContext } from "../../shared/context/global-provider";
import { ShareContext } from '../../shared/context/share-state';
import { useMetadata } from '../../shared/context/meta-provider';
import { Button, Form, Navbar, Container, Nav } from 'react-bootstrap';
import GridViewIcon from '../../icons/grid-view';
import CustomIcons from '../../shared/components/custom-icons';
import ErrorIcon from '../../icons/error';
import _ from 'lodash';
import ViewAssets from '../../shared/components/view-asset';
import ViewAssetsHooks from '../../shared/hooks/view-assets-hooks';
import SearchHooks from '../../shared/hooks/search-hooks';
import AssetService from '../../../services/api/assetsService';
import AssetFilter from '../../search/asset-filter';
import Skeleton from 'react-loading-skeleton';
import ArrowIcon from "../../icons/arrow";
import Badge from 'react-bootstrap/Badge';
import GridFilteredAssets from './GridFilteredAssets';
import RowFilteredAssets from './RowFIlteredAssets';
import path from '../../shared/routePaths';
import DeleteAssetModal from "../../shared/components/view-asset/delete-asset";
import MobileModal from '../../shared/components/mobile-modal';
import MobileFilter from '../../shared/components/mobile-filter';

const MyAssetsSkeleton = (props) => {
    return (
        <div className='my-assets-skeleton-cont'>
            <Skeleton width={250} height={24} style={{ marginBottom:24 }}/>
            <div className='my-assets-card-skeleton-cont'>
                {
                    Array.from({ length: props.placeholderSkeleton }, (_, index) => {
                        // Replace the return statement with your desired component or JSX logic
                        return(<div className='asset-card-skeleton' key={index}>
                            <div className='ratio ratio-4x3'>
                                <Skeleton />
                            </div>
                            <Skeleton height={58} />
                        </div>);
                    })
                }
            </div>
        </div>);
};

const MyAssets = (props) => {
    const { tenantUuid, isCustomerAdmin, isAssetOwner, userUuid } = useContext(SessionContext);

    const {
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        nextUUID,
        prevUUID,
        currentUUID,
        viewAssetsShow,
        viewAssetBtnDisable,
    } = ViewAssetsHooks(props);
    const { updateToastMessage } = useContext(GlobalContext);
    const { updateSearchValue } = SearchHooks();

    const {
        updateMenuTabKey,
        menuTabKey,
        PAGE_SIZE,
        displayMyAssetItems,
        setDisplayMyAssetItems,
        checkedMyAssets,
        setCheckedMyAssets,
        filters,
        setFilters,
        filterAssetsApi,
        loading,
        setLoading,
        clearFilters,
        minFileSize,
        maxFileSize,
        pd,
        pdFrom,
        pdTo,
        setType,
        type,
        numFilters,
        setNumFilters,
        displayFilters,
        setDisplayFilters,
        view,
        setView,
        isTablet,
        setIsMobileModalFilterOpen,
        setFilterState,
        originalData,
        setOriginalData,
        filterSearchData,
        setFilterSearchData,
        searchQuery,
        setSearchQuery,
        activeType,
        setActiveType,
        setCurrentPage,
        currentPage,
        orientationVal,
        applyFilters,
        sort,
        sortMobile,
        setSortByMobile,
        assetsTypeCount,
        isFilter,
        changeType,
    } = useContext(ShareContext);

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const pathPrefix = "/";
    // Type
    const [dynamicTotals, setDynamicTotals] = useState([]);
    const [allAssetsTotal, setAllAssetsTotal] = useState(0);
    const [filterTypeDisplayItem, setFilterTypeDisplayItem] = useState([]);

    // Sort
    const [sortFilenameOrder, setSortFilenameOrder] = useState('asc');
    const [sortTypeOrder, setSortTypeOrder] = useState('asc');
    const [sortSizeOrder, setSortSizeOrder] = useState('asc');

    // Modal
    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
    const [showSingleDeleteModal, setShowSingleDeleteModal] = useState(false);
    const [specificCard, setSpecificCard] = useState(null);

    // Notification
    const [showAlert, setShowAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    const [isMobileModalOpenAllAsset, setIsMobileModalOpenAllAsset] = useState(false);
    const [isMobileModalOpenFilter, setIsMobileModalOpenFilter] = useState(false)
    const [countFilter, setCountFilter] = useState(0);
    const [applyFilterAll, setApplyFilterAll] = useState([]);
    // Pagination
    //const [currentPage, setCurrentPage] = useState(1);
    const [inputPage, setInputPage] = useState('');

    const pageSize = 10;
    const startIdx = (currentPage - 1) * pageSize;
    const endIdx = Math.min(startIdx + pageSize, displayMyAssetItems?.length);

    // memoize displayedCards to prevent unnecessary re-renders
    const displayedCards = useMemo(() => {
        return displayMyAssetItems?.slice(startIdx, endIdx);
    }, [displayMyAssetItems, startIdx, endIdx]);

    const onInputChange = (e) => {
        setInputPage(e.target.value);
    };

    // Refresh MetaData
    const { setAssets } = useMetadata();

    const onInputKeyPress = (e) => {
        if(e.key === "Enter"){
            const pageNumber = parseInt(inputPage);
            const totalItems = displayMyAssetItems?.length;
            const totalPages = Math.ceil(totalItems / 10);

            if(!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= displayMyAssetItems?.length){
                 if (pageNumber > totalPages) {
                setCurrentPage(totalPages);
                } else {
                    setCurrentPage(pageNumber);
                }
                setInputPage('');
            } else {
                console.error('Invalid page number');
            }
        }
    }

    const assetTypes = [
        { id: 1, type: 'all', title: 'All Assets', icon: './images/icons/all_assets.svg', total: 0, alt: 'all assets icon' },
        { id: 2, type: 'image', title: 'Images', icon: './images/icons/images.svg', total: 0, alt: 'images icon' },
        { id: 3, type: 'video', title: 'Videos', icon: './images/icons/videos.svg', total: 0, alt: 'videos icon' },
        { id: 4, type: 'document', title: 'Documents', icon: './images/icons/documents.svg', total: 0, alt: 'documents icon' },
        { id: 5, type: 'audio', title: 'Audio', icon: './images/icons/audio.svg', total: 0, alt: 'audio icon' }
    ];

    const calculateDynamicTotals = () => {
        if (!displayMyAssetItems) return;

        const newDynamicTotals = {};

        assetTypes.forEach(assetType => {
            const filteredTypeCards = originalData.filter(card => card.type === assetType.type);
            newDynamicTotals[assetType.type] = filteredTypeCards.length;
        })

        const totalForAllAssets = originalData.length;
        newDynamicTotals['all'] = totalForAllAssets;
     
        setDynamicTotals(newDynamicTotals);
        setAllAssetsTotal(totalForAllAssets);
        setAssets({
            allAssets: newDynamicTotals['all'] || 0,
            image: newDynamicTotals['image'] || 0,
            video: newDynamicTotals['video'] || 0,
            document: newDynamicTotals['document'] || 0,
            audio: newDynamicTotals['audio'] || 0
        });
    }

    const filterByType = async (type) => {
        setLoading(true);

        const newFill = originalData.filter(asset => asset.type === type);
        const newAssets = filterSearchData.length > 0 ? filterSearchData.filter(asset => asset.type === type) : searchQuery || numFilters > 0 ? filterSearchData.length === 0 ? '' : originalData.filter(asset => asset.type === type) : originalData.filter(asset => asset.type === type);
        setCurrentPage(1);
        setFilterTypeDisplayItem(newFill);
        setDisplayMyAssetItems(newAssets);
        setLoading(false);
    };

    const filterCards = (type) => {
        if(type === 'all'){
            setDisplayMyAssetItems(filterSearchData.length > 0 ? filterSearchData : searchQuery || numFilters > 0 ? filterSearchData.length === 0 ? '' : originalData : originalData);
            setFilterTypeDisplayItem(originalData);
            setCheckedMyAssets([]);
        } else {
            filterByType(type);
            setCheckedMyAssets([]);
        }
        changeType(null, isFilter, type === 'all' ? '' : type, undefined, undefined, undefined, userUuid);
        setActiveType(type);
        setIsMobileModalOpenAllAsset(false)

    }

    const handleChangeViewMode = (mode) => {
        setView(mode);
    };

    // pagination
    const handlePageChange = (page) => {
        setCheckedMyAssets([]);
        setCurrentPage(page);
    }

    const handleKeyDown = (e) => {
        const searchText = e.target.value;
        setSearchQuery(searchText);
        debounceSearch(searchText)
    }

    const debounceSearch = _.debounce((searchText) => {
        let filtered;

        const filterItem = _.filter(filterTypeDisplayItem, function(item) {
            if (filters.publishedDates[0] != null && filters.publishedDates[1] != null) {
                const originalTimestamp = new Date(item.timestamp);
                const originalTimestampYear = originalTimestamp.getUTCFullYear();
                const originalTimestampMonth = String(originalTimestamp.getUTCMonth() + 1).padStart(2, '0');
                const originalTimestampDate = String(originalTimestamp.getUTCDate()).padStart(2, '0');

                const startDate = new Date(filters.publishedDates[0]);
                const startDateYear = startDate.getUTCFullYear();
                const startDateMonth = String(startDate.getUTCMonth() + 1).padStart(2, '0');
                const startDateDay = String(startDate.getUTCDate()).padStart(2, '0');

                const endDate = new Date(filters.publishedDates[1]);
                const endDateYear = endDate.getUTCFullYear();
                const endDateMonth = String(endDate.getUTCMonth() + 1).padStart(2, '0');
                const endDateDay = String(endDate.getUTCDate()).padStart(2, '0');

                const originalTimestampString = `${originalTimestampYear}${originalTimestampMonth}${originalTimestampDate}`;
                const startDateString = `${startDateYear}${startDateMonth}${startDateDay}`;
                const endDateString = `${endDateYear}${endDateMonth}${endDateDay}`;

                if(!(originalTimestampString >= startDateString && originalTimestampString <= endDateString)){
                    return false;
                }
            }

            if (filters.maxFileSize != 0 && item.fileSize > filters.maxFileSize) {
                return false;
            }

            if (filters.minFileSize != 0 && item.fileSize < filters.minFileSize) {
                return false;
            }

            return true;
        });
        let filteredOriginalData = originalData?.filter(item => {
            return filterTypeDisplayItem.some(displayItem => displayItem.type === item.type);
        });
        let filteredApply = filterTypeDisplayItem?.filter(item => {
            return applyFilterAll.some(displayItem => displayItem.type === item.type);
        });
        if(filterItem.length !== 0){
            filtered = filterItem?.filter(item => item.basename.toLowerCase().includes(searchText.toLowerCase()));
        } else if (applyFilterAll.length > 0) {
            filtered = filteredApply?.filter(item => item.basename.toLowerCase().includes(searchText.toLowerCase()));
        } else {
            filtered = filteredOriginalData?.filter(item => item.basename.toLowerCase().includes(searchText.toLowerCase()));
        }

        let fill = originalData?.filter(item => item.basename.toLowerCase().includes(searchText.toLowerCase()));
        let searchApplyFilterAll = applyFilterAll?.filter(item => item.basename.toLowerCase().includes(searchText.toLowerCase()));
        setDisplayMyAssetItems(filtered);
        setCheckedMyAssets([]);
        if(applyFilterAll.length === 0){
            setFilterSearchData(fill);
        }else if(searchText && applyFilterAll.length > 0){
            setFilterSearchData(searchApplyFilterAll);
        }else{
            setFilterSearchData(applyFilterAll);
        }
         //setActiveType('all');
    }, 500);

    // Filter
    const handleApplyFilters = (filterType, collectionUuid, searchResult, bookmarkUuid, userUuid) => {
        setLoading(true);

        applyFilters(filterType, collectionUuid, searchResult, bookmarkUuid, userUuid);
        setLoading(false);

        if(isTablet) {
            setIsMobileModalFilterOpen(false);
            setDisplayFilters(false);
            setFilterState(false);
        }
    };

    const handleClearAll = () => {
        setLoading(true);
        const searchInput = document.getElementById("search-input-my-asset");

        setFilterSearchData([]);
        setDisplayMyAssetItems(filterTypeDisplayItem);
        clearFilters(undefined, undefined, undefined, userUuid);
        setApplyFilterAll([], null, null, null, userUuid);
        if(searchInput.value) {
            setSearchQuery(searchInput.value);
            setDisplayMyAssetItems(filterTypeDisplayItem?.filter(item => item.basename.toLowerCase().includes(searchInput.value.toLowerCase())));
            setFilterSearchData(originalData?.filter(item => item.basename.toLowerCase().includes(searchInput.value.toLowerCase())));

        } else {
            //setDisplayMyAssetItems(originalData);
        }

        setCheckedMyAssets([]);
    };

    // Select all
    const handleSelectAllChange = useCallback((e) => {
        checkedMyAssets.length === displayedCards.length ? setSelectAllChecked(true) : setSelectAllChecked(false);

        setCheckedMyAssets(prevCheckedAssets => {
            if(!selectAllChecked){
                const compareTwoArray = prevCheckedAssets.length === displayedCards.length && displayedCards.every(asset => prevCheckedAssets.includes(asset.uuid));

                return compareTwoArray ? [] : displayedCards.map(card => card.uuid);
            }
            return prevCheckedAssets;
        });
    }, [displayedCards]);


    // Select individual card
    const handleCheckboxChange = (cardUuid) => {
        if([...checkedMyAssets].includes(cardUuid)) {
            setCheckedMyAssets([...checkedMyAssets.filter(id => id !== cardUuid)]);
        } else {
            setCheckedMyAssets([...checkedMyAssets, cardUuid]);
        }
    };

    // Modal component
    const toggleBulkDeleteModal = () => {
        if(checkedMyAssets.length === 0){
            // show error aler
            updateToastMessage(`Select at least one asset to delete`, 'error');
        } else {
            setShowBulkDeleteModal(!showBulkDeleteModal);
        }
    };

    const handleBulkDeleteConfirmation = () => {
        bulkDeleteAsset();
        toggleBulkDeleteModal();
        setCheckedMyAssets([]);
    };

    const bulkDeleteAsset = async () => {
        try {
            const editedArray = Array.isArray(checkedMyAssets) && checkedMyAssets.map((check) => check);
            const assetnames = editedArray.map(uuid => {
                const matchedCard = displayMyAssetItems?.filter(card => card.uuid === uuid)[0];
                return matchedCard?.key?.split('/').pop();
            });
            const bodyAsset = {
                "uuid": tenantUuid,
                "useruuid": userUuid,
                "assetuuids": editedArray,
                "assetnames": assetnames
            }

            const response = await AssetService.purgeRecord(bodyAsset, tenantUuid);

            if(response.statusCode === 200) {
                const updatedItems = originalData.filter(asset => !checkedMyAssets.includes(asset.uuid));
                const filteredByTypeAssets = updatedItems.filter(asset => {
                    if (activeType === 'all') {
                        return true;
                    } else {
                        return asset.type === activeType;
                    }
                });
                setDisplayMyAssetItems(filteredByTypeAssets);
                setOriginalData(updatedItems);
                setFilterSearchData(updatedItems);

                // show success alert
                updateToastMessage(checkedMyAssets.length > 1 ? `${checkedMyAssets.length} assets deleted` : `${checkedMyAssets.length} asset deleted`, 'success');
            } else {
                updateToastMessage(checkedMyAssets.length > 1 ? `${checkedMyAssets.length} assets not deleted` : `${checkedMyAssets.length} not asset deleted`, 'error');
            }
        } catch (error) {
            // show error alert
            updateToastMessage(checkedMyAssets.length > 1 ? `${checkedMyAssets.length} assets not deleted` : `${checkedMyAssets.length} not asset deleted`, 'error');
        } finally {
            setLoading(false);
            setCheckedMyAssets([]);
            toggleBulkDeleteModal();
        }
    };

    const singleDeleteAsset = async () => {
        try {
            const bodyAsset = {
                "uuid": tenantUuid,
                "useruuid": userUuid,
                "assetuuids": [specificCard],
                "assetnames": [displayMyAssetItems.filter(card => card.uuid === specificCard)[0].key.split('/').pop()]
            }

            const response = await AssetService.purgeRecord(bodyAsset, tenantUuid);

            if(response.statusCode === 200) {
                const updatedItems = originalData.filter(asset => asset.uuid !== specificCard);
                const filteredByTypeAssets = updatedItems.filter(asset => {
                    if (activeType === 'all') {
                        return true;
                    } else {
                        return asset.type === activeType;
                    }
                });
                setFilterSearchData(updatedItems);
                setDisplayMyAssetItems(filteredByTypeAssets);
                setOriginalData(updatedItems);
                updateToastMessage("1 asset deleted", 'success');
            }
        } catch (error) {
            // show error alert
            setShowAlert(true);
            setAlertStyle("danger");
            setAlertMessage(`Error: ${error}`);
        } finally {
            setLoading(false);
            setSpecificCard(null);
            setShowSingleDeleteModal(false);
        }

    };

    const toggleSingleDeleteModal = (cardId) => {
        setSpecificCard(cardId);
        setShowSingleDeleteModal(!showSingleDeleteModal);
    };

    // Function to handle single delete confirmation
    const handleSingleDeleteConfirmation = () => {
        singleDeleteAsset();
        setShowSingleDeleteModal(false);
    };

    const noResultSearch = (searchText) => {
        return (
            <div className="no-result-search">
                <p>No results found for "{searchText}"</p>
                <ul>
                    <li>Check and ensure your spelling is correct</li>
                    <li>Try a different search</li>
                </ul>
            </div>
        )
    }

    const svgSortIcon = () => {
        return (
            <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.41793 0.368862C5.65474 0.132356 5.97574 -0.000488281 6.31043 -0.000488281C6.64511 -0.000488281 6.96611 0.132356 7.20293 0.368862L12.2479 5.41386C12.4257 5.59036 12.547 5.81569 12.5965 6.06127C12.646 6.30684 12.6213 6.56158 12.5258 6.79314C12.4302 7.02469 12.2679 7.22263 12.0597 7.36181C11.8514 7.50099 11.6064 7.57515 11.3559 7.57486H1.26393C1.01419 7.57475 0.770089 7.50066 0.562421 7.36195C0.354753 7.22324 0.192827 7.02612 0.0970798 6.79548C0.00133255 6.56483 -0.0239453 6.31098 0.0244363 6.06598C0.072818 5.82098 0.192691 5.5958 0.368927 5.41886L5.41393 0.373859L5.41793 0.368862ZM5.41793 17.2889L0.372927 12.2439C0.196691 12.0669 0.076818 11.8417 0.0284363 11.5967C-0.0199453 11.3517 0.00533255 11.0979 0.10108 10.8672C0.196827 10.6366 0.358753 10.4395 0.566421 10.3008C0.774089 10.1621 1.01819 10.088 1.26793 10.0879H11.3559C11.6057 10.088 11.8498 10.1621 12.0574 10.3008C12.2651 10.4395 12.427 10.6366 12.5228 10.8672C12.6185 11.0979 12.6438 11.3517 12.5954 11.5967C12.547 11.8417 12.4272 12.0669 12.2509 12.2439L7.20293 17.2889C6.96611 17.5254 6.64511 17.6582 6.31043 17.6582C5.97574 17.6582 5.65474 17.5254 5.41793 17.2889Z" fill="white"/>
            </svg>
        )
    }

    const chunkArray = (array, chunkSize) => {
        let chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    const sortByFileName = () => {
        setSortFilenameOrder(sortFilenameOrder === 'asc' ? 'desc' : 'asc');
        let chunks = chunkArray(displayMyAssetItems, 10);

        const returnedChunks = chunks.map((chunk, i) => {
            if (currentPage - 1 === i) {
                return chunk.slice().sort((a, b) => {
                    const nameA = a.basename.toLowerCase();
                    const nameB = b.basename.toLowerCase();
                    return sortFilenameOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                });
            }
            return chunk;
        });

        const mergedArray = returnedChunks.reduce((acc, val) => acc.concat(val), []);

        setDisplayMyAssetItems(mergedArray);
    }

    const sortByType = () => {
        setSortTypeOrder(sortTypeOrder === 'asc' ? 'desc' : 'asc');
        let chunks = chunkArray(displayMyAssetItems, 10);

        const returnedChunks = chunks.map((chunk, i) => {
            if (currentPage - 1 === i) {
                return chunk.slice().sort((a, b) => {
                    const typeA = a.type.toLowerCase();
                    const typeB = b.type.toLowerCase();

                    return sortTypeOrder === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
                });
            }

            return chunk;
        });

        const mergedArray = returnedChunks.reduce((acc, val) => acc.concat(val), []);

        setDisplayMyAssetItems(mergedArray);
    }

    const sortBySize = () => {
        setSortSizeOrder(sortSizeOrder === 'asc' ? 'desc' : 'asc');
        let chunks = chunkArray(displayMyAssetItems, 10);

        const returnedChunks = chunks.map((chunk, i) => {
            if (currentPage - 1 === i) {
                return chunk.slice().sort((a, b) => {
                    const sizeA = a.fileSize;
                    const sizeB = b.fileSize;

                    return sortSizeOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
                });
            }

            return chunk;
        });

        const mergedArray = returnedChunks.reduce((acc, val) => acc.concat(val), []);

        setDisplayMyAssetItems(mergedArray);
    }

    const updateOriginalData = (data) => {
        setOriginalData(data);
    }

    const showToastMessage = (isTrue) => {
        if(isTrue){
            // show success alert
            updateToastMessage("1 asset deleted", 'success');
        }
    }

    const handlePaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text');
        const modifiedText = pastedText.replace(/\s/g, '');
        event.target.value = modifiedText;
        debounceSearch(modifiedText);
    }

    useEffect(() => {
        setType('');
        const filterAssets = async (filters) => {
            try {
                let minFileSizeInBytes = null;
                let maxFileSizeInBytes = null;
        
                if (minFileSize != null) {
                    minFileSizeInBytes = minFileSize * 1048576;
                }
        
                if (maxFileSize != null) {
                    maxFileSizeInBytes = maxFileSize * 1048576;
                }
                
                let filterAssets = {
                    group: tenantUuid,
                    pageSize: 1000,
                    types: filters?.changeType ? filters?.type : type,
                    publishedDates: filters?.clearFilter ? filters?.publishedDates : [pdFrom, pdTo],
                    modifiedDates: filters?.clearFilter ? filters?.mmodifiedDates : [null, null],
                    minFileSize: filters?.clearFilter ? filters?.minFileSize : minFileSizeInBytes != null && minFileSizeInBytes > 0 ? minFileSizeInBytes : 0,
                    maxFileSize: filters?.clearFilter ? filters?.maxFileSize : maxFileSizeInBytes != null && maxFileSizeInBytes > 0 ? maxFileSizeInBytes : undefined,
                    sort: isTablet ? filters?.clearFilter ? 1 : sortMobile : sort,
                    sorting: filters?.clearFilter ? 'desc' : sort === 1 ? 'desc' : 'asc',
                    sortby: "timestamp",
                    type: type
                };

                if(isCustomerAdmin || isAssetOwner){
                    const assets = await AssetService.filterAssets(filterAssets);
                    setDisplayMyAssetItems(assets.hits);
                    setOriginalData(assets.hits);
                    setFilterSearchData(assets.hits);
                    setLoading(false);
                    setCurrentPage(1);
                    setFilterTypeDisplayItem(assets.hits);
                }
            } catch(error) {
                console.error('Error fetching assets:', error);
                setLoading(false);
            }
        };

        filterAssets(filters);
    }, []);

    useEffect(() => {
        calculateDynamicTotals();
    }, [originalData]);

    const handleMobileModalClose = () => {
        setIsMobileModalOpenAllAsset(false);
        setIsMobileModalOpenFilter(false);
        setDisplayFilters(false)
    }

    const assetCount = (type) => {
        if(type === 'all'){
            return assetsTypeCount.allAssets;
        } else if(type === 'image'){
            return assetsTypeCount.image;
        } else if(type === 'video'){
            return assetsTypeCount.video;
        } else if(type === 'document'){
            return assetsTypeCount.document;
        } else if(type === 'audio'){
            return assetsTypeCount.audio;
        }
    };

    return (
        <>
            {showAlert && (
                (alertStyle === 'danger' && <div className="my-assets-notification danger">
                    <div className="notification-holder">
                        <span><ErrorIcon color="#D80029" /></span>
                        <span>{alertMessage}</span>
                    </div>
                </div>) ||
                (alertStyle === 'success' && <div className="my-assets-notification success">
                    <div className="notification-holder">
                        <span><CustomIcons variant='check' color="#000000" /></span>
                        <span>{alertMessage}</span>
                    </div>
                </div>)
            )}

            <div className="my-assets">
            <h2 className={`${isTablet ? 'd-none' : null}`}>My Assets</h2>
                {isTablet ?
                <>
                 <div className='mobile-setting-bar'>
                    <div className='mobile-setting-filter'>
                        <Button
                            onClick={() => { setIsMobileModalOpenAllAsset(true);}}
                            variant="default">
                            <h3 id="mobile-type-active">
                            { activeType === 'all' && 'All Assets' }
                            { activeType === 'image' && 'Images' }
                            { activeType === 'video' && 'Videos' }
                            { activeType === 'document' && 'Documents' }
                            { activeType === 'audio' && 'Audio' }
                            </h3>
                            <span className='d-flex align-items-center'>
                            {isMobileModalOpenAllAsset ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                            </span>
                        </Button>
                        <Button
                            onClick={() => { setDisplayFilters(true);}}
                            variant="default">
                            <h3 id="mobile-type-active">Filters {numFilters !== 0 && `(${numFilters})`}</h3>
                            <span className='d-flex align-items-center'>
                            {displayFilters ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                            </span>
                        </Button>
                        <div className="my-assets-filter-grid-row">
                        <div
                            className={`grid-icon ${view === 'grid' ? 'active d-none' : ''}`}
                            onClick={() => handleChangeViewMode('grid')}
                        >
                            <GridViewIcon />
                        </div>
                        <div
                            className={`row-icon ${view === 'row' ? 'active d-none' : ''}`}
                            onClick={() => handleChangeViewMode('row')}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                            </svg>
                        </div>
                    </div>
                    </div>
                    <div className="my-assets-filter-search-box">
                        <span>
                            <CustomIcons className='my-svg' variant="search" />
                        </span>
                        <input
                            type="text"
                            id="search-input-my-asset"
                            placeholder="Search filename"
                            onChange={(e) => handleKeyDown(e)}
                            value={searchQuery}
                            disabled={originalData?.length === 0}
                            onPaste={handlePaste}
                        />
                    </div>
                    {checkedMyAssets?.length > 0 &&
                    <div className='mobile-check-delete'>
                        <div className="my-assets-filter-select-all">
                        <input
                            type="checkbox"
                            id="select-all-checkbox"
                            onChange={handleSelectAllChange}
                            checked={selectAllChecked}
                        />
                        <label
                            htmlFor="select-all-checkbox"
                            className={displayedCards?.length !== 0 &&
                                            checkedMyAssets?.length === displayedCards?.length
                                                ? 'checked' : checkedMyAssets?.length
                                                                ? 'dashed' : ''
                                      }
                        >
                            {displayedCards?.length !== 0 && checkedMyAssets?.length === displayedCards?.length && (
                                <CustomIcons variant='check' color="#000000" />
                            )}
                        </label>
                        {
                            checkedMyAssets.length > 0 ?
                                <label htmlFor="select-all-checkbox">{`${checkedMyAssets.length} selected`}</label> : <label htmlFor="select-all-checkbox">Select all</label>
                        }
                    </div>
                        <div className='mobile-user-bulk-delete'>
                                <Button
                                    onClick={toggleBulkDeleteModal}
                                    variant="default" className="icon-button icon-button-text icon-button-left">
                                    <CustomIcons variant="trash" className="custom-icon" />
                                </Button>
                            </div>
                    </div>
                }
                 </div>
                </>

                :
                <>
                <ul className="my-assets-types">
                    {assetTypes.length > 0 && assetTypes.map(asset => (
                        <li
                            key={asset.id}
                            className={asset.type === activeType ? 'active' : ''}
                            onClick={() => filterCards(asset.type)}
                        >
                            <img src={asset.icon} alt={asset.alt} />
                            <span>{asset.title}</span>
                            {assetsTypeCount ?
                            <span>{assetCount(asset.type)}</span>
                            :
                            <span>{asset.type === 'all' ? allAssetsTotal : dynamicTotals[asset.type]}</span>
                            }
                        </li>
                    ))}
                </ul>

                <div className="my-assets-filter">
                    <div className="my-assets-filter-select-all">
                        <input
                            type="checkbox"
                            id="select-all-checkbox"
                            onChange={handleSelectAllChange}
                            checked={selectAllChecked}
                        />

                        <label
                            htmlFor="select-all-checkbox"
                            className={displayedCards?.length !== 0 &&
                                            checkedMyAssets?.length === displayedCards?.length
                                                ? 'checked' : checkedMyAssets?.length
                                                                ? 'dashed' : ''
                                      }
                        >
                            {displayedCards?.length !== 0 && checkedMyAssets?.length === displayedCards?.length && (
                                <CustomIcons variant='check' color="#000000" />
                            )}
                        </label>
                        {
                            checkedMyAssets.length > 0 ?
                                <label htmlFor="select-all-checkbox">{checkedMyAssets?.length === displayedCards?.length ?
                                        `All selected` : `${checkedMyAssets.length} selected`}</label> : <label htmlFor="select-all-checkbox">Select all</label>
                        }
                    </div>
                    <div className="my-assets-filter-search-box">
                        <span>
                            <CustomIcons className='my-svg' variant="search" />
                        </span>
                        <input
                            type="text"
                            id="search-input-my-asset"
                            placeholder="Search filename"
                            onChange={(e) => handleKeyDown(e)}
                            disabled={originalData?.length === 0}
                            value={searchQuery}
                            onPaste={handlePaste}
                        />
                    </div>
                    <div className="my-assets-filter-dropdown" onClick={() => setDisplayFilters(!displayFilters)}>
                        <div>Filters {numFilters !== 0 && `(${numFilters})`}</div>
                        <span>{displayFilters ? <CustomIcons variant="arrow" direction="up" /> : <CustomIcons variant="arrow" direction="down" />}</span>
                    </div>
                    <div className="my-assets-filter-delete" onClick={toggleBulkDeleteModal}>
                        <span><CustomIcons variant="trash" /></span>
                        <div>Delete</div>
                    </div>
                    <div className="my-assets-filter-grid-row">
                        <div
                            className={`grid-icon ${view === 'grid' ? 'active' : ''}`}
                            onClick={() => handleChangeViewMode('grid')}
                        >
                            <GridViewIcon />
                        </div>
                        <div
                            className={`row-icon ${view === 'row' ? 'active' : ''}`}
                            onClick={() => handleChangeViewMode('row')}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                            </svg>
                        </div>
                    </div>
                </div>
                </>
                }
                {
                    displayFilters &&
                    isTablet ?
                        <MobileFilter handleMobileModalFilterClose={handleMobileModalClose} applyFilters={handleApplyFilters} clearFilters={handleClearAll} userUuid={userUuid}/>
                        :
                        <div className={`${!isTablet ? 'filter-dropdown-shown' : null}`}>
                            <AssetFilter clear={handleClearAll} applyFilters={handleApplyFilters} show={displayFilters} userUuid={userUuid}/>
                        </div>
                }

                {
                    loading ?
                        <div className="my-assets-cards-container" style={{ paddingTop: displayedCards?.length === 0 && '48px' }}>
                            <MyAssetsSkeleton placeholderSkeleton={10} />
                        </div>
                    :
                        <div className="my-assets-cards-container" style={{ paddingTop: displayedCards?.length === 0 && '48px' }}>
                        {!loading && displayedCards?.length !== 0 && (
                            <div className="my-assets-cards-showing">
                                <p>{`Showing ${startIdx + 1}-${endIdx} of ${assetsTypeCount ? assetsTypeCount.allAssets : displayMyAssetItems?.length}`}{(assetsTypeCount ? assetsTypeCount.allAssets : displayMyAssetItems?.length) === 1 ? ' asset' : ' assets'}</p>
                            </div>
                        )}
                        {view === 'grid' && displayedCards?.length !== 0 && !viewAssetsShow && (
                            <div className="my-assets-cards-grid">
                                {
                                !loading && displayedCards.length > 0 && displayedCards.map(realData => {
                                    let assetFile = {
                                        key: realData.key,
                                        uuid: realData.uuid,
                                        basename: realData.basename,
                                        timestamp: realData.timestamp,
                                        type: realData.type,
                                        size: realData.fileSize,
                                        overallStatus: realData.overallStatus
                                    }
                                    return <GridFilteredAssets
                                                key={realData.uuid}
                                                data={assetFile}
                                                allDataDisplay={displayedCards}
                                                handleViewAssetOpen={handleViewAssetOpen}
                                                onCheckboxChange={handleCheckboxChange}
                                                checkedMyAssets={checkedMyAssets}
                                                toggleSingleDeleteModal={toggleSingleDeleteModal}
                                                isTablet={isTablet}
                                            />
                                })
                                }
                            </div>
                        )}
                        {view === 'row' && displayedCards?.length !== 0 && !viewAssetsShow && (
                            <div className="my-assets-cards-row">
                                {!isTablet &&
                                    <div className="my-assets-card-header">
                                        <div></div>
                                        <div onClick={sortByFileName}>Filename{svgSortIcon()}</div>
                                        <div onClick={sortByType}>Type{svgSortIcon()}</div>
                                        <div onClick={sortBySize}>Size{svgSortIcon()}</div>
                                        <div>Actions</div>
                                    </div>
                                }
                                {!loading && displayedCards.length > 0 && displayedCards.map(realData => {
                                    let assetFile = {
                                        key: realData.key,
                                        uuid: realData.uuid,
                                        basename: realData.basename,
                                        timestamp: realData.timestamp,
                                        type: realData.type,
                                        size: realData.fileSize,
                                        overallStatus: realData.overallStatus
                                    }

                                    return <RowFilteredAssets
                                                key={realData.uuid}
                                                data={assetFile}
                                                allDataDisplay={displayedCards}
                                                onCheckboxChange={handleCheckboxChange}
                                                selectAllChecked={selectAllChecked}
                                                toggleSingleDeleteModal={toggleSingleDeleteModal}
                                                handleViewAssetOpen={handleViewAssetOpen}
                                                checkedMyAssets={checkedMyAssets}
                                                isTablet={isTablet}
                                            />
                                })}
                            </div>
                        )}
                        {originalData?.length === 0 && searchQuery.length === 0 && (
                            <div className="no-assets-yet">
                                <div className="svg-field">
                                    <img src="./images/no-assets.svg" alt="No Assets Yet" />
                                </div>
                                <div className="no-asset-text-container">
                                    <h3>No assets yet</h3>
                                    <p>Get started by adding your images, videos, documents and audio files</p>
                                </div>
                                <div className="no-asset-button-container">
                                    <button onClick={() => updateMenuTabKey(path.UPLOAD)}>Upload Files</button>
                                </div>
                            </div>
                        )}
                            {originalData?.length !== 0 && displayMyAssetItems?.length === 0 && searchQuery.length === 0 && (
                            <div className="no-filter-result">
                                <p className='font-bold'>No results found</p>
                                    <ul>
                                        <li>Check and ensure your inputs and selections are valid</li>
                                        <li>Try inputting and/or selecting other valid Filters options</li>
                                    </ul>
                            </div>
                        )}
                        {originalData?.length !== 0 && displayedCards?.length === 0 && searchQuery?.length !== 0 && noResultSearch(searchQuery)}
                        {/* {viewAssetsShow && (<ViewAssets
                                                updateFSLightBox={props.updateFSLightBox}
                                                fetchedFileData={fetchedFileData}
                                                nextUUID={nextUUID}
                                                prevUUID={prevUUID}
                                                viewAssetsShow={viewAssetsShow}
                                                viewAssetBtnDisable={viewAssetBtnDisable}
                                                handleViewNext={handleViewNext}
                                                handleViewPrev={handleViewPrev}
                                                handleViewClose={handleViewClose}
                                                fileData={fileData}
                                                updateSearchValue={updateSearchValue}
                                                updateOriginalData={updateOriginalData}
                                                showToastMessage={showToastMessage}
                                            />
                        )} */}
                        {!loading && displayedCards?.length !== 0 && (
                            <div className="pagination-container" style={{ display: displayMyAssetItems?.length <= 10 && 'none' }} >
                                <Pagination
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={displayMyAssetItems?.length}
                                    onChange={handlePageChange}
                                />
                                {!isTablet &&
                                <div className="pagination-container-input">
                                    Go to
                                    <Input
                                        style={{ width: 71 }}
                                        value={inputPage}
                                        onChange={onInputChange}
                                        onKeyPress={onInputKeyPress}
                                    />
                                    Page
                                </div>
                                }
                            </div>
                        )}
                        </div>
                }
                {showBulkDeleteModal && checkedMyAssets?.length !== 0 && (
                      <DeleteAssetModal
                      loading={loading}
                      modalShow={showBulkDeleteModal}
                      handleModalClose={toggleBulkDeleteModal}
                      modalType={'bulkDelete'}
                      handleModalDelete={handleBulkDeleteConfirmation}
                      checkedMyAssets={checkedMyAssets}
                      originalData={originalData}
                      className={'mobile-my-asset-modal'}
                  />
                )}
                {showSingleDeleteModal &&
                    <DeleteAssetModal
                    loading={loading}
                    modalShow={showSingleDeleteModal}
                    handleModalClose={() => setShowSingleDeleteModal(false)}
                    handleModalDelete={handleSingleDeleteConfirmation}
                    deleteFilesData={specificCard && displayMyAssetItems.find(asset => asset.uuid === specificCard)?.key?.split("/").pop()}
                    className={'mobile-my-asset-modal'}
                />
                }

            </div>
            {isMobileModalOpenAllAsset &&
                    <MobileModal title={'File type'} onClose={handleMobileModalClose}>
                         <div className="mobile-modal-file-type">
                            <Navbar expand="lg" className="justify-content-between p-0">
                                <Container fluid className='g-0'>
                                    <Nav>
                                    {assetTypes.length && assetTypes.map(asset => (
                                        <Nav.Item className={asset.type === activeType ? 'selected' : ''} key={asset.id}>
                                            <Nav.Link onClick={() => filterCards(asset.type)}>
                                                <img src={asset.icon} alt={asset.alt} />
                                                <h3>{asset.title}</h3>
                                                <Badge><p>{asset.type === 'all' ? allAssetsTotal : dynamicTotals[asset.type]}</p></Badge>
                                            </Nav.Link>
                                        </Nav.Item>
                                        ))}
                                    </Nav>
                                </Container>
                            </Navbar>
                        </div>
                    </MobileModal>
            }
        </>
    )
}

export default MyAssets;
