import { useState, useEffect, Suspense, lazy, useContext } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { createStore, getStoreItem, setStoreItem } from '../../../store';
import { Select } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import { ApiContext } from '../../shared/context/api-state';
import { SessionContext } from '../../shared/context/session-provider';
import { useMetadata } from '../../shared/context/meta-provider';
import { IsTablet } from "../../shared/utils";
import Localization from '../../shared/localization';
import _, { debounce } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import CollectionHooks from '../../shared/hooks/collection-hooks';
import CustomIcons from "../../shared/components/custom-icons";
import path from '../../shared/routePaths';
import MobileModal from "../../shared/components/mobile-modal";
import CollectionCardSkeleton from "../collection-skeleton";

const CollectionCard = lazy(() => import('../collection-card'));

const CollectionView = (props) => {
    const { getTenantCollection, getAssetsById } = useContext(ApiContext);
    const [allCollection, setAllCollection] = useState([]);
    const [fetchCollection, setFetchCollection] = useState(false);
    const [sort, setSortBy] = useState(1);
    const [loading, setLoading] = useState(true);
    const [fetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const placeholderSkeleton = 3;
    const PAGE_SIZE = 12;
    const pageSize = 1000;

    // Check isTablet?
    const isTablet = IsTablet();

    // Share Context
    const { updateMenuTabKey,
        isMobileModalOpen,
        handleMobileModalOpen,
        handleMobileModalClose,
        totalPages,
        setTotalPages,
        setAssetsToken,
        assetsToken,
        collectionCard,
        setCollectionCard
     } = useContext(ShareContext);

    // Session Context
    const { isEndUser,
        isUserAdmin,
        tenantUuid } = useContext(SessionContext);

    const { handleClickCollectionCard } = CollectionHooks();

    const handleSortBy = async (value) => {
        setSortBy(value);
        const allCollection = await getCollection(pageSize, assetsToken, value);
        let pageData = getData(allCollection, 1, PAGE_SIZE);
        setTotalPages(pageData.totalPages);
        setCurrentPage(1);
        setAllCollection(allCollection);
        setCollectionCard(pageData.data);
    }

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
            return(<div className={isTablet ? "col-12" : "col-4"} key={index}><CollectionCardSkeleton /></div>);
        });
    };

    const getData = (allItems, page, pageSize) => {
        let pg = page || 1,
        pgSize = pageSize || 100,
        offset = (pg - 1) * pgSize,
        pagedItems = _.drop(allItems, offset).slice(0, pgSize);
        return {
            page: pg,
            pageSize: pgSize,
            total: allItems?.length,
            totalPages: Math.ceil(allItems?.length / pgSize),
            data: pagedItems
        };
    };

    const loadItems = async () => {
        if (fetching) {
            return;
        }
        setIsFetching(true);

        let page = currentPage + 1;

        if(assetsToken && page >= totalPages ) {
            let assets = await getCollection();
            setAllCollection((prevAssets) => {
                const allAssets = [...prevAssets, ...assets];
                const pageData = getData(allAssets, currentPage, PAGE_SIZE);
                setTotalPages(pageData.totalPages);
                setIsFetching(false);

                return allAssets;
            });
        }

        let pageData = getData(allCollection, page, PAGE_SIZE);
        setCurrentPage(page);
        setTotalPages(pageData.totalPages);
        setCollectionCard([...collectionCard, ...pageData.data]);
        setIsFetching(false);

        return;
    };

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;
        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= totalPages) {
                return;
            }

            loadItems();
        }
    };

    const { collections } = useMetadata();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    const getCollection = async (pageSize, assetsToken, sort) => {
        setFetchCollection(false);

        try {
            const collectionData = await getTenantCollection(undefined, pageSize, assetsToken, sort);
            if (collectionData.nextToken) {
                setAssetsToken(collectionData.nextToken);
            } else {
                setAssetsToken(undefined);
            }
            const tenantCollection = collectionData.Items;

            if(tenantCollection && tenantCollection.length > 0) {
                const sortedFilterRes = tenantCollection; //_.sortBy(tenantCollection, 'timestamp').reverse();

                if(sortedFilterRes.length > 0) {
                    sortedFilterRes.forEach(async (element, index) => {
                        let elArray = element;

                        await getStoreItem(element.uuid, createStore('collections', 'asset-collections')).then(async storeItem => {
                            setStoreItem(element.uuid, elArray, createStore('collections', 'asset-collections'));
                        });
                    });

                    setFetchCollection(true);
                    setLoading(false);
                    return sortedFilterRes;
                } else {
                    setFetchCollection(true);
                }
            } else {
                setFetchCollection(true);
            }
        } catch (error) {
            console.error("Error fail to get collection: ", error)
        };
    };

    useEffect(() => {
        if(props.menuTabKey === path.COLLECTIONS) {
            const RenderCollectionCard = debounce(async () => {
                const allCollection = await getCollection(pageSize, "", sort);
                let pageData = getData(allCollection, 1, PAGE_SIZE);
                setTotalPages(pageData.totalPages);
                setCurrentPage(1);
                setAllCollection(allCollection);
                setCollectionCard(pageData.data);
            }, 300);

            RenderCollectionCard();

            return () => RenderCollectionCard.cancel();
        }
    }, [props.menuTabKey]);

    return(
        <>
            {
                props.menuTabKey === path.COLLECTIONS &&
                    <section>
                        <Container>

                            <div className='collection-view-wrapper'>
                                {
                                    fetchCollection ?
                                        collectionCard.length > 0
                                        ?
                                            <>
                                                <h2>{Localization.Collection.Title}</h2>
                                                <div className="row asset-view-container">
                                                    <div className="col-12">
                                                        <Navbar expand="lg" className="justify-content-between">
                                                            <Nav className="w-100">
                                                                <Nav.Item>
                                                                    <div className="collection-view-show-number">
                                                                        {
                                                                            collections > 1 ? `${collections} collections` : `${collections} collection`
                                                                        }
                                                                    </div>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <div className="collection-view-sort-by">
                                                                        <Select
                                                                            id="sort-collection"
                                                                            className='border-0'
                                                                            defaultValue={1}
                                                                            value={sort}
                                                                            bordered={false}
                                                                            suffixIcon={<CustomIcons variant="arrow" direction="expand"/>}
                                                                            options={[
                                                                                { value: 1, label: 'New to Old' },
                                                                                { value: 2, label: 'Old to New' },
                                                                                { value: 3, label: 'A to Z' },
                                                                                { value: 4, label: 'Z to A' },
                                                                            ]}
                                                                            aria-label="sort"
                                                                            onChange={(value) => handleSortBy(value)}
                                                                        />
                                                                    </div>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Navbar>
                                                        <div className="mobile-collection-view-container">
                                                            <h3>{allCollection.length > 1 ? `${allCollection.length} collections` : `${allCollection.length} collection`}</h3>

                                                            <div className="mobile-collection-new-to-old"  onClick={handleMobileModalOpen}>
                                                                <h3>
                                                                    {sort === 1 && 'New to Old'}
                                                                    {sort === 2 && 'Old to New'}
                                                                    {sort === 3 && 'A to Z'}
                                                                    {sort === 4 && 'Z to A'}
                                                                    <CustomIcons variant="arrow" direction="expand"/>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="collection-view-card-container">
                                                    <div className="row">
                                                        {
                                                            collectionCard!= null && collectionCard.map((collection) => {
                                                                return (
                                                                    <Suspense key={collection.uuid} fallback={<div className="col-4"><CollectionCardSkeleton /></div>}>
                                                                        <div className="col-4" key={collection.uuid}>
                                                                            <CollectionCard
                                                                                uuid={collection.uuid}
                                                                                collection={collection}
                                                                                handleClickCollectionCard={handleClickCollectionCard}/>
                                                                        </div>
                                                                    </Suspense>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        :
                                        <>
                                            <div className="no-collection-view">
                                                <img src="./images/no-assets.svg" alt="No Collection Illustration"/>
                                                <h2>{Localization.Collection.NoCollections}</h2>
                                                {
                                                    (!isEndUser && !isUserAdmin) &&
                                                        <>
                                                            <p>{Localization.Collection.NoCollectionsDesc}</p>
                                                            <button className="btn btn-primary" onClick={() => updateMenuTabKey(path.UPLOAD)}>{Localization.Collection.NoCollectionsBtn}</button>
                                                        </>
                                                }
                                            </div>
                                        </>
                                    :
                                        <div className="row">
                                            <Skeleton className="mb-3" width={200} height={30} />
                                            {renderPlaceholderSkeleton()}
                                        </div>
                                }
                            </div>
                        </Container>

                        {
                            isMobileModalOpen && (
                                <MobileModal title="Sort By" onClose={handleMobileModalClose}>
                                    <div className="mobile-modal-sort-only">
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="new-to-old"
                                                name="sort"
                                                value={1}
                                                checked={sort === 1}
                                                onChange={() => { handleSortBy(1); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="new-to-old">
                                                <h3>New to Old</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="old-to-new"
                                                name="sort"
                                                value={2}
                                                checked={sort === 2}
                                                onChange={() => { handleSortBy(2); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="old-to-new">
                                                <h3>Old to New</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="a-to-z"
                                                name="sort"
                                                value={3}
                                                checked={sort === 3}
                                                onChange={() => { handleSortBy(3); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="a-to-z">
                                                <h3>A to Z</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="z-to-a"
                                                name="sort"
                                                value={4}
                                                checked={sort === 4}
                                                onChange={() => { handleSortBy(4); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="z-to-a">
                                                <h3>Z to A</h3>
                                            </label>
                                        </div>
                                    </div>
                                </MobileModal>
                            )
                        }
                    </ section>
            }

        </>
    );
};

export default CollectionView;
