import React, { useState, useRef } from 'react';
import CustomIcons from '../custom-icons';

const MobileModal = ({ title, titleWithExtension, onClose, children, withBtn, isCapitalize }) => {

    const [startY, setStartY] = useState(null);
    const [offset, setOffset] = useState(0);
    const modalRef = useRef(null);

    // for the first part of the long text
    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -7); // Remove the last 3 characters and the extension
        return truncatedFulltext;
    };

    // for the end part of the long text
    const truncateEndText = (fulltext) => {
        const extension = fulltext.substr(-4);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    };

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        if (!startY) return;
        const currentY = e.touches[0].clientY;
        const deltaY = currentY - startY;
        if (deltaY < 0) return;
        setOffset(deltaY);
    };

    const handleTouchEnd = () => {
        if (offset > 100) { // Adjust swipe sensitivity here
            onClose();
        }
        setStartY(null);
        setOffset(0);
    };

    const modalStyle = {
        transform: `translateY(${offset}px)`
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="mobile-modal" onClick={(e) => { e.stopPropagation(); onClose(); } }>
            <div className="mobile-modal-content"
                id={withBtn && 'with-btn-bottom'}
                style={modalStyle}
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                onClick={handleContentClick}
            >
                <div className="mobile-modal-content-wrapper">
                    <div className="mobile-modal-header">
                        {title && (
                            <div className="list-view-title-container">
                                <h3 className={`text-truncate ${isCapitalize ? 'text-capitalize' : null}`}>{title}</h3>
                            </div>
                        )}
                        {titleWithExtension && (
                            <div className="list-view-title-container">
                                <h3 className={`first-text ${isCapitalize ? 'text-capitalize' : null}`}>{truncateFirstText(titleWithExtension.substring(titleWithExtension.lastIndexOf("/") + 1))}</h3>
                                <h3 className="end-text">{truncateEndText(titleWithExtension.substring(titleWithExtension.lastIndexOf("/") + 1))}</h3>
                            </div>
                        )}
                        <div className="mobile-modal-close" onClick={onClose} >
                            <CustomIcons variant="close" />
                        </div>
                    </div>
                    <div className="mobile-modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileModal;
