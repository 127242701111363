import { useState, useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import BookmarkEdit from '../bookmark-edit';
import BookmarkSuccess from '../bookmark-success';
import { IsTablet, IsMobile } from '../../../components/shared/utils';

const BookmarkOffCanvas = (props) => {
    const isMobile = IsMobile();
    const isTablet = IsTablet();
    const [showEditPage, setShowEditPage] = useState(true);

    const handleContainerSwitch = () => {
        setShowEditPage((prev) => {
            const newShowEditPage = !prev;
            return newShowEditPage;
        });
    };

    return(<>
        {
            isMobile?
            <Offcanvas style={{ width: '100%' }} show={props.offcanvasShow} placement="end" backdrop={false} scroll={false} onHide={() => {props.clickBookmarkOffcanvas()}}>            
                <Offcanvas.Body style={{ padding: '0' }} >
                {
                    <div className="bookmark-offcanvas-wrapper">
                        <div className="bookmark-offcanvas-cont progress-state">
                            {
                                showEditPage ?
                                    <BookmarkEdit 
                                        offcanvasShow= {props.offcanvasShow}
                                        checkIfTyping = {props.checkIfTyping}
                                        bookmarkUuid = {props.bookmarkUuid}
                                        bookmarkTitle = {props.bookmarkTitle}
                                        handleContainerSwitch={handleContainerSwitch}
                                        viewMobile={true}
                                        clickBookmarkOffcanvas = {props.clickBookmarkOffcanvas}
                                    />
                                :
                                    <BookmarkSuccess 
                                        offcanvasShow= {props.offcanvasShow}
                                        handleContainerSwitch={handleContainerSwitch}
                                        clickBookmarkOffcanvas = {props.clickBookmarkOffcanvas}
                                    />
                            }
                        </div> 
                    </div>  
                }
                </Offcanvas.Body>
            </Offcanvas>
        :    
            isTablet?
                <Offcanvas style={{ maxWidth:'40%', width: '700px' }} show={props.offcanvasShow} placement="end" backdrop={true} scroll={true} onHide={() => {props.clickBookmarkOffcanvas()}}>
                    <Offcanvas.Body>
                    {
                        <div className="bookmark-offcanvas-wrapper">
                            <div className="bookmark-offcanvas-cont progress-state">
                                {
                                    showEditPage ?
                                        <BookmarkEdit 
                                            offcanvasShow= {props.offcanvasShow}
                                            checkIfTyping = {props.checkIfTyping}
                                            bookmarkUuid = {props.bookmarkUuid}
                                            bookmarkTitle = {props.bookmarkTitle}
                                            handleContainerSwitch={handleContainerSwitch}
                                            viewMobile={true}
                                            clickBookmarkOffcanvas = {props.clickBookmarkOffcanvas}
                                        />
                                    :
                                        <BookmarkSuccess 
                                            offcanvasShow= {props.offcanvasShow}
                                            handleContainerSwitch={handleContainerSwitch}
                                            clickBookmarkOffcanvas = {props.clickBookmarkOffcanvas}
                                        />
                                }
                            </div> 
                        </div>  
                    }
                    </Offcanvas.Body>
                </Offcanvas>   
            ://1024px up
                <Offcanvas style={{ maxWidth:'34%', width: '700px' }} show={props.offcanvasShow} placement="end" backdrop={true} scroll={true} onHide={() => {props.clickBookmarkOffcanvas()}}>
                    <Offcanvas.Body>
                        {
                        <div className="bookmark-offcanvas-wrapper">
                            <div className="bookmark-offcanvas-cont progress-state">
                                {
                                    <BookmarkEdit 
                                        offcanvasShow= {props.offcanvasShow}
                                        checkIfTyping = {props.checkIfTyping}
                                        bookmarkUuid = {props.bookmarkUuid}
                                        bookmarkTitle = {props.bookmarkTitle}        
                                        clickBookmarkOffcanvas = {props.clickBookmarkOffcanvas}
                                    />

                                }
                            </div> 
                        </div>  
                        }
                    </Offcanvas.Body>
                </Offcanvas> 
        }
    </>)
}

export default BookmarkOffCanvas;

