import { useState, useContext, useEffect, useRef } from 'react';
import GenericModal from '../../shared/components/generic-modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { getStoreItem, createStore, removeStoreItem } from '../../../store';
import { ShareContext } from '../../shared/context/share-state';
import { ApiContext } from '../../shared/context/api-state';
import { SessionContext } from '../../shared/context/session-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import BookmarkHooks from '../../shared/hooks/bookmark-hooks';
import AssetService from '../../../services/api/assetsService';
import CustomIcons from '../../shared/components/custom-icons';
import Localization from '../../shared/localization';
import path from '../../shared/routePaths';
import { IsTablet, IsMobile } from '../../shared/utils';
import { useMetadata } from '../../shared/context/meta-provider';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const BookmarkEdit = (props) => {
    const [newBookmarkTitle, setNewBookmarkTitle] = useState(props.bookmarkTitle);
    const [charCount, setCharCount] = useState(0);
    const [modalType, SetModalType] = useState("");
    const [bookmarkSaving, setBookmarkSaving] = useState(false);
    const [editBookmarkError, setEditBookmarkError] = useState("");
    const [bookmarkDeleting, setBookmarkDeleting] = useState(false);
    const [modalLoading, SetModalLoading] = useState(false);
    const [modalShow, SetModalShow] = useState(false);
    const [bookmarkTitleOnfocus, setBookmarkTitleOnfocus] = useState(false);
    const textareaRef = useRef(null);
    const prevWindowWidth = useRef(window.innerWidth);

    //Url
    const pathPrefix = '/';
    const navigate = useNavigate();

    //View
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    const { getBookmarks } = useContext(ApiContext);
    const { updateToastMessage } = useContext(GlobalContext);
    const { tenantUuid, userUuid } = useContext(SessionContext);
    const { updateBookmarkSearch } = BookmarkHooks();
    const { bookmarks, setBookmarks, userBookmarks, setUserBookmarks } = useMetadata();
    const { updateMenuTabKey, allBookmark, setAllBookmark } = useContext(ShareContext);

    const charCounter = (event) => {
        const newValue = event.target.value;
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Control', 'v', 'V', 'a', 'A'];
        const isControlKey = event.ctrlKey && (event.key === 'v' || event.key === 'V' || event.key === 'a' || event.key === 'A');
    
        if (newValue.length <= 30 || allowedKeys.includes(event.key) || isControlKey) {
            setCharCount(newValue.length);
            setNewBookmarkTitle(newValue);
        } else if (event.type === 'keydown' && !allowedKeys.includes(event.key) && !isControlKey) {
            event.preventDefault();
        } else {
            setCharCount(30);
            setNewBookmarkTitle(newValue.slice(0, 30));
        }
    };

    const handleModalClose = () => {
        SetModalShow(false);
    };

    const handleDeleteBookmark = () => {
        SetModalShow(true);
        SetModalLoading(true);
        SetModalType("deleteBookmark");
    };

    const handleTextAreaResize = (event) => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        if (event.keyCode === 13) { // 13 is the keycode for Enter key
            textarea.style.height = 'auto'; // Allow textarea to adjust height based on content
            return;
        }

        if (event.keyCode === 8) { // 8 is the keycode for backspace
            textarea.style.height = '0'; // Temporarily set height to 0 to allow natural expansion
            textarea.style.height = textarea.scrollHeight + 'px'; // Set height based on content
            return;
        }

        const newHeight = Math.max(textarea.scrollHeight, 56);
        textarea.style.height = newHeight + 'px';
    };

    const handleWindowResize = () => {
        const textarea = textareaRef.current;
        const currentWindowWidth = window.innerWidth;

        if (currentWindowWidth > prevWindowWidth.current) {
            // Window is increasing in size
            textarea.style.height = '0';
            textarea.style.height = textarea.scrollHeight + 'px';

        }  else if (currentWindowWidth < prevWindowWidth.current) {
            // Window is decreasing in size
            const newHeight = Math.max(textarea.scrollHeight, 56);
            textarea.style.height = newHeight + 'px';
        }

      prevWindowWidth.current = currentWindowWidth;
    };

    const isAllBookmarks = async () => {
        await getStoreItem(props.bookmarkUuid, createStore('bookmarks', 'asset-bookmarks'));
    };

    const handleModalDelete = async () => {
        setBookmarkDeleting(true);

        const body = [ props.bookmarkTitle ].reduce((acc, name) => {
            acc[name] = {
                uuid: props.bookmarkUuid,
                name: name,
                tenantUuid: tenantUuid,
                userUuid: userUuid,
                timestamp: Date.now()
            };
            return acc;
        }, {});

        try {
            // make sure that bookmark stored in indexdb
            if(!(await isAllBookmarks())) {
                await getBookmarks(props.bookmarkUuid, "bookmark");
            }

            //Call delete collection api
            const response = await AssetService.deleteSetBookmark(body, tenantUuid);
            if (response.statusCode !== 200) {
                updateToastMessage(`Not deleted`, 'error');
                console.error('Error on deleting:', response.response);
            }

            //When call is successful, delete the bookmark in the asset-bookmarks
            setBookmarks(bookmarks - 1);
            setUserBookmarks(userBookmarks - 1)
            await getStoreItem(props.bookmarkUuid, createStore('bookmarks', 'asset-bookmarks'))
                .then(res => {
                    removeStoreItem(props.bookmarkUuid, createStore('bookmarks', 'asset-bookmarks'));
                    const newAllBookmark = allBookmark.filter(asset => asset.uuid !== props.bookmarkUuid);
                    setAllBookmark(newAllBookmark);
                })
                .catch(error => {
                    console.error(error);
                });
            
            //Navigate back to bookmark page
            updateToastMessage('Folder deleted',"success");
            const route = `${pathPrefix + path.BOOKMARK}`;
            navigate(route,{ replace: true });
            updateMenuTabKey(path.BOOKMARK);
        } catch (error) {
            console.error('error in deleting bookmark:', error.message);
            setBookmarkDeleting(false);
        }
    }; 

    const handleEditBookmark = async () => {
        setBookmarkSaving(true);
        setEditBookmarkError('');

        const body = {
            bookmark: {
                name: newBookmarkTitle,
                uuid: props.bookmarkUuid,
                userUuid: userUuid,
                tenantUuid: tenantUuid,
                timestamp: Date.now()
            }
        };
        try {
            // make sure that bookmark stored in indexdb
            if(!(await isAllBookmarks())) {
                await getBookmarks(props.bookmarkUuid, "bookmark");
            }

            //Call delete collection api
            const response = await AssetService.updateSetBookmark(body, tenantUuid);

            if (response.statusCode !== 200) {
                setEditBookmarkError('duplicated');
                setBookmarkSaving(false);
                return;
            }

            //When call is successful, delete the bookmark in the asset-bookmarks
            await getStoreItem(props.bookmarkUuid, createStore('bookmarks', 'asset-bookmarks'))
                .then(res => {
                    res.name = newBookmarkTitle;
                })
                .catch(error => {
                    console.error(error);
                });
            
                setBookmarkSaving(false);
                updateBookmarkSearch(newBookmarkTitle, props.bookmarkUuid);
                updateMenuTabKey(path.BOOKMARK + '/' + props.bookmarkUuid);

                if(props.viewMobile) {
                    props.handleContainerSwitch();
                } else {
                    props.clickBookmarkOffcanvas();
                    updateToastMessage('Folder edits saved', 'success');
                }
        } catch (error) {
            console.error('error in deleting bookmark:', error.stack);
            setBookmarkSaving(false);
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        const newHeight = Math.max(textarea.scrollHeight);
        textarea.style.height = newHeight + 'px';

        // Event listener for when textarea input changes
        textarea.addEventListener('input', handleTextAreaResize);
        textarea.addEventListener('keydown', handleTextAreaResize);

        // Event listener for when window size changes
        window.addEventListener('resize', handleWindowResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            textarea.removeEventListener('input', handleTextAreaResize);
            textarea.removeEventListener('keydown', handleTextAreaResize);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            <GenericModal 
                modalType={modalType}
                modalShow={modalShow}
                modalLoading={modalLoading}
                handleModalClose={handleModalClose}
                handleModalDelete={handleModalDelete}
                bookmarkDeleting={bookmarkDeleting}
                bookmarkTitle = {newBookmarkTitle}
            />

            <div className='bookmark-edit-wrapper'>
                <div className="bookmark-offcanvas-header web">
                    <div>
                        <h3 className='mb-0'>Edit bookmark folder</h3>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={props.clickBookmarkOffcanvas}><CancelIcon /></button>
                        </div>
                    </div>
                </div>
                <div className="bookmark-offcanvas-header mobile">
                    <div>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={props.clickBookmarkOffcanvas}><ArrowBackRoundedIcon/></button>
                        </div>
                        <h3 className='mb-0'>Edit bookmark folder</h3>
                    </div>
                </div>
                <div className="form-container">
                    <div className='bookmark form-group'>
                        <div className='input-title-container'>
                            <label htmlFor="BookmarkTitle" className={editBookmarkError === 'duplicated' ? 'red-font' : ''}>Folder</label>
                            <textarea
                                type='text'
                                ref={textareaRef}
                                id="bookmarkTitle"
                                onChange={charCounter}
                                onInput={charCounter}
                                value={newBookmarkTitle}
                                placeholder="Enter folder name"
                                className={editBookmarkError === 'duplicated' ? 'form-control red-border' : 'form-control'}
                                onFocus={(e) => {
                                    setCharCount(e.target.value.length);
                                    setBookmarkTitleOnfocus(true);
                                }}
                                onBlur={(e) => {
                                    setBookmarkTitleOnfocus(false);
                                }}
                            ></textarea>
                            <div className="input-counter-container">
                            {editBookmarkError === 'duplicated' &&
                            <label className={`error-label`}>
                                <CustomIcons variant="error"/>
                                {Localization.BookMark.Modal.Alert.TitleExist}
                            </label>
                            }
                            {bookmarkTitleOnfocus &&
                                <>
                                {editBookmarkError !== 'duplicated' && <label className={`limit-label ${charCount >= 30 ? '' : 'hidden'}`}>Character limit reached</label>}
                                <label className={`counter-label ${bookmarkTitleOnfocus? '' : 'hidden'}`}>{charCount}/30</label>
                                </>
                            }
                            </div>
                        </div>
                        <div className='btn-wrapper'>
                            <div className="save-btn button-group">
                                <button
                                    type="submit"
                                    disabled={charCount === 0 || bookmarkSaving}
                                    className={`btn btn-primary`}
                                    onClick={handleEditBookmark}
                                >
                                    Save {bookmarkSaving && <Spinner variant='gray' />}
                                </button>
                            </div>
                            <div className="delete-btn button-group">
                                <button className="btn btn-primary delete-bookmark-btn" type="submit" onClick={handleDeleteBookmark}>Delete folder</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BookmarkEdit;