import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import { SessionContext } from "../../shared/context/session-provider";
import { ShareContext } from '../../shared/context/share-state';
import BreadCrumb from "../../shared/components/breadcrumb";
import AssetFilter from "../../search/asset-filter";
import AssetCard from "../../search/asset-card";
import AssetCardSkeleton from "../../search/asset-card-skeleton";
import path from "../../shared/routePaths";
import ViewAssetsHooks from '../../shared/hooks/view-assets-hooks';
import Localization from "../../shared/localization";
import MobileFilter from "../../shared/components/mobile-filter";
import MobileType from "../../shared/components/mobile-type";
import SettingNavbar from "../../shared/components/setting-navbar";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CustomIcons from "../../shared/components/custom-icons";
import Table from 'react-bootstrap/Table';
import Skeleton from "react-loading-skeleton";
import BookmarkOffCanvas from "../bookmark-offcanvas";
import { getStoreItem, createStore } from "../../../store";
import _ from "lodash";

const BookmarkSearch = (props) => {
    const placeholderSkeleton = 4;
    const [iconClicked, setIconClicked] = useState(false);
    const [bookmarkCount, setBookmarkCount] = useState({});
    const [offCanvasType, setOffCanvasType] = useState("edit");

    // Share Context
    const { 
        mobileView,
        setMobileView,
        numFilters,
        view,
        setView,
        type,
        loading,
        setLoading,
        isFilter,
        fetching,
        filters,
        loadAssetsFunc,
        setIsFetching,
        setFilterState,
        displayFilters,
        clearFilters,
        applyFilters,
        setDisplayFilters,
        onClickFilters,
        handleMobileModalTypeOpen,
        handleMobileModalFilterOpen,
        isMobileModalTypeOpen,
        isMobileModalFilterOpen,
        displayBookmarkAssets,
        setDisplayBookmarkAssets,
        isTablet,
        resetState,
        resetDefaultState,
        allBookmarkAssets,
        setAllBookmarkAssets,
        setCurrentPage,
        currentPage,
        PAGE_SIZE,
        getData,
        bookmarkTotalPages,
        setBookmarkTotalPages,
        setAssetsTypeCount,
        filterTenantAssetsApi,
        nextBookmarkAssetToken
    } = useContext(ShareContext);

    const pathPrefix = "/";
    const [sortFilenameOrder, setSortFilenameOrder] = useState('asc');
    const [sortTypeOrder, setSortTypeOrder] = useState('asc');
    const [sortSizeOrder, setSortSizeOrder] = useState('asc');

    // View Asset Hook
    const {
        handleViewAssetOpen,
    } = ViewAssetsHooks(props);

    const changeView = (view) => {
        setView(view);
    };
    
    const loadItems = async () => {
        if (fetching) return;
    
        setIsFetching(true);
    
        let nextPage = currentPage + 1;
        let pageData = getData(allBookmarkAssets, filters, nextPage, PAGE_SIZE);
        let allAssets = [...allBookmarkAssets];
        let updatedDisplayItems = [...displayBookmarkAssets];
    
        if (nextBookmarkAssetToken && nextPage >= bookmarkTotalPages) {
            try {
                filters.token = nextBookmarkAssetToken;
                let newAssets = await filterTenantAssetsApi(filters, props.bookmarkUuid);
                allAssets = [...allAssets, ...newAssets];
                pageData = getData(allAssets, filters, nextPage, PAGE_SIZE);
        
                setBookmarkTotalPages(pageData.totalPages);
            } catch (err) {
                console.error('ERR in showing assets:',err.message)
            }
        }
    
        // Filter unique items to avoid duplication
        const uniqueItems = pageData.data.filter(
            newItem => !updatedDisplayItems.some(prevItem => prevItem.uuid === newItem.uuid)
        );
        updatedDisplayItems = [...updatedDisplayItems, ...uniqueItems];
    
        // Update states
        setAllBookmarkAssets(allAssets);
        setDisplayBookmarkAssets(updatedDisplayItems);
        setCurrentPage(nextPage);
    
        if (uniqueItems.length === 0) {
            setIsFetching(false);
            return;
        }
    
        setIsFetching(false);
    };
    
    const handleScroll = () => {        
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;

        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= bookmarkTotalPages) {
                return;
            }
            loadItems();
        }
    };

    const sortByFileName = () => {
        setSortFilenameOrder(sortFilenameOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayBookmarkAssets].sort((a, b) => {
            const nameA = a.basename;
            const nameB = b.basename;

            return sortFilenameOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setDisplayBookmarkAssets(sortedAsset);
    };

    const sortByType = () => {
        setSortTypeOrder(sortTypeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayBookmarkAssets].sort((a, b) => {
            const typeA = a.type;
            const typeB = b.type;

            return sortTypeOrder === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        });

        setDisplayBookmarkAssets(sortedAsset);
    };

    const sortBySize = () => {
        setSortSizeOrder(sortSizeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayBookmarkAssets].sort((a, b) => {
            const sizeA = a.fileSize;
            const sizeB = b.fileSize;

            return sortSizeOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
        });

        setDisplayBookmarkAssets(sortedAsset);
    };

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return <AssetCardSkeleton key={index}/>;
        });
    };

    const rowSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return(<div className='asset-card-skeleton-list-view col-12'>
                    <div>
                        <div className='skeleton-list-view'>
                            <div>
                                <Skeleton height={60} />
                            </div>
                            <div>
                                <Skeleton height={60} />
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            );
        });
    };
    const clickBookmarkOffcanvas = () => { setIconClicked(!iconClicked); }

    useEffect(() => {
        const retrieveBookmarkCount = async () => {
            const storeItem = await getStoreItem(props.bookmarkUuid, createStore('bookmarks', 'asset-bookmarks'));
            setAssetsTypeCount(null);
            const { total: allAssets = 0, image = 0 , document = 0, audio = 0, video = 0 } = storeItem;
            setBookmarkCount({ allAssets, image, document, audio, video });
        }
        
       if(props.menuTabKey === path.BOOKMARK + pathPrefix + props.bookmarkUuid) {
            setLoading(true);
            setIsFetching(true);
            retrieveBookmarkCount();
            resetDefaultState();
        }
    }, [props.menuTabKey]);

    useEffect(() => {
        if(props.menuTabKey === path.BOOKMARK + pathPrefix + props.bookmarkUuid) {
            loadAssetsFunc(filters, undefined, null, props.bookmarkUuid);
            window.scrollTo(0, 0);
            document.body.scrollTo(0, 0);
        }
    }, [resetState]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <>
            <section>
                {
                //show Bookmark Offcanvas
                <BookmarkOffCanvas
                    offcanvasShow = {iconClicked}
                    offCanvasType = {offCanvasType}
                    bookmarkUuid = {props.bookmarkUuid}
                    bookmarkTitle = {props.bookmarkBreadcrumb.title}
                    clickBookmarkOffcanvas = {clickBookmarkOffcanvas}
                />
                
                }
                <Container>
                    <div className='bookmark-view-wrapper'>
                        <BreadCrumb breadcrumb={props.bookmarkBreadcrumb.breadcrumb} handleClickBreadcrumb={props.handleClickBookmarkBreadcrumb} />
                        <div className="bookmark-view-search">
                            <section className="pt-5 pb-5">
                                <div className="bookmark-title-wrapper">
                                    <h2>
                                        {props.bookmarkBreadcrumb.title}
                                        <span className="icon" onClick={clickBookmarkOffcanvas}>
                                            <CustomIcons variant="edit" />
                                        </span>
                                    </h2>
                                </div>
                                <div className="row asset-view-container">
                                    <div className="mobile-type-active-container" onClick={() => { handleMobileModalTypeOpen(); setDisplayFilters(false); setFilterState(false);}} >
                                        <h3 id="mobile-type-active">
                                            { type === '' && 'All Assets' }
                                            { type === 'image' && 'Images' }
                                            { type === 'video' && 'Videos' }
                                            { type === 'document' && 'Documents' }
                                            { type === 'audio' && 'Audio' }
                                        </h3>
                                    <KeyboardArrowDownRoundedIcon />
                                    </div>
                                    <div className="mobile-filter-container" onClick={() => { handleMobileModalFilterOpen(); onClickFilters();}} >
                                        <h3>Filters {numFilters !== 0 && <span>{`(${numFilters})`}</span>}</h3>
                                        <KeyboardArrowDownRoundedIcon />
                                    </div>
                                    <div className="mobile-view-container">
                                        <div className={`mobile-view-icon ${mobileView ? "hide" : "show"}`} onClick={() => { setMobileView(true); changeView('grid'); }}><CustomIcons variant="grid"/></div>
                                        <div className={`mobile-view-icon ${mobileView ? "show" : "hide"}`} onClick={() => { setMobileView(false); changeView('row'); }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <SettingNavbar bookmarkUuid={props.bookmarkUuid} bookmarksAssetCount={bookmarkCount}/>
                                </div>
                            </section>
                            {
                                displayFilters && !isTablet &&
                                    <section className={view == 'row' ? 'pb-5' : 'pb-5'}>
                                        <AssetFilter clear={clearFilters} applyFilters={applyFilters} show={displayFilters} bookmarkUuid={props.bookmarkUuid}/>
                                    </section>
                            }
                            <section className="pb-5">
                            { view == 'row'?
                                <div className='assets-container'>
                                    {
                                        !loading ? (displayBookmarkAssets === null || displayBookmarkAssets?.length > 0) &&
                                            <Table className="table-assets">
                                                <thead>
                                                    <tr className='table-dark'>
                                                        <th scope="col">
                                                        </th>
                                                        <th scope="col" className='paragraph-1' onClick={sortByFileName}>
                                                            Filename
                                                            <FontAwesomeIcon icon={faSort} />
                                                        </th>
                                                        <th className='small-column text-left paragraph-1' scope="col" onClick={sortByType}>
                                                            Type
                                                            <FontAwesomeIcon icon={faSort} />
                                                        </th>
                                                        <th className='small-column text-left paragraph-1' scope="col" onClick={sortBySize}>
                                                            Size
                                                            <FontAwesomeIcon icon={faSort} />
                                                        </th>
                                                        <th className='small-column text-left paragraph-1' scope="col">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !loading && displayBookmarkAssets!= null && displayBookmarkAssets.length > 0 && displayBookmarkAssets.map(file => {
                                                            let assetFile = {
                                                                overallStatus: file.overallStatus,
                                                                uuid: file.uuid,
                                                                basename: file.basename,
                                                                bookmarks:file.bookmarks,
                                                                timestamp: file.timestamp,
                                                                type: file.type,
                                                                size: file.fileSize
                                                            }
                                                            return (<AssetCard
                                                                view={view}
                                                                key={assetFile.uuid}
                                                                file={assetFile}
                                                                menuTabKey={props.menuTabKey}
                                                                displayItems={displayBookmarkAssets}
                                                                handleViewAssetOpen={handleViewAssetOpen}
                                                                handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                                        })
                                                    }
                                                    </tbody>
                                                </Table> : rowSkeleton()
                                        }
                                        {
                                            !loading && (displayBookmarkAssets === null || displayBookmarkAssets?.length === 0) && isFilter &&
                                                <>
                                                    <div className='no-cards-found'>
                                                        <p className='font-bold'>No results found</p>
                                                        <ul>
                                                            <li>Check and ensure your inputs and selections are valid</li>
                                                            <li>Try inputting and/or selecting other valid Filters options</li>
                                                        </ul>
                                                    </div>
                                                </>
                                        }
                                        {!loading && (displayBookmarkAssets === null || displayBookmarkAssets?.length === 0) && !isFilter &&
                                            <div className="asset-view-no-asset">
                                                <img src="../images/no-assets.svg" alt="No Assets Illustration"/>
                                                <h2>{Localization.BookMark.AssetList.NoSavedAssets}</h2>
                                                <p>{Localization.BookMark.AssetList.NoAssetDesc}</p>
                                            </div>
                                        }
                                </div> : <></> }
                            { view === 'grid'?  
                                <div className="assets-container row g-3">
                                    {!loading && (displayBookmarkAssets === null || displayBookmarkAssets?.length === 0) && !isFilter &&
                                        <div className="asset-view-no-asset">
                                            <img src="../images/no-assets.svg" alt="No Assets Illustration"/>
                                            <h2>{Localization.BookMark.AssetList.NoSavedAssets}</h2>
                                            <p>{Localization.BookMark.AssetList.NoAssetDesc}</p>
                                        </div>
                                    }
                                    {!loading && (displayBookmarkAssets === null || displayBookmarkAssets?.length === 0) && isFilter &&
                                        <div className='no-cards-found'>
                                            <p className='font-bold'>No results found</p>
                                            <ul>
                                                <li>Check and ensure your inputs and selections are valid</li>
                                                <li>Try inputting and/or selecting other valid Filters options</li>
                                            </ul>
                                        </div>
                                    }
                                    {!loading ? 
                                    displayBookmarkAssets!= null && displayBookmarkAssets?.length > 0 && displayBookmarkAssets.map((file, index, elements) => {
                                        let assetFile = {
                                            overallStatus: file.overallStatus,
                                            uuid: file.uuid,
                                            basename: file.basename,
                                            bookmarks:file.bookmarks,
                                            timestamp: file.timestamp,
                                            type: file.type,
                                            size: file.fileSize
                                        }

                                        return (<AssetCard 
                                            view={view} 
                                            key={assetFile.uuid}
                                            file={assetFile}
                                            menuTabKey={props.menuTabKey}
                                            displayItems={displayBookmarkAssets}
                                            handleViewAssetOpen={handleViewAssetOpen}
                                            handleMouseEnter={props.handleMouseEnter}
                                            handleMouseLeave={props.handleMouseLeave}
                                            handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                        })
                                    : 
                                        renderPlaceholderSkeleton()
                                    }
                                </div> : <></> }      
                            </section>
                        </div>
                        {
                            isMobileModalTypeOpen && (
                                <MobileType bookmarkUuid={props.bookmarkUuid} />
                            )
                        }
                        {
                            isMobileModalFilterOpen && (
                                <MobileFilter hasSort={true} bookmarkUuid={props.bookmarkUuid} />
                            )
                        }
                    </div>
                </Container>
            </section>
        </>
    );
}

export default BookmarkSearch;