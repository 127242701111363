/* eslint-disable no-undef */
import { useState, useEffect, useContext, useRef } from 'react';
import { ShareContext } from '../../shared/context/share-state';
import { createStore, setStoreItem } from '../../../store';
import { ApiContext } from '../../shared/context/api-state';
import { UploadContext } from '../../shared/context/upload-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import { IsTablet, IsDesktop } from '../../shared/utils';
import Button from 'react-bootstrap/Button';
import Localization from '../../shared/localization';
import Container from 'react-bootstrap/Container';
import UploadDropZone from '../upload-dropzone';
import UploadProcess from '../upload-process';
import UploadOffCanvas from '../upload-offcanvas';
import GenericModal from '../../shared/components/generic-modal';
import UsersService from '../../../services/api/usersService';
import UploadSettings from '../upload-settings';
import path from '../../shared/routePaths';
import AWS from 'aws-sdk';

const UploadView = (props) => {
    const [deleteFilesData, setDeleteFilesData] = useState([]);
    const [modalType, SetModalType] = useState("");
    const [modalShow, SetModalShow] = useState(false);
    const uploadStickyTopRef = useRef(null);
    const [enableSetting, setEnableSetting] = useState({
        edit: true,
        tag: true,
        collection: true,
        delete: false
    });
    const [owners, setOwners] = useState([]);
    const [isTopSet, setIsTopSet] = useState(false);
    const isDesktop = IsDesktop();
    const isTablet = IsTablet();
    // Share Context
    const { menuTabKey, resetDefaultState, setAssetTagFiles } = useContext(ShareContext);

    // Upload Context
    const { activeSetting,
        stickyRef,
        settingRef,
        processRef,
        tempfilesData,
        filesData,
        uploadError,
        offcanvasState,
        progressState,
        setProgressState,
        apiUploadFile,
        checkedFiles,
        disableUploadBtn,
        setDisableUploadBtn,
        stickySetting,
        stickyCanvas,
        uploadBtn,
        updateSelectAllChecked,
        updateCheckedFiles,
        updateUploadError,
        updateStickyCanvas,
        updateStickySetting,
        updateActiveSetting,
        updateOffcanvasState,
        updateFileData,
        updateUploadBtn,
        updateDisableUploadBtn,
        updateActiveSettingView,
        activeSettingView,
        setTagAsset,
        setTagMultipleSelection,
        mergeTagArrays, handleProgress, percentage } = useContext(UploadContext);

    // Global Context
    const { updateToastMessage,
        isUploadSuccess } = useContext(GlobalContext);

    // Api Context
    const { getTenantCollection } = useContext(ApiContext);

    //Image Analysis
    const handleImageAnalysis = async (file) => {
        try {
          const fileReader = new FileReader();
          fileReader.onload = async () => {
            const imageBytes = new Uint8Array(fileReader.result);

            var rekognition = new AWS.Rekognition();
            const params = {
                Image: {
                  Bytes: imageBytes,
                },
                MinConfidence: 1,
            };

            await rekognition.detectLabels(params, (err, data) => {
                if (err) console.log(err, err.stack); // an error occurred
                else {
                    console.log('data', data);
                }
            });
          };

          fileReader.readAsArrayBuffer(file);
        } catch (error) {
          console.error('Error analysing image:', error);
        }
    };

    //Video Analysis
    const handleVideoTranscription = () => {
        if (!selectedFile) return;

        const transcribeService = new AWS.TranscribeService();

        const params = {
            LanguageCode: 'en-US', // Change language code if needed
            Media: {
            MediaFileUri: URL.createObjectURL(selectedFile),
            },
            /* Add any additional parameters based on your analysis needs */
        };

        transcribeService.startTranscriptionJob(params, (err, data) => {
            if (err) {
            console.error(err);
            } else {
            setTranscription('Transcription job started. Check AWS Transcribe console for results.');
            }
        });
    };

    // Delete Single Files Data
    const handleDelete = (file) => {
        const newDeleteFilesData = {
            name: file.name,
            id: file.id,
            file: file
        };

        setDeleteFilesData(newDeleteFilesData);
        SetModalType('delete');
        SetModalShow(true);
    }

    // Handler Settings Options
    const handleSetting = (settingType) => {
        switch (settingType) {
            case 'edit':
                getOwner();
                updateActiveSetting('edit');
                updateOffcanvasState(true);
                updateUploadError(false);
                updateUploadBtn(true);
                break;
            case 'collection':
                updateActiveSetting('collection');
                updateOffcanvasState(true);
                updateUploadError(false);
                updateUploadBtn(true);
                break;
            case 'tag':
                updateActiveSetting('tag');
                updateOffcanvasState(true);
                updateUploadError(false);
                updateUploadBtn(true);
                break;
            case 'delete':
                const newDeleteFilesData = [];
                for(let i = 0; i < checkedFiles.length; i++) {
                    newDeleteFilesData.push({
                        name: checkedFiles[i].name,
                        id: checkedFiles[i].id,
                        file: checkedFiles[i].object,
                    })
                }
                setDeleteFilesData(newDeleteFilesData);
                SetModalType('delete');
                SetModalShow(true);
                if(!isDesktop){
                    updateActiveSetting('');
                }
                break;
            case 'grid':
                updateActiveSettingView('grid');
                break;
            case 'list':
                updateActiveSettingView('list');
                break;
            case 'uploadProgress':
                if(offcanvasState){
                    updateOffcanvasState(false);
                    updateActiveSetting('');
                }else{
                    updateOffcanvasState(true);
                    updateActiveSetting('upload-progress');
                }
                updateUploadError(false);
                break;
            default:
                break;
        }

        setEnableSetting((prevEnableSetting) => ({
            ...prevEnableSetting,
            delete: checkedFiles.length > 0
        }));

        setTimeout(() => {
            settingRef.current.style.width = processRef.current.offsetWidth + 'px';
        }, 250);
    };

    // Handler Select All checkbox
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        updateSelectAllChecked(isChecked);
        updateCheckedFiles(() => {
            const tagging = filesData.files.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
            mergeTagArrays(tagging);

            return isChecked ? filesData.files : [];
        });
        setEnableSetting((prevEnableSetting) => ({
            ...prevEnableSetting,
            delete: isChecked
        }));
    };

    // Handler Single Select checkbox
    const handleSelectedChange = (event, file) => {
        const isChecked = event.target.checked;

        if(isUploadSuccess){
            return;
        }

        setTagAsset([]);
        setTagMultipleSelection([]);
        updateCheckedFiles((prevSelectedFiles) => {
            const newSelectedFiles = isChecked
              ? [...prevSelectedFiles, file]
              : prevSelectedFiles.filter((selectedFile) => selectedFile.id !== file.id);

              updateSelectAllChecked(newSelectedFiles.length === filesData.files.length);
            //updateSelectAllChecked(true);
            setEnableSetting((prevEnableSetting) => ({
                ...prevEnableSetting,
                delete: newSelectedFiles.length > 0
            }));

            if(newSelectedFiles.length > 0) {
                if(newSelectedFiles.length > 1) {
                    const tagging = newSelectedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
                    mergeTagArrays(tagging);
                } else {
                    const tagArray = newSelectedFiles[0].attributes.tags ? newSelectedFiles[0].attributes.tags.split(',') : [];
                    setTagAsset(tagArray);
                }
            }
            return newSelectedFiles;
        });
    };

    // Handler Click Select & Checked UploadCard
    const handleSelectedClick = (el, file) => {
        const isChecked = el.target.dataset.checked === "true";

        if(isUploadSuccess || file.state !== ""){
            return;
        }

        setTagAsset([]);
        setTagMultipleSelection([]);
        updateCheckedFiles((prevSelectedFiles) => {
            const newSelectedFiles = isChecked
              ? prevSelectedFiles.filter((selectedFile) => selectedFile.id !== file.id)
              : [...prevSelectedFiles, file];

            updateSelectAllChecked(newSelectedFiles.length === filesData.files.length);

            setEnableSetting((prevEnableSetting) => ({
              ...prevEnableSetting,
              delete: newSelectedFiles.length > 0
            }));

            if(newSelectedFiles.length > 0) {
                if(newSelectedFiles.length > 1) {
                    const tagging = newSelectedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
                    mergeTagArrays(tagging);
                } else {
                    const tagArray = newSelectedFiles[0].attributes.tags ? newSelectedFiles[0].attributes.tags.split(',') : [];
                    setTagAsset(tagArray);
                }
            }
            return newSelectedFiles;
        });
    };

    // Handler Modal Close
    const handleModalClose = () => SetModalShow(false);

    // Handler Modal Delete
    const handleModalDelete = async () => {
        // Set Toast Message
        updateToastMessage(deleteFilesData.length > 1 ? `${deleteFilesData.length} files deleted` : `1 file deleted`, 'success');
        setAssetTagFiles(prev => {
            return Array.isArray(prev) ? prev.filter(item => item.key !== deleteFilesData.name) : [];
        });

        const uploadedFiles = filesData.files;

        if(deleteFilesData.length > 0) {
            const uploadedFiles = filesData.files;

            let i = 0;
            while (i < uploadedFiles.length) {
                let l = 0;
                while (l < deleteFilesData.length) {
                    if (uploadedFiles[i].id === deleteFilesData[l].id) {
                        uploadedFiles.splice(i, 1);
                        deleteFilesData.splice(l, 1);
                        i--;
                        l--;
                        break;
                    }
                    l++;
                }
                i++;
            }
        } else {
            for(let i = 0;i < uploadedFiles.length; i++) {
                if(uploadedFiles[i].id === deleteFilesData.id) {
                    uploadedFiles.splice(i, 1);
                    i--;
                }
            }
        };

        SetModalType('');
        SetModalShow(false);
        updateFileData(uploadedFiles);

        setTimeout(() => {
            const inputFiles = document.querySelectorAll('.upload-card-wrapper .upload-card-checkbox input');

            if(inputFiles.length > 0) {
                updateCheckedFiles((prevSelectedFiles) => {
                    const inputFilesArray = Array.from(inputFiles);
                    const newSelectedFiles = prevSelectedFiles.filter((selectedFile) => inputFilesArray.some(file => file.id === selectedFile.id));

                    setEnableSetting((prevEnableSetting) => ({
                        ...prevEnableSetting,
                        delete: newSelectedFiles.length > 0
                    }));

                    return newSelectedFiles;
                });
            } else {
                setEnableSetting((prevEnableSetting) => ({
                    ...prevEnableSetting,
                    delete: uploadedFiles.length > 0
                }));
            }
        }, 10);

        if(!uploadedFiles.length > 0) {
            updateSelectAllChecked(false);
            updateDisableUploadBtn(false);
            updateActiveSetting("");
            updateUploadError(false);
        }
    };

    // Handler Error Proceeds
    const handlerErrorProceeds = () => {
        updateUploadError(false);
        updateUploadBtn(true);
        updateActiveSetting("");

        if(!filesData.files.length > 0) {
            updateSelectAllChecked(false);
            updateDisableUploadBtn(false);
        }

        if(activeSetting === '') {
            updateOffcanvasState(false);
        }

        if(settingRef.current) {
            settingRef.current.style = '';
        }
    };

    // Update File Attributes
    const updateFileAttributes = (updateAttributes, checkedFiles) => {
        const updatedFiles = filesData.files;

        if(updateAttributes) {
            updatedFiles.forEach((file) => {
                checkedFiles.forEach((checkedFile) => {
                    if(file.id === checkedFile.id) {
                        const checkFilesArr = Object.entries(checkedFile.attributes).map(([key, value]) => ({ key, value }));
                        for(let i = 0; i < checkFilesArr.length; i++) {
                            if(checkFilesArr[i].key === updateAttributes.key) {
                                checkFilesArr[i].value = updateAttributes.value;
                                break;
                            }

                            if(i === checkFilesArr.length - 1) {
                                checkFilesArr.push(updateAttributes);
                            }
                        }
                        const attributes = checkFilesArr.reduce((a0, c0) => ({
                            ...a0,
                            [c0.key]: c0.value,
                        }), {});

                        file.attributes = attributes;
                    }
                })
            });
        }

        updateFileData(updatedFiles);
    };

    // Get Owner APIs
    const getOwner = async () => {
        const usersPromise = await UsersService.getUsers();
        setOwners(usersPromise);
    }

    window.addEventListener('beforeunload', function(event) {
        if(filesData.files) {
            // Check if the page is being refreshed
            if (event.currentTarget.performance.navigation.type === 1 && filesData.files.length > 0) {
                event.preventDefault();
                event.returnValue = '';
            }
        }
    });

    useEffect(() => {
        if(processRef.current && settingRef.current) {
            settingRef.current.style.width = null;
            setTimeout(() => {
                settingRef.current.style.width = processRef.current.offsetWidth + 'px';
            }, 300);
        }
    }, [offcanvasState]);

    useEffect(() => {
        if(menuTabKey === path.UPLOAD) {
            resetDefaultState();

            // Get Collection
            const getCollection = async () => {
                return await getTenantCollection().then((res) => {
                    const collectionData = res.map(item => ({
                        ...item,
                        checked: false
                    }));
                    const sortCollection = _.sortBy(collectionData, [function(o) { return o.name; }]);

                    setStoreItem('all-collections', sortCollection, createStore('get-collections', 'all-collections'));
                }).catch((e) => {console.error(`fail to get collection`)});
            };

            getCollection();
        }
    }, [menuTabKey]);

    const divGridElement = document.querySelector('.grid-btn');
    const divListElement = document.querySelector('.list-btn');
    if (settingRef.current) {
        settingRef.current.style.width = null;
    }
    if(divGridElement && divListElement) {
        if(!isDesktop) {
            if(activeSettingView !== 'grid') {
                divGridElement.style.display = 'block';
                divListElement.style.display = 'none';
            } else {
                divGridElement.style.display = 'none';
                divListElement.style.display = 'block';
            }
        } else {
            divGridElement.style.display = 'block';
            divListElement.style.display = 'block';
        }
    }
    const uploadFiles = filesData.files ? filesData.files.filter(file => file.state === "COMPLETED"): 0
    const errorFiles = uploadError ? tempfilesData.length > 0 ? tempfilesData : 0 : filesData.files ? filesData.files.filter(file => file.state === "ERROR") : 0

    useEffect(() => {
        const filesNotAnalysing = filesData?.files ? filesData.files.every(item => item.state !== 'ANALYSING') : false;

        if(filesNotAnalysing) {
            updateDisableUploadBtn(false);
        } else {
            updateDisableUploadBtn(true);
        }
    }, [filesData]);

    useEffect(() => {
      const handleScroll = () => {
        if(isDesktop && uploadStickyTopRef.current){
            if (window.scrollY > 15) {
                uploadStickyTopRef.current.classList.add('sticky');
                setIsTopSet(true);
            } else {
                uploadStickyTopRef.current.classList.remove('sticky');
                setIsTopSet(false);
            }
        }else{
            return null
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    useEffect(() => {
            window.scrollBy(0, -1);
    }, [props.FSLightbox]);
    return (
        <>
            <section>
                <Container className={offcanvasState ? !uploadError ? 'upload-offcanvas-show' : 'upload-offcanvas-show error-canvas' : null} ref={stickyRef}>
                    {(filesData.total > 0 && !uploadError && uploadBtn && isDesktop) || (progressState && isDesktop) || (uploadError && isDesktop) ?
                        <div className='upload-view-sticky-top' ref={uploadStickyTopRef}>
                            <div className='upload-view-sticky-top-container'>
                                <UploadSettings
                                    onSelectAllChange={handleSelectAllChange}
                                    onHandleSetting={handleSetting}
                                    isActive={activeSetting}
                                    isEnable={enableSetting}
                                    isDelete={disableUploadBtn}
                                    progressState={progressState}
                                    checkedFiles={checkedFiles}
                                    stickySetting={stickySetting}
                                    settingRef={settingRef}
                                />
                                <div className='upload-view-sticky-top-container-button'>
                                    {progressState ? (
                                        uploadFiles.length + errorFiles.length === filesData.total && filesData.total > 0 && percentage >= 100 &&
                                            <Button variant='primary' onClick={handleProgress}>{Localization.Upload.Offcanvas.Progress.Button}</Button>
                                    ):
                                        filesData.total > 0 &&
                                            <Button variant='primary' disabled={disableUploadBtn} onClick={() => {handleSetting('uploadProgress'); apiUploadFile();}}>{`Upload ${filesData.total} File${filesData.total > 1 ? 's' : ''}`}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    <div className={`upload-view-wrapper ${(filesData.total > 0 && !uploadError && uploadBtn && isDesktop) || (progressState && isDesktop) ? 'upload-view-height' : ''}`}>
                        <h2>{Localization.Upload.Title}</h2>
                        {
                            !progressState
                            ?
                                <div className={filesData.files && filesData.files.length > 0 ? 'upload-view-cont upload-view-cont-uploaded' : 'upload-view-cont'}>
                                    <UploadDropZone />
                                </div>
                            : null
                        }
                        <UploadProcess
                            filesData={filesData}
                            handleDelete={handleDelete}
                            handleSetting={handleSetting}
                            activeSetting={activeSetting}
                            handleSelectAllChange={handleSelectAllChange}
                            handleSelectedChange={handleSelectedChange}
                            handleSelectedClick={handleSelectedClick}
                            checkedFiles={checkedFiles}
                            enableSetting={enableSetting}
                            disableDelete={disableUploadBtn}
                            progressState={progressState}
                            FSLightbox={props.FSLightbox}
                            updateFSLightBox={props.updateFSLightBox}
                            processRef={processRef}
                            stickySetting={stickySetting}
                            settingRef={settingRef}
                            isDesktop={isDesktop}
                        />
                    </div>
                    <UploadOffCanvas
                        stickyRef={props.stickyRef}
                        totalFiles={filesData.total}
                        offcanvasState={offcanvasState}
                        apiUploadFile={apiUploadFile}
                        disableUploadBtn={disableUploadBtn}
                        progressState={progressState}
                        uploadedFiles={filesData.files ? filesData.files.filter(file => file.state === "COMPLETED"): 0}
                        errFiles={uploadError ? tempfilesData.length > 0 ? tempfilesData : 0 : filesData.files ? filesData.files.filter(file => file.state === "ERROR") : 0}
                        stickyCanvas={stickyCanvas}
                        uploadError={uploadError}
                        handlerErrorProceeds={handlerErrorProceeds}
                        activeSetting={activeSetting}
                        checkedFiles={checkedFiles}
                        updateFileAttributes={updateFileAttributes}
                        owners={owners}
                        processRef={processRef}
                        onHandleSetting={handleSetting}
                        isDesktop={isDesktop}
                        isTopSet={isTopSet}
                    />
                    {
                        filesData.total > 0 && !uploadError && uploadBtn && !isDesktop &&
                                <div className='upload-view-sticky-bottom upload-settings-mobile'>
                                    {checkedFiles.length > 0 &&
                                        <div className='upload-settings-bottom'>
                                            <UploadSettings
                                                onSelectAllChange={handleSelectAllChange}
                                                onHandleSetting={handleSetting}
                                                isActive={activeSetting}
                                                isEnable={enableSetting}
                                                isDelete={disableUploadBtn}
                                                progressState={progressState}
                                                SetProgressState={setProgressState}
                                                checkedFiles={checkedFiles}
                                                stickySetting={stickySetting}
                                            />
                                        </div>
                                        }
                                        <div>
                                            {
                                                !activeSetting ?
                                                    <Button className='upload-btn' variant='primary' disabled={disableUploadBtn} onClick={apiUploadFile}>{`Upload ${filesData.total} File${filesData.total > 1 ? 's' : ''}`}</Button>
                                                    :
                                                    <Button className='return-btn' variant='primary' onClick={() => updateActiveSetting("")}>
                                                        {'Return'}
                                                    </Button>
                                            }
                                        </div>
                                  </div>
                    }
                </Container>
                {!isDesktop && uploadFiles.length + errorFiles.length === filesData.total && filesData.total > 0 && progressState &&
                    <div className="upload-view-sticky-bottom upload-settings-mobile">
                        <div>
                            <Button variant='primary' onClick={handleProgress}>{Localization.Upload.Offcanvas.Progress.Button}</Button>
                        </div>
                    </div>
                }
            </ section>
            <GenericModal
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                className={'mobile-upload-delete'}
                modalType={modalType}
                handleModalDelete={handleModalDelete}
                deleteFilesData={deleteFilesData}
            />
        </>
    )
}

export default UploadView;