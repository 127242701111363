import React, { useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from '../../shared/context/session-provider';
import { ApiContext } from '../../shared/context/api-state';
import { formatTime } from '../../shared/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';

import ImageIcon from '../../icons/image';
import DocumentIcon from '../../icons/document';
import CustomIcons from '../../shared/components/custom-icons';
import MovieIcon from '../../icons/movie';
import { faFileAudio, faFileExcel, faFilePowerpoint, faFileWord } from '@fortawesome/free-solid-svg-icons';
import GridSkeleton from './GridSkeleton';


const GridFilteredAssets = ({ data, allDataDisplay, handleViewAssetOpen, onCheckboxChange, checkedMyAssets, toggleSingleDeleteModal, isTablet }) => {

    const { tenantUuid } = useContext(SessionContext);
    const { getAssetsById } = useContext(ApiContext);

    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [key, setKey] = useState(null);
    const [fileUUID, setFileUUID] = useState(null);
    const [basename, setBasename] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [type, setType] = useState(null);
    const [size, setSize] = useState(null);
    const [status, setStatus] = useState(null);

    // image
    const [imageUrl, setImageUrl] = useState(null);
    const [gifUrl, setGifUrl] = useState(null);

    // document
    const [mime, setMime] = useState(null);


    // audio / video
    const vidRef= useRef();
    const [playVideo, setPlayVideo] = useState(false);
    const [videoContentType, setVideoContentType] = useState(null);
    const [videoBlob, setVideoBlob] = useState(null);

    const audioRef = useRef();
    const [playAudio, setPlayAudio] = useState(false);
    const [audioContentType, setAudioContentType] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);

    const [mute, setMute] = useState(true);
    const [duration, setDuration] = useState(null);

    // checkbox
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setAllData(allDataDisplay);
        setFileUUID(data.uuid);
        setBasename(data.basename);
        setTimestamp(data.timestamp);
        setType(data.type);
        setSize(data.size);
        setLoading(true);

        if(data.overallStatus === 'COMPLETED') {
            getAssetsById(data.uuid, tenantUuid).then(asset => {
                if(asset) {
                    setFileUUID(asset.uuid);
                    setKey(asset.key);
                    setStatus(asset.overallStatus);

                    if (asset.type === 'image') {
                        if (asset.mime === 'image/gif') {
                            const proxyurl = URL.createObjectURL(asset.proxyBlob);
                            const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                            setImageUrl(proxyurl);
                            setGifUrl(ingestUrl);
                            setLoading(false);

                            return;
                        }

                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setLoading(false);
                    }

                    if (asset.type === 'document') {
                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setMime(asset.mime);
                        setLoading(false);
                    }

                    if (asset.type === 'video') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                        const proxyUrl = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(proxyUrl);
                        setVideoBlob(ingestUrl);
                        setVideoContentType(asset.mime);
                        setDuration(asset.duration);
                        setLoading(false);
                    }

                    if (asset.type === 'audio') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);

                        setMime(asset.mime);
                        setAudioContentType(asset.mime);
                        setAudioUrl(ingestUrl);
                        setDuration(asset.duration);
                        setLoading(false);
                    }
                } else {
                    setImageUrl(null);
                    setLoading(false);
                }

            });
        } else {
            setStatus(data.overallStatus);
            setLoading(false);
        }
    }, []);

    // useEffect(() => {
    //     selectAllChecked ? setIsChecked(true) : setIsChecked(false);
    // }, [selectAllChecked]);

    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -4 + (get_file_extension(fulltext).length * -1)); // Remove the last 3 or 4 characters and the extension

        return truncatedFulltext;
    }

    const get_file_extension = (filename) => {
        const parts = filename.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1];
        } else {
            return null;
        }
    };

    const truncateEndText = (fulltext) => {
        const extension = "." + get_file_extension(fulltext);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    }

    // Audio
    const onPressMute = (e, type) => {
        e.stopPropagation();
        e.preventDefault();

        if (type == 'video') {
            setMute(!mute);
            vidRef.current.muted = mute;
        }
        if (type == 'audio') {
            setMute(!mute);
            audioRef.current.muted = mute;
        }
    }

    const onAudioMouseEnter = () => {
        if (audioRef.current) {
            setPlayAudio(true);
            audioRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const currentTime = audioRef.current?.currentTime;
                const formattedRemainingTime = formatTime(audioRef.current?.duration - currentTime);

                setDuration(formattedRemainingTime);
            });
            audioRef.current.play();
        }
    }

    const onAudioMouseLeave = () => {
        if (audioRef.current) {
            setPlayAudio(false);
            audioRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const duration = audioRef.current?.duration;
                const formattedDuration = formatTime(duration);

                setDuration(formattedDuration);
            });
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setMute(true);
        }
    }

    // Video
    const onVideoMouseEnter = () => {
        if (vidRef.current) {
            setPlayVideo(true);
            vidRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the video
                const currentTime = vidRef.current?.currentTime;
                const formattedRemainingTime = formatTime(vidRef.current?.duration - currentTime);

                setDuration(formattedRemainingTime);
            });
            vidRef.current.play();
        }
    }

    const onVideoMouseLeave = () => {
        if (vidRef.current) {
            setPlayVideo(false);
            vidRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the video
                const duration = vidRef.current?.duration;
                const formattedDuration = formatTime(duration);

                setDuration(formattedDuration);
            });
            vidRef.current.pause();
            vidRef.current.currentTime = 0;
            setMute(true);
        }
    }

    const handleCheckboxChangeTrigger = () => {
        onCheckboxChange(fileUUID);
    }

    return (<>
        {
            loading
            ?
                <GridSkeleton view="grid" />
            :
                status !== 'COMPLETED' ?
                    null
                :
                <div className="asset-card" style={{border: checkedMyAssets.includes(fileUUID) ? '1px solid black' : '1px solid #E1E1E1'}} onMouseEnter={type === 'audio' ? onAudioMouseEnter : type === 'video' ? onVideoMouseEnter : null} onMouseLeave={type === 'audio' ? onAudioMouseLeave : type === 'video' ? onVideoMouseLeave : null}>
                    {type === 'audio' && (
                        <div className="asset-card-body audio" onClick={() => handleViewAssetOpen(fileUUID, allData)}>
                            <div className="ratio ratio-4x3">
                                <div className='svg-icon'>
                                    <FontAwesomeIcon className='audio-icon' icon={faFileAudio} />
                                    <audio muted={mute} ref={audioRef}>
                                        <source src={audioUrl} type={audioContentType}/>
                                    </audio>
                                </div>
                                <div className='volume-icon-section' style={{ display: playAudio ? 'flex': 'none' }}>
                                    <div className="grid-view-title-container" style={{width: '60%'}}>
                                        <div className="first-text">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                        <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                    </div>
                                    {!isTablet &&
                                        <div className="volume-svg" onClick={(e) => onPressMute(e, type)}>{mute ? <VolumeOffIcon /> : <VolumeUpIcon />}</div>
                                    }
                                    <p>{duration}</p>
                                </div>
                            </div>
                            <div className="asset-card-type-icon">
                                <AudiotrackRoundedIcon className='my-svg' color='white' />
                            </div>
                        </div>
                    )}
                    {type === 'video' && (
                        <div className="asset-card-body video" onClick={() => handleViewAssetOpen(fileUUID, allData)}>
                            <div className="ratio ratio-4x3">
                                <video ref={ vidRef } muted={mute} playsInline>
                                    <source src={videoBlob} type="video/mp4"/>
                                </video>
                            </div>

                            <div className='volume-icon-section' style={{ display: playVideo? 'flex': 'none' }}>
                                <div className="grid-view-title-container" style={{width: '60%'}}>
                                    <div className="first-text">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                    <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                </div>
                                {!isTablet &&
                                    <div className="volume-svg" onClick={(e) => onPressMute(e, type)}>{mute ? <VolumeOffIcon /> : <VolumeUpIcon />}</div>
                                }
                                <p>{ duration }</p>
                            </div>
                            <div className="asset-card-type-icon">
                                <MovieIcon className='my-svg' color='white' />
                            </div>
                        </div>
                    )}
                    {type === 'image' && (
                        <div className="asset-card-body image" onClick={() => handleViewAssetOpen(fileUUID, allData)}>
                            <div className="ratio ratio-4x3">
                                {key.endsWith(".gif")? <img src={gifUrl} alt={basename} /> : <img src={imageUrl} alt={basename} /> }
                            </div>
                            <div className="asset-card-body-title">
                                <div className="grid-view-title-container">
                                    <div className="first-text">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                    <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                </div>
                            </div>
                            <div className="asset-card-type-icon">
                                {/* { card.filename && card.filename.endsWith('.gif') && <CustomIcons className='my-svg' variant='gif' color="white"/> }
                                { card.filename && card.filename.endsWith('.jpg') && <ImageIcon className='my-svg' color='white' />}
                                { card.filename && card.filename.endsWith('.png') && <ImageIcon className='my-svg' color='white' />} */}
                                <ImageIcon className='my-svg' color='white' />
                            </div>
                        </div>
                    )}
                    {type === 'document' && (
                        <div className="asset-card-body document" onClick={() => handleViewAssetOpen(fileUUID, allData)}>
                            {
                            mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mime === 'application/vnd.ms-excel' ?  
                                <div className="ratio ratio-4x3">
                                    <FontAwesomeIcon icon={faFileExcel} className='svg-icon'/>
                                </div>
                            :
                            mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mime === 'application/msword' ?  
                                <div className="ratio ratio-4x3">
                                    <FontAwesomeIcon icon={faFileWord} className='svg-icon'/>
                                </div>
                            :
                            mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || mime === 'application/vnd.ms-powerpoint' ?  
                                <div className="ratio ratio-4x3">
                                    <FontAwesomeIcon icon={faFilePowerpoint} className='svg-icon'/>
                                </div>
                            :
                            <div className="ratio ratio-4x3">
                                <img src={imageUrl} alt={basename} />
                            </div>
                            }
                            <div className="asset-card-body-title">
                                <div className="grid-view-title-container">
                                    <div className="first-text">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                    <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                </div>
                            </div>
                            <div className="asset-card-type-icon">
                                { key && /\.(pdf|doc|docx|xls|xlsx|ppt|pptx)$/i.test(key) && <DocumentIcon className='my-svg' color='white' /> }
                            </div>
                        </div>
                    )}
                    <div className="asset-card-footer">
                        <input
                            type="checkbox"
                            checked={checkedMyAssets.includes(fileUUID)}
                            onChange={handleCheckboxChangeTrigger}
                            id={fileUUID}
                        />
                        <label htmlFor={fileUUID}>
                            <CustomIcons variant='check' color="#000000" />
                        </label>
                        <span onClick={() => toggleSingleDeleteModal(fileUUID)}><CustomIcons variant="trash" /></span>
                    </div>
                </div>
        }
        </>
    );
}

export default React.memo(GridFilteredAssets);