import _ from 'lodash';
import { Select } from 'antd';
import Skeleton from 'react-loading-skeleton';
import path from "../../shared/routePaths";
import { IsTablet } from "../../shared/utils";
import BookmarkCardSkeleton from '../bookmark-skeleton';
import S3Utils from '../../../services/util/s3utils';
import { Navbar, Container, Nav } from 'react-bootstrap';
import SolutionManifest from '../../../solution-manifest';
import CustomIcons from "../../shared/components/custom-icons";
import BookmarkHooks from '../../shared/hooks/bookmark-hooks';
import Localization from '../../shared/localization';
import { ApiContext } from "../../shared/context/api-state";
import { ShareContext } from "../../shared/context/share-state";
import { useMetadata } from '../../shared/context/meta-provider';
import { useState, useEffect, Suspense, lazy, useContext } from "react";
import { createStore, getStoreItem, setStoreItem } from '../../../store';
import BookmarkCard from '../bookmark-card';
import AssetCardSkeleton from '../../search/asset-card-skeleton';
import MobileModal from "../../shared/components/mobile-modal";


const BookMarkView = (props) => {
    const [sort, setSortBy] = useState(1);
    const [loading, setLoading] = useState(true);
    const [fetching, setIsFetching] = useState(false);
    const [fetchBookmark, setFetchBookmark] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const isTablet = IsTablet();
    const placeholderSkeleton = 3;
    const { getAllBookmarks } = useContext(ApiContext);
    const { handleClickBookmarkCard } = BookmarkHooks();
    const { userBookmarks } = useMetadata();
    const {
        allBookmark,
        setAllBookmark,
        isMobileModalOpen,
        handleMobileModalOpen,
        handleMobileModalClose,
        STANDARD_PAGE_SIZE,
        PAGE_SIZE,
        getData,
        bookmarkCard,
        setBookmarkCard,
        nextBookmarkToken,
        setNextBookmarkToken
    } = useContext(ShareContext);

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
            return(<div className={isTablet ? "col-12" : "col-4"} key={index}><BookmarkCardSkeleton /></div>);
        });
    };

    const handleSortBy = (value) => {
        switch (value) {
            case 1:
                const sortedDataDesc = _.sortBy(allBookmark, (item) => -item.timestamp);
                setBookmarkCard(sortedDataDesc);
                break;
            case 2:
                const sortedDataAtoZ = _.orderBy(allBookmark, [(item) => item.name]);
                setBookmarkCard(sortedDataAtoZ);
                break;
            case 3:
                const sortedDataZtoA = _.orderBy(allBookmark, [(item) => item.name], ['desc']);
                setBookmarkCard(sortedDataZtoA);
                break;
            default:
                break;
        }
        setSortBy(value);
    };

    const loadItems = async () => {
        if (fetching) return;
    
        setIsFetching(true);
    
        // Check if bookmarkCard is iterable
        if (!Array.isArray(bookmarkCard)) {
            setIsFetching(false);
            return;
        }
    
        try {
            const nextPage = currentPage + 1;
            let bookmarkCardPreview = [...bookmarkCard];
            let pageData;
    
            // Fetch new bookmarks if required
            if (nextBookmarkToken && nextPage >= totalPages) {
                try {
                    const newBookmark = await getBookmark();
    
                    if (Array.isArray(newBookmark)) {
                        bookmarkCardPreview = [...bookmarkCardPreview, ...newBookmark];
                    } else {
                        bookmarkCardPreview = [...bookmarkCardPreview];
                    }
                } catch (err) {
                    console.error("Error fetching bookmarks:", err.message);
                    setIsFetching(false);
                    return;
                }
            }
    
            // Calculate pageData
            pageData = getData(bookmarkCardPreview, null, nextPage, PAGE_SIZE);
            if (!pageData || !Array.isArray(pageData.data)) {
                console.error("Invalid pageData or data is not iterable:", pageData);
                setIsFetching(false);
                return;
            }
    
            // Filter unique items
            const uniqueItems = pageData.data.filter(
                newItem => !bookmarkCard.some(prevItem => prevItem.uuid === newItem.uuid)
            );
    
            bookmarkCardPreview = [...bookmarkCardPreview, ...uniqueItems];
    
            // Update states
            setBookmarkCard(bookmarkCardPreview);
            setAllBookmark(bookmarkCardPreview);
            setTotalPages(pageData.totalPages);
            setCurrentPage(nextPage);
    
            // Stop fetching if no new items are found
            if (uniqueItems.length === 0) {
                setIsFetching(false);
                return;
            }
        } catch (error) {
            console.error("Unexpected error in loadItems:", error.message);
        } finally {
            setIsFetching(false);
        }
    };
    
    
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;
        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= totalPages) {
                return;
            }

            loadItems();
        }
    };

    const getBookmark = async () => {
        setBookmarkCard([]);
        setFetchBookmark(false);

        try {
            // retrieve the bookmarks under specific user
            const userBookmarks = await getAllBookmarks(null, 'bookmarks', STANDARD_PAGE_SIZE, nextBookmarkToken);
            setNextBookmarkToken(userBookmarks.nextToken);  

            if(userBookmarks && userBookmarks.response?.length > 0) {
                // filter the bookmarks without assigned ingests
                const sortedFilterRes = _.sortBy(userBookmarks.response,'timestamp').reverse();
                if(sortedFilterRes.length > 0) {

                    await sortedFilterRes.forEach(async (element, index) => {
                        let elArray = element;
                        await getStoreItem(element.uuid, createStore('bookmarks', 'asset-bookmarks')).then(async storeItem => {
                            setAllBookmark((prevBookmark) => {
                                const index = prevBookmark.findIndex(prevEl => prevEl.uuid === element.uuid);
                                if (index !== -1) {
                                    const updatedBookmarks = [...prevBookmark];
                                    updatedBookmarks[index] = elArray;
                                    return updatedBookmarks;
                                } else {
                                    return [...prevBookmark, elArray];
                                }
                            });
                            
                            setStoreItem(element.uuid, elArray, createStore('bookmarks', 'asset-bookmarks'));
                        });
                    });

                    setFetchBookmark(true);
                    setLoading(false);

                    return sortedFilterRes;
                } else {
                    setBookmarkCard([]);
                    setFetchBookmark(true);
                }
            } else {
                setBookmarkCard([]);
                setFetchBookmark(true);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        if(props.menuTabKey === path.BOOKMARK) {
            const renderBookmarkCard = async () => {
                const allBookmark = await getBookmark();
                setBookmarkCard(Array.isArray(allBookmark) ? [...allBookmark] : []);
            }
            renderBookmarkCard();
        }
    },[props.menuTabKey]);

    return (
        <>
            {
                props.menuTabKey === path.BOOKMARK &&
                <section>
                    <Container>
                        <div className="bookmark-view-wrapper">
                            {
                                fetchBookmark ?
                                    bookmarkCard.length > 0 ?
                                        <>
                                        <h2>{Localization.BookMark.Main.Title}</h2>
                                        <div className="row asset-view-wrapper">
                                            <div className="col-12">
                                                <Navbar expand="lg" className="justify-content-between">
                                                    <Nav className="w-100">
                                                        <Nav.Item>
                                                            <div className="bookmark-view-show-number">
                                                                {`${userBookmarks} ${userBookmarks <= 1 ? 'folder' : 'folders'}`}
                                                            </div>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <div className="bookmark-view-sort-by">
                                                                <Select
                                                                    id="sort-bookmark"
                                                                    className='border-0'
                                                                    defaultValue={1}
                                                                    value={sort}
                                                                    bordered={false}
                                                                    suffixIcon={<CustomIcons variant="arrow" direction="expand"/>}
                                                                    options={[
                                                                        { value: 1, label: 'Recently added' },
                                                                        { value: 2, label: 'A to Z' },
                                                                        { value: 3, label: 'Z to A' },
                                                                    ]}
                                                                    aria-label="sort"
                                                                    onChange={(value) => handleSortBy(value)}
                                                                />
                                                            </div>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Navbar>
                                                <div className="mobile-bookmark-view-container">
                                                    <h3>{`${userBookmarks} ${userBookmarks <= 1 ? 'folder' : 'folders'}`}</h3>
                                                                
                                                    <div className="mobile-bookmark-new-to-old"  onClick={handleMobileModalOpen}>
                                                        <h3>
                                                            {sort === 1 && 'Recently added'}
                                                            {sort === 2 && 'A to Z'}
                                                            {sort === 3 && 'Z to A'}
                                                            <CustomIcons variant="arrow" direction="expand"/>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bookmark-view-card-container">
                                            <div className="row">
                                            {
                                                bookmarkCard.map((bookmark) => {
                                                    return (
                                                       <Suspense key={bookmark.uuid} fallback={<div className="col-4"><BookmarkCardSkeleton view='single' /></div>}>
                                                            <div className="col-4" key={bookmark.uuid}>
                                                                <BookmarkCard
                                                                    uuid={bookmark.uuid}
                                                                    handleClickBookmarkCard={handleClickBookmarkCard}/>
                                                            </div>
                                                        </Suspense>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                        </>
                                    :
                                    <>
                                        <div className="no-bookmark-view">
                                            <img src="./images/no-assets.svg" alt="No Bookmark Illustration"/>
                                            <h2>{Localization.BookMark.Main.NoBookmarks}</h2>
                                            <p>{Localization.BookMark.Main.NoBookmarksDesc}</p>
                                        </div>
                                    </>
                                :
                                <div className="row">
                                    <Skeleton className="mb-3" width={200} height={30} />
                                    {renderPlaceholderSkeleton()}
                                </div>
                            }
                        </div>
                    </Container>

                    {
                        isMobileModalOpen && (
                            <MobileModal title="Sort By" onClose={handleMobileModalClose}>
                                <div className="mobile-modal-sort-only">
                                    <div className="sort-data">
                                        <input
                                            type="radio"
                                            id="new-to-old"
                                            name="sort"
                                            value={1}
                                            checked={sort === 1}
                                            onChange={() => { handleSortBy(1); handleMobileModalClose(); }}
                                        />
                                        <label htmlFor="new-to-old">
                                            <h3>Recently added</h3>
                                        </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="a-to-z"
                                                name="sort"
                                                value={2}
                                                checked={sort === 2}
                                                onChange={() => { handleSortBy(2); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="a-to-z">
                                                <h3>A to Z</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="z-to-a"
                                                name="sort"
                                                value={3}
                                                checked={sort === 3}
                                                onChange={() => { handleSortBy(3); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="z-to-a">
                                                <h3>Z to A</h3>
                                            </label>
                                        </div>
                                    </div>
                                </MobileModal>
                            )
                        }
                </section>
            }
        </>
    );
}

export default BookMarkView;