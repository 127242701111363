import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = forwardRef(({ src, onCropComplete }, ref) => {
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  useImperativeHandle(ref, () => ({
    makeClientCrop: () => {
      if (completedCrop) {
        handleCropComplete(completedCrop);
      }
    }
  }));

  const handleCropComplete = (crop) => {
    if (crop.width && crop.height && imgRef.current) {
      const image = imgRef.current;
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        const croppedUrl = URL.createObjectURL(blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          onCropComplete(croppedUrl, base64data); // Call onCropComplete with the cropped URL and base64 image
        };
      });
    }
  };

  return (
    <div className='image-cropper-container'>
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <img ref={imgRef} src={src} alt="Crop me" className="react-crop-image" />
      </ReactCrop>
    </div>
  );
});

export default ImageCropper;