import { useContext, useState } from "react";
import CustomInput from "../forms/custom-input";
import { ShareContext } from "../../context/share-state";
import CustomCheckbox from "../forms/custom-checkbox";
import CustomIcons from "../custom-icons";
import { sunsetOrange } from "../../utils";
import Localization from "../../localization";

const AddBookMark = (props) => {
    
    const { allBookmark, 
            selectedAssetUuid,
            isCreateSelected, 
            setIsCreateSelected } = useContext(ShareContext);

    const getSortedBookmarks = () => {
        try {
            const sortedBookmarks = [...allBookmark].sort((a, b) => b.timestamp - a.timestamp);
            return sortedBookmarks;
        } catch (error) {
            console.error("Error sorting bookmarks:", error);
            return allBookmark;
        }
    };
    const sortedBookmarks = getSortedBookmarks();

    const handleCreateBtnSelect = () => {
        setIsCreateSelected(!isCreateSelected);
    }

    return (
        <>
            <div className="bookmark-input-wrapper">
                <div className="bookmark-names">
                    {sortedBookmarks.length > 0 && sortedBookmarks.map((item) => {
                        const isChecked = item.assets.some(asset => asset.uuid === selectedAssetUuid);
                        return (
                            <CustomCheckbox
                                key={item.uuid}
                                bookmarkKey={item.uuid}
                                label={item.name}
                                validated={true}
                                disabled={false}
                                customCheck={isChecked}
                            />
                        );
                    })}
                </div>
                    {
                        allBookmark.length > 0 ?
                            isCreateSelected ?
                                <CustomInput 
                                    label={Localization.BookMark.Modal.Input.Label}
                                    placeholder={Localization.BookMark.Modal.Input.Placeholder}
                                    type="text"
                                    validated={true} 
                                    charlimit   
                                /> 
                            :
                                <button className="create-btn" onClick={handleCreateBtnSelect}>
                                    <CustomIcons variant="add" className="add-icon" color={sunsetOrange} />
                                    <p>Create new folder</p>
                                </button> 
                        :
                            <CustomInput 
                                label={Localization.BookMark.Modal.Input.Label}
                                placeholder={Localization.BookMark.Modal.Input.Placeholder}
                                type="text"
                                validated={true}    
                                charlimit
                            /> 
                    }
            </div>
        </>
    )
}

export default AddBookMark;