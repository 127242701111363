/* eslint-disable no-undef */
import ServiceUtil from '../util/serviceUtil';
import ENDPOINTS from '../util/endpoints';

class metaService {
    static async getMetaData(tenantUuid) {
        const query = {
            uuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.MetaData, query, '', tenantUuid);
    }
}

export default metaService;
