import { useState, useEffect, useContext, useRef } from 'react';
import { Tab, Tabs, Form, Button } from 'react-bootstrap';
import { Table, Dropdown, Tag } from 'antd';
import { formatDateTime, IsMobile, IsTablet } from '../../shared/utils';
import { AccountContext } from "../../shared/context/account-provider";
import { isDateInRange } from '../../shared/utils';
import { ShareContext } from '../../shared/context/share-state';
import { SessionContext } from '../../shared/context/session-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import { AdditionalUserOffcanvasContext } from '../../shared/context/additional-user-offcanvas-provider';
import CustomIcons from '../../shared/components/custom-icons';
import Localization from '../../shared/localization';
import UsersService from '../../../services/api/usersService';
import GenericModal from '../../shared/components/generic-modal';
import UserRequestSettingsBar from './user-request-settings-bar';
import UserRequestFilter from './user-request-filter';
import SolutionManifest from '../../../solution-manifest';
import _ from 'lodash';
import ArrowIcon from "../../icons/arrow";
import AdditionalUserOffcanvas from './additional-user-offcanvas';
import MobileModal from '../../shared/components/mobile-modal';
import UserRequestMobileModals from './user-request-mobile-modals';

const UserRequest = (props) => {
    // Account Context
    const { userGroups } = useContext(AccountContext);

    // Session Context
    const { tenantUuid,
            userUuid,
            groupsRoles,
            subscriptionPlan,
            getSession } = useContext(SessionContext);

    const [customSelectMultipleClearAll, setCustomSelectMultipleClearAll] = useState(false);

    // Pagination
    const [paginationStart, setPaginationStart] = useState(0);
    const [paginationEnd, setPaginationEnd] = useState(0);
    const [paginationTotal, setPaginationTotal] = useState(0);

    // Pending Data
    const [currentPage, setCurrentPage] = useState(1);

    // Approved Data
    const [tableApprovedData, setTableApprovedData] = useState([]);
    const [tableFilterApprovedData, setTableFilterApprovedData] = useState([]);

    // Rejected Data
    const [tableRejectedData, setTableRejectedData] = useState([]);
    const [tableFilterRejectedData, setTableFilterRejectedData] = useState([]);

    // Filter
    const [rangePickerValue, setRangePickerValue] = useState(null);
    const [rangePickerValueFrom, setRangePickerValueFrom] = useState(null);
    const [rangePickerValueTo, setRangePickerValueTo] = useState(null);
    const [userGroupFilterValue, setUserGroupFilterValue] = useState(null);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [filterDateText, setFilterDateText] = useState("");
    const [isFilterDate, setIsFilterDate] = useState(false);


    // Share Context
    const { listFilterTenantUser, tablePageSize, userRequestExpandedRowKeys, setUserRequestExpandedRowKeys,
        setSelectAllRequestChecked,
        checkedRequestUser,
        setCheckedRequestUser,
        isRequestFilter,
        setIsRequestFilter,
        openRequestFilter,
        setOpenRequestFilter,
        setMobileViewApprove,
        isLoading,
        setIsLoading,
        tabKey,
        setTabKey,
        tablePendingData,
        setTablePendingData,
        tableFilterPendingData,
        setTableFilterPendingData,
        dropdownMenuCheckedRole,
        setDropdownMenuCheckedRole } = useContext(ShareContext);

    // Additional User Context
    const { setOffcanvasData,
        setOffcanvasShow } = useContext(AdditionalUserOffcanvasContext);

    // Global Context
    const { updateToastMessage } = useContext(GlobalContext);

    const [dropdownVisibleMap, setDropdownVisibleMap] = useState({});
    const [fullNameSearch, setFullNameSearch] = useState("");
    const [alertNotificationData, setAlertNotificationData] = useState({
        variant: "",
        content: ""
    });
    const [modalData, setModalData] = useState({
        show: false,
        userRequestType: null,
        userRequestLoading: false,
        userRequestData: [],
        userRequestParams: {},
    });
    const [isMobileModalOpenRequestType, setIsMobileModalOpenRequestType] = useState(false);
    const [isMobileModalOpenAction, setIsMobileModalOpenAction] = useState(false);
    const [isViewMobileModalOpen, setIsViewMobileModalOpen] = useState(false);
    const [isViewMobileModalOpenApproved, setIsViewMobileModalOpenApproved] = useState(false);
    const [isViewMobileModalOpenApprovedPaid, setIsViewMobileModalOpenApprovedPaid] = useState(false);
    const [isMobileModalOpenFilter, setIsMobileModalOpenFilter] = useState(false);
    const [isMobileModalOpenEdit, setIsMobileModalOpenEdit] = useState(false);
    const [countFilter, setCountFilter] = useState(0);
    const [sortOrder, setSortOrder] = useState(true);
    const [sortOrderMobile, setSortOrderMobile] = useState('ascend');
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    if(sortOrder){
        if(tabKey === 'pending') {
            tablePendingData.sort((a, b) => {
                return a.fullname.localeCompare(b.fullname);
            });
        }
        else if(tabKey === 'pending' && isRequestFilter || isFilterDate) {
            tableFilterPendingData.sort((a, b) => {
                return a.fullname.localeCompare(b.fullname);
            });
        } else if(tabKey === 'approved' && isRequestFilter || isFilterDate) {
            tableFilterApprovedData.sort((a, b) => {
                return a.fullname.localeCompare(b.fullname);
            });
        } else if(tabKey === 'rejected' && isRequestFilter || isFilterDate) {
            tableFilterRejectedData.sort((a, b) => {
                return a.fullname.localeCompare(b.fullname);
            });
        }
    }else{
        if(tabKey === 'pending') {
            tablePendingData.sort((a, b) => {
                return b.fullname.localeCompare(a.fullname);
            });
        }
        else if(tabKey === 'pending' && isRequestFilter || isFilterDate) {
            tableFilterPendingData.sort((a, b) => {
                return b.fullname.localeCompare(a.fullname);
            });
        } else if(tabKey === 'approved' && isRequestFilter || isFilterDate) {
            tableFilterApprovedData.sort((a, b) => {
                return b.fullname.localeCompare(a.fullname);
            });
        } else if(tabKey === 'rejected' && isRequestFilter || isFilterDate) {
            tableFilterRejectedData.sort((a, b) => {
                return b.fullname.localeCompare(a.fullname);
            });
        }
    }
    const handleMobileSortByAsc = () => {
        setSortOrderMobile('ascend');
    };
    const handleMobileSortByDes = () => {
        setSortOrderMobile('descend');
    };
    const handleDesktopSortBy = (pagination) => {
        if(Object.keys(pagination).length === 0){
            setSortOrder(!sortOrder);
        }else if(currentPage !== pagination.current){
            return
        }else{
        setSortOrder(!sortOrder);
        const updatedTableData = tablePendingData.map(item => ({
            ...item,
            checked: false
        }));
        const updatedCheckedUser = tablePendingData.map(item => ({
            key: item.key,
            checked: false
        }));
        setCheckedRequestUser([]);
        setSelectAllRequestChecked(false);
        setCheckedRequestUser(updatedCheckedUser);
        setTablePendingData(updatedTableData);
        handleUncheck();

        }
    };

    const tableColumns = [
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, record) => (
                <div className='ant-table-custom-cell'>
                    <span>
                        <Form.Check
                            type='checkbox'
                            id={record.key}
                            onChange={(event) => handleCheckbox(event, record.key)}
                            checked={record.checked}
                            disabled={record.disabled}
                        />
                    </span>
                    <span className='truncated-span'>{record.fullname}</span>
                </div>
            ),
        },
        {
          title: 'User Group',
          dataIndex: 'userGroup',
          sorter: (a, b) => a.userGroup.localeCompare(b.userGroup)
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (_, record) => {
                if(tabKey === 'pending') {
                    return(<Dropdown
                        dropdownRender={() => dropdownMenu(record)}
                        trigger={['click']}
                        placement="bottomLeft"
                        open={dropdownVisibleMap[record.key]}
                        onOpenChange={visible => {
                            setDropdownVisibleMap(prevMap => ({
                                ...prevMap,
                                [record.key]: visible,
                            }));
                        }}
                    >
                        <div className='ant-dropdown-select'>
                            <div className='ant-tag-container'>
                                {record.groupRoles.some(item => item.checked) ? record.groupRoles.map((selectedRole) => (
                                    selectedRole.checked ? (
                                    <Tag
                                        key={selectedRole.name}
                                        className={
                                        selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                                        selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                        selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                        selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                        selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                        'custom-select-checkbox-tag'
                                        }
                                    >
                                        {selectedRole.name}
                                    </Tag>
                                    ) : null
                                )) : "Select a role"}
                            </div>
                            <CustomIcons variant='arrow' className="ant-dropdown-arrow"/>
                        </div>
                    </Dropdown>)
                } else if(tabKey === 'approved') {
                    {
                        return(<div className='ant-tag-container'>{record.groupRoles.map((selectedRole) => (
                        <Tag
                            key={selectedRole.label}
                            className={
                            selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                            selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                            selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                            selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                            selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                            'custom-select-checkbox-tag'
                            }
                        >
                            {selectedRole.label}
                        </Tag>))}</div>)
                    }
                }
            },
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (text, record) => (
            <>
                <div className='actions_groups_btns'>
                    <button
                        onClick={() => (handleApproveUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='tick' />
                    </button>
                    <button
                        onClick={() => (handleRejectUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='cancel' />
                    </button>
                    <button
                        className={`icon-button ${userRequestExpandedRowKeys.includes(record.key) ? 'expanded' : ''}`}
                        onClick={() => (handleExpand(record.key))}
                    >
                      {userRequestExpandedRowKeys.includes(record.key) ? <CustomIcons variant='arrow' direction='up' /> : <CustomIcons variant='arrow' />}
                    </button>
                </div>
            </>
          )
        },
    ];

    const tableColumnsWithoutRoles = [
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, record) => (
                <div className='ant-table-custom-cell'>
                    <span>
                        <Form.Check
                            type='checkbox'
                            id={record.key}
                            onChange={(event) => handleCheckbox(event, record.key)}
                            checked={record.checked}
                            disabled={record.disabled}
                        />
                    </span>
                    <span className='truncated-span'>{record.fullname}</span>
                </div>
            ),
        },
        {
          title: 'User Group',
          dataIndex: 'userGroup',
          sorter: (a, b) => a.userGroup.localeCompare(b.userGroup)
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (text, record) => {
            if(isTablet){
                return(
                <button className="mobile-more-vertical" onClick={() => handleMobileModalOpen(record)}>
                    <CustomIcons variant="more" />
                </button>
                );
            }else{
                return(
            <>
                <div className='actions_groups_btns'>
                    <button
                        onClick={() => (handleApproveUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='tick'/>
                    </button>
                    <button
                        onClick={() => (handleRejectUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='cancel' />
                    </button>
                    <button
                        className={`icon-button ${userRequestExpandedRowKeys.includes(record.key) ? 'expanded' : ''}`}
                        onClick={() => (handleExpand(record.key))}
                    >
                      {userRequestExpandedRowKeys.includes(record.key) ? <CustomIcons variant='arrow' direction='up' /> : <CustomIcons variant='arrow'/>}
                    </button>
                </div>
            </>);}
          }
        },
    ];//dropss

    const [hasRecords, setHasRecords] = useState();
    const handleMobileModalOpen = (record) => {
        setHasRecords(record);
        setIsMobileModalOpenAction(true);
    }
    const handleViewModal = () => {
        setIsViewMobileModalOpen(true);
    }
    const handleViewModalApproved = () => {
        setIsViewMobileModalOpenApproved(true);
    }
    const handleMobileModalEdit = (record) =>{
        setIsMobileModalOpenEdit(true)
        setHasRecords(record);
        if (userRequestExpandedRowKeys.includes(record.key)) {
            setUserRequestExpandedRowKeys(userRequestExpandedRowKeys.filter(rowKey => rowKey !== record.key));
        } else {
            setUserRequestExpandedRowKeys([...userRequestExpandedRowKeys, record.key]);
        }
    }
    const handleMobileModalFilters = (record) =>{
        setIsMobileModalOpenFilter(true)
    }
    const tableColumnsWithoutUserGroups = [
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
            ...(isTablet ? { sortOrder: sortOrder ? 'ascend' : 'descend' } : {}),
            sortDirections: ['ascend', 'descend', 'ascend'],
            className: isTablet ? (tabKey === 'approved' ? 'approve-cell mobile-col-name' : 'mobile-col-name') : null,
            render: (_, record) => {
                return(
                <div className='ant-table-custom-cell'>
                    <span className={` ${isTablet ? tabKey !== 'pending'? 'd-none' : null : null}`}>
                        <Form.Check
                            type='checkbox'
                            id={record.key}
                            onChange={(event) => handleCheckbox(event, record.key)}
                            checked={record.checked}
                            disabled={record.disabled}
                        />
                    </span>
                    {isTablet ?
                    <div className='mobile-select-role'>
                        <span className='truncated-span' onClick={() => document.getElementById(record.key).click()}>{record.fullname}</span>
                    {tabKey === 'pending' ?
                        <button onClick={()=> handleMobileModalEdit(record)}
                        >
                            <div className='ant-dropdown-select'>
                                <div className='ant-tag-container'>
                                    {record.groupRoles.some(item => item.checked) ? record.groupRoles.map((selectedRole) => (
                                        selectedRole.checked ? (
                                            <Tag
                                                key={selectedRole.name}
                                                className={
                                                    selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                                                        selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                                            selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                                                selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                                                    selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                                                        'custom-select-checkbox-tag'
                                                }
                                            >
                                                {selectedRole.name}
                                            </Tag>
                                        ) : null
                                    )) : "Select a role"}
                                </div>
                                <span className="d-flex align-items-center">{userRequestExpandedRowKeys.includes(record.key) ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}</span>
                            </div>
                        </button>
                        :
                        tabKey === 'approved' &&
                        <div className='ant-tag-container'>
                            {record.groupRoles.map((selectedRole) => (
                                <Tag
                                    key={selectedRole.label}
                                    className={
                                        selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                                            selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                                selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                                    selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                                        selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                                            'custom-select-checkbox-tag'
                                    }
                                >
                                    {selectedRole.label}
                                </Tag>
                            ))}
                        </div>
                    }
                    </div>
                    :<span className='truncated-span'>{record.fullname}</span>
                    }
                </div>)
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            className: 'col-role',
            render: (_, record) => {
                if(tabKey === 'pending') {
                    return(<Dropdown
                        dropdownRender={() => dropdownMenu(record)}
                        trigger={['click']}
                        placement="bottomLeft"
                        open={dropdownVisibleMap[record.key]}
                        onOpenChange={visible => {
                            setDropdownVisibleMap(prevMap => ({
                                ...prevMap,
                                [record.key]: visible,
                            }));
                        }}
                    >
                        <div className='ant-dropdown-select'>
                            <div className='ant-tag-container'>
                                {record.groupRoles.some(item => item.checked) ? record.groupRoles.map((selectedRole) => (
                                    selectedRole.checked ? (
                                    <Tag
                                        key={selectedRole.name}
                                        className={
                                        selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                                        selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                        selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                        selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                        selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                        'custom-select-checkbox-tag'
                                        }
                                    >
                                        {selectedRole.name}
                                    </Tag>
                                    ) : null
                                )) : "Select a role"}
                            </div>
                            <CustomIcons variant='arrow' className="ant-dropdown-arrow"/>
                        </div>
                    </Dropdown>)
                } else if(tabKey === 'approved') {
                    {
                        return(<div className='ant-tag-container'>{record.groupRoles.map((selectedRole) => (
                        <Tag
                            key={selectedRole.label}
                            className={
                            selectedRole.color === 'green' ? 'ant-dropdown-select-tag green' :
                            selectedRole.color === 'blue' ? 'ant-dropdown-select-tag blue' :
                            selectedRole.color === 'pink' ? 'ant-dropdown-select-tag pink' :
                            selectedRole.color === 'purple' ? 'ant-dropdown-select-tag purple' :
                            selectedRole.color === 'orange' ? 'ant-dropdown-select-tag orange' :
                            'custom-select-checkbox-tag'
                            }
                        >
                            {selectedRole.label}
                        </Tag>))}</div>)
                    }
                }
            },
        },
        {
          title: 'Actions',
          key: 'actions',
          className: isTablet ? 'mobile-col-actions' : null,
          render: (text, record) => {
            if(isTablet){
                return(
                <button className="mobile-more-vertical" onClick={() => handleMobileModalOpen(record)}>
                    <CustomIcons variant="more" />
                </button>
                );
            }else{
                return(
            <>
                <div className='actions_groups_btns'>
                    <button
                        onClick={() => (handleApproveUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='tick' />
                    </button>
                    <button
                        onClick={() => (handleRejectUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='cancel' />
                    </button>
                    <button
                        className={`icon-button ${userRequestExpandedRowKeys.includes(record.key) ? 'expanded' : ''}`}
                        onClick={() => (handleExpand(record.key))}
                    >
                      {userRequestExpandedRowKeys.includes(record.key) ? <CustomIcons variant='arrow' direction='up' /> : <CustomIcons variant='arrow' />}
                    </button>
                </div>
            </>)}
          }
        },
    ];
    const tableColumnsWithoutRolesUserGroups = [
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
            sortDirections: ['ascend', 'descend', 'ascend'],
            ...(isTablet ? { sortOrder: sortOrder ? 'ascend' : 'descend' } : {}),
            className: isTablet ? (tabKey === 'rejected' ? 'approve-cell mobile-col-name' : 'mobile-col-name') : null,
            render: (_, record) => (
                <div className='ant-table-custom-cell'>
                    <span className={` ${isTablet ? tabKey !== 'pending'? 'd-none' : null : null}`}>
                        <Form.Check
                            type='checkbox'
                            id={record.key}
                            onChange={(event) => handleCheckbox(event, record.key)}
                            checked={record.checked}
                            disabled={record.disabled}
                        />
                    </span>
                    <span className='truncated-span'>{record.fullname}</span>
                </div>
            ),
        },
        {
            title: ' ',
            className: isTablet ? 'd-none' : null,
        },
        {
          title: 'Actions',
          key: 'actions',
          className: isTablet ? 'mobile-col-actions' : null,
          render: (text, record) => {
            if(isTablet){
                return(
                <button className="mobile-more-vertical" onClick={() => handleMobileModalOpen(record)}>
                    <CustomIcons variant="more" />
                </button>
                );
            }else{
                return(
            <>
                <div className='actions_groups_btns'>
                    <button
                        onClick={() => (handleApproveUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='tick'/>
                    </button>
                    <button
                        onClick={() => (handleRejectUser(record))}
                        className='icon-button'
                        disabled={tabKey === "approved" || tabKey === "rejected"}
                    >
                        <CustomIcons variant='cancel' />
                    </button>
                    <button
                        className={`icon-button ${userRequestExpandedRowKeys.includes(record.key) ? 'expanded' : ''}`}
                        onClick={() => (handleExpand(record.key))}
                    >
                      {userRequestExpandedRowKeys.includes(record.key) ? <CustomIcons variant='arrow' direction='up' /> : <CustomIcons variant='arrow'/>}
                    </button>
                </div>
            </>);}
          }
        },
    ];
    const mobileTableColumnsWithoutUserGroups = () => {
        if (isTablet) {
            return tableColumnsWithoutUserGroups.filter(column => column.title !== 'Role');
        }
        return tableColumnsWithoutUserGroups;
    }
    const updateCustomSelectMultipleClearAll = (value) => {
        setCustomSelectMultipleClearAll(value);
    };

    const handleDropdownCheckboxClearAll = (record) => {
        const updatedData = record.groupRoles.map(item => ({ ...item, checked: false, disabled: false }));
        const recordKey = record.key;

        setDropdownMenuCheckedRole(prevState => ({
            ...prevState,
            [recordKey]: []
        }));

        updateMenuData(record.key, updatedData);
    };

    const handleDropdownCheckbox = (record, id) => {
        const { groupRoles, key } = record;
        setIsMobileModalOpenEdit(false)
        setUserRequestExpandedRowKeys([]);
        const updatedData = groupRoles.map(role =>
            (role.id === id ? { ...role, checked: !role.checked } : { ...role, checked: false })
        );
        // Determine which roles are checked
        const checkedRoles = updatedData.filter(role => role.checked);
        if(isTablet){setHasRecords({ ...hasRecords, groupRoles: updatedData });}
        // Determine if any special roles are checked
        const specialRolesChecked = checkedRoles.some(role =>
            [SolutionManifest.Roles.CustomerAdmin, SolutionManifest.Roles.AssetOwner, SolutionManifest.Roles.EndUser].includes(role.value)
        );

        // Update state based on checked roles
        const updatedState = {
            ...dropdownMenuCheckedRole,
            [key]: specialRolesChecked ? [id] : (checkedRoles.length > 0 ? checkedRoles.map(role => role.id) : [])
        };

        // Update the roles data
        setDropdownMenuCheckedRole(updatedState);
        updateMenuData(key, updatedData, updatedData.find(role => role.id === id));
    };

    const dropdownMenu = (record) => {
        const checkedRoles = record.groupRoles.filter(item => item.checked);
        const customerAdminRole = checkedRoles.find(role => role.value === SolutionManifest.Roles.CustomerAdmin);
        const assetOwnerRole = checkedRoles.find(role => role.value === SolutionManifest.Roles.AssetOwner);
        const endUserRole = checkedRoles.find(role => role.value === SolutionManifest.Roles.EndUser);
        return(
            <div className='ant-dropdown-menu'>
                {
                    record.groupRoles.map(role => (
                        <div className='ant-dropdown-menu-item'
                            key={role.id}>
                            <Form.Check
                                type='radio'
                                id={role.id}
                                label={role.name}
                                disabled={role.disabled}
                                checked={dropdownMenuCheckedRole[record.key] ? dropdownMenuCheckedRole[record.key].includes(role.id) : false}
                                onChange={() => handleDropdownCheckbox(record, role.id)}
                            />
                        </div>))
                }
                {
                    (customerAdminRole || assetOwnerRole || endUserRole) &&
                        <div className='ant-dropdown-menu-note'>
                            {
                                customerAdminRole &&
                                    <span>{Localization.Settings.Roles.CustomerAdmin}</span>
                            }
                            {
                                assetOwnerRole &&
                                    <span>{Localization.Settings.Roles.AssetOwner}</span>
                            }
                            {
                                endUserRole &&
                                    <span>{Localization.Settings.Roles.EndUser}</span>
                            }
                        </div>
                }
                <div className='ant-dropdown-menu-divider'>
                    <hr />
                </div>
                <div className='ant-dropdown-menu-clear-all'>
                    <a href={SolutionManifest.Roles.Url} target='_blank'>Learn about user roles</a>
                </div>
            </div>
        );
    };

    const handleApproveUser = (record) => {
        getSession().then(async session => {
            const authorizerName = session.name ? session.name : session.user.username;

            if(checkedRequestUser.length > 0 && !record) {
                const getTableData = tablePendingData.filter(item => checkedRequestUser.includes(item.key));
                const checkedRolesData = getTableData.map(item => {
                    const checkedRoles = item.groupRoles.filter(role => role.checked);
                    return { name: item.fullname, roles: checkedRoles.map((role) => role.name ) };
                });
                const allHaveRoles = checkedRolesData.every(item => item.roles.length > 0);

                if(allHaveRoles) {
                    const paramsUser = getTableData.map(user => {
                        const getRolesValue = user.groupRoles
                            .filter(item => item.checked === true)
                            .map(item => item.value);

                        return({
                            "username": user.username,
                            "roles": getRolesValue,
                            "authorizerName": authorizerName,
                            "name": user.fullname
                        });
                    });
                    const params = {
                        "uuid": tenantUuid,
                        "approveStatus": true,
                        "users": paramsUser,
                        "userUuid": userUuid
                    };

                    if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                        if(isTablet){
                            if(isViewMobileModalOpenApproved){
                                setMobileViewApprove(true);
                                setIsLoading(true);
                            }else{
                                setIsViewMobileModalOpenApprovedPaid(true);
                                setIsMobileModalOpenAction(true);
                            }
                        }else{
                            setOffcanvasShow(true);
                        }
                        setOffcanvasData({
                            userRequestType: false,
                            userRequestLoading: false,
                            userRequestData: checkedRolesData,
                            userRequestParams: paramsUser,
                            state: 0
                        });
                    } else {
                        if(isViewMobileModalOpenApproved){
                            handleUserRequest(params);
                            setIsLoading(true);
                        }else{
                            setModalData({
                                show: true,
                                userRequestType: true,
                                userRequestData: checkedRolesData,
                                userRequestParams: params
                            });
                        }
                    }
                } else {
                    updateToastMessage(Localization.Settings.UserRequest.NoRolesSelected, "error");
                }
            } else {
                if(record) {
                    const getRolesName = record.groupRoles
                    .filter(item => item.checked === true)
                    .map(item => item.name);

                    const getRolesValue = record.groupRoles
                        .filter(item => item.checked === true)
                        .map(item => item.value);
                    const params = {
                        "uuid": tenantUuid,
                        "approveStatus": true,
                        "users": [
                            {
                                "username": record.username,
                                "roles": getRolesValue,
                                "authorizerName": authorizerName,
                                "fullname": record.fullname
                            }
                        ],
                        "userUuid": userUuid
                    }
                    if(getRolesName.length > 0) {
                        if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                            if(isTablet){
                                if(isViewMobileModalOpenApproved){
                                    setMobileViewApprove(true);
                                    setIsLoading(true);
                                }else{
                                setIsViewMobileModalOpenApprovedPaid(true);
                                setIsMobileModalOpenAction(true);
                                }
                            }else{
                                setOffcanvasShow(true);
                            }
                            setOffcanvasData({
                                userRequestType: false,
                                userRequestLoading: false,
                                userRequestData: [{
                                    name: record.fullname,
                                    roles: getRolesName
                                }],
                                userRequestParams: [
                                    {
                                        "username": record.username,
                                        "roles": getRolesValue,
                                        "authorizerName": authorizerName,
                                        "name": record.fullname
                                    }
                                ],
                                state: 0
                            });
                        } else {
                            if(isViewMobileModalOpenApproved){
                                handleUserRequest(params);
                                setIsLoading(true);
                            }else{
                            setModalData({
                                show: true,
                                userRequestType: true,
                                userRequestData: [{
                                    name: record.fullname,
                                    roles: getRolesName
                                }],
                                userRequestParams: {
                                    "uuid": tenantUuid,
                                    "approveStatus": true,
                                    "users": [
                                        {
                                            "username": record.username,
                                            "roles": getRolesValue,
                                            "authorizerName": authorizerName,
                                            "fullname": record.fullname,
                                            "name": record.fullname
                                        }
                                    ],
                                    "userUuid": userUuid
                                }
                            });
                        }
                        }
                    } else {
                        updateToastMessage(Localization.Settings.UserRequest.NoRolesSelected, "error");
                    }
                } else {
                    updateToastMessage(Localization.Settings.UserRequest.NoUsersSelectedApprove, "error");
                }

            }
        });
    };

    const handleRejectUser = (record) => {
        getSession().then(async session => {
            const authorizerName = session.name ? session.name : session.user.username;

            if(checkedRequestUser.length > 0 && !record) {
                const getTableData = tablePendingData.filter(item => checkedRequestUser.includes(item.key));
                const checkedRolesData = getTableData.map(item => {
                    return { name: item.fullname };
                });
                const paramsUser = getTableData.map(user => {
                    return({
                        "username": user.username,
                        "authorizerName": authorizerName,
                        "name": user.fullname
                    });
                });
                const params = {
                    "uuid": tenantUuid,
                    "approveStatus": false,
                    "users": paramsUser,
                    "userUuid": userUuid
                };
                setModalData({
                    show: true,
                    userRequestType: false,
                    userRequestData: checkedRolesData,
                    userRequestParams: params
                });
            } else {
                if(record) {
                    setModalData({
                        show: true,
                        userRequestType: false,
                        userRequestData: [{
                            name: record.fullname
                        }],
                        userRequestParams: {
                            "uuid": tenantUuid,
                            "approveStatus": false,
                            "users": [
                                {
                                    "username": record.username,
                                    "authorizerName": authorizerName,
                                    "name": record.fullname
                                }
                            ],
                            "userUuid": userUuid

                        }
                    });
                } else {
                    updateToastMessage(Localization.Settings.UserRequest.NoUsersSelected, "error");
                }

            }
        });
    };

    const handleMobileRejectUser = () =>{
        handleRejectUser(hasRecords);
        handleMobileModalClose();
    }
    const handleUncheck = () => {
        const updatedData = (tabKey === 'pending' && (isRequestFilter || isFilterDate) ? tableFilterPendingData : tablePendingData).map(item =>
            ({ ...item, checked: false })
        );
        setCheckedRequestUser([]);
        setSelectAllRequestChecked(false);
        if (isRequestFilter || isFilterDate) {
            setTableFilterPendingData(updatedData);
        }
        setTablePendingData(updatedData);
    };
    const handleCheckbox = (event, key) => {
        const isChecked = event.target.checked;
        const currentPageData = (isRequestFilter || isFilterDate ? tableFilterPendingData : tablePendingData).slice((currentPage - 1) * tablePageSize, currentPage * tablePageSize);
        const updatedData = (isRequestFilter || isFilterDate ? tableFilterPendingData : tablePendingData).map(item =>
            item.key === key ? { ...item, checked: !item.checked } : item
        );
        const updatedCurrentData = currentPageData.map(item =>
            item.key === key ? { ...item, checked: !item.checked } : item
        );
        if (isChecked) {
            const checkedKeys = updatedData
              .filter(item => item.checked)
              .map(item => item.key);

            setCheckedRequestUser(checkedKeys);
        } else {
            setCheckedRequestUser(prevCheckedUser =>
                prevCheckedUser.filter(userKey => userKey !== key)
            );
        }
        if (updatedCurrentData.every(item => item.checked)) {
            setSelectAllRequestChecked(true);
        } else {
            setSelectAllRequestChecked(false);
        }
        if(isRequestFilter || isFilterDate){
            setTableFilterPendingData(updatedData);
        }
        setTablePendingData(updatedData);
    };

    const handleExpand = (key) => {
        const element = document.querySelector(`[data-row-key="${key}"]`);

        if (userRequestExpandedRowKeys.includes(key)) {
            element.classList.remove('ant-table-expanded-row')
            setUserRequestExpandedRowKeys(userRequestExpandedRowKeys.filter(rowKey => rowKey !== key));
        } else {
            element.classList.add('ant-table-expanded-row')
            setUserRequestExpandedRowKeys([...userRequestExpandedRowKeys, key]);
        }
    };
    useEffect(() => {
        const element = document.querySelectorAll(`[data-row-key]`);
        if (element && userRequestExpandedRowKeys.length === 0) {
            element.forEach(elements => {
                elements.classList.remove('ant-table-expanded-row');
            });
        }
      },[userRequestExpandedRowKeys]);
    const expandedRowRender = (record) => (
        <div className='ant-table-custom-expanded-cell'>
            <div className='paragraph-1 mb-0'>
                <span><CustomIcons variant='email' color='#000000'/> {record.email}</span>
                <span><CustomIcons variant='user' color='#000000' /> {record.username}</span>
            </div>
            <div className='paragraph-1 mb-0'>
                <span className='font-bold'>{tabKey === 'pending' ? 'Submitted on' : tabKey === 'approved' ? `Approved by ${record.authorizerName}` : tabKey === 'rejected' ? `Rejected by ${record.authorizerName}` : ''}</span>
                <span>{tabKey === 'pending' ? formatDateTime(record.createDate) : tabKey === 'approved' ? formatDateTime(record.approveDate) : tabKey === 'rejected' ? formatDateTime(record.rejectedDate) : "" }</span>
            </div>
        </div>
    );

    const resetTab = () => {
        setPaginationStart(0);
        setPaginationEnd(0);
        setPaginationTotal(0);
        setSelectAllRequestChecked(false);
        setIsRequestFilter(false);
        setCheckedRequestUser([]);
        setFullNameSearch("");
        setTableFilterPendingData([]);
        setTableFilterApprovedData([]);
        setTableFilterRejectedData([]);
        setRangePickerValue(null);
        setRangePickerValueFrom(null);
        setRangePickerValueTo(null);
        setUserGroupFilterValue(null);
        setTablePendingData((prevData) => (
            prevData.map(item => ({
                ...item,
                checked: false
            }))
        ));
        setTableApprovedData((prevData) => (
            prevData.map(item => ({
                ...item,
                checked: false
            }))
        ));
        setTableRejectedData((prevData) => (
            prevData.map(item => ({
                ...item,
                checked: false
            }))
        ));
    };

    const handleTabChange = (key) => {
        setTabKey(key);

        if(key === 'pending') {
            setAlertNotificationData({
                variant: "",
                content: ""
            });
            setFilterDateText("pending");
        }
        if(key === 'approved') {
            setAlertNotificationData({
                variant: "default",
                info: true,
                content: Localization.Settings.UserRequest.AlertNotificationApproved
            });
            setFilterDateText("approved");
        }

        if(key === 'rejected') {
            setAlertNotificationData({
                variant: "default",
                info: true,
                content: Localization.Settings.UserRequest.AlertNotificationRejected
            });
            setFilterDateText("rejected");
        }
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllRequestChecked(isChecked);
        const currentPageData = (isRequestFilter || isFilterDate ? tableFilterPendingData : tablePendingData).slice((currentPage - 1) * tablePageSize, currentPage * tablePageSize);
        const updatedTableData = (isRequestFilter || isFilterDate ? tableFilterPendingData : tablePendingData).map(item => ({
            ...item,
            checked: isChecked
        })).sort((a, b) => {
            return a.key.localeCompare(b.key);
        });

        const updatedCheckedUser = (currentPageData).map(item => ({
            key: item.key,
            checked: isChecked
        }));

        if (isChecked) {
            const checkedKeys = updatedCheckedUser.map(item => item.key);
            setCheckedRequestUser(checkedKeys);
        } else {
            setCheckedRequestUser([]);
        }
        if(isRequestFilter || isFilterDate){
            setTableFilterPendingData(updatedTableData);
        }
        setTablePendingData(updatedTableData);
    };

    const updateMenuData = (dataKey, newGroupRoles) => {
        let updatedDataArray = [];

        if(newGroupRoles !== undefined) {
            updatedDataArray = tablePendingData.map(item => {
                if (item.username === dataKey) {
                  return { ...item, groupRoles: newGroupRoles};
                }
                return item;
            });
        }

        setTablePendingData(updatedDataArray);
    };

    const handleUserRequest = async (params) => {
        setModalData(prevData => ({
            ...prevData,
            userRequestLoading: true
        }));

        const userApprover = await UsersService.userApprover(params);
        const userRequest = await UsersService.listUserRequest(tenantUuid);

        if(userApprover?.code === 200) {
            if(params.approveStatus) {
                await listFilterTenantUser();
                updateToastMessage(`${params.users.length} ${params.users.length > 1 ? "user requests" : "user request"} approved`, "success");
            } else {
                updateToastMessage(`${params.users.length} ${params.users.length > 1 ? "user requests" : "user request"} rejected`, "success");
            }

            if(userRequest.code === 200) {
                props.updateUserRequestPending(userRequest.response.pending);
                props.updateUserRequestApproved(userRequest.response.approved);
                props.updateUserRequestRejected(userRequest.response.rejected);
            }

            setModalData({
                show: false,
                userRequestType: null,
                userRequestData: [],
                userRequestLoading: false,
                userRequestParams: {}
            });
            resetTab();
            setIsLoading(false);
            if(!isViewMobileModalOpenApprovedPaid){
                setIsMobileModalOpenAction(false)
            }
            setIsViewMobileModalOpenApproved(false);
        } else {
            updateToastMessage(`Error: ${userApprover}`, "error");
        }
    };

    const handleModalClose = () => setModalData({
        show: false,
        userRequestType: null,
        userRequestData: [],
        userRequestParams: {}
    });

    const handleFullNameSearch = (event, data) => {
        const searchText = event.target.value;
        const filteredData = _.filter(data, (item) => {
            return item.fullname.toLowerCase().includes(searchText.toLowerCase());
        });

        if(searchText === "") {
            setIsRequestFilter(false);
        } else {
            setIsRequestFilter(true);
        }

        if(tabKey === 'pending') {
            setTableFilterPendingData(filteredData);
        } else if(tabKey === 'approved') {
            setTableFilterApprovedData(filteredData);
        } else if(tabKey === 'rejected') {
            setTableFilterRejectedData(filteredData);
        }
        setFullNameSearch(searchText);
    };

    const onClickFilters = () => {
        setOpenRequestFilter(!openRequestFilter);
    };

    const handleFilterUserGroup = (value) => {
        setUserGroupFilterValue(value);
    };

    const handleFilterDate = (value) => {
        setRangePickerValue(value);

        if(!value) {
            setRangePickerValueFrom(null);
            setRangePickerValueTo(null);
            return;
        }
        setRangePickerValueFrom(value[0].valueOf());
        setRangePickerValueTo(value[1].valueOf());
    };

    const unCheckBoxFilter = () =>{
        setCheckedRequestUser([]);
        const updatedTableDataPending = tablePendingData.map(item => ({
            ...item,
            checked: false
        }));
        setTablePendingData(updatedTableDataPending);
        if(tabKey === 'pending' && isRequestFilter || isFilterDate) {
            const updatedTableData = tableFilterPendingData.map(item => ({
                ...item,
                checked: false
            }));
            setTableFilterPendingData(updatedTableData);
        } /*else if(tabKey === 'approved' && isRequestFilter || isFilterDate) {
            const updatedTableData = tableFilterApprovedData.map(item => ({
                ...item,
                checked: false
            }));
            setTableFilterApprovedData(updatedTableData);
        } else if(tabKey === 'rejected' && isRequestFilter || isFilterDate) {
            const updatedTableData = tableFilterRejectedData.map(item => ({
                ...item,
                checked: false
            }));
            setTableFilterRejectedData(updatedTableData);
        }*/
    };

    const handleApplyFilter = (data) => {
        setIsFilterDate(true);
        let filteredArray = _.filter(data, (item) => {
            const createDateTimestamp = new Date(item.createDate);
            const approvedDateTimestamp = new Date(item.approveDate);
            const rejectedDateTimestamp = new Date(item.rejectedDate);
            let dateObject;

            if(tabKey === 'pending') {
                dateObject = createDateTimestamp;
            } else if(tabKey === 'approved') {
                dateObject = approvedDateTimestamp;
            } else if(tabKey === 'rejected') {
                dateObject = rejectedDateTimestamp;
            }

            const isInRange = rangePickerValueFrom && rangePickerValueTo ? isDateInRange(dateObject, rangePickerValueFrom, rangePickerValueTo) : true;
            let hasMatchingDepartment = true;
            if(userGroupFilterValue) {
                hasMatchingDepartment = userGroupFilterValue.length > 0 ? userGroupFilterValue.includes(item.userRoleValue) : true;
            }
            return isInRange && hasMatchingDepartment;
        });

        filteredArray = _.filter(filteredArray, (item) => {
            return item.fullname.toLowerCase().includes(fullNameSearch.toLowerCase());
        });
        setCheckedRequestUser([]);
        const uncheckFilterData = filteredArray.map(item => ({
            ...item,
            checked: false
        }));
        if(tabKey === 'pending') {
            setTableFilterPendingData(uncheckFilterData);
        } else if(tabKey === 'approved') {
            setTableFilterApprovedData(filteredArray);
        } else if(tabKey === 'rejected') {
            setTableFilterRejectedData(filteredArray);
        }
        if(rangePickerValue != null){
            setCountFilter(1);
        }
        setSelectAllRequestChecked(false);
        setSortOrder(sortOrderMobile === 'ascend' ? true : false);
        handleMobileModalClose();
    };

    const handleClearAllFilter = (data) => {
        setCustomSelectMultipleClearAll(true);
        setRangePickerValue(null);
        setRangePickerValueFrom(null);
        setRangePickerValueTo(null);
        setUserGroupFilterValue(null);
        setIsRequestFilter(false);
        setIsFilterDate(false);
        setTableFilterPendingData(tablePendingData);
        setTableFilterApprovedData(tableApprovedData);
        setTableFilterRejectedData(tableRejectedData);
        setCountFilter(0);
        setSortOrder(true);
        setSortOrderMobile('ascend');
        handleUncheck();
        unCheckBoxFilter();
        handleMobileModalClose();
        const searchText = fullNameSearch;
        const filteredData = _.filter(data, (item) => {
            return item.fullname.toLowerCase().includes(searchText.toLowerCase());
        });

        if(searchText === "") {
            setIsRequestFilter(false);
        } else {
            setIsRequestFilter(true);
        }

        if(tabKey === 'pending') {
            setTableFilterPendingData(filteredData);
        } else if(tabKey === 'approved') {
            setTableFilterApprovedData(filteredData);
        } else if(tabKey === 'rejected') {
            setTableFilterRejectedData(filteredData);
        }
        setFullNameSearch(searchText);
    };

    const handlePagination = (page, tablePageSize) => {
        setCurrentPage(page);
        setSelectAllRequestChecked(false);
        setCheckedRequestUser([]);
        handleUncheck();

    }

    useEffect(() => {
        let totalItems;

        if(tabKey === 'pending') {
            totalItems = isRequestFilter || isFilterDate ? tableFilterPendingData.length : tablePendingData.length;
            setPaginationTotal(totalItems);
        } else if(tabKey === 'approved') {
            totalItems = isRequestFilter || isFilterDate ? tableFilterApprovedData.length : tableApprovedData.length;
            setPaginationTotal(totalItems);
        } else if(tabKey === 'rejected') {
            totalItems = isRequestFilter || isFilterDate ? tableFilterRejectedData.length : tableRejectedData.length;
            setPaginationTotal(totalItems);
        }

        const startItem = (currentPage - 1) * tablePageSize + 1;
        const endItem = Math.min(currentPage * tablePageSize, totalItems);

        setPaginationStart(startItem);
        setPaginationEnd(endItem);
    });

    useEffect(() => {
        let data = [];

        const fetchGroupRoles = async () => {
            try {
                const userGroupData = userGroups;
                const groupRolesData = groupsRoles;

                for(let userRequest of props.userRequestPending) {
                    const arrGroupRoles = groupRolesData.map(groupRole => ({
                        value: groupRole.value,
                        name: groupRole.label,
                        color: groupRole.color,
                        checked: false,
                        disabled: false
                    }));

                    const userArrGroupRoles = arrGroupRoles.map(groupRole => ({
                        ...groupRole,
                        id: groupRole.value + '_' + userRequest.username,
                    }));

                    const userRole = userArrGroupRoles.find(userRole => userRole?.id === userRole.value + '_' + userRequest.username);

                    data.push({
                        key: userRequest.username,
                        username: userRequest.username,
                        fullname: userRequest.fullname,
                        email: userRequest.email,
                        createDate: userRequest.createDate,
                        userGroup: "-",
                        userRoleLabel: userRole?.label ? userRole?.label : "-",
                        userRoleValue: userRole?.value ? userRole?.value : "-",
                        groupRoles: userArrGroupRoles,
                        checked: false,
                        disabled: false
                    });
                }

                setUserGroupOptions(userGroupData);
                setTablePendingData(data);
            } catch (error) {
              console.error('Error fetching users:', error);
            }
        };

        fetchGroupRoles();
    }, [props.userRequestPending]);

    useEffect(() => {
        let data = [];

        const fetchGroupRoles = async () => {
            try {
                for(let userRequest of props.userRequestApproved) {
                    const arrGroupRoles = groupsRoles.filter(groupRole => userRequest.userRoles.includes(groupRole.value));

                    const userArrGroupRoles = arrGroupRoles.map(groupRole => ({
                        ...groupRole,
                        id: groupRole.value + '_' + userRequest.username,
                    }));

                    const userRole = userArrGroupRoles.find(userRole => userRole?.id === userRole.value + '_' + userRequest.username);

                    data.push({
                        key: userRequest.username,
                        username: userRequest.username,
                        fullname: userRequest.fullname,
                        email: userRequest.email,
                        approveDate: userRequest.approveDate,
                        userGroup: "-",
                        userRoleLabel: userRole?.label ? userRole?.label : "-",
                        userRoleValue: userRole?.value ? userRole?.value : "-",
                        groupRoles: userArrGroupRoles,
                        authorizerName: userRequest.authorizerName,
                        checked: false,
                        disabled: true
                    });
                }

                setTableApprovedData(data);
            } catch (error) {
              console.error('Error fetching users:', error);
            }
        };

        fetchGroupRoles();
    }, [props.userRequestApproved]);

    useEffect(() => {
        let data = [];

        const fetchGroupRoles = async () => {
            try {
                for(let userRequest of props.userRequestRejected) {
                    data.push({
                        key: userRequest.username,
                        username: userRequest.username,
                        fullname: userRequest.fullname,
                        email: userRequest.email,
                        userGroup: "-",
                        rejectedDate: userRequest.rejectedDate,
                        authorizerName: userRequest.authorizerName,
                        groupRoles: [],
                        checked: false,
                        disabled: true
                    });
                }

                setTableRejectedData(data);
            } catch (error) {
              console.error('Error fetching users:', error);
            }
        };

        fetchGroupRoles();
    }, [props.userRequestRejected]);
    const tabsRef = useRef();
    const handleMobileModalClose = () => {
        setIsMobileModalOpenAction(false);
        setIsMobileModalOpenFilter(false);
        setIsMobileModalOpenRequestType(false);
        setIsViewMobileModalOpenApproved(false);
        setIsViewMobileModalOpenApprovedPaid(false);
        setIsViewMobileModalOpen(false);
        setIsMobileModalOpenEdit(false);
        setUserRequestExpandedRowKeys([]);
    }
    const handleMobilePending = (key) =>{
        handleTabChange(key);
        setIsMobileModalOpenRequestType(false);
        setCheckedRequestUser([]);
        setSelectAllRequestChecked(false);
        handleUncheck();
        handleClearAllFilter();
    }
    const handleMobileApproved = (key) =>{
        handleTabChange(key);
        setIsMobileModalOpenRequestType(false);
        setCheckedRequestUser([]);
        setSelectAllRequestChecked(false);
        handleUncheck();
        handleClearAllFilter();
    }
    const handleMobileApproveUsers = () =>{
        handleApproveUser(hasRecords);
    }
    const handleMobileRejected = (key) =>{
        handleTabChange(key);
        setIsMobileModalOpenRequestType(false);
        setSelectAllRequestChecked(false);
        handleUncheck();
        handleClearAllFilter();
    }
    const handleRequestType = () =>{
            setIsMobileModalOpenRequestType(true)
            const updatedTableData = tablePendingData.map(item => ({
                ...item,
                checked: false
            }));
            const updatedCheckedUser = tablePendingData.map(item => ({
                key: item.key,
                checked: false
            }));
            setCheckedRequestUser([]);
            setSelectAllRequestChecked(false);
            setCheckedRequestUser(updatedCheckedUser);
            setTablePendingData(updatedTableData);
    }

    return(
        <>
            <div className='user-request'>
                { /*alertNotificationData.variant && alertNotificationData.content && <AlertNotification variant={alertNotificationData.variant} info={alertNotificationData.info} content={alertNotificationData.content} />*/}
                {!isTablet ? <h2 className="h2-mb-48">User Request</h2> : null}
               {
                isMobileModalOpenRequestType && (
                    <MobileModal title={'Request Type'} onClose={handleMobileModalClose}>
                        <div className="mobile-modal-more-vertical">
                                <Button
                                    className={`type-request ${tabKey === 'pending' ? 'active' : ''}`}
                                    onClick={() => {handleMobilePending('pending') }}
                                    variant="default">
                                    <span className='filters-h3'>Pending <span>{tablePendingData.length}</span></span>
                                </Button>
                                <Button
                                    className={`type-request ${tabKey === 'approved' ? 'active' : ''}`}
                                    onClick={() => {handleMobileApproved('approved') }}
                                    variant="default">
                                    <span className='filters-h3'>Approved <span>{tableApprovedData.length}</span></span>
                                </Button>
                                <Button
                                    className={`type-request ${tabKey === 'rejected' ? 'active' : ''}`}
                                    onClick={() => {handleMobileRejected('rejected') }}
                                    variant="default">
                                    <span className='filters-h3'>Rejected <span>{tableRejectedData.length}</span></span>
                                </Button>
                        </div>
                    </MobileModal>
                    )
               }
                <Tabs
                    defaultActiveKey="pending"
                    id="user-request-tabs"
                    onSelect={(key) => handleTabChange(key)}
                    activeKey={tabKey}
                >
                    <Tab eventKey="pending"  title={!isTablet ? <span>Pending <i>{tablePendingData.length}</i></span> : ""}>
                    <div className='mobile-setting-bar'>
                                {
                                    isTablet ?
                                    <div className='mobile-setting-pending'>
                                                <Button
                                                    onClick={() => {setIsMobileModalOpenRequestType(true) }}
                                                    variant="default">
                                                    <h3 className='filters-h3'>
                                                    {tabKey === 'approved' ?
                                                        <span>Approve</span>
                                                        : tabKey === 'rejected' ?
                                                            <span>Rejected</span>
                                                        :
                                                            <span>Pending</span>
                                                    }
                                                    </h3>
                                                    <span className='d-flex align-items-center'>
                                                    {isMobileModalOpenRequestType ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                                    </span>
                                                </Button>
                                    </div>
                                    : null
                                }{tablePendingData.length > 0 || isRequestFilter
                                    ?
                                    <UserRequestSettingsBar
                                        tabKey={tabKey}
                                        handleSelectAllChange={handleSelectAllChange}
                                        fullNameSearch={fullNameSearch}
                                        handleFullNameSearch={handleFullNameSearch}
                                        tableData={tablePendingData}
                                        onClickFilters={onClickFilters}
                                        handleApproveUser={handleApproveUser}
                                        handleRejectUser={handleRejectUser}
                                        openFilter={openRequestFilter}
                                        handleMobileModalFilters = {handleMobileModalFilters}
                                        isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                        countFilter={countFilter}
                                    />: null
                                }</div>
                        {
                                tablePendingData.length > 0 || isRequestFilter
                                ?
                                <>
                                    {
                                        (isTablet ? tabKey == "pending"? isMobileModalOpenFilter : false : openRequestFilter) &&
                                            <UserRequestFilter
                                                filterDateText = {filterDateText}
                                                userGroupOptions={userGroupOptions}
                                                RangePickerValue={rangePickerValue}
                                                handleFilterUserGroup={handleFilterUserGroup}
                                                handleFilterDate={handleFilterDate}
                                                tableData={tablePendingData}
                                                handleClearAllFilter={handleClearAllFilter}
                                                handleApplyFilter={handleApplyFilter}
                                                userGroupFilterValue={userGroupFilterValue}
                                                customSelectMultipleClearAll={customSelectMultipleClearAll}
                                                updateCustomSelectMultipleClearAll={updateCustomSelectMultipleClearAll}
                                                isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                                handleMobileModalClose = {handleMobileModalClose}
                                                sortOrderMobile = {sortOrderMobile}
                                                handleMobileSortByAsc={handleMobileSortByAsc}
                                                handleMobileSortByDes={handleMobileSortByDes}
                                                tabKey={tabKey}
                                            />
                                    }
                                    {
                                        isRequestFilter && tableFilterPendingData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResult + " for " + `"${fullNameSearch}"`}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDesc.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        : isFilterDate & tableFilterPendingData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResultFilter}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDescDate.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>:
                                            <div className='user-request-table'>
                                                <div className='show-request-number'>
                                                    Showing <span>{paginationStart}</span>-<span>{paginationEnd}</span> of <span>{paginationTotal}</span> <span>{paginationTotal > 1 ? 'requests' :'request'}</span>
                                                </div>
                                                {
                                                    userGroups && userGroups !== null
                                                    ?
                                                        <Table
                                                            className='custom-ant-table'
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterPendingData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tablePendingData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false}
                                                            columns={tableColumns}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterPendingData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tablePendingData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />
                                                    :
                                                        <Table
                                                            className='custom-ant-table'
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterPendingData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tablePendingData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false}
                                                            columns={isTablet ? mobileTableColumnsWithoutUserGroups() : tableColumnsWithoutUserGroups}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterPendingData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tablePendingData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />

                                                }
                                            </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='user-request-empty'>
                                        <img src="./images/user-management-empty-state.svg" alt="No User Request Illustration"/>
                                        <h2>{Localization.Settings.UserRequest.NoUserRequest}</h2>
                                        <p className='paragraph-1'>{Localization.Settings.UserRequest.NoUserRequestDesc}</p>
                                    </div>
                                </>
                        }
                    </Tab>
                    <Tab eventKey="approved" title={!isTablet ? <span>Approved <i>{tableApprovedData.length}</i></span> : ""}>
                    <div className='mobile-setting-bar'>
                                {
                                    isTablet ?
                                    <div className='mobile-setting-pending'>
                                                <Button
                                                    onClick={() => {setIsMobileModalOpenRequestType(true) }}
                                                    variant="default">
                                                    <h3 className='filters-h3'>
                                                    {tabKey === 'approved' ?
                                                        <span>Approved</span>
                                                        : tabKey === 'rejected' ?
                                                            <span>Rejected</span>
                                                        :
                                                            <span>Pending</span>
                                                    }
                                                    </h3>
                                                    <span className='d-flex align-items-center'>
                                                    {isMobileModalOpenRequestType ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                                    </span>
                                                </Button>
                                    </div>
                                    : null
                                }
                                {tableApprovedData.length > 0 || isRequestFilter ?
                                    <UserRequestSettingsBar
                                        handleSelectAllChange={handleSelectAllChange}
                                        fullNameSearch={fullNameSearch}
                                        handleFullNameSearch={handleFullNameSearch}
                                        tableData={tableApprovedData}
                                        onClickFilters={onClickFilters}
                                        handleApproveUser={handleApproveUser}
                                        handleRejectUser={handleRejectUser}
                                        openFilter={openRequestFilter}
                                        handleMobileModalFilters = {handleMobileModalFilters}
                                        isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                        countFilter={countFilter}
                                    />: null
                                }</div>
                        {
                                tableApprovedData.length > 0 || isRequestFilter
                                ?
                                <>
                                    {
                                        (isTablet ? tabKey == "approved"? isMobileModalOpenFilter : false  : openRequestFilter) &&
                                            <UserRequestFilter
                                                filterDateText = {filterDateText}
                                                userGroupOptions={userGroupOptions}
                                                RangePickerValue={rangePickerValue}
                                                handleFilterUserGroup={handleFilterUserGroup}
                                                handleFilterDate={handleFilterDate}
                                                tableData={tableApprovedData}
                                                handleClearAllFilter={handleClearAllFilter}
                                                handleApplyFilter={handleApplyFilter}
                                                userGroupFilterValue={userGroupFilterValue}
                                                customSelectMultipleClearAll={customSelectMultipleClearAll}
                                                updateCustomSelectMultipleClearAll={updateCustomSelectMultipleClearAll}
                                                isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                                handleMobileModalClose = {handleMobileModalClose}
                                                sortOrderMobile = {sortOrderMobile}
                                                handleMobileSortByAsc={handleMobileSortByAsc}
                                                handleMobileSortByDes={handleMobileSortByDes}
                                                tabKey={tabKey}
                                            />
                                    }
                                    {
                                        isRequestFilter && tableFilterApprovedData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResult + " for " + `"${fullNameSearch}"`}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDesc.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        :isFilterDate & tableFilterApprovedData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResultFilter}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDescDate.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>:
                                            <div className='user-request-table'>
                                                <div className='show-request-number'>
                                                    Showing <span>{paginationStart}</span>-<span>{paginationEnd}</span> of <span>{paginationTotal}</span> <span>{paginationTotal > 1 ? 'requests' :'request'}</span>
                                                </div>
                                                <div className='table-listing'>{alertNotificationData.variant && alertNotificationData.content && <span>{alertNotificationData.content}</span>}</div>
                                                {
                                                    userGroups && userGroups !== null
                                                    ?
                                                        <Table
                                                            className='custom-ant-table custom-table-listing'
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterApprovedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tableApprovedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false}
                                                            columns={tableColumns}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterApprovedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tableApprovedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />
                                                    :
                                                        <Table
                                                            className='custom-ant-table custom-table-listing'
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterApprovedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tableApprovedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false}
                                                            columns={isTablet ? mobileTableColumnsWithoutUserGroups() : tableColumnsWithoutUserGroups}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterApprovedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tableApprovedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />
                                                }


                                            </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='user-request-empty'>
                                        <img src="./images/user-management-empty-state.svg" alt="No User Request Illustration"/>
                                        <h2>{Localization.Settings.UserRequest.NoUserRequest}</h2>
                                        <p className='paragraph-1'>{Localization.Settings.UserRequest.NoUserRequestApprovedDesc}</p>
                                    </div>
                                </>
                        }
                    </Tab>
                    <Tab eventKey="rejected" title={!isTablet ? <span>Rejected <i>{tableRejectedData.length}</i></span>: ""}>
                    <div className='mobile-setting-bar'>
                                { isTablet ?
                                    <div className='mobile-setting-pending'>
                                                <Button
                                                    onClick={() => {setIsMobileModalOpenRequestType(true) }}
                                                    variant="default">
                                                    <h3 className='filters-h3'>
                                                    {tabKey === 'approved' ?
                                                        <span>Approve</span>
                                                        : tabKey === 'rejected' ?
                                                            <span>Rejected</span>
                                                        :
                                                            <span>Pending</span>
                                                    }
                                                    </h3>
                                                    <span className='d-flex align-items-center'>
                                                    {isMobileModalOpenRequestType ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                                    </span>
                                                </Button>
                                    </div>
                                    : null
                                }{tableRejectedData.length > 0 || isRequestFilter ?
                                    <UserRequestSettingsBar
                                        handleSelectAllChange={handleSelectAllChange}
                                        fullNameSearch={fullNameSearch}
                                        handleFullNameSearch={handleFullNameSearch}
                                        tableData={tableRejectedData}
                                        onClickFilters={onClickFilters}
                                        handleApproveUser={handleApproveUser}
                                        handleRejectUser={handleRejectUser}
                                        openFilter={openRequestFilter}
                                        handleMobileModalFilters = {handleMobileModalFilters}
                                        isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                        countFilter={countFilter}
                                    /> : null}</div>
                        {
                                tableRejectedData.length > 0 || isRequestFilter
                                ?
                                <>
                                    {
                                        (isTablet ? tabKey == "rejected"? isMobileModalOpenFilter : false  : openRequestFilter) &&
                                            <UserRequestFilter
                                                filterDateText = {filterDateText}
                                                userGroupOptions={userGroupOptions}
                                                RangePickerValue={rangePickerValue}
                                                handleFilterUserGroup={handleFilterUserGroup}
                                                handleFilterDate={handleFilterDate}
                                                tableData={tableRejectedData}
                                                handleClearAllFilter={handleClearAllFilter}
                                                handleApplyFilter={handleApplyFilter}
                                                userGroupFilterValue={userGroupFilterValue}
                                                customSelectMultipleClearAll={customSelectMultipleClearAll}
                                                updateCustomSelectMultipleClearAll={updateCustomSelectMultipleClearAll}
                                                isMobileModalOpenFilter = {isMobileModalOpenFilter}
                                                handleMobileModalClose = {handleMobileModalClose}
                                                sortOrderMobile = {sortOrderMobile}
                                                handleMobileSortByAsc={handleMobileSortByAsc}
                                                handleMobileSortByDes={handleMobileSortByDes}
                                            />
                                    }
                                    {
                                        isRequestFilter && tableFilterRejectedData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResult + " for " + `"${fullNameSearch}"`}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDesc.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        :isFilterDate & tableFilterRejectedData.length === 0
                                        ?
                                            <div className='user-request-empty-filter'>
                                                <p className='paragraph-1 font-bold'>{Localization.Settings.UserRequest.NoResultFilter}</p>
                                                <ul className='paragraph-1'>
                                                    {
                                                        Localization.Settings.UserRequest.NoResultDescDate.map((desc) => <li key={desc}>{desc}</li>)
                                                    }
                                                </ul>
                                            </div>:
                                            <div className='user-request-table'>
                                                <div className='show-request-number'>
                                                    Showing <span>{paginationStart}</span>-<span>{paginationEnd}</span> of <span>{paginationTotal}</span> <span>{paginationTotal > 1 ? 'requests' :'request'}</span>
                                                </div>
                                                <div className='table-listing'>{alertNotificationData.variant && alertNotificationData.content && <span>{alertNotificationData.content}</span>}</div>
                                                {
                                                    userGroups && userGroups !== null
                                                    ?
                                                        <Table
                                                            className={`custom-ant-table custom-table-listing ${isTablet ? 'mobile-rejected-table' : null}`}
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterPendingData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tableRejectedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false}
                                                            columns={tableColumnsWithoutRoles}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterRejectedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tableRejectedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />
                                                    :
                                                        <Table
                                                            className={`custom-ant-table custom-table-listing ${isTablet ? 'mobile-rejected-table' : null}`}
                                                            showQuickJumper
                                                            expandable={{
                                                                expandedRowRender: expandedRowRender,
                                                                expandRowByClick: false,
                                                                expandedRowKeys: userRequestExpandedRowKeys,
                                                            }}
                                                            pagination={isRequestFilter || isFilterDate ? tableFilterRejectedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false : tableRejectedData.length > tablePageSize ? {
                                                                showSizeChanger: false,
                                                                showQuickJumper: true,
                                                                tablePageSize: tablePageSize,
                                                                position: ['none', "bottomCenter"],
                                                                onChange: handlePagination
                                                            } : false }
                                                            columns={tableColumnsWithoutRolesUserGroups}
                                                            dataSource={isRequestFilter || isFilterDate ? tableFilterRejectedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            })) : tableRejectedData.map((item) => ({
                                                                ...item,
                                                                expanded: userRequestExpandedRowKeys.includes(item.key),
                                                            }))}
                                                            rowKey={(record) => record.key}
                                                            onChange={handleDesktopSortBy}
                                                        />
                                                }

                                            </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='user-request-empty'>
                                        <img src="./images/user-management-empty-state.svg" alt="No User Request Illustration"/>
                                        <h2>{Localization.Settings.UserRequest.NoUserRequest}</h2>
                                        <p className='paragraph-1'>{Localization.Settings.UserRequest.NoUserRequestRejectedDesc}</p>
                                    </div>
                                </>
                        }
                    </Tab>
                </Tabs>
                {isTablet &&
                    <UserRequestMobileModals
                        tabKey={tabKey}
                        isMobileModalOpenAction = {isMobileModalOpenAction}
                        hasRecords = {hasRecords}
                        handleMobileModalClose = {handleMobileModalClose}
                        isViewMobileModalOpen = {isViewMobileModalOpen}
                        isMobileModalOpenEdit = {isMobileModalOpenEdit}
                        dropdownMenuCheckedRole ={dropdownMenuCheckedRole}
                        handleDropdownCheckbox = {handleDropdownCheckbox}
                        handleViewModal={handleViewModal}
                        isViewMobileModalOpenApproved ={isViewMobileModalOpenApproved}
                        handleViewModalApproved = {handleViewModalApproved}
                        handleMobileRejectUser={handleMobileRejectUser}
                        isViewMobileModalOpenApprovedPaid = {isViewMobileModalOpenApprovedPaid}
                        handleMobileApproveUsers={handleMobileApproveUsers}
                        isLoading={isLoading}
                        isPaidPlan = {subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId}
                    />
                }
                {
                    subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                        <AdditionalUserOffcanvas
                            updatePaidPlan={props.updatePaidPlan}
                            resetTab={resetTab}
                            updateInvoices={props.updateInvoices}
                            updateUserRequestPending={props.updateUserRequestPending}
                            updateUserRequestApproved={props.updateUserRequestApproved}
                            updateUserRequestRejected={props.updateUserRequestRejected}
                            isViewMobileModalOpenApprovedPaid={isViewMobileModalOpenApprovedPaid}
                            handleMobileModalClose={handleMobileModalClose}/>
                }
                { modalData && <GenericModal
                                    modalType="userRequest"
                                    modalShow={modalData.show}
                                    handleModalClose={handleModalClose}
                                    userRequestType={modalData.userRequestType}
                                    userRequestData={modalData.userRequestData}
                                    handleUserRequest={handleUserRequest}
                                    userRequestLoading ={modalData.userRequestLoading}
                                    userRequestParams={modalData.userRequestParams} />}
            </div>
        </>
    );
};

export default UserRequest;
