import { useEffect, useContext } from "react";
import { Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { ShareContext } from '../../shared/context/share-state';
import ViewAssets from '../../shared/components/view-asset';
import ViewAssetsHooks from '../../shared/hooks/view-assets-hooks';
import SearchHooks from '../../shared/hooks/search-hooks';
import path from "../../shared/routePaths";
import _ from "lodash";
import { SessionContext } from "../../shared/context/session-provider";

const AssetSearch = (props) => {
    // View-Asset Hooks    
    const {
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        nextUUID,
        prevUUID,
        viewAssetsShow,
        viewAssetBtnDisable,
    } = ViewAssetsHooks(props);
    
    // Share Context
    const { updateSearchValue } = SearchHooks();
    const { updateMenuTabKey, menuTabKey } = useContext(ShareContext);
    const { displayItems } = useContext(SessionContext);
    
    //Navigation 
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location; // Get the state from location

    // Fallback to null if location.state is not defined
    const assetState = state ? state : null;
    const assetUuid = assetState ? assetState.fileUUID : props.assetUuid;
    const displayAssetItems = assetState?.displayItems? assetState.displayItems : displayItems;

    // Fetch data if specific asset using the assetId
    useEffect(() => {        
        if (assetUuid) {
            const handleAssetInformation = async () => {
                await handleViewAssetOpen(assetUuid, displayAssetItems)
            }
            handleAssetInformation();

        } else {
            updateMenuTabKey(path.EXPLORE)
        }
    },[navigate]);

     // Handle search navigation type
     useEffect(() => {
        const navigationType = performance.getEntriesByType("navigation")[0]?.type;

        if (navigationType === "navigate" && !assetState) {
            const handleAssetInformation = async () => {
                await handleViewAssetOpen(assetUuid, displayAssetItems)
            }
            handleAssetInformation();
        }
    }, []);


    return (
        assetUuid && (
            <section>
                <Container>
                    <div className='collection-view-wrapper'>
                        <div className="collection-view-search">
                            <section className="pt-5 pb-5">
                                <div className="collection-title-wrapper">
                                    <div className="title">
                                    </div>
                                </div>
                            </section>
                        </div>
                        {
                            <ViewAssets
                                updateFSLightBox={props.updateFSLightBox}
                                fetchedFileData={fetchedFileData}
                                nextUUID={assetState?.nextUUID ? assetState.nextUUID : nextUUID}
                                prevUUID={assetState?.prevUUID ? assetState.prevUUID : prevUUID}
                                viewAssetsShow={viewAssetsShow}
                                viewAssetBtnDisable={viewAssetBtnDisable}
                                handleViewNext={handleViewNext}
                                handleViewPrev={handleViewPrev}
                                handleViewClose={handleViewClose}
                                fileData={fileData}
                                updateSearchValue={updateSearchValue}
                            />
                        }
                    </div>
                </Container>
            </section>
        )
    );
}

export default AssetSearch;