import { useContext } from 'react';
import { ShareContext } from '../../context/share-state';
import { useMetadata } from '../../context/meta-provider';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import MobileModal from '../mobile-modal';

const MobileType = (props) => {
    const {
        type,
        changeType,
        handleMobileModalTypeClose,
        getAssetCount,
        isImageSearch,
        isFilter,
        assetsTypeCount,
        imageSearchTotal,
        textSearchTotal
    } = useContext(ShareContext);

    // Meta Data
    const { assets } = useMetadata();

    // const assetCount = props.collectionUuid || props.bookmarkUuid ? [] : assets;
    const assetCount = (props) => {
        if(assetsTypeCount) { 
            return assetsTypeCount;
        } else if (props.collectionUuid) {
            return props.collectionAssetCount;
        } else if (props.bookmarkUuid) {
            return props.bookmarksAssetCount;
        } else if(props.searchResult) { 
            return textSearchTotal || [];
        } else if(isImageSearch) { 
            return imageSearchTotal || 0;
        } else {
            return assets;
        }
    };

    return(
        <MobileModal title="File type" onClose={handleMobileModalTypeClose}>
            <div className="mobile-modal-file-type">
                <Navbar expand="lg" className="justify-content-between p-0">
                    <Container fluid className='g-0'>
                        <Nav>
                            {!isImageSearch && (
                            <Nav.Item className={type === ''? 'selected': ''}>
                                <Nav.Link onClick={(e) => { changeType(e, isFilter, '', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                    <img src="/images/icons/all_assets.svg" alt="all assets icon" />
                                    <h3>All Assets</h3>
                                    <Badge><p>{assetCount(props).allAssets}</p></Badge>
                                </Nav.Link>
                            </Nav.Item>
                            )}
                            <Nav.Item className={type === 'image'? 'selected': ''}>
                                <Nav.Link onClick={(e) => { changeType(e, isFilter, 'image', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                <img src="/images/icons/images.svg" alt="images icon" />
                                    <h3>Images</h3>
                                    <Badge><p>{assetCount.image}</p></Badge>
                                    {<Badge>{assetCount(props).image}</Badge>}
                                </Nav.Link>
                            </Nav.Item>
                            {!isImageSearch && (
                                <>
                                <Nav.Item className={type === 'video'? 'selected': ''}>
                                    <Nav.Link onClick={(e) => { changeType(e, isFilter, 'video', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                    <img src="/images/icons/videos.svg" alt="videos icon" />
                                        <h3>Videos</h3>
                                        <Badge><p>{assetCount(props).video}</p></Badge>
                                    </Nav.Link>
                                </Nav.Item>
                                    <Nav.Item className={type === 'document'? 'selected': ''}>
                                        <Nav.Link onClick={(e) => { changeType(e, isFilter, 'document', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                        <img src="/images/icons/documents.svg" alt="documents icon" />
                                            <h3>Documents</h3>
                                            <Badge><p>{assetCount(props).document}</p></Badge>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={type === 'audio'? 'selected': ''}>
                                        <Nav.Link onClick={(e) => { changeType(e, isFilter, 'audio', props.collectionUuid); handleMobileModalTypeClose(); }}>
                                        <img src="/images/icons/audio.svg" alt="audio icon" />
                                            <h3>Audio</h3>
                                            <Badge><p>{assetCount(props).audio}</p></Badge>
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            )}
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </MobileModal>
    );
};

export default MobileType;
