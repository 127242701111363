import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ShareContext } from "../../context/share-state";
import path from "../../routePaths";

const BookmarkHooks = () => {
    const {
        defaultBookmarkBreadcrumb,
        setBookmarkBreadcrumb,
        setBookmarkUuid,
        updateMenuTabKey,
        setNextBookmarkAssetToken,
        setAllBookmarkAssets,
        setDisplayBookmarkAssets } = useContext(ShareContext);
    const navigate = useNavigate();
    const pathPrefix = "/";
    
    const resetBookmarkState = () => {
        setBookmarkUuid("");
        setNextBookmarkAssetToken('');
        setBookmarkBreadcrumb(defaultBookmarkBreadcrumb);
        updateMenuTabKey(path.BOOKMARK);
    };

    const handleClickBookmarkBreadcrumb = () => {
        setAllBookmarkAssets([]);
        setDisplayBookmarkAssets([]);
        navigate(pathPrefix + path.BOOKMARK, { replace: true });
        resetBookmarkState();
    };

    const handleClickBookmarkCard = async (name, uuid) => {
        resetBookmarkState();
        navigate(pathPrefix + path.BOOKMARK + pathPrefix + uuid, { replace: true });
        setBookmarkBreadcrumb(prevBreadcrumb => ({
            title: name,
            breadcrumb: [
              ...prevBreadcrumb.breadcrumb,
              {
                name: name,
                url: "#",
                active: true
              }
            ]
        }));
        setBookmarkUuid(uuid);
        updateMenuTabKey(path.BOOKMARK + pathPrefix + uuid);
    };

    const updateBookmarkSearch = (name, uuid) => {
        resetBookmarkState();
        setBookmarkBreadcrumb(() => ({
            title: name,
            breadcrumb: [
              ...defaultBookmarkBreadcrumb.breadcrumb,
              {
                name: name,
                url: "#",
                active: true
              }
            ]
        }));
        navigate(pathPrefix + path.BOOKMARK + pathPrefix + uuid, { replace: true });
        setBookmarkUuid(uuid);
        updateMenuTabKey(path.BOOKMARK + pathPrefix + uuid);
      };

    return { handleClickBookmarkCard, handleClickBookmarkBreadcrumb, updateBookmarkSearch, resetBookmarkState };
}

export default BookmarkHooks;