import { useContext } from "react";
import { GlobalContext } from "../../shared/context/global-provider"
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const BookmarkSuccess = (props) => {
    const { updateToastMessage } = useContext(GlobalContext);
    
    const handleDoneButtonClick = () => {
        props.clickBookmarkOffcanvas();
        updateToastMessage('Folder edits saved',"success");
      };

    return (
            <>
                <div className={`bookmark-success-wrapper ${props.offcanvasShow? 'hidden':''}`} >
                    <div className="content-wrapper">
                        <div className='icon-container'>
                            <i><CheckCircleRoundedIcon /></i>
                        </div>
                        <h3>
                            Edits saved successfully
                        </h3>
                        <p>
                            Your edits have been saved.
                        </p>
                    </div>
                    <div className="done-btn button-group">
                        <button className="btn btn-primary" type="submit" onClick={handleDoneButtonClick}>Done</button>
                    </div>
                </div> 
            </>
        )
}

export default BookmarkSuccess;