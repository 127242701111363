import { Tooltip } from 'react-tooltip'
import PropTypes from 'prop-types';

const TooltipComponent = (props) => {
    return(
        <Tooltip id={props.id} place={props.placement} content={props.content} />
    );
}

export default TooltipComponent;

TooltipComponent.propTypes = {
    content: PropTypes.node,
    placement: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any
};
