const SolutionManifest = {
  "DefaultGroup": "Default",
  "AdminGroup": ["admin", "new_users_request"],
  "Roles": {
    "CustomerAdmin": "customer_admin",
    "EndUser": "end_user",
    "UserAdmin": "user_admin",
    "AssetOwner": "asset_owner",
    "AssetApprover": "asset_approver",
    "Url": "https://hello.elko.digital/resource-content/user-roles"
  },
  "RolesCombination": [
    {
        "combination": ["user_admin", "end_user"],
        "roles": "ua_eu"
    }, {
        "combination": ["user_admin", "asset_owner"],
        "roles": "ua_ao"
    }, {
        "combination": ["asset_owner", "end_user"],
        "roles": "ao_eu"
    }, {
        "combination": ["user_admin", "asset_owner", "end_user"],
        "roles": "ua_ao_eu"
    }
  ],
  "AdminEmail": "support.elko.digital",
  "DomainName": "elko.digital",
  "S3": {
    "UseAccelerateEndpoint": true,
    "ExpectedBucketOwner": "423815390462"
  },
  "IotHost": "a2d7u62ze9mfm7-ats.iot.ap-southeast-1.amazonaws.com",
  "StateMachines": {
    "Ingest": "elkprd-027d4437e4de-ingest-main",
    "Analysis": "elkprd-027d4437e4de-analysis-main",
    "Main": "elkprd-027d4437e4de-main"
  },
  "ApiEndpoint": "https://px5ubguy7j.execute-api.ap-southeast-1.amazonaws.com/demo",
  "GoogleApiEndpoint": "https://8ubg2cursd.execute-api.ap-southeast-1.amazonaws.com/google",
  "IotTopic": "elkprd-027d4437e4de/status",
  "Proxy": {
    "Bucket": "elkprd-027d4437e4de-423815390462-ap-southeast-1-proxy"
  },
  "Ingest": {
    "Bucket": "elkprd-027d4437e4de-423815390462-ap-southeast-1-ingest"
  },
    "UserProfile": {
    "Bucket": "elkprd-027d4437e4de-423815390462-ap-southeast-1-dam-userprofile",
    "FolderPath": ""
  },
  "SolutionId": "ELKPRD",
  "Version": "v3.1.0",
  "KnowledgeGraph": {
    "ApiKey": "",
    "Endpoint": ""
  },
  "Region": "ap-southeast-1",
  "Cognito": {
    "Group": {
      "Viewer": "viewer",
      "Creator": "creator",
      "Admin": "admin"
    },
    "UserPoolId": "ap-southeast-1_wM44ygopT",
    "ClientId": "1i1ro9o7kobk4bdd8vauqhhvr9",
    "IdentityPoolId": "ap-southeast-1:778a56c3-b2be-4d2d-92ce-30b6ff6eb9d1",
    "RedirectUri": "https://dybb2he7tv7m4.cloudfront.net"
  },
  "LastUpdated": "2023-11-17T02:56:05.662Z",
  "CustomUserAgent": "AWSSOLUTION/elkprd/v3.1.0",
  "StackName": "elkprd-027d4437e4de",
  "AIML": {
    "celeb": false,
    "face": false,
    "facematch": false,
    "label": false,
    "moderation": false,
    "person": false,
    "text": false,
    "segment": false,
    "customlabel": false,
    "minConfidence": 80,
    "customLabelModels": [],
    "frameCaptureMode": 0,
    "textROI": [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ],
    "framebased": false,
    "transcribe": false,
    "keyphrase": false,
    "entity": false,
    "sentiment": false,
    "customentity": false,
    "textract": false
  },
  "SearchOptions": {
    "keyphrase": false,
    "text": false,
    "label": true,
    "customlabel": false,
    "ingest": true
  },
  "ApiOps": {
    "ResendUserEmailCode": "api/user-resend-email",
    "AdditionalUser": "additional-user",
    "AssetsStorage": "assets-storage",
    "FreeTrial": "free-trial",
    "ExtentFreeTrial": "extent-free-trial",
    "VerifyTenantEmail": "api/verify-tenant-email",
    "StripeListInvoices": "stripe-list-invoices",
    "StripePaymentMethods": "stripe-payment-method",
    "StripeGetId": "stripe-get-id",
    "StripeListProductPlan": "api/list-product-plan",
    "StripePaidPlan": "stripe-paid-plan",
    "SubDomain": "api/check-sub-domain",
    "MetaData": "meta-data",
    "UserSettings": "user-settings",
    "UserApprover": "user-approver",
    "UserVerifyEmail": "api/user-verify-email",
    "UserRegistration": "api/user-registration",
    "MapAssets": "map-assets",
    "Collections": "collections",
    "Bookmarks": "bookmarks",
    "Assets": "assets",
    "Analysis": "analysis",
    "Search": "search",
    "LoadAssets": 'load-assets',
    "FilterAssets": 'filter-assets',
    "Execution": "execution",
    "AttachPolicy": "attach-policy",
    "FaceCollections": "rekognition/face-collections",
    "FaceCollection": "rekognition/face-collection",
    "Faces": "rekognition/faces",
    "Face": "rekognition/face",
    "CustomLabelModels": "rekognition/custom-label-models",
    "CustomVocabularies": "transcribe/custom-vocabularies",
    "CustomLanguageModels": "transcribe/custom-language-models",
    "CustomEntityRecognizers": "comprehend/custom-entity-recognizers",
    "Stats": "stats",
    "Users": "users",
    "AIOptionsSettings": "settings/aioptions",
    "UserProfile": "user-profile",
    "DeleteProxyAssets": "delete-proxy-assets",
    "UserListing": "user-listing",
    "AuditTrail": "audit-trail",
    "UserBackupCodes": "user-backup-codes",
    "VerifyUserBackupCodes": "api/verify-backup-codes",
    "Reset2FA": "reset-2fa",
    "Notification": "notification",
    "TextSearch": "text-search-assets",
    "ImageSimilar": "image-similar"
  },
  "Statuses": {
    "Uploading": "UPLOADING",
    "Processing": "PROCESSING",
    "Completed": "COMPLETED",
    "Error": "ERROR",
    "None": "NONE",
    "NotStarted": "NOT_STARTED",
    "Started": "STARTED",
    "InProgress": "IN_PROGRESS",
    "NoData": "NO_DATA",
    "Removed": "REMOVED",
    "IngestStarted": "INGEST_STARTED",
    "IngestCompleted": "INGEST_COMPLETED",
    "IngestError": "INGEST_ERROR",
    "AnalysisStarted": "ANALYSIS_STARTED",
    "AnalysisCompleted": "ANALYSIS_COMPLETED",
    "AnalysisError": "ANALYSIS_ERROR"
  },
  "FrameCaptureMode": {
    "MODE_NONE": 0,
    "MODE_1FPS": 1,
    "MODE_2FPS": 2,
    "MODE_3FPS": 3,
    "MODE_4FPS": 4,
    "MODE_5FPS": 5,
    "MODE_10FPS": 10,
    "MODE_12FPS": 12,
    "MODE_15FPS": 15,
    "MODE_ALL": 1000,
    "MODE_HALF_FPS": 1001,
    "MODE_1F_EVERY_2S": 1002,
    "MODE_1F_EVERY_5S": 1003
  },
  "AnalysisTypes": {
    "Rekognition": {
      "Celeb": "celeb",
      "Face": "face",
      "FaceMatch": "facematch",
      "Label": "label",
      "Moderation": "moderation",
      "Person": "person",
      "Text": "text",
      "Segment": "segment",
      "CustomLabel": "customlabel"
    },
    "Transcribe": "transcribe",
    "Comprehend": {
      "Keyphrase": "keyphrase",
      "Entity": "entity",
      "Sentiment": "sentiment",
      "CustomEntity": "customentity"
    },
    "Textract": "textract"
  },
  "allowedExtensions": /(\.jpg|\.jpeg|\.png|\.gif|\.pdf|\.mp4|\.mov|\.mp3|\.wav|\.svg|\.tif|\.tiff|\.xls|\.xlsx|\.ppt|\.pptx|\.doc|\.docx)$/i,
  "allowedImageExtensions": /(\.jpg|\.jpeg|\.png)$/i,
  "ImageMaximumAllowedHeight": 10000,
  "ImageMaximumAllowedWidth": 10000,
  "documentExcludeFileTypes": ['image/svg+xml', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint'],
  "matchingTagsCount": 3,
  "Stripe": {
    "PublishableKey": "pk_live_51O29EwJIwlk2pETY6y44AX13xAgT3yyJ9T5QVw9ErcWOjxjknkKCaI59I4pxzCrlKrk54SaIzcoJH7c8meVMZs3N00RlVnWjbK"
  },
  "SubscriptionPlan": {
    "FreeTrialId": "freeTrial",
    "PaidPlanId": "paidPlan"
  },
  "UploadLimit": {
    "Name": 256,
    "Size": 50
  },
  "Plan": {
    "Url": "https://hello.elko.digital/plan",
    "FreeTrialUrl": "https://hello.elko.digital/free-trial-register"
  },
  "groupRoles": [
    {
      "name":"Customer Admin",
      "color":"purple",
      "code":"customer_admin"
    },
    {
      "name":"Asset Owner",
      "color":"orange",
      "code":"asset_owner"
    },
    {
      "name":"End User",
      "color":"green",
      "code":"end_user"
    }
  ]
};

export default SolutionManifest;