import { useState, useEffect, useContext, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShareContext } from '../../shared/context/share-state';
import { SessionContext } from '../../shared/context/session-provider';
import AssetService from '../../../services/api/assetsService';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import path from '../../shared/routePaths';
import GridViewIcon from '../../icons/grid-view';
import AssetCard from '../asset-card';
import AssetFilter from '../asset-filter';
import AssetCardSkeleton from '../asset-card-skeleton';
import _, { debounce } from 'lodash';
import Localization from '../../shared/localization';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MobileFilter from '../../shared/components/mobile-filter';
import MobileType from '../../shared/components/mobile-type';
import SettingNavbar from '../../shared/components/setting-navbar';
import Skeleton from 'react-loading-skeleton';
import { ApiContext } from '../../shared/context/api-state';

const RecentlyAddedList = (props) => {
    const { updateMenuTabKey,
        mobileView,
        setMobileView,
        loadAssetsFunc,
        displayFilters,
        setDisplayFilters,
        applyFilters,
        clearFilters,
        view,
        setView,
        allBookmark,
        setFilterState,
        isMobileModalTypeOpen,
        isMobileModalFilterOpen,
        filters,
        currentPage,
        setCurrentPage,
        loading,
        setLoading,
        fetching,
        setIsFetching,
        numFilters,
        type,
        isFilter,
        PAGE_SIZE,
        AllAssets,
        setAllAssets,
        displayItems,
        setDisplayItems,
        onClickFilters,
        getData,
        handleMobileModalTypeOpen,
        handleMobileModalFilterOpen,
        isTablet,
        setIsImageSearch,
        setImageBase64,
        setAssetsToken,
        assetsToken,
        totalPages,
        setTotalPages,
        nextBookmarkToken,
        setAssetsTypeCount
    } = useContext(ShareContext);
    const { isEndUser, isUserAdmin } = useContext(SessionContext);
    const { getBookmarks } = useContext(ApiContext);

    const placeholderSkeleton = 12;

    const [sortFilenameOrder, setSortFilenameOrder] = useState('asc');
    const [sortTypeOrder, setSortTypeOrder] = useState('asc');
    const [sortSizeOrder, setSortSizeOrder] = useState('asc');

    //----- Function -----//
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;
        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= totalPages) {
                return;
            }
            loadItems();
        }
    };

    const loadItems = async () => {
        if (fetching) return;
    
        setIsFetching(true);
    
        let page = currentPage + 1;
        let pageData = getData(AllAssets, filters, page, PAGE_SIZE);
        let allAssets = [...AllAssets];
        let updatedDisplayItems = [...displayItems];
    
        if (assetsToken && page >= totalPages) {
            try {
                filters.token = assetsToken;
                let newAssets = await filterTenantAssetsApi(filters);
                allAssets = [...allAssets, ...newAssets];
                pageData = getData(allAssets, filters, page, PAGE_SIZE);
        
                setTotalPages(pageData.totalPages);
            } catch (err) {
                console.error('ERR in showing assets:',err.message)
            }
        }
    
        // Filter unique items to avoid duplication
        const uniqueItems = pageData.data.filter(
            newItem => !updatedDisplayItems.some(prevItem => prevItem.uuid === newItem.uuid)
        );
        updatedDisplayItems = [...updatedDisplayItems, ...uniqueItems];
    
        // Update states
        setAllAssets(allAssets);
        setDisplayItems(updatedDisplayItems);
        setCurrentPage(page);
    
        if (uniqueItems.length === 0) {
            setIsFetching(false);
            return;
        }
    
        setIsFetching(false);
    };
    
    const changeView = (view) => {
        setView(view);
    }

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return <AssetCardSkeleton key={index}/>;
        });
    };

    const renderPlaceholderSkeletonListView = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return(<div className='asset-card-skeleton-list-view col-12'>
                    <div>
                        <div className='skeleton-list-view'>
                            <div>
                                <Skeleton height={60} />
                            </div>
                            <div>
                                <Skeleton height={60} />
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            );
        });
    };

    const sortByFileName = () => {
        setSortFilenameOrder(sortFilenameOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayItems].sort((a, b) => {
            const nameA = a.basename.toLowerCase();
            const nameB = b.basename.toLowerCase();

            return sortFilenameOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setDisplayItems(sortedAsset);
    }

    const sortByType = () => {
        setSortTypeOrder(sortTypeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayItems].sort((a, b) => {
            const typeA = a.type.toLowerCase();
            const typeB = b.type.toLowerCase();

            return sortTypeOrder === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        });

        setDisplayItems(sortedAsset);
    }

    const sortBySize = () => {
        setSortSizeOrder(sortSizeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displayItems].sort((a, b) => {
            const sizeA = a.fileSize;
            const sizeB = b.fileSize;

            return sortSizeOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
        });

        setDisplayItems(sortedAsset);
    }

    const filterTenantAssetsApi = async (filterAssets) => {
        let response;
        let allTenantAssets = [];
        response = await AssetService.filterAssets(filterAssets);
        allTenantAssets.push(...response.hits); // merge all arriving assets

        if (response.nextToken) {
            setAssetsToken(response.nextToken);
        } else {
            setAssetsToken("");
        }

        return allTenantAssets;
    };

    const RenderAssets = useCallback(
        debounce(async (currentFilters) => {
            setLoading(true);
            setIsFetching(true);
            setImageBase64("");
            await loadAssetsFunc(currentFilters);
            setIsImageSearch(false);
            setAssetsTypeCount(null);
        }, 300),
        [loadAssetsFunc]
    );

    useEffect(() => {
        const retrievedBookmarks = async () => {
            try {
                // retrieve the bookmarks under specific user
                await getBookmarks(null, 'bookmarks', nextBookmarkToken);
            } catch (error) {
                console.error("Error fail to get bookmarks:",error.message);
            }
        }

        if(props.menuTabKey === path.EXPLORE) {
            RenderAssets(filters);
        }

        if(props.menuTabKey !== path.BOOKMARK) {
            retrievedBookmarks();
        }

        // Cleanup for debounced function
        return () => {
            RenderAssets.cancel();
        };
    }, [props.menuTabKey]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <div className="container">
            <section className="pb-5">
                <h2 className="h2-mb-48">{props.title}</h2>
                <div className="row asset-view-container">
                    <div className="mobile-type-active-container" onClick={() => { handleMobileModalTypeOpen(); setDisplayFilters(false); setFilterState(false); }}>
                        <h3 id="mobile-type-active">
                            { type === '' && 'All Assets' }
                            { type === 'image' && 'Images' }
                            { type === 'video' && 'Videos' }
                            { type === 'document' && 'Documents' }
                            { type === 'audio' && 'Audio' }
                        </h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-filter-container" onClick={() => { handleMobileModalFilterOpen(); onClickFilters(); }}>
                        <h3>Filters {numFilters !== 0 && <span>{`(${numFilters})`}</span>}</h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-view-container">
                        <div className={`mobile-view-icon ${mobileView ? "hide" : "show"}`} onClick={() => { setMobileView(true); changeView('grid'); }}><GridViewIcon /></div>
                        <div className={`mobile-view-icon ${mobileView ? "show" : "hide"}`} onClick={() => { setMobileView(false); changeView('row'); }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                            </svg>
                        </div>
                    </div>
                    <SettingNavbar />
                </div>
            </section>
            {
                (displayFilters && !isTablet) &&
                    <section className={view === 'row' ? 'pb-5' : 'pb-5'}>
                        <AssetFilter clear={clearFilters} applyFilters={applyFilters} show={displayFilters}/>
                    </section>
            }
            <section className="pb-5">
                { view === 'row'? <div className='assets-container'>
                    {!loading && (displayItems === null || displayItems.length === 0) && !isFilter &&
                        <div className="asset-view-no-asset">
                            <img src="./images/no-assets.svg" alt="No Assets Illustration"/>
                            <h2>{Localization.Search.AssetList.NoAssets}</h2>
                            {
                                (!isEndUser && !isUserAdmin) &&
                                    <>
                                        <p>{Localization.Search.AssetList.NoAssetsDesc}</p>
                                        <button className="btn btn-primary" onClick={() => updateMenuTabKey(path.UPLOAD)}>{Localization.Search.AssetList.NoAssetsBtn}</button>
                                    </>
                            }
                        </div>
                    }
                    {!loading && (displayItems === null || displayItems.length === 0) && isFilter &&
                        <div className='no-cards-found'>
                            <p className='font-bold'>No results found</p>
                            <ul>
                                <li>Check and ensure your inputs and selections are valid</li>
                                <li>Try inputting and/or selecting other valid Filters options</li>
                            </ul>
                        </div>
                    }
                    {
                        !loading ? displayItems!= null && displayItems.length > 0 &&
                            <Table className="table-assets">
                                <thead>
                                    <tr className='table-dark'>
                                        <th scope="col">
                                        </th>
                                        <th scope="col" className='paragraph-1'>
                                            <div onClick={sortByFileName}>
                                                Filename
                                                <FontAwesomeIcon icon={faSort} />
                                            </div>
                                        </th>
                                        <th className='small-column paragraph-1' scope="col">
                                            <div onClick={sortByType}>
                                                Type
                                                <FontAwesomeIcon icon={faSort} />
                                            </div>
                                        </th>
                                        <th className='small-column paragraph-1' scope="col">
                                            <div onClick={sortBySize}>
                                                Size
                                                <FontAwesomeIcon icon={faSort} />
                                            </div>
                                        </th>
                                        <th className='small-column paragraph-1' scope="col">
                                            <div>
                                                Actions
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading && displayItems!= null && displayItems.map(file => {
                                            let assetFile = {
                                                uuid: file.uuid,
                                                basename: file.basename,
                                                timestamp: file.timestamp,
                                                bookmarks: file.bookmarks,
                                                type: file.type,
                                                size: file.fileSize,
                                                mime: file.mime,
                                                overallStatus: file.overallStatus
                                            }
                                            return (<AssetCard
                                                displayItems={displayItems}
                                                view={view}
                                                key={assetFile.uuid}
                                                file={assetFile}
                                                bookmarks={assetFile.bookmarks}
                                                menuTabKey={props.menuTabKey}
                                                handleViewAssetOpen={props.handleViewAssetOpen}
                                                handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                        })
                                    }
                                </tbody>
                            </Table>
                        : renderPlaceholderSkeletonListView()
                    }
                </div> : <></> }
                { view === 'grid'?  <div className="assets-container row g-3">
                    {!loading && (displayItems === null || displayItems.length === 0) && !isFilter &&
                        <div className="asset-view-no-asset">
                            <img src="./images/no-assets.svg" alt="No Assets Illustration"/>
                            <h2>{Localization.Search.AssetList.NoAssets}</h2>
                            {
                                (!isEndUser && !isUserAdmin) &&
                                    <>
                                        <p>{Localization.Search.AssetList.NoAssetsDesc}</p>
                                        <button className="btn btn-primary" onClick={() => updateMenuTabKey(path.UPLOAD)}>{Localization.Search.AssetList.NoAssetsBtn}</button>
                                    </>
                            }
                        </div>
                    }
                    {!loading && (displayItems === null || displayItems.length === 0) && isFilter &&
                        <div className='no-cards-found'>
                            <p className='font-bold'>No results found</p>
                            <ul>
                                <li>Check and ensure your inputs and selections are valid</li>
                                <li>Try inputting and/or selecting other valid Filters options</li>
                            </ul>
                        </div>
                    }
                    {
                        !loading ? displayItems!= null && displayItems.map((file, index, elements) => {
                            let assetFile = {
                                uuid: file.uuid,
                                basename: file.basename,
                                timestamp: file.timestamp,
                                bookmarks: file.bookmarks,
                                type: file.type,
                                size: file.fileSize,
                                overallStatus: file.overallStatus
                            }

                            return (<AssetCard
                                view={view}
                                key={assetFile.uuid}
                                file={assetFile}
                                displayItems={displayItems}
                                menuTabKey={props.menuTabKey}
                                bookmarks={assetFile.bookmarks}
                                handleViewAssetOpen={props.handleViewAssetOpen}
                                handleRemoveFileModal={props.handleRemoveFileModal}/>)
                        }) : renderPlaceholderSkeleton()
                    }
                </div> : <></> }
            </section>

            {
                isMobileModalTypeOpen && isTablet && (
                    <MobileType />
                )
            }
            {
                isMobileModalFilterOpen && isTablet && (
                    <MobileFilter hasSort={true} />
                )
            }
        </div>
    )
}

export default RecentlyAddedList;