import { useContext, useState } from "react";
import CustomIcons from "../../shared/components/custom-icons";
import Localization from "../../shared/localization";
import { ShareContext } from "../../shared/context/share-state";
import { ApiContext } from "../../shared/context/api-state";
import { SessionContext } from "../../shared/context/session-provider";
import BookmarkCardSkeleton from "../bookmark-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePowerpoint, faFileWord } from "@fortawesome/free-solid-svg-icons";


const BookmarkCard = (props) => {
    const { bookmarkCard } = useContext(ShareContext);
    const { tenantUuid } = useContext(SessionContext);
    const { getAssetsById } = useContext(ApiContext);

    const [loading, setLoading] = useState(true);
    const [assetsData, setAssetsData] = useState([]);
    const [assetsFetched, setAssetsFetched] = useState(false); // To track if assets have been fetched

    const bookmark = bookmarkCard.find((item) => item.uuid === props.uuid) || {};

    const fetchAssets = async () => {
        if (assetsFetched) return; // Prevent repeated calls

        try {
            if (bookmark.assets && bookmark.assets.length > 0) {
                const sortedAssets = bookmark.assets.sort((a, b) => b.timestamp - a.timestamp);
                const top3Assets = sortedAssets.slice(0, 3);
                const assetUuids = top3Assets.map((asset) => asset.uuid);

                // Fetch all assets in parallel
                const assetPromises = assetUuids.map((uuid) => getAssetsById(uuid, tenantUuid));
                const assets = await Promise.all(assetPromises);

                // Map the results to updated asset data
                const updatedAssets = assets.map((asset) => {
                    const isGif = asset?.mime === 'image/gif' || asset?.mime === 'application/pdf';
                    const blob = isGif ? asset.proxyBlob : asset.ingestBlob;
                    const imageUrl = URL.createObjectURL(blob);

                    return {
                        uuid: asset.uuid,
                        type: asset.type,
                        imageUrl,
                        blob,
                        key: asset.key
                    };
                });

                setAssetsData(updatedAssets);
                setAssetsFetched(true); // Mark assets as fetched
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading && !assetsFetched) {
        fetchAssets(); // Trigger fetchAssets only once during initial load
    }

    const renderAsset = (key, type, imageUrl, altText, isSmall = false) => {
        const extension = key.split(".").pop().toLowerCase();
        const iconClass = `bookmark-card-icon ${isSmall ? 'small' : ''}`;
        switch (type) {
            case 'video':
                return (
                    <div className={iconClass}>
                        <CustomIcons variant="movie" />
                    </div>
                );
            case 'document':
                switch (extension) {
                    case 'pdf':
                        return (
                            <div className="bookmark-card-img">
                                <img src={imageUrl} alt={altText} />
                            </div>
                        );
                    case 'doc':
                    case 'docx':
                        return (
                            <div className={iconClass}>
                                <FontAwesomeIcon className="svg-icon" icon={faFileWord} />
                            </div>
                        );
                    case 'xls':
                    case 'xlsx':
                        return (
                            <div className={iconClass}>
                                <FontAwesomeIcon className="svg-icon" icon={faFileExcel} />
                            </div>
                        );
                    case 'ppt':
                    case 'pptx':
                        return (
                            <div className={iconClass}>
                                <FontAwesomeIcon className="svg-icon" icon={faFilePowerpoint} />
                            </div>
                        );
                    default:
                        return (
                            <div className={iconClass}>
                                <CustomIcons variant="faFile" className="svg-icon" />
                            </div>
                        );
                }
            case 'audio':
                return (
                    <div className={iconClass}>
                        <CustomIcons variant="faFileAudio" className="svg-icon" />
                    </div>
                );
            default:
                if (imageUrl) {
                    return (
                        <div className="bookmark-card-img">
                            <img src={imageUrl} alt={altText} />
                        </div>
                    );
                }
                return null;
        }
    };

    if (loading) {
        return (
            <div className="bookmark-card">
                <div className="bookmark-card-wrapper">
                    <div className="bookmark-card-group">
                        <BookmarkCardSkeleton />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bookmark-card">
                <div className="bookmark-card-wrapper">
                    <div className="bookmark-card-group" onClick={() => props.handleClickBookmarkCard(bookmark?.name, bookmark?.uuid)}>
                    {assetsData.length >= 3 && (
                        <div className="bookmark-card-ratio three-image">
                            <div>
                                {renderAsset(assetsData[0]?.key, assetsData[0]?.type, assetsData[0]?.imageUrl, bookmark?.name)}
                            </div>
                            <div>
                                {renderAsset(assetsData[1]?.key, assetsData[1]?.type, assetsData[1]?.imageUrl, bookmark?.name, true)}
                                {renderAsset(assetsData[2]?.key, assetsData[2]?.type, assetsData[2]?.imageUrl, bookmark?.name, true)}
                            </div>
                        </div>
                    )}
                    {assetsData.length === 2 && (
                        <div className="bookmark-card-ratio two-image">
                            {renderAsset(assetsData[0]?.key, assetsData[0]?.type, assetsData[0]?.imageUrl, bookmark?.name)}
                            {renderAsset(assetsData[1]?.key, assetsData[1]?.type, assetsData[1]?.imageUrl, bookmark?.name)}
                        </div>
                    )}
                    {assetsData.length === 1 && (
                        <div className="bookmark-card-ratio one-image">
                            {renderAsset(assetsData[0]?.key, assetsData[0]?.type, assetsData[0]?.imageUrl, bookmark?.name)}
                        </div>
                    )}
                    {assetsData.length === 0 && (
                        <div className="bookmark-card-ratio no-asset">
                            <div className="bookmark-card-icon">
                                <h3>{Localization.BookMark.AssetList.NoAssetsTitle}</h3>
                            </div>
                        </div>
                    )}
                    </div>
                    {
                        <h3>{bookmark?.name}</h3>
                    }
                </div>
            </div>
        </>
    );
}

export default BookmarkCard;