import { useState, createContext, useContext, useRef, useEffect } from 'react';
import { createStore, removeStoreItem, setStoreItem } from '../../../../store';
import { ShareContext } from '../share-state';
import { SessionContext } from '../session-provider';
import { GlobalContext } from '../global-provider';
import { randomHexstring, getFileType, createAuditBody } from '../../utils';
import { IsDesktop } from '../../../shared/utils';
import AssetService from '../../../../services/api/assetsService';
import SolutionManifest from '../../../../solution-manifest';
import MediaManager from '../../../../services/api/media/mediaManager';
import path from '../../routePaths';
import Localization from '../../localization';
import ServiceUtil from '../../../../services/util/serviceUtil';
import AWS from 'aws-sdk';
import auditTrailService from '../../../../services/api/auditTrailService';
import * as UTIF from 'utif';

export const UploadContext = createContext();

export const UploadProvider = ({children}) => {
    const [tempfilesData, setTempFilesData] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [filesArr, setFilesArr] = useState([]);
    const [uploadError, setUploadError] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [successPercentage, setSuccessPercentage] = useState(0);
    const [errorPercentage, setErrorPercentage] = useState(0);
    const [offcanvasState, setOffcanvasState] = useState(false);
    const [progressState, setProgressState] = useState(false);
    const [isUploadSuccess, setUploadSuccess] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [checkedFiles, setCheckedFiles] = useState([]);
    const [uploadBtn, setUploadBtn] = useState(true);
    const [disableUploadBtn, setDisableUploadBtn] = useState(true);
    const [stickyCanvas, setStickyCanvas] = useState(false);
    const [stickySetting, setStickySetting] = useState(false);
    const [activeSetting, setActiveSetting] = useState("");
    const [activeSettingView, setActiveSettingView] = useState("grid");

    // Upload Collection
    const [collection, setCollection] = useState([]);
    const [collectionKey, setCollectionKey] = useState(0);
    const [collectionAsset, setCollectionAsset] = useState([]);
    const [collectionMultipleSelection, setCollectionMultipleSelection] = useState([]);

    // Upload Tag
    const [tagAsset, setTagAsset] = useState([]);
    const [tagMultipleSelection, setTagMultipleSelection] = useState([]);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);

    // Global Context
    const { updateToastMessage } = useContext(GlobalContext);

    const isDesktop = IsDesktop();

    const stickyRef = useRef(null);
    const settingRef = useRef(null);
    const processRef = useRef(null);
    const videoRef = useRef([]);
    const audioRef = useRef([]);
    const imageRef = useRef([]);
    const pdfRef = useRef([]);
    const collectionDescRef = useRef(null);
    const collectionSameRef = useRef(null);

    // Share Context
    const { updateMenuTabKey,
        setAssetTagFiles,
        setImageSearchUrl,
        setIsLoading,
        setImageSearchLabels } = useContext(ShareContext);

    // Update ActiveSettingView
    const updateActiveSettingView = (newValue) => {
        setActiveSettingView(newValue);

        const divGridElement = document.querySelector('.grid-btn');
        const divListElement = document.querySelector('.list-btn');

        if(!isDesktop) {
             if(newValue !== 'list') {
                 divGridElement.style.display = 'block';
                 divListElement.style.display = 'none';
             } else {
                 divGridElement.style.display = 'none';
                 divListElement.style.display = 'block';
             }
         } else {
             divGridElement.style.display = 'block';
             divListElement.style.display = 'block';
         }
    };

    // Update Collection
    const updateCollection = (newValue) => {
        setCollection(newValue);
    };

    // Update CollectionKey
    const updateCollectionKey = (newValue) => {
        setCollectionKey(newValue);
    };

    // Update CollectionAsset
    const updateCollectionAsset = (newValue) => {
        setCollectionAsset(newValue);
    };

    // Update CollectionMultipleSelection
    const updateCollectionMultipleSelection = (newValue) => {
        setCollectionMultipleSelection(newValue);
    };

    // Update File Collection
    const updateFileCollection = (updateCollection, checkedFiles) => {
        const updatedFiles = filesData.files;

        if(updateCollection) {
            updatedFiles.forEach((file) => {
                checkedFiles.forEach((checkedFile) => {
                    if(file.id === checkedFile.id) {
                        file.collection = updateCollection;
                    }
                })
                if (file.collection !== undefined && file.collection.length === 0) {
                    delete file.collection;
                }
            });
        }

        updateFileData(updatedFiles);
    };

    // Update DisableUploadBtn
    const updateDisableUploadBtn = (newValue) => {
        setDisableUploadBtn(newValue);
    };

    // Update Upload Btn
    const updateUploadBtn = (newValue) => {
        setUploadBtn(newValue);
    };

    // Update File Data
    const updateFileData = (newValue) => {
        const newFilesData = {
            files: newValue,
            total: newValue.length
        };
        setFilesArr(newValue);
        setFilesData(newFilesData);
    }

    // Update StickySetting State
    const updateOffcanvasState = (newValue) => {
        setOffcanvasState(newValue)
    };

    // Update StickySetting State
    const updateActiveSetting = (newValue) => {
        setActiveSetting(newValue);

        if(newValue === "") {
            setOffcanvasState(false);
            // if(settingRef.current.style.maxWidth !== null) {
                if(settingRef.current){
                    settingRef.current.style.maxWidth = null;
                }
            // }
        }
        const divElement = document.querySelector('.headroom-wrapper');

        if(!isDesktop) {
            divElement.style.display = newValue === '' ? 'block' : 'none';
        }
    };

    // Update StickySetting State
    const updateStickySetting = (newValue) => {
        setStickySetting(newValue)
    };

    // Update SelectAllChecked State
    const updateSelectAllChecked = (newValue) => {
        setSelectAllChecked(newValue)
    };

    // Update SelectAllChecked State
    const updateStickyCanvas = (newValue) => {
        setStickyCanvas(newValue)
    };

    // Update UploadError State
    const updateUploadError = (newValue) => {
        setUploadError(newValue);
    };

    // Update CheckedFiles State
    const updateCheckedFiles = (newValue) => {
        setCheckedFiles(newValue);
    };

    /* Map Assets with Collection */
    const mapCollection = async (data) => {
        const bodyMapAssets = {
            "input": {
                "map": data,
                "tenantUuid": tenantUuid
            }
        }

        try {
            const mapAssets = await AssetService.mapAssets(bodyMapAssets, {}, tenantUuid);
            return mapAssets;
        } catch (error) {
            console.error(`fail to map assets to collection`);
            return false;
        }
    };

    // Update Files UUID
    const updateUUID = (id, uuid) => {
        const updateFilesUUID = filesData.files;
        const filterUpdateFiles = updateFilesUUID.filter(file => file.id === id);
        updateFilesUUID.forEach(file => file.id === id ? file.uuid = uuid : null)

        // if(filterUpdateFiles[0].collection) {
        //     const dataCollection = [
        //         {
        //             "uuid": filterUpdateFiles[0].uuid,
        //             "collections": filterUpdateFiles[0].collection
        //         }
        //     ];

        //     mapCollection(dataCollection);
        // }

        const newFilesData = {
            files: updateFilesUUID,
            total: updateFilesUUID.length
        };

        setFilesData(newFilesData);
        return filterUpdateFiles[0];
    };

    const handleVideoJobCompletion = async (rekog, jobId) => {
        try {
            while (true) {
                const data = await rekog.getLabelDetection({ JobId: jobId }).promise();
                const jobStatus = data.JobStatus;

                if (jobStatus === 'SUCCEEDED') {
                    const labels = data.Labels;
                    return labels; // Return labels when job succeeds
                } else if (jobStatus !== 'IN_PROGRESS') {
                    throw new Error("Label detection job failed or was aborted.");
                }

                // Wait for 5 seconds before checking again
                await new Promise(resolve => setTimeout(resolve, 2500));
            }
        } catch (error) {
            throw error; // Re-throw error to handle it at a higher level if needed
        }
    };

    const handleVideoJobTextCompletion = async (rekog, jobId) => {
        try {
            while (true) {
                const data = await rekog.getTextDetection({ JobId: jobId }).promise();
                const jobStatus = data.JobStatus;

                if (jobStatus === 'SUCCEEDED') {
                    const textDetections = data.TextDetections;
                    return textDetections; // Return text detections when job succeeds
                } else if (jobStatus !== 'IN_PROGRESS') {
                    throw new Error("Text detection job failed or was aborted.");
                }

                // Wait for 2.5 seconds before checking again
                await new Promise(resolve => setTimeout(resolve, 2500));
            }
        } catch (error) {
            throw error; // Re-throw error to handle it at a higher level if needed
        }
    };

    const handleVideoJobCelebrityCompletion = async (rekog, jobId) => {
        try {
            while (true) {
                const data = await rekog.getCelebrityRecognition({ JobId: jobId }).promise();
                const jobStatus = data.JobStatus;

                if (jobStatus === 'SUCCEEDED') {
                    const celebrities = data.Celebrities;
                    return celebrities; // Return celebrities when job succeeds
                } else if (jobStatus !== 'IN_PROGRESS') {
                    throw new Error("Celebrity recognition job failed or was aborted.");
                }

                // Wait for 2.5 seconds before checking again
                await new Promise(resolve => setTimeout(resolve, 2500));
            }
        } catch (error) {
            throw error; // Re-throw error to handle it at a higher level if needed
        }
    };

    // async function getLabels(rekog, jobId) {
    //     return new Promise((resolve, reject) => {
    //       rekog.getLabelDetection({ JobId: jobId }, (err, data) => {
    //         if (err) {
    //           reject(err);
    //         } else {
    //           const labels = data.Labels;
    //           resolve(labels);
    //         }
    //       });
    //     });
    // }

    const processFileAttributes = (file, tenantUuid, userUuid) => {
        return new Promise((resolve, reject) => {
            const url = URL.createObjectURL(file);

            if (file.type.startsWith('image/')) {
                let orientation;
                if (file.type.startsWith('image/tiff')) {
                    // Handle TIFF images
                    const reader = new FileReader();
                    reader.onload = function(e) {
                        const buffer = e.target.result;
                        const ifds = UTIF.decode(buffer);
                        if (ifds.length > 0) {
                            UTIF.decodeImage(buffer, ifds[0]);
                            const width = ifds[0].width;
                            const height = ifds[0].height;

                            if (width > height) {
                                orientation = "Landscape";
                            } else if (width < height) {
                                orientation = "Portrait";
                            } else if (width === height) {
                                orientation = "Square";
                            }

                            const attributeList = [
                                { key: 'group', value: tenantUuid },
                                { key: 'userUuid', value: userUuid },
                                { key: 'tags', value: "" },
                                { key: 'orientation', value: orientation },
                                { key: 'width', value: `${width}` },
                                { key: 'height', value: `${height}`},
                            ];
                            resolve(attributeList);
                        }
                    };
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(file);
                } else {
                    const img = new Image();

                    img.onload = function() {
                        const width = this.width;
                        const height = this.height;

                        if (width > height) {
                            orientation = "Landscape";
                        } else if (width < height) {
                            orientation = "Portrait";
                        } else if (width === height) {
                            orientation = "Square";
                        }

                        const attributeList = [
                            { key: 'group', value: tenantUuid },
                            { key: 'userUuid', value: userUuid },
                            { key: 'tags', value: "" },
                            { key: 'orientation', value: orientation },
                            { key: 'width', value: `${width}` },
                            { key: 'height', value: `${height}`},
                        ];
                        resolve(attributeList);
                    };

                    img.onerror = reject;
                    img.src = url;
                }
            } else if (file.type.startsWith('video/')) {
                const video = document.createElement('video');

                video.onloadedmetadata = function() {
                    const width = this.videoWidth;
                    const height = this.videoHeight;
                    let orientation;

                    if (width > height) {
                        orientation = "Landscape";
                    } else if (width < height) {
                        orientation = "Portrait";
                    } else if (width === height) {
                        orientation = "Square";
                    }

                    const attributeList = [
                        { key: 'group', value: tenantUuid },
                        { key: 'userUuid', value: userUuid },
                        { key: 'tags', value: "" },
                        { key: 'orientation', value: orientation },
                        { key: 'width', value: `${width}` },
                        { key: 'height', value: `${height}`},
                    ];
                    resolve(attributeList);
                };

                video.onerror = reject;
                video.src = url;
            } else {
                // If the file type is neither image nor video, resolve with an empty list or handle as needed
                const attributeList = [
                    { key: 'group', value: tenantUuid },
                    { key: 'userUuid', value: userUuid },
                    { key: 'tags', value: "" }
                ];
                resolve(attributeList);
            }
        });
    };

    //Handle Analysis
    const handleAnalysis = async (bucket, name, type, minConfidence = SolutionManifest.AIML.minConfidence) => {
        const maxLabels = 20;

        function getTop30Labels(labels, type) {
            // Sort labels based on confidence level in descending order
            labels.sort((a, b) => (type === 'video' ? b.Label.Confidence - a.Label.Confidence : b.Confidence - a.Confidence));

            // Slice the array to get the top 30 labels
            const topLabels = labels.slice(0, maxLabels);

            topLabels.sort((a, b) => (type === 'video' ? a.Label.Name.localeCompare(b.Label.Name) : a.Name.localeCompare(b.Name)));

            return topLabels;
        }

        try {
            // Validate input parameters
            if (!bucket || !name || !type) {
                throw new Error('Bucket, name, and type are required.');
            }

            // Initialize AWS Rekognition
            const rekognition = new AWS.Rekognition();

            // Set parameters based on type
            const params = {
                MinConfidence: minConfidence,
            };

            if (type === 'image') {
                params.Image = { S3Object: { Bucket: bucket, Name: name } };

                // const paramsCelebrityFaces = {
                //     Image: { S3Object: { Bucket: bucket, Name: name } }
                // };

                const paramsFaces = {
                    Image: { S3Object: { Bucket: bucket, Name: name } }
                };

                // Detect labels
                let detectLabels = await rekognition.detectLabels(params).promise();

                // Detect Celebrities
                // const detectCelebrities = await rekognition.recognizeCelebrities(paramsCelebrityFaces).promise();

                // Detect Text
                const detectText = await rekognition.detectText(paramsFaces).promise();

                // Create a Set to store unique names
                let uniqueNames = new Set();

                // Add existing label names from detectLabels to the set
                detectLabels.Labels.forEach((label) => {
                    uniqueNames.add(
                        label.Name.replace(/[^a-zA-Z0-9 ]/g, '')
                    );
                });

                // Add celebrity names to detectLabels
                // detectCelebrities.CelebrityFaces.forEach((celebrity) => {
                //     // Format the name to title case and remove special characters
                //     let formattedName = celebrity.Name
                //         .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
                //         .toLowerCase()
                //         .split(' ')
                //         .map(word => word.charAt(0).toUpperCase() + word.substring(1))
                //         .join(' ');

                //     // Check if the name is unique and confidence is above the threshold
                //     if (!uniqueNames.has(formattedName) && celebrity.Face.Confidence >= minConfidence) {
                //         uniqueNames.add(formattedName);

                //         // Create a new label object for the celebrity
                //         let newLabel = {
                //             Name: formattedName,
                //             Confidence: celebrity.Face.Confidence,
                //             Instances: [],
                //             Parents: [],
                //             Aliases: [],
                //             Categories: []
                //         };

                //         // Add the new label to detectLabels
                //         detectLabels.Labels.push(newLabel);
                //     }
                // });

                detectText.TextDetections.forEach((textDetection) => {
                    // Format the detected text to title case and remove special characters
                    let formattedText = textDetection.DetectedText
                        .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
                        .toLowerCase()
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.substring(1))
                        .join(' ');

                    // Check if the text is unique and confidence is above the threshold
                    if (!uniqueNames.has(formattedText) && textDetection.Confidence >= minConfidence) {
                        uniqueNames.add(formattedText);

                        // Create a new label object for the detected text
                        let newLabel = {
                            Name: formattedText,
                            Confidence: textDetection.Confidence,
                            Instances: [],
                            Parents: [],
                            Aliases: [],
                            Categories: []
                        };

                        // Add the new label to detectLabels
                        detectLabels.Labels.push(newLabel);
                    }
                });

                // Filter out labels with empty or whitespace-only names
                detectLabels.Labels = detectLabels.Labels.filter(label => label.Name.trim() !== '');

                const top30Labels = getTop30Labels(detectLabels.Labels, type);

                return top30Labels;
            // } else if (type === 'video') {
            //     //Detection for text and celebrities
            //     // const paramsText = {
            //     //     Video: { S3Object: { Bucket: bucket, Name: name } }
            //     // };
            //     // const startTextDetection = await rekognition.startTextDetection(paramsText).promise();
            //     // const videoTexts = await handleVideoJobTextCompletion(rekognition, startTextDetection.JobId);

            //     // const paramsCelebrityFaces = {
            //     //     Video: { S3Object: { Bucket: bucket, Name: name } }
            //     // };
            //     // const startCelebritiesRecognition = await rekognition.startCelebrityRecognition(paramsCelebrityFaces).promise();
            //     // const videoCelebrities = await handleVideoJobCelebrityCompletion(rekognition, startCelebritiesRecognition.JobId);

            //     params.Video = { S3Object: { Bucket: bucket, Name: name } };
            //     const startLabelDetection = await rekognition.startLabelDetection(params).promise();
            //     const videoLabels = await handleVideoJobCompletion(rekognition, startLabelDetection.JobId);

            //     // Add videoTexts and videoCelebrities to videoLabels
            //     // const combinedLabels = [...videoLabels, ...videoTexts.map(text => ({ Label: { Name: text.TextDetection.DetectedText, Confidence: text.TextDetection.Confidence } })), ...videoCelebrities.map(celeb => ({ Label: { Name: celeb.Celebrity.Name, Confidence: celeb.Celebrity.Confidence } }))];

            //     const uniqueVideoLabels = videoLabels.reduce((uniqueLabels, { Label }) => {
            //         const existingIndex = uniqueLabels.findIndex(item => item.Label.Name === Label.Name);
            //         if (existingIndex === -1) {
            //             uniqueLabels.push({ Label: { Name: Label.Name, Confidence: Label.Confidence } });
            //         }
            //         return uniqueLabels;
            //     }, []);

            //     const top30Labels = getTop30Labels(uniqueVideoLabels, type);

            //     return top30Labels;
            }
        } catch (error) {
            console.error('Error analyzing image:', error.message);
            return []; // Return an empty array if an error occurs
        }
    };

    // Upload Files
    const uploadFilesData = async (files, uploadRemainingSize) => {
        if(activeSetting ==='upload-error') {
            setOffcanvasState(false);
        }
        const maxWidth = SolutionManifest.ImageMaximumAllowedWidth;
        const maxHeight = SolutionManifest.ImageMaximumAllowedHeight;
        const allowedExtensions = SolutionManifest.allowedExtensions;
        const tempFiles = [];
        const offset = 250;
        const { scrollTop } = document.documentElement;

        let currentFilesData = [];
        let sticky = offset,
            settingScrollTop;

        const validateResolution = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
        
                reader.onload = (e) => {
                    try {
                        // TIFF validation
                        if (file.type === "image/tiff" || file.name.endsWith(".tiff")) {
                            const buffer = new Uint8Array(e.target.result);
                            const ifds = UTIF.decode(buffer);                             
                            if (ifds.length > 0) {
                                UTIF.decodeImage(buffer, ifds[0]);
                                const { width, height } = ifds[0];
        
                                if (width <= maxWidth && height <= maxHeight) {
                                    resolve(true);
                                } else {
                                    reject(`Resolution ${width}x${height} exceeds ${maxWidth}x${maxHeight}`);
                                }
                            } else {
                                reject("Unable to parse TIFF file or no image data found.");
                            }
                        } else {
                            // Non-TIFF image validation
                            const img = new Image();
                            img.src = e.target.result;
        
                            img.onload = () => {
                                const { width, height } = img;
                                if (width <= maxWidth && height <= maxHeight) {
                                    resolve(true);
                                } else {
                                    reject(`Resolution ${width}x${height} exceeds ${maxWidth}x${maxHeight}`);
                                }
                            };
        
                            img.onerror = () => reject("Error loading image.");
                        }
                    } catch (error) {
                        reject(`Error processing file: ${error.message}`);
                    }
                };
        
                reader.onerror = () => reject("Error reading file.");
                if (file.type === "image/tiff" || file.name.endsWith(".tiff")) {
                    reader.readAsArrayBuffer(file);
                } else {
                    reader.readAsDataURL(file);
                }
            });
        };

        const isUploadSizeExceeded = (files) => {
            const units = ['Bytes', 'KB', 'MB', 'GB'];
            const sizeMatch = uploadRemainingSize.match(/^(\d+(\.\d+)?)\s*([a-zA-Z]+)$/);

            const [, size, , unit] = sizeMatch;
            const remainingSizeInBytes = parseFloat(size) * Math.pow(1024, units.indexOf(unit.toUpperCase()));
            const totalFileSizeInBytes = filesArr.length > 0 ? filesArr.reduce((total, file) => total + file.object.size, 0) : files.reduce((total, file) => total + file.size, 0);

            // Check if total file size is less than or equal to remaining size
            return totalFileSizeInBytes >= remainingSizeInBytes;
        };

        // Sticky Function
        if (stickyRef.current) {
            if (scrollTop >= sticky) {
                setStickyCanvas(true);
            } else {
                setStickyCanvas(false);
            }
        }

        if(processRef.current) {
            settingScrollTop = processRef.current.offsetTop + offset - 80;

            if(scrollTop >= settingScrollTop) {
                setStickySetting(true);
            } else {
                setStickySetting(false);
            }
        }

        // Reset Checked files if uploaded default length is 0
        if(filesArr.length === 0) {
            setSelectAllChecked(false);
            setCheckedFiles([]);
        }

        // Checking Upload File Size Exceeded Storage or not
        if (isUploadSizeExceeded(files)) {
            // Handle case where file size exceeds remaining size
            updateToastMessage(Localization.Upload.StorageExceeded, 'error');

            return;
        };
        for(const file of files) {
            const sameFilePath = filesArr.some(uploadedFile => uploadedFile.object.path === file.path);
            const fileSize = file.size / 1024 / 1024; // in MiB

            const isResolutionValid = 
                file.type.startsWith("image/") 
                    ? await validateResolution(file).catch((error) => {
                        console.error(`File ${file.name}:`, error);
                        return false;
                    }) 
                    : true;


            if(isResolutionValid && !sameFilePath && allowedExtensions.exec(file.name) && fileSize <= SolutionManifest.UploadLimit.Size && !filesArr.some(existingFile => existingFile.name === file.name) && file.name.length < SolutionManifest.UploadLimit.Name) {
                const attributeList = await processFileAttributes(file, tenantUuid, userUuid);
                const attributes = attributeList.reduce((a0, c0) => ({
                    ...a0,
                    [c0.key]: c0.value,
                }), {});

                filesArr.unshift({
                    aiSmartTags: "",
                    name: file.name,
                    type: getFileType(file.name),
                    object: file,
                    id: getFileType(file.name) + "-" + randomHexstring(),
                    attributes: attributes,
                    state: getFileType(file.name) === 'image' ? 'ANALYSING' : '',
                    status: 'INGEST_STARTED',
                    uuid: '',
                    timestamp: file.timestamp ? file.timestamp : new Date().getTime()
                });

                currentFilesData.push({
                    aiSmartTags: "",
                    name: file.name,
                    type: getFileType(file.name),
                    object: file,
                    id: getFileType(file.name) + "-" + randomHexstring(),
                    attributes: attributes,
                    state: getFileType(file.name) === 'image' ? 'ANALYSING' : '',
                    status: 'INGEST_STARTED',
                    uuid: '',
                    timestamp: file.timestamp ? file.timestamp : new Date().getTime()
                })
                setFilesArr(filesArr);
            } else {
                tempFiles.push({
                    name: file.name,
                    type: file.type,
                    object: file,
                    attributes: files.attributes,
                    error: sameFilePath ? "same" : !allowedExtensions.exec(file.name) ? "extension" : fileSize > SolutionManifest.UploadLimit.Size ? "size" : file.name.length > SolutionManifest.UploadLimit.Name ? "name" : null
                });
            }
        };

        const newItems = filesData?.files && filesArr.filter(item => !filesData.files.some(existingItem => existingItem.name === item.name));
        const concatItems = filesData.files && [...filesData.files, ...newItems];

        const newFilesData = {
            files: filesData.files ? concatItems : filesArr,
            total: filesData.files ? concatItems.length : filesArr.length
        };

        setFilesData({ files: newFilesData.files, total: newFilesData.files.length });

        async function processProxyStateFiles(stateFiles) {
            let assets = [];
            const BATCH_SIZE = 9;  // Number of files to process simultaneously
            currentFilesData = [...stateFiles];

            for (let i = 0; i < stateFiles.length; i += BATCH_SIZE) {
                // Get the current batch of files
                const batch = stateFiles.slice(i, i + BATCH_SIZE);

                // Process the batch in parallel
                await Promise.all(batch.map(async (file) => {
                    let uuid = ServiceUtil.uuid4();
                    const fileType = getFileType(file.name);

                    if (fileType === 'image') { //|| fileType === 'video') {
                        await AssetService.uploadProxyS3TempAssets(file.name, file.object, tenantUuid, uuid);

                        const labels = await handleAnalysis(SolutionManifest.Proxy.Bucket, `temp/${tenantUuid}/${uuid}/${file.name}`, fileType);

                        const updatedFilesData = currentFilesData.map(fileData => {
                            if (fileData.name === file.name && fileData.state === 'ANALYSING') {
                                if (labels) {
                                    const tags = labels.map(label => fileType === 'video' ? label.Label.Name : label.Name).join(',');
                                    return {
                                        ...fileData,
                                        state: '',
                                        attributes: {
                                            ...fileData.attributes,
                                            tags: tags
                                        },
                                        aiSmartTags: tags,
                                        timestamp: fileData.timestamp ? fileData.timestamp : new Date().getTime()
                                    };
                                } else {
                                    const fileDataMatch = filesData.files.find(fd => fd.name === fileData.name);
                                    const attributes = fileDataMatch.attributes;
                                    return {
                                        ...fileData,
                                        state: '',
                                        attributes: attributes
                                    };
                                }
                            } else {
                                return { ...fileData };
                            }
                        });

                        currentFilesData = updatedFilesData;

                        setAssetTagFiles(prevAssetTagFiles => {
                            const updatedPrevAssetTagFiles = Array.isArray(prevAssetTagFiles) && prevAssetTagFiles.length > 0
                                ? [...prevAssetTagFiles, { key: file.name, labels: labels, type: fileType }]
                                : [{ key: file.name, labels: labels, type: fileType }];

                            setFilesData((prevFilesData) => {
                                const updatedFiles = prevFilesData.files.map(fileData => {
                                    const updatedFile = updatedFilesData.find(item => item.name === fileData.name);
                                    const fileTags = Array.isArray(prevAssetTagFiles)
                                        ? prevAssetTagFiles
                                            .filter(item => item.key === fileData.name)[0]?.labels
                                            ?.map(label => label.Name)
                                            .join(',')
                                        : '';

                                    const updatedOldFile = {
                                        ...fileData,
                                        attributes: {
                                            ...fileData.attributes,
                                            tags: fileData.attributes.tags ? fileData.attributes.tags : fileTags ? fileTags : ""
                                        },
                                        state: fileData.attributes.tags || fileTags ? '' : fileData.state,
                                        aiSmartTags: fileData.aiSmarttags ? fileData.aiSmarttags : fileTags
                                    };

                                    return updatedFile ? { ...updatedOldFile, ...updatedFile } : updatedOldFile;
                                });
                                return { files: updatedFiles, total: updatedFiles.length }
                            });
                            return updatedPrevAssetTagFiles;
                        });

                        assets.push({
                            "name": `temp/${tenantUuid}/${uuid}/${file.name}`
                        });
                    }
                }));
            }

            if (assets.length > 0) {
                await AssetService.deleteProxyS3TempAssets(assets, tenantUuid);
            }
        }

        if(tempFiles.length > 0) {
            setFilesData({ files: newFilesData.files, total: newFilesData.total });
            setIsLoading(false);
            setTempFilesData(tempFiles);
            setUploadError(true);
            setOffcanvasState(true);
            setUploadBtn(false);
        } else {
            setTempFilesData([]);
            setUploadBtn(true);
            setIsLoading(false);
            setUploadError(false);
        }
        setSelectAllChecked(false);

        if(currentFilesData.length > 0) {
            await processProxyStateFiles(currentFilesData)
            .then(async () => {
            })
            .catch((err) => {
                const updateFileData = newFilesData.files
                .map(file => {
                    file.state = '';
                    file.timestamp = file.timestamp ? file.timestamp : new Date().getTime();
                    return file;
                });

                setFilesData({ files: updateFileData, total: updateFileData.length });
                console.error('Error processProxyStateFiles:', err);
            });
        };
    };

    // Upload S3 Assets
    const apiUploadFile = async () => {
        const stateFiles = filesData.files;
        const mediaManager = MediaManager.getSingleton();
        setDisableUploadBtn(true);
        setOffcanvasState(false);
        setProgressState(true);
        setActiveSetting("");
        setUploadBtn(false);
        setSelectAllChecked(false);
        setCheckedFiles([]);
        registerMediaManagerEvents(mediaManager);
        stateFiles.forEach(file => file.state = 'UPLOADING');
        if(!isDesktop){
            updateActiveSetting('');
        }
        const options = {
            files: stateFiles.map(file => file.name)
        };

        // Access Upload Apis Call
        await processStateFiles(stateFiles)
            .then(async () => {
                const newFilesData = {
                    files: stateFiles,
                    total: stateFiles.length
                };
                setFilesData(newFilesData);
                setUploadSuccess(true)

                setTimeout(async () => {
                    await Promise.all([
                      mediaManager.scanProcessingRecords()
                    ]);
                }, 10);

                // Add upload asset/s activity to audit trail
                const auditBody = createAuditBody(tenantUuid, userUuid, "asset", "upload", options);
                await auditTrailService.addActivity(auditBody, tenantUuid);
            })
            .catch((err) => {
                console.error('Error processing files:', `${err}: ${stateFiles}`);
            });
    }

    // Loop through files & Api call with batching
    async function processStateFiles(stateFiles) {
        const BATCH_SIZE = 9;  // Define your batch size here

        for (let i = 0; i < stateFiles.length; i += BATCH_SIZE) {
            const batch = stateFiles.slice(i, i + BATCH_SIZE);

            // Upload the batch files concurrently
            await Promise.all(
                batch.map(async (file) => {
                    if (file.collection && file.collection.length > 0) {
                        file.collection.forEach((c) => {
                            setStoreItem(c, [], createStore('collections', 'asset-collections'));
                        });
                    }
                    await AssetService.uploadS3Assets(file.name, file, updateUUID, {}, tenantUuid);
                })
            );
        }
    }

    // Handler Progress
    const handleProgress = async () => {
        setDisableUploadBtn(false);
        setOffcanvasState(false);
        updateMenuTabKey(path.EXPLORE);
        setProgressState(false);
        setPercentage(0);
        setSuccessPercentage(0);
        setErrorPercentage(0);
        setFilesData([]);
        setFilesArr([]);
        setTempFilesData([]);
    };

    // registerMediaManagerEvents
    const registerMediaManagerEvents = async (mediaManager) => {
        mediaManager.eventSource.addEventListener(MediaManager.Event.Media.Updated, async (event) => {
            if(event.detail && event.detail.$data.attributes.group === tenantUuid) {
                if(event.detail && event.detail.$data.status === SolutionManifest.Statuses.IngestStarted) {
                    const updateStatus = filesData.files;
                    await updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = SolutionManifest.Statuses.Uploading : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    setFilesData(newFilesData);
                }
                else if(event.detail && event.detail.$data.status === SolutionManifest.Statuses.IngestCompleted ||
                    event.detail.$data.status === SolutionManifest.Statuses.AnalysisStarted ||
                    event.detail.$data.status === SolutionManifest.Statuses.AnalysisCompleted) {
                    const updateStatus = filesData.files;
                    await updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = SolutionManifest.Statuses.Completed : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    const errorFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Error);
                    const completedFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Completed);
                    setFilesData(newFilesData);

                    if(errorFile.length > 0) {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;
                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setErrorPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                    } else {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage));
                    }
                }
            };
        });
        mediaManager.eventSource.addEventListener(MediaManager.Event.Media.Error, async (event) => {
            if(event.detail && event.detail.$data.attributes.group === tenantUuid) {
                if(event.detail && event.detail.$data.overallStatus === SolutionManifest.Statuses.Error) {
                    const updateStatus = filesData.files;
                    updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = event.detail.$data.overallStatus : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    const errorFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Error);
                    const completedFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Completed);
                    setFilesData(newFilesData);

                    if(completedFile.length > 0) {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;

                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setErrorPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                    } else {
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;
                        setErrorPercentage(Math.trunc(individualErrorPercentage));
                        setPercentage(Math.trunc(individualErrorPercentage));
                    }
                }
            };
        });
    };

    const mergeTagArrays = (arr) => {
        const merged = [].concat(...arr);
        const duplicates = new Set(arr[0]);

        for (let i = 1; i < arr.length; i++) {
            const subarray = arr[i];
            const subarraySet = new Set(subarray);

            for (const string of duplicates) {
                if (!subarraySet.has(string)) {
                    duplicates.delete(string);
                }
            }
        }

        if (Array.from(duplicates).length > 0) {
            const uniqueDuplicates = merged.filter(item => Array.from(duplicates).indexOf(item) === -1);
            const uniqueMerged = [...new Set(uniqueDuplicates)];

            setTagAsset(Array.from(duplicates));
            setTagMultipleSelection(uniqueMerged);
            return duplicates && uniqueMerged;
        } else {
            const uniqueMerged = [...new Set(merged)];
            setTagMultipleSelection(uniqueMerged);
            return merged;
        }
    };

    const getFileLabels = async (file) => {
        let assets = [];
        const uuid = ServiceUtil.uuid4();
        await AssetService.uploadProxyS3TempAssets(file.name, file, tenantUuid, uuid);

        const labels = await handleAnalysis(SolutionManifest.Proxy.Bucket, `temp/${tenantUuid}/${uuid}/${file.name}`, getFileType(file.name));

        assets.push({
            "name": `temp/${tenantUuid}/${uuid}/${file.name}`
                })
        if(assets.length > 0) {
            await AssetService.deleteProxyS3TempAssets(assets, tenantUuid);
        }

        return labels;
    };

    let cancelUpload = false; // Cancellation flag

    // Function to set the cancellation flag
    const cancelUploadProcess = () => {
        cancelUpload = true;
    };

    // Upload Image Files
    const uploadImageFileData = async (files) => {
        const allowedImageExtensions = SolutionManifest.allowedImageExtensions;
        setImageSearchUrl("");

        if (files && files.length > 0) {
            const file = files[0];
            const fileSize = file.size / 1024 / 1024; // in MiB

            if (allowedImageExtensions.exec(file.name) === null || fileSize >= SolutionManifest.UploadLimit.Size) {
                return {
                    message: Localization.Upload.ErrorImageUpload
                };
            }

            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = async function(e) {
                    let base64 = reader.result;

                    // Handle TIFF file conversion if it's a TIFF file
                    if (file.type === 'image/tiff') {
                        try {
                            const buffer = e.target.result;
                            const ifds = UTIF.decode(buffer);

                            if (ifds.length > 0) {
                                UTIF.decodeImage(buffer, ifds[0]);
                                const rgba = UTIF.toRGBA8(ifds[0]);

                                // Create a canvas to draw the TIFF
                                const canvas = document.createElement('canvas');
                                canvas.width = ifds[0].width;
                                canvas.height = ifds[0].height;

                                const ctx = canvas.getContext('2d');
                                const imgData = ctx.createImageData(canvas.width, canvas.height);
                                imgData.data.set(rgba);
                                ctx.putImageData(imgData, 0, 0);

                                // Convert canvas to Base64
                                base64 = canvas.toDataURL();  // Replace with the TIFF-converted Base64 string
                            }
                        } catch (error) {
                            console.error("Error converting TIFF to Base64:", error);
                            reject({
                                message: "Error processing TIFF file."
                            });
                        }
                    }

                    // Resolve the promise with the Base64 string
                    resolve({
                        message: "",
                        base64: base64
                    });
                };

                reader.onerror = function(error) {
                    console.error("Error converting to Base64:", error);
                    reject({
                        message: "Error reading file."
                    });
                };

                // Use different reading methods depending on the file type
                if (file.type === 'image/tiff') {
                    reader.readAsArrayBuffer(file);  // For TIFF files, read as ArrayBuffer
                } else {
                    reader.readAsDataURL(file);  // For other image files, read as DataURL (original process)
                }
            });
        }

        return {
            message: Localization.Upload.ErrorImageUpload
        };
    };


    cancelUpload = false;

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [filesData]);


    return (
        <UploadContext.Provider value={{
            mergeTagArrays,
            tagAsset,
            setTagAsset,
            tagMultipleSelection,
            setTagMultipleSelection,
            collectionDescRef,
            collectionSameRef,
            activeSettingView,
            updateActiveSettingView,
            collection,
            collectionKey,
            collectionAsset,
            collectionMultipleSelection,
            updateCollection,
            updateCollectionKey,
            updateCollectionAsset,
            updateCollectionMultipleSelection,
            updateFileCollection,
            updateDisableUploadBtn,
            updateUploadBtn,
            updateFileData,
            updateOffcanvasState,
            updateActiveSetting,
            updateStickySetting,
            updateStickyCanvas,
            updateUploadError,
            updateSelectAllChecked,
            updateCheckedFiles,
            activeSetting,
            handleProgress,
            apiUploadFile,
            stickySetting,
            selectAllChecked,
            checkedFiles,
            uploadBtn,
            disableUploadBtn,
            stickyCanvas,
            uploadFilesData,
            uploadImageFileData,
            cancelUploadProcess,
            getFileLabels,
            videoRef,
            audioRef,
            imageRef,
            pdfRef,
            stickyRef,
            settingRef,
            processRef,
            tempfilesData,
            filesData,
            uploadError,
            percentage,
            successPercentage,
            errorPercentage,
            offcanvasState,
            progressState,
            setProgressState,
            isUploadSuccess
        }}>
            {children}
        </UploadContext.Provider>
    );
};