import { useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../components/shared/context/session-provider";
import { IsTablet } from "../../components/shared/utils";
import Button from 'react-bootstrap/Button';
import path from "../../components/shared/routePaths";
import RegistrationService from '../../services/api/registrationService';
import SpinnerIcon from "../../components/icons/spinner";
import Localization from "../../components/shared/localization";
import ErrorIcon from "../../components/icons/error";
import AlertNotification from "../../components/shared/components/alert-notification";
import CustomIcons from '../../components/shared/components/custom-icons';

const UserVerifyEmail = () => {
  const isTablet = IsTablet();
  // Session Context
  const { tenantUuid, subdomainName } = useContext(SessionContext);

  // User Uuid
  const [userUuid, setUserUuid] = useState(undefined);

  // Email
  const [emailAddress, setEmailAddress] = useState(undefined);
  const [emailVerified, setEmailVerified] = useState(false);

  // Email Verified Code
  const [emailVerifyCode, setEmailVerifyCode] = useState(undefined);
  const [emailVerifyError, setEmailVerifyError] = useState(undefined);
  const [isVerifyCode, setIsVerifyCode] = useState(false);
  const [resendVerifyCode, setResendVerifyCode] = useState({
    status: null,
    loading: false
  });

  // Alert Notification
  const [alertNotification, setAlertNotification] = useState({
    show: false,
    variant: "success",
    message: Localization.UserVerifyEmail.SuccessResendCode,
  });

  const [resendVerifyCodeCountdown, setResendVerifyCodeCountdown] = useState(30);

  // Manual Verify Code
  const [manualVerifyState, setManualVerifyState] = useState(false);

  // Navigate
  const navigate = useNavigate();
  const pathPrefix = "/";
  const queryString = window.location.search;

  // Error Message
  const errorMsg = {
    ErrorVerifyEmail: Localization.UserVerifyEmail.ErrorVerifyEmail,
    ErrorEmailCode: Localization.UserVerifyEmail.ErrorEmailCode,
    ErrorInvalidSubscriptionPlan: Localization.UserVerifyEmail.ErrorInvalidSubscriptionPlan,
  };

  const getQueryStringParameter = (name) => {
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name);
  };

  const handleVerifyCodeChange = async (event) => {
    setEmailVerifyCode(event.target.value.replace(/\D/g, ''));
    setEmailVerifyError(undefined);

    if(event.target.value.length >= 6) {
      setIsVerifyCode(true);

      try {
        const verifyEmailRes = await RegistrationService.onPOSTUserVerifyEmail({
          uuid: userUuid,
          code: event.target.value,
          subdomain_uuid: tenantUuid
        });

        if(verifyEmailRes.code === errorMsg.ErrorVerifyEmail.name ||
            verifyEmailRes.code === errorMsg.ErrorEmailCode.name ||
            verifyEmailRes.code === errorMsg.ErrorInvalidSubscriptionPlan.name) {
          setAlertNotification({
            show: false
          });
          switch (verifyEmailRes.code) {
            case errorMsg.ErrorVerifyEmail.name:
              setEmailVerifyError(errorMsg.ErrorVerifyEmail.error);
              break;
            case errorMsg.ErrorEmailCode.name:
              setEmailVerifyError(errorMsg.ErrorEmailCode.error);
              break;
            case errorMsg.ErrorInvalidSubscriptionPlan.name:
              setEmailVerifyError(errorMsg.ErrorInvalidSubscriptionPlan.error);
              break;
            default:
              setEmailVerifyError('An unknown error occurred.');
              break;
          };

          setIsVerifyCode(false);
        };

        if(verifyEmailRes.code === 200) {
          setEmailVerified(true);
          setManualVerifyState(false);
        }
      } catch(error) {
        // Handle error if verification fails
        console.error("Email verification failed:", error);
        navigate(pathPrefix + path.LOGIN);
      }
    }
  };

  useEffect(() => {
    let timer;

    if (resendVerifyCode.status && resendVerifyCodeCountdown > 0) {
      timer = setTimeout(() => {
        setResendVerifyCodeCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (resendVerifyCodeCountdown === 0) {
      setResendVerifyCode({
        status: null,
        loading: false
      });
      setResendVerifyCodeCountdown(30);
    }

    return () => clearTimeout(timer);
  }, [resendVerifyCode, resendVerifyCodeCountdown]);

  useEffect(() => {
    const userVerifyEmailFunc = async () => {
        const uuidParam = getQueryStringParameter('uuid');
        const codeParam = getQueryStringParameter('code');

        try {
            const verifyEmailRes = await RegistrationService.onGETUserVerifyEmail({
                uuid: uuidParam,
                code: codeParam,
                subdomain_uuid: tenantUuid
            });

            if (verifyEmailRes.code === 200) {
              setEmailVerified(true);
              setEmailAddress(verifyEmailRes.response.email);
            } else {
              if(localStorage.getItem('verifyEmailAddress') && localStorage.getItem('verifyUserUuid')) {
                setManualVerifyState(true);
                setEmailAddress(localStorage.getItem('verifyEmailAddress'));
                setUserUuid(localStorage.getItem('verifyUserUuid'));
              } else {
                setManualVerifyState(false);
                navigate(pathPrefix + path.LOGIN);
              }
            };
        } catch (error) {
            // Handle error if verification fails
            console.error("Email verification failed:", error);
            navigate(pathPrefix + path.LOGIN);
        };
    };

    userVerifyEmailFunc();
  }, []);

  const handleResendEmailCode = async () => {
    try {
      setResendVerifyCode({
        status: null,
        loading: true
      });

      const resendEmailRes = await RegistrationService.resendUserEmailCode({
          uuid: localStorage.getItem("verifyUserUuid"),
          subdomain_name: subdomainName,
          tenant_uuid: tenantUuid
      });

      if (resendEmailRes.statusCode === 200) {
        setResendVerifyCode({
          status: true,
          loading: false
        });
        setAlertNotification({
          show: true,
          variant: "success",
          message: Localization.UserVerifyEmail.SuccessResendCode,
        });
      } else {
        setResendVerifyCode({
          status: false,
          loading: false
        });
        setAlertNotification({
          show: false
        });
      };
    } catch (error) {
      // Handle error if resend email code fails
      console.error("Resend Email Code failed:", error);
    };
  };

  return (
    <>
    {
      emailVerified &&
        <div className="registration-wrapper">
          <div className={isTablet ? "registration-left-cont col-12" : "registration-left-cont col-6"}>
            <div className="registration-form">
              <a href="/" className="registration-header d-flex flex-row">
                <img
                  src="/images/elko-logo.svg"
                  className="dam-logo-img"
                  alt="Dam Logo"
                />
              </a>
              <div className="general-container">
              <div className="content-wrapper">
                <h1 className="pb-5">Your application is under review</h1>
                <p className="paragraph-1">
                  Thank you for verifying your email address <span className="font-bold">({emailAddress})</span>. An acknowledgment email will be sent to you shortly. Make sure to check your spam or junk folder as well.
                </p>
                <p className="paragraph-1 mb-5">
                We will notify you once your access is confirmed. Reach out to your organisation’s administrator(s) if you need any updates on your application status
                </p>
                <div className="button-group">
                  <Button variant="primary"  href={pathPrefix + path.LOGIN}>
                    Done
                  </Button>
                </div>
                </div>
              </div>
            </div>
          </div>
          {
            !isTablet &&
              <div className="registration-right-cont col-6">
                <div className="content-container">
                  <div className="content-wrapper d-flex flex-column gap-5 p-0">
                  <img src="/images/under-review.png" alt="under-review"/>
                  <div className="text-center">

                      <h2>
                      Search by image
                      </h2>
                      <h3>
                      Upload an image and discover similar assets effortlessly
                      </h3>
                      </div>
                  </div>
                </div>
                {/* <svg width="1094" height="661" viewBox="0 0 1094 661" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-33.9844" y="-261.523" width="1407.35" height="714.133" transform="rotate(8.86 -33.9844 -261.523)" fill="white" />
                </svg>
                <img src="/images/Ring.svg" className="ring-top-icon" alt="ringicon" /> */}
              </div>
          }
        </div>
    }
    {
      manualVerifyState &&
        <div className="registration-wrapper">
          <div className={isTablet ? "registration-left-cont col-12" : "registration-left-cont col-6"}>
            <div className="registration-form">
              <a href="/" className="registration-header d-flex flex-row">
                <img
                  src="/images/elko-logo.svg"
                  className="dam-logo-img"
                  alt="Dam Logo"
                />
              </a>
              <div className="general-container">
              <div className="content-wrapper">
             
                {
                  alertNotification.show &&
                  <div className="message-container mb-0">
                    <AlertNotification variant={alertNotification.variant} content={alertNotification.message} />
                    </div>
                }
             
            
                <h1 className="pb-5 verify-email">Verify your email</h1>
            
                <p className="paragraph-1">
                  We have sent a 6-digit verification code to <span className="font-bold">{emailAddress}</span>.
                </p>
                <p className="paragraph-1 mb-5">
                Check your inbox and enter the code below within 1 hour. Don’t forget to look in your spam or junk folder.
                </p>

                <div className="form-container pb-5">
                  <div className={emailVerifyError ? "form-group form-error" : "form-group"}>
                    <label htmlFor="verify-code">Verification code</label>
                    <input
                      id="verify-code"
                      className="form-control"
                      type="text"
                      pattern="\d*"
                      maxLength="6"
                      placeholder="Enter verification code"
                      onChange={(event) => handleVerifyCodeChange(event)}
                      value={emailVerifyCode}
                      disabled={isVerifyCode}
                    ></input>
                    { isVerifyCode && <SpinnerIcon className="verify-code-spinner"/> }
                    { emailVerifyError &&
                        <div className="error-msg">
                          <ErrorIcon />{emailVerifyError}
                        </div> }
                  </div>
                </div>

                <p className="paragraph-1 registration-reset-email">Didn't get the email? <button disabled={resendVerifyCode.loading || resendVerifyCode.status} onClick={handleResendEmailCode} className="text-button font-bold">Resend code { resendVerifyCode.loading && <CustomIcons variant="spinner" /> }</button> { resendVerifyCode.status && `in 00:${String(resendVerifyCodeCountdown).padStart(2, '0')}`}</p>

                <p className="paragraph-1">Have issues verifying? Contact <a target="_blank" rel="noreferrer" href="https://hello.elko.digital/contact" className="font-bold">elko support</a></p>
              </div>
            </div>
            </div>
          </div>
          {
            !isTablet &&
              <div className="registration-right-cont col-6">
                   <div className="content-container">
                  <div className="content-wrapper d-flex flex-column gap-5">
                  <img src="/images/verify.png" alt="verify-image"/>
                  <div className="text-center">

                      <h2>
                      Harness the power of AI technology
                      </h2>
                      <h3>
                      Our AI technology will suggest relevant keywords thereby allowing for seamless organisation and retrieval of your assets
                      </h3>
                      </div>
                  </div>
                </div>
                {/* <svg width="1094" height="661" viewBox="0 0 1094 661" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-33.9844" y="-261.523" width="1407.35" height="714.133" transform="rotate(8.86 -33.9844 -261.523)" fill="white" />
                </svg> */}
                {/* <img src="/images/Ring.svg" className="ring-top-icon" alt="ringicon" /> */}
              </div>
          }
        </div>
    }
    </>
  );
};

export default UserVerifyEmail;