import { useState, useContext, useEffect, useRef } from 'react';
import { ShareContext } from '../../shared/context/share-state';
import { GlobalContext } from "../../shared/context/global-provider";
import { SessionContext } from '../../shared/context/session-provider';
import { getStoreItem, setStoreItem, createStore, removeStoreItem} from '../../../store';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../shared/context/api-state';
import Spinner from 'react-bootstrap/Spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import Localization from '../../shared/localization';
import AssetService from '../../../services/api/assetsService';
import CustomIcons from '../../shared/components/custom-icons';
import GenericModal from "../../shared/components/generic-modal";
import CollectionHooks from '../../shared/hooks/collection-hooks';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import path from '../../shared/routePaths';
import { useMetadata } from '../../shared/context/meta-provider';

const CollectionEdit = (props) => {
    const [newCollectionTitle, setNewCollectionTitle] = useState(props.collectionTitle);
    const [charCount, setCharCount] = useState(0);
    const [modalType, SetModalType] = useState("");
    const [modalShow, SetModalShow] = useState(false);
    const [modalLoading, SetModalLoading] = useState(false);
    const [collectionSaving, setCollectionSaving] = useState(false);
    const [editCollectionError, setEditCollectionError] = useState("");
    const [collectionDeleting, setCollectionDeleting] = useState(false);
    const [collectionTitleOnfocus, setCollectionTitleOnfocus] = useState(false);
    const textareaRef = useRef(null);
    const prevWindowWidth = useRef(window.innerWidth);

    // Refresh MetaData
    const { setCollections } = useMetadata();

    //Url
    const pathPrefix = '/';
    const navigate = useNavigate();

    const { getAssetCollections, getAllCollections } = useContext(ApiContext);
    const { updateMenuTabKey, allCollectionItems } = useContext(ShareContext);
    const { updateToastMessage } = useContext(GlobalContext);
    const { updateCollectionSearch } = CollectionHooks();
    const { tenantUuid, userUuid } = useContext(SessionContext);

    const charCounter = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 50) {
            setCharCount(newValue.length);
            setNewCollectionTitle(newValue);
        } else {
            setCharCount(50);
            setNewCollectionTitle(newValue.slice(0, 50));
        }
    }

    const handleKeyDown = (event) => {
        if (charCount >= 50 && event.key !== 'Backspace') {
          event.preventDefault();
        }
    };

    const handleTextAreaResize = (event) => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        if (event.keyCode === 13) { // 13 is the keycode for Enter key
            textarea.style.height = 'auto'; // Allow textarea to adjust height based on content
            return;
        }

        if (event.keyCode === 8) { // 8 is the keycode for backspace
            textarea.style.height = '0'; // Temporarily set height to 0 to allow natural expansion
            textarea.style.height = textarea.scrollHeight + 'px'; // Set height based on content
            return;
        }

        const newHeight = Math.max(textarea.scrollHeight, 56);
        textarea.style.height = newHeight + 'px';
    }

    const handleWindowResize = () => {
        const textarea = textareaRef.current;
        const currentWindowWidth = window.innerWidth;

        if (currentWindowWidth > prevWindowWidth.current) {
            // Window is increasing in size
            textarea.style.height = '0';
            textarea.style.height = textarea.scrollHeight + 'px';

        }  else if (currentWindowWidth < prevWindowWidth.current) {
            // Window is decreasing in size
            const newHeight = Math.max(textarea.scrollHeight, 56);
            textarea.style.height = newHeight + 'px';
        }

      prevWindowWidth.current = currentWindowWidth;
    }

    const handleModalClose = () => {
        SetModalShow(false);
    };

    const isAllCollections = async () => {
        await getStoreItem('all-collections', createStore('get-collections', 'all-collections'));
    }

    const isAssetCollections = async () => {
        await getStoreItem(props.collectionUuid, createStore('collections', 'asset-collections'));
    }

    const handleModalDelete = async () => {
       setCollectionDeleting(true);

        const bodyCollection = {
            "input" :
                {
                    "uuid": props.collectionUuid,
                    "tenantUuid": tenantUuid,
                    "name": newCollectionTitle,
                    "userUuid": userUuid
                }
        }

        try {
            //check if asset- and all-collections are NOT undefined
                if(!(await isAllCollections()) && !(await isAssetCollections())) {
                    await getAssetCollections();
                    await getAllCollections();
                } else if (!(await isAllCollections()) && (await isAssetCollections())) {
                    await getAllCollections();
                } else if ((await isAllCollections()) && !(await isAssetCollections())) {
                    await getAssetCollections();
                }

            //Call delete collection api
                const response = await AssetService.deleteCollection(bodyCollection, {}, tenantUuid);
                if (!response.input) {
                    updateToastMessage(`Not deleted`, 'error');
                }

            //When call is successful, delete the images in the asset-asset
                for(const item of allCollectionItems) {
                    await getStoreItem(item.uuid)
                        .then(res => {
                            removeStoreItem(item.uuid);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }

            //Now Delete collection in asset-collections
                removeStoreItem(props.collectionUuid, createStore('collections', 'asset-collections'));
                setCollectionDeleting(false);
                updateToastMessage('Collection deleted',"success");
                props.clickCollectionOffcanvas();

            //Now Update collection in all-collections
                const storeItem = await getStoreItem('all-collections', createStore('get-collections', 'all-collections'));
                const newAllCollections = storeItem.filter(collection => collection.uuid !== props.collectionUuid);
                await setStoreItem('all-collections', newAllCollections, createStore('get-collections', 'all-collections'));

            //Navigate back to collections page
                const route = `${pathPrefix + path.COLLECTIONS}`;
                navigate(route,{ replace: true });
                updateMenuTabKey(path.COLLECTIONS); //re-loads the collections
                setCollections(prev => prev - 1);

        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteCollection = () => {
        SetModalShow(true);
        SetModalLoading(true);
        SetModalType("deleteCollection");
    };

    const handleEditCollection = async () => {
        if(newCollectionTitle === props.collectionTitle) {
            return;
        }
        setCollectionSaving(true);

        const bodyCollection = {
            "tenantUuid": tenantUuid,
            "name": newCollectionTitle,
            "uuid": props.collectionUuid,
            "old_name": props.collectionTitle,
            "userUuid": userUuid
        }

        try {
            //check if asset- and all-collections are NOT undefined
                if(!(await isAllCollections()) && !(await isAssetCollections())) {
                    await getAssetCollections();
                    await getAllCollections();
                } else if (!(await isAllCollections()) && (await isAssetCollections())) {
                    await getAllCollections();
                } else if ((await isAllCollections()) && !(await isAssetCollections())) {
                    await getAssetCollections();
                }

            const response = await AssetService.editCollection(bodyCollection, {}, tenantUuid);
            if(response.statusCode === 200) {
                const isAssetUuids = [];

                // Change name in asset-collections
                    const storeCollectionTitle = await getStoreItem(props.collectionUuid, createStore('collections', 'asset-collections'));
                    storeCollectionTitle.name = newCollectionTitle;
                    await setStoreItem(props.collectionUuid, storeCollectionTitle, createStore('collections', 'asset-collections'));

                // Change collection name in all-collections
                    const storeItem = await getStoreItem('all-collections', createStore('get-collections', 'all-collections'));
                    const updatedStoreItem = storeItem.map(collection => {
                        if (collection.uuid === props.collectionUuid) {
                            // Extract asset uuids
                            collection.assets?.forEach(asset => {
                                isAssetUuids.push(asset.uuid);
                            });
                            return { ...collection, name: newCollectionTitle };
                        } else {
                            return collection;
                        }
                    });

                    await setStoreItem('all-collections', updatedStoreItem, createStore('get-collections', 'all-collections'));

                // Change collection name in asset-asset
                    let isAsset;
                    for (let i=0; i < isAssetUuids.length; i++) {
                        isAsset = await getStoreItem(isAssetUuids[i]);
                        // Iterate through each collection
                        for (const collection of isAsset.collections) {
                            // Check if the collection name matches props.collectionTitle
                            if (collection.name === props.collectionTitle) {
                                // If match found, change the name in asset-asset
                                collection.name = newCollectionTitle;
                            }
                        }
                        await setStoreItem(isAssetUuids[i], isAsset);
                    }

                setCollectionSaving(false);
                setEditCollectionError('');
                updateCollectionSearch(newCollectionTitle, props.collectionUuid);
                props.handleContainerSwitch();

            } else {
                updateToastMessage(`Not saved`, 'error');
            }

        } catch (error) {
            console.error(error);
            setEditCollectionError('duplicated');
            setCollectionSaving(false);
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        const newHeight = Math.max(textarea.scrollHeight);
        textarea.style.height = newHeight + 'px';

        // Event listener for when textarea input changes
        textarea.addEventListener('input', handleTextAreaResize);
        textarea.addEventListener('keydown', handleTextAreaResize);

        // Event listener for when window size changes
        window.addEventListener('resize', handleWindowResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            textarea.removeEventListener('input', handleTextAreaResize);
            textarea.removeEventListener('keydown', handleTextAreaResize);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            <GenericModal
                    modalType={modalType}
                    modalShow={modalShow}
                    modalLoading={modalLoading}
                    handleModalClose={handleModalClose}
                    handleModalDelete={handleModalDelete}
                    collectionDeleting={collectionDeleting}
                    collectionTitle = {props.collectionTitle}
            />

            <div className='collection-edit-wrapper'>
                <div className="collection-offcanvas-header web">
                    <div>
                        <h3 className='mb-0'>Edit collection</h3>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={props.clickCollectionOffcanvas}><CancelIcon /></button>
                        </div>
                    </div>
                </div>
                <div className="collection-offcanvas-header mobile">
                    <div>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={props.clickCollectionOffcanvas}><ArrowBackRoundedIcon/></button>
                        </div>
                        <h3 className='mb-0'>Edit collection</h3>
                    </div>
                </div>

                <div className="form-container">
                    <div className="collection form-group">
                        <div className="input-title-container">
                            <label htmlFor="CollectionTitle" className={editCollectionError === 'duplicated' ? 'red-font' : ''}>Collection</label>
                            <textarea
                                type='text'
                                ref={textareaRef}
                                id="collectionTitle"
                                onChange={charCounter}
                                onKeyDown={handleKeyDown}
                                value={newCollectionTitle}
                                placeholder="Enter collection name"
                                className={editCollectionError === 'duplicated' ? 'form-control red-border' : 'form-control'}
                                onFocus={(e) => {
                                    setCharCount(e.target.value.length);
                                    setCollectionTitleOnfocus(true);
                                }}
                                onBlur={(e) => {
                                    setCollectionTitleOnfocus(false);
                                }}
                            ></textarea>
                            <div className="input-counter-container">
                            {editCollectionError === 'duplicated' &&
                            <label className={`error-label`}>
                                <CustomIcons variant="error"/>
                                {Localization.Alerts.NewCollectionNameDuplication}
                            </label>
                            }
                            {collectionTitleOnfocus &&
                                <>
                                {editCollectionError !== 'duplicated' && <label className={`limit-label ${charCount >= 50 ? '' : 'hidden'}`}>Character limit reached</label>}
                                <label className={`counter-label ${collectionTitleOnfocus? '' : 'hidden'}`}>{charCount}/50</label>
                                </>
                            }
                            </div>
                        </div>
                        <div className='btn-wrapper'>
                            <div className="save-btn button-group">
                                <button
                                    type="submit"
                                    disabled={charCount === 0 || collectionSaving}
                                    className={`btn btn-primary`}
                                    onClick={handleEditCollection}
                                >
                                    Save {collectionSaving && <Spinner variant='gray' />}
                                </button>
                            </div>
                            <div className="delete-btn button-group">
                                <button className="btn btn-primary delete-collection-btn" type="submit" onClick={handleDeleteCollection}>Delete collection</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionEdit;