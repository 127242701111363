import { useDropzone } from "react-dropzone";
import { ShareContext } from "../../shared/context/share-state";
import { IsDesktop, black, convertImageUrl, checkProtocol, isImageUrl } from "../../shared/utils";
import { TextField } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { UploadContext } from "../../shared/context/upload-provider";
import CloseIcon from "../../icons/close";
import Localization from "../../shared/localization";
import DOMPurify from "dompurify";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import LinkIcon from "../../icons/link";
import AlertNotification from "../../shared/components/alert-notification";
import path from "../../shared/routePaths";
import SearchHooks from "../../shared/hooks/search-hooks";
import CropRoundedIcon from "../../icons/crop";
import TrashIcon from "../../icons/trash";
import ImageCropper from "../../shared/components/image-cropper";

const ImageSearchUpload = (props) => {
  // Share Context
  const { updateMenuTabKey,
    setIsImageSearch,
    setImageSearchUrl,
    setImageSearchLabels,
    menuTabKey,
    setResetState,
    setImageBase64,
    imgSrc,
    setImgSrc } = useContext(ShareContext);

  // UploadContext
  const { uploadImageFileData, getFileLabels, cancelUploadProcess } = useContext(UploadContext);

  const [messageInfo, setMessageInfo] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isImageSearchFocus, setImageSearchFocus] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const imageCropperRef = useRef(null);
  const isDesktop = IsDesktop();

  const handleCloseClick = () => {
    props.setIsImageSearchModalOpen(false);
    cancelUploadProcess();
  };

  const { setShowSearch, setSearchSubmitted, setSearchKeyword } = SearchHooks();

  const handleSearchImageUrlClick = async () => {
    setIsUploading(true);
    setMessageInfo("");

    try {
      // Check if the URL is valid with either HTTP or HTTPS protocol
      let validImageUrl = imageUrl;
      const isValid = await isImageUrl(validImageUrl);

      if(!isValid) {
        if (!validImageUrl.startsWith('http://') && !validImageUrl.startsWith('https://')) {
          // Check if the URL starts with http:// or https://\
          // If not, use checkProtocol to determine the correct protocol
          const protocolToUse = await checkProtocol(validImageUrl);
          if (!protocolToUse) {
            throw new Error('Neither HTTP nor HTTPS protocol worked for the URL.');
          }
          validImageUrl = `${protocolToUse}://${validImageUrl}`;
        }
      }

      const result = await convertImageUrl(validImageUrl);
      if (!result.isImage) {
        throw new Error('The URL does not point to a valid image.');
      }

      let file = result.file; // Use the converted JPG file if available
      if (!file) {
        // Fetch the original image as a blob if no converted file is available
        const response = await fetch(validImageUrl, { mode: 'no-cors' });
        const blob = await response.blob();
        if (blob.size === 0) {
          throw new Error('Blob is empty');
        } else {
          file = new File([blob], "image.jpg", { type: "image/jpeg" });
          setImageSearchUrl(validImageUrl);
          setIsUploading(false);
          setShowSearch(true);
          setSearchSubmitted(true);
          setImgSrc(validImageUrl);
        }
      } else if(result.isImage) {
        setImageSearchUrl(validImageUrl);
        setIsUploading(false);
        setShowSearch(true);
        setSearchSubmitted(true);
        setImgSrc(validImageUrl);
      }
    } catch (_) {
      setMessageInfo(Localization.Upload.ImageUploadErrorInvalidUrl);
      setIsUploading(false);
    }
  };

  const onDrop = async (files) => {
    setIsUploading(true);
    setMessageInfo("");
    const response = await uploadImageFileData(files);
    setMessageInfo(response.message);

    if (!response.message) {
      setIsUploading(false);
      setShowSearch(true);
      setSearchSubmitted(true);
      if (typeof response.base64 === 'string') {
        // Call setImgSrc with the Base64 string
        setImageBase64(response.base64.split(',')[1]);
        setImgSrc(response.base64);
      }
    } else {
      setIsUploading(false);
    }
  };

  const handleSearchClick = async () => {
    setIsImageSearch(true);
    if(menuTabKey !== path.SEARCH) {
      updateMenuTabKey(path.SEARCH);
    } else {
      setSearchKeyword("");
      setResetState(true);
    }
  }

  const handleRemoveClick = async () => {
    setIsCropping(false);
    setImgSrc("");
    setImageUrl("");
  }

  const handleImageCropClick = async () => {
    setIsCropping(true);
  }

  const handleCropCancelClick = async () => {
    setIsCropping(false);
  }

  const handleCropSaveClick = async () => {
    await imageCropperRef.current.makeClientCrop(); // Trigger the crop complete logic
    setIsCropping(false);
  };

  const handleCroppedImage = (url, base64) => {
    setImgSrc(url);
    setImageBase64(base64.split(',')[1]);
    setIsCropping(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/bmp': [],
      'image/tiff': []
    }});

  const contentToRender = isDesktop ? Localization.Upload.Dropzone : Localization.Upload.MobileDropzone;

  const sanitizedContent = DOMPurify.sanitize(contentToRender);

  const contentToRenderImageDropzoneInfo2 = isDesktop ? Localization.Upload.ImageDropzoneInfo2 : Localization.Upload.MobileImageDropzoneInfo2;

  const sanitizedContentImageDropzoneInfo2 = DOMPurify.sanitize(contentToRenderImageDropzoneInfo2);

  return (
    <div className="image-search-upload-section">
      <svg className='pointer' width="100" height="100" viewBox="0 70 200 100">
        <path
          d="M100,80
                Q65,150
                10,150
                L10,160
                L190,160
                L190,150
                Q135,150
                100,80"
          fill="white"
          stroke="none"
        />
        <path
          d="M100,80
                Q65,150
                10,150"
          fill="none"
          stroke="black"
          strokeWidth="4"
        />
        <path
          d="M100,80
                Q135,150
                190,150"
          fill="none"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
      <div className="image-search-upload-title-container">
        <h3>Image analysis</h3>
        <button
          className="btn-close"
          type="button"
          id="button-close"
          onMouseDown={handleCloseClick}
          title="Clear"
        >
          <CloseIcon color={black} />
        </button>
      </div>
      {isUploading ? (
        <div className="image-search-upload-loader-container">
          <div className="loader"></div>
          <div>Uploading...</div>
        </div>
      ) : (
        <>
          {messageInfo && messageInfo.length > 0 && (
            <>
              <div className="image-search-upload-message-container">
                <AlertNotification variant={"error"} content={messageInfo} />
              </div>
            </>
          )}
          <div className="image-search-upload-box-container">
            {imgSrc ? (
              isCropping ? (
                <div className='image-cropper-section'>
                  <ImageCropper src={imgSrc} onCropComplete={handleCroppedImage}
                    ref={imageCropperRef} />
                </div>
              ) : (
                <div className='search-image-container'>
                  <img src={imgSrc} alt="Uploaded File" className="search-image" />
                </div>
              )
            ) : (
              <div
                className={
                  isDragActive
                    ? "upload-dropzone-wrapper upload-dropzone-active"
                    : "upload-dropzone-wrapper"
                }
              >
                <div
                  {...getRootProps({ className: "dropzone" })}
                  className="upload-dropzone-cont"
                >
                  <input
                    className="upload-input"
                    {...getInputProps()}
                    type="file"
                    accept="image/png, image/jpeg, image/bmp, image/tiff"
                    capture="environment"
                  />
                  <div className="upload-dropzone-content">
                    <UploadRoundedIcon />
                    <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                    <div className="upload-dropzone-info paragraph-2">
                      {Localization.Upload.ImageDropzoneInfo}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="image-search-upload-url-link">
            <div className="image-search-upload-url-top">
            {!imgSrc ? (
                <>
                  <div className={`image-search-input ${isImageSearchFocus ? 'black-border' : ''} w-100`}>
                    <TextField
                      id="image-url-input-box"
                      className="image-url-input-box"
                      placeholder="Paste URL link"
                      value={imageUrl}
                      onChange={(e) => setImageUrl(e.target.value)}
                      onFocus={() => setImageSearchFocus(true)}
                      onBlur={() => setImageSearchFocus(false)}
                      InputProps={{
                        startAdornment: <LinkIcon color="#000000" />,
                      }}
                    />
                  </div>
                  {!imgSrc && !isDesktop &&
                    <div className="image-search-upload-url-bottom paragraph-2">
                      {sanitizedContentImageDropzoneInfo2}
                    </div>
                  }
                  <div className="button-group">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleSearchImageUrlClick}
                    >
                      Search
                    </button>
                  </div>
                </>
              )
              :
              isCropping ? (
                <>
                <div className="action-group cancel">
                  <div className="button-group">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={handleCropCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="action-group save">
                  <div className="button-group">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleCropSaveClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
                </>
              ) : (
              <>
                <div className="action-group">
                  <a href="#" onClick={handleImageCropClick} >
                    <CropRoundedIcon color={black} />
                  </a>
                  <a href="#" onClick={handleRemoveClick} >
                    <TrashIcon color={black} />
                  </a>
                </div>
                <div className="button-group">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={handleSearchClick}
                  >
                    Analyse
                  </button>
                </div>
              </>
              )
            }
            </div>
            {!imgSrc && isDesktop &&
              <div className="image-search-upload-url-bottom paragraph-2">
                {sanitizedContentImageDropzoneInfo2}
              </div>
            }
          </div>
        </>
      )}
    </div>
  );
};

export default ImageSearchUpload;