import { black, iconSize } from '../../shared/utils';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const DuplicateRoundedIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <ContentCopyRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default DuplicateRoundedIcon;