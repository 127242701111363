import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Localization from '../../../localization';
import CustomIcons from '../../custom-icons';
import { IsMobile } from '../../../utils';
const DeleteAssetModal = (props) => {
    const isMobile = IsMobile();
    const lastCheckedAssetId = props.checkedMyAssets && props.checkedMyAssets.length > 0 ? props.checkedMyAssets[props.checkedMyAssets.length - 1] : null;
    const singleBulkCheck = lastCheckedAssetId && props.originalData.find(asset => asset.uuid === lastCheckedAssetId);
    return(
        <Modal className={props.className} show={props.modalShow} onHide={props.handleModalClose}>
            
            { props.modalType == "bulkDelete" ?
                <>
                    <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false }>
                        <Modal.Title className='h3'>{Localization.Modal.Delete.Title}</Modal.Title>
                    </Modal.Header>
                    {isMobile ?
                        <Modal.Body>
                            <p>Are you sure you want to delete {props.checkedMyAssets.length !== 1 ? <span>{props.checkedMyAssets.length} assets</span> : <span className='text-break'>the asset <b>{singleBulkCheck.key.split("/").pop()}</b></span>}? Once deleted, {props.checkedMyAssets.length !== 1 ? 'they' : 'it'} will be removed from your workspace and users can no longer view or download {props.checkedMyAssets.length !== 1 ? 'them' : 'it'}. <br/> <br/> This action cannot be undone.</p>
                        </Modal.Body>
                    :
                        <Modal.Body>
                        <p>Are you sure you want to delete the {props.checkedMyAssets.length !== 1 ? `${props.checkedMyAssets.length} assets` : 'asset'} listed below? Once deleted, {props.checkedMyAssets.length !== 1 ? 'they' : 'it'} will be removed from your workspace and users can no longer view or download {props.checkedMyAssets.length !== 1 ? 'them' : 'it'}. This action cannot be undone.</p>
                            
                                    <div className='modal-filename-bulk'>
                                        <ul>
                                        {
                                            props.checkedMyAssets?.map(id => {
                                                const card = props.originalData.find(asset => asset.uuid === id);
                                                return <li key={id}>{card?.key?.split("/").pop()}</li>;
                                            })
                                        
                                        }
                                        </ul>
                                    </div>
                                
                        </Modal.Body>
                      }
                    <Modal.Footer>
                        <Button variant='secondary' onClick={props.handleModalClose}> {Localization.Modal.Delete.CancelButton}</Button>
                        <Button variant='primary' style={{ marginLeft: !isMobile ? '16px' : '0'}} disabled={props.loading} onClick={props.handleModalDelete}>{Localization.Modal.Delete.ConfirmButton} {props.loading && <CustomIcons variant="spinner" />}</Button>
                    </Modal.Footer>
            </>
            :
                    <>
                        <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false }>
                            <Modal.Title className='h3'>{Localization.Modal.Delete.Title}</Modal.Title>
                        </Modal.Header>
                        {isMobile ?
                            <Modal.Body>
                                <p>Are you sure you want to delete the asset <span className='text-break'><b>{props.deleteFilesData}</b></span>? Once deleted, it will be removed from your workspace and users can no longer view or download it. <br/> <br/> This action cannot be undone.</p>
                            </Modal.Body>
                        :
                            <Modal.Body>
                                {Localization.Modal.Delete.Desc1}
                                <div className="modal-filename">{props.deleteFilesData}</div>
                            </Modal.Body>
                        }
                        <Modal.Footer>
                            <Button variant='secondary' onClick={props.handleModalClose}> {Localization.Modal.Delete.CancelButton}</Button>
                            <Button variant='primary' style={{ marginLeft: !isMobile ? '16px' : '0'}} disabled={props.loading} onClick={props.handleModalDelete}>{Localization.Modal.Delete.ConfirmButton} {props.loading && <CustomIcons variant="spinner" />}</Button>
                        </Modal.Footer>
                    </>

            }
        </Modal>
    );
}

export default DeleteAssetModal;