import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { SessionContext } from '../../../shared/context/session-provider';
import { IsMobile } from '../../../shared/utils';
import { ShareContext } from '../../../shared/context/share-state';
import { AdditionalUserOffcanvasContext } from '../../../shared/context/additional-user-offcanvas-provider';
import { useMetadata } from '../../../shared/context/meta-provider';
import { GlobalContext } from '../../../shared/context/global-provider';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DOMPurify from 'dompurify';
import Localization from '../../../shared/localization';
import StripeService from '../../../../services/api/stripeService';
import UsersService from '../../../../services/api/usersService';
import CustomIcons from '../../../shared/components/custom-icons';
import ViewMobileModal from "../../../shared/components/view-mobile-modal";

const AdditionalUserOffcanvas = (props) => {
    // Session Context
    const { tenantUuid, customerId, userUuid } = useContext(SessionContext);

    // Meta data
    const { users, setUsers } = useMetadata();

    // Additional User Hooks
    const { setOffcanvasData,
        OffcanvasData,
        OffcanvasShow,
        setOffcanvasShow } = useContext(AdditionalUserOffcanvasContext);

    // Share Context
    const { listFilterTenantUser, mobileViewApprove, setMobileViewApprove, setIsLoading } = useContext(ShareContext);

    // Global Context
    const { updateToastMessage } = useContext(GlobalContext);
    const isMobile = IsMobile();
    const handleOffcanvasClose = () => {
        setOffcanvasShow(false);
        props.handleMobileModalClose();
        setOffcanvasData({
            userRequestLoading: false,
            userRequestData: [],
            userRequestParams: {},
            userRequestInvoices: {},
            state: 0,
            customer_id: ""
        });
    };

    const handleStripeApproverUser = async () => {
        setOffcanvasData(prevData => ({
            ...prevData,
            userRequestLoading: true
        }));

        const stripeGetAdditionalUserInvoices = await StripeService.stripeGetAdditionalUserInvoices(customerId, tenantUuid, OffcanvasData.userRequestData.length, userUuid, OffcanvasData.userRequestParams);

        if(stripeGetAdditionalUserInvoices.statusCode === 200) {
            setOffcanvasData(prevData => ({
                ...prevData,
                userRequestLoading: false,
                userRequestInvoices: stripeGetAdditionalUserInvoices.response,
                state: 1,
                customer_id: customerId
            }));
            if(isMobile){
                setMobileViewApprove(false);
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        if(mobileViewApprove){
            handleStripeApproverUser();
        }
    },[mobileViewApprove]);

    const handleStripePayment = async () => {
        setOffcanvasData(prevData => ({
            ...prevData,
            userRequestLoading: true
        }));

        const stripeAdditionalUser = await StripeService.stripeAdditionalUser(OffcanvasData.customer_id, tenantUuid, OffcanvasData.userRequestParams, true, userUuid);

        if(stripeAdditionalUser.statusCode === 200) {
            setOffcanvasData(prevData => ({
                ...prevData,
                state: 2,
                userRequestLoading: false,
            }));

            // Update users count
            const updatedUserCount = users + OffcanvasData?.userRequestParams?.length;
            setUsers(updatedUserCount);
        } else {
            setOffcanvasData({
                userRequestLoading: false,
                userRequestData: [],
                userRequestParams: {},
                userRequestInvoices: {},
                state: 3,
                customer_id: ""
            });
        };
    };

    const handleUserRequestDone = async () => {
        try {
            setOffcanvasData(prevData => ({
                ...prevData,
                userRequestLoading: true
            }));
            props.updatePaidPlan(null);

            const [userRequest, stripeInvoices, stripePaidPlan] = await Promise.all([
                UsersService.listUserRequest(tenantUuid),
                StripeService.stripeListInvoice(OffcanvasData.customer_id, tenantUuid),
                StripeService.stripePaidPlan(OffcanvasData.customer_id, tenantUuid)
            ]);

            if (stripeInvoices.code === 200) {
                props.updateInvoices(stripeInvoices.response);
            }

            if (userRequest.code === 200) {
                await listFilterTenantUser();
                setOffcanvasShow(false);
                props.handleMobileModalClose();
                setOffcanvasData({
                    userRequestLoading: false,
                    userRequestData: [],
                    userRequestParams: {},
                    userRequestInvoices: {},
                    state: 0,
                    customer_id: ""
                });
                updateToastMessage(`${OffcanvasData.userRequestParams.length} user ${OffcanvasData.userRequestParams.length > 1 ? "requests" : "request"} approved`, 'success')
                const { pending, approved, rejected } = userRequest.response;
                props.updateUserRequestPending(pending);
                props.updateUserRequestApproved(approved);
                props.updateUserRequestRejected(rejected);
                props.resetTab();
            }

            if(stripePaidPlan.code === 200) {
                props.updatePaidPlan(stripePaidPlan.response);
            }
        } catch (error) {
            // Handle errors here
            console.error("Error in handleUserRequestDone:", error);
        };
    };
    const handleOrderSummaryModalClose = () => {
        setOffcanvasData(prevData => ({
            ...prevData,
            state: 0
        }));
    }
    if(isMobile){
        return(
            <>
        {OffcanvasData.state === 0 ?
            props.isViewMobileModalOpenApprovedPaid && (
                    <ViewMobileModal
                    title={"Approve"}
                    onClose={props.handleMobileModalClose}
                    withBtn={true}
                    >
                    <div className="view-mobile">
                    {
                        <>
                            { OffcanvasData.userRequestData.length === 1 ?
                                Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.SingleUserDesc
                                :
                                Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.MultiUserDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length)
                            }
                            <div className='user-request-offcanvas-box'>
                                <div>
                                    {
                                        OffcanvasData.userRequestData.map((data) => {
                                            return (<p className='paragraph-1' key={data.name}><b>{data.name}</b> {`(${data.roles.join(', ')})`}</p>);
                                        })
                                    }
                                </div>
                            </div>
                            <div className="view-mobile-modal-btn mobile-offcanvas-btn">
                                <div className="button-groups-offcanvas">
                                <Button variant='primary' onClick={handleStripeApproverUser} disabled={OffcanvasData.userRequestLoading}>{ OffcanvasData.userRequestData.length > 1 ? Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.Button.replace(/\${request}/g, 'requests') : Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.Button.replace(/\${request}/g, 'request') } {OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                                <div className='paragraph-2'>{ Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.SmallDesc }</div>
                                </div>
                            </div>
                        </>
                        }
                        </div>
                        </ViewMobileModal>
                    ):
                        <>
                        {
                            OffcanvasData.state === 1 ? (
                                <ViewMobileModal
                                title={Localization.Settings.UserRequest.Offcanvas.OrderSummary.Title}
                                onClose={handleOrderSummaryModalClose}
                                withBtn={true}
                              >
                                <div className="view-mobile">
                                <>
                                    {
                                        OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate ?
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Settings.UserRequest.Offcanvas.OrderSummary.DescSG)}}></span>
                                        :
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Settings.UserRequest.Offcanvas.OrderSummary.DescOverseas)}}></span>
                                    }
                                    <div className='user-request-offcanvas-invoices'>
                                        <div className='user-request-offcanvas-invoices-plan'>
                                            <div>
                                                <span>{OffcanvasData.userRequestInvoices.name}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_excluding_tax}`}</span>
                                            </div>
                                            <div>
                                                <span>{`Qty: ${OffcanvasData.userRequestData.length}`}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${(OffcanvasData.userRequestInvoices.amount_excluding_tax/OffcanvasData.userRequestData.length).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} per user`}</span>
                                            </div>
                                            <div>
                                                <span>{`Pro-rated charges for ${OffcanvasData.userRequestInvoices.proration_start_date} - ${OffcanvasData.userRequestInvoices.proration_end_date}`}</span>
                                            </div>
                                        </div>
                                        <div className='user-request-offcanvas-invoices-amount'>
                                            <div>
                                                <span>Amount { OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate && `(excl. tax)`}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_excluding_tax}`}</span>
                                            </div>
                                            {
                                                OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate &&
                                                    <div>
                                                        <span>{`GST (${OffcanvasData.userRequestInvoices.tax_rate}%)`}</span>
                                                        <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.tax_amount}`}</span>
                                                    </div>
                                            }
                                        </div>
                                        <div className='user-request-offcanvas-invoices-total'>
                                            <h2>You will pay</h2>
                                            <h2>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_total}`}</h2>
                                        </div>
                                    </div>
                                    <div className="view-mobile-modal-btn mobile-offcanvas-btn">
                                        <div className="button-groups-offcanvas">
                                            <Button variant='primary' onClick={handleStripePayment} disabled={OffcanvasData.userRequestLoading}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Button }{OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                                            <div className='paragraph-2'>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.SmallDesc }</div>
                                        </div>
                                    </div>
                                </>
                                </div>
                                </ViewMobileModal>
                            ):
                                <>
                                    {
                                        OffcanvasData.state === 2
                                        ?
                                        <ViewMobileModal noHeader={true}>
                                         <div className="view-mobile">
                                            <>
                                                <div className='user-request-offcanvas-success' style={{marginTop: '84px'}}>
                                                    <CustomIcons variant="tick"/>
                                                    <h3>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.Title}</h3>
                                                    <p>{ OffcanvasData.userRequestData.length > 1 ? Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.MultipleUsersDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length) : Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.SingleUserDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length) }</p>
                                                    <div className="view-mobile-modal-btn mobile-offcanvas-btn success-error">
                                                        <div className="button-groups-offcanvas">
                                                          <Button variant='primary' onClick={handleUserRequestDone} disabled={OffcanvasData.userRequestLoading}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.Button }{OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            </div>
                                        </ViewMobileModal>
                                        :
                                            <ViewMobileModal noHeader={true}>
                                         <div className="view-mobile">
                                            <>
                                                <div className='user-request-offcanvas-error' style={{marginTop: '84px'}}>
                                                    <CustomIcons variant="error"/>
                                                    <h3>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Title}</h3>
                                                    <p>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Desc }</p>
                                                    <div className="view-mobile-modal-btn mobile-offcanvas-btn success-error">
                                                        <div className="button-groups-offcanvas">
                                                            <Button variant='primary' onClick={handleOffcanvasClose}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Button }</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            </div>
                                        </ViewMobileModal>
                                    }
                                </>
                        }
                    </>
                    }
          </>
        )
    }
    return (
        <Offcanvas placement="end" className="user-request-offcanvas" show={OffcanvasShow}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {
                        OffcanvasData.state === 0
                        ?
                            <>
                                <div>
                                    { Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.Title }
                                </div>
                                <div onClick={handleOffcanvasClose}>
                                    <button className='icon-button' onClick={() => handleOffcanvasClose}><CustomIcons variant="cancel" /></button>
                                </div>
                            </>
                        :
                            <>
                                {
                                    OffcanvasData.state === 1
                                    ?
                                    <>
                                        <div>
                                            { Localization.Settings.UserRequest.Offcanvas.OrderSummary.Title }
                                        </div>
                                        <div onClick={handleOffcanvasClose}>
                                            <button className='icon-button' onClick={() => handleOffcanvasClose}><CustomIcons variant="cancel" /></button>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </>
                    }

                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {
                    OffcanvasData.state === 0
                    ?
                        <>
                            { OffcanvasData.userRequestData.length === 1 ?
                                Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.SingleUserDesc
                                :
                                Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.MultiUserDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length)
                            }
                            <div className='user-request-offcanvas-box'>
                                <div>
                                    {
                                        OffcanvasData.userRequestData.map((data) => {
                                            return (<p className='paragraph-1' key={data.name}><b>{data.name}</b> {`(${data.roles.join(', ')})`}</p>);
                                        })
                                    }
                                </div>
                            </div>
                            <Button variant='primary' onClick={handleStripeApproverUser} disabled={OffcanvasData.userRequestLoading}>{ OffcanvasData.userRequestData.length > 1 ? Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.Button.replace(/\${request}/g, 'requests') : Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.Button.replace(/\${request}/g, 'request') } {OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                            <div className='paragraph-2'>{ Localization.Settings.UserRequest.Offcanvas.ApproverUserRequest.SmallDesc }</div>
                        </>
                    :
                        <>
                        {
                            OffcanvasData.state === 1
                            ?
                                <>
                                    {
                                        OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate ?
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Settings.UserRequest.Offcanvas.OrderSummary.DescSG)}}></span>
                                        :
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Settings.UserRequest.Offcanvas.OrderSummary.DescOverseas)}}></span>
                                    }
                                    <div className='user-request-offcanvas-invoices'>
                                        <div className='user-request-offcanvas-invoices-plan'>
                                            <div>
                                                <span>{OffcanvasData.userRequestInvoices.name}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_excluding_tax}`}</span>
                                            </div>
                                            <div>
                                                <span>{`Qty: ${OffcanvasData.userRequestData.length}`}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${(OffcanvasData.userRequestInvoices.amount_excluding_tax/OffcanvasData.userRequestData.length).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} per user`}</span>
                                            </div>
                                            <div>
                                                <span>{`Pro-rated charges for ${OffcanvasData.userRequestInvoices.proration_start_date} - ${OffcanvasData.userRequestInvoices.proration_end_date}`}</span>
                                            </div>
                                        </div>
                                        <div className='user-request-offcanvas-invoices-amount'>
                                            <div>
                                                <span>Amount { OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate && `(excl. tax)`}</span>
                                                <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_excluding_tax}`}</span>
                                            </div>
                                            {
                                                OffcanvasData.userRequestInvoices.tax_amount && OffcanvasData.userRequestInvoices.tax_rate &&
                                                    <div>
                                                        <span>{`GST (${OffcanvasData.userRequestInvoices.tax_rate}%)`}</span>
                                                        <span>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.tax_amount}`}</span>
                                                    </div>
                                            }
                                        </div>
                                        <div className='user-request-offcanvas-invoices-total'>
                                            <h2>You will pay</h2>
                                            <h2>{`${OffcanvasData.userRequestInvoices.currency}${OffcanvasData.userRequestInvoices.amount_total}`}</h2>
                                        </div>
                                    </div>
                                    <Button variant='primary' onClick={handleStripePayment} disabled={OffcanvasData.userRequestLoading}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Button }{OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                                    <div className='paragraph-2'>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.SmallDesc }</div>
                                </>
                            :
                                <>
                                    {
                                        OffcanvasData.state === 2
                                        ?
                                            <>
                                                <div className='user-request-offcanvas-success'>
                                                    <CustomIcons variant="tick"/>
                                                    <h3>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.Title}</h3>
                                                    <p>{ OffcanvasData.userRequestData.length > 1 ? Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.MultipleUsersDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length) : Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.SingleUserDesc.replace(/\${totalUserRequest}/g, OffcanvasData.userRequestData.length) }</p>
                                                    <Button variant='primary' onClick={handleUserRequestDone} disabled={OffcanvasData.userRequestLoading}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Success.Button }{OffcanvasData.userRequestLoading && <CustomIcons variant="spinner" />}</Button>

                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className='user-request-offcanvas-error'>
                                                    <CustomIcons variant="error"/>
                                                    <h3>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Title}</h3>
                                                    <p>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Desc }</p>
                                                    <Button variant='primary' onClick={handleOffcanvasClose}>{ Localization.Settings.UserRequest.Offcanvas.OrderSummary.Error.Button }</Button>
                                                </div>
                                            </>
                                    }
                                </>
                        }
                        </>
                }
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AdditionalUserOffcanvas;
