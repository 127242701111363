import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { SessionContext } from '../../shared/context/session-provider';
import { Table, Tag, Checkbox, Dropdown, DatePicker } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import { useMetadata } from '../../shared/context/meta-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import { AccountContext } from "../../shared/context/account-provider";
import Localization from '../../shared/localization';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import UsersService from '../../../services/api/usersService';
import GenericModal from '../../shared/components/generic-modal';
import SearchIcon from '../../icons/search';
import ArrowIcon from "../../icons/arrow";
import SpinnerIcon from '../../icons/spinner';
import CustomIcons from '../../shared/components/custom-icons';
import SolutionManifest from '../../../solution-manifest';
import StripeService from '../../../services/api/stripeService';
import MobileModal from '../../shared/components/mobile-modal';
import ViewMobileModal from '../../shared/components/view-mobile-modal';
import Skeleton from "react-loading-skeleton";
import ButtonWithDropDown from '../../shared/components/button-with-dropdown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import NotificationService from '../../../services/api/notificationService';

const UserList = (props) => {
    // Session Context
    const {
        fullname,
        username,
        userUuid,
        groupsRoles,
        tenantUuid,
        isCustomerAdmin,
        subscriptionPlan,
        customerId,
        subdomainName,
        userPoolID
    } = useContext(SessionContext);

    // Share Context
    const { listFilterTenantUser,
        userData,
        totalUsers,
        tablePageSize,
        userListExpandedRows,
        setUserListExpandedRows,
        selectAllUserList,
        setSelectAllUserList,
        selectedUserListKeys,
        setSelectedUserListKeys,
        selectAllUserListCount,
        setSelectAllUserListCount,
        openUserListFilter,
        setOpenUserListFilter,
        isMobile,
        isTablet } = useContext(ShareContext);

    //account context
    const {
        reAuthenticate,
        resetMFAForUser } = useContext(AccountContext);

    const [searchfullname, setSearchFullname] = useState("");
    const [allowSearchChange, setAllowSearchChange] = useState("true");
    const [expandededitusers, setExpandedEditUsers] = useState([]);
    const [checkedGroupItems, setCheckedGroupItems] = useState([]);

    const [deleteBulkList, setDeleteBulkList] = useState([]);

    const [customSelectMultipleClearAll, setCustomSelectMultipleClearAll] = useState(false);

    const [userGroup, setUserGroup] = useState({});

    const [saveLoading, setSaveLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Global Context
    const { updateToastMessage } = useContext(GlobalContext);

    const [modalShow, SetModalShow] = useState(false);
    const [modalLoading, SetModalLoading] = useState(false);
    const [modalType, SetModalType] = useState("");
    const [infoMessage, SetInfoMessage] = useState("");
    const [displayMessage, SetDisplayMessage] = useState("");
    const [modaldeleteusername, SetModalDeleteUsername] = useState("");
    const [modaldeleteuuid, SetModalDeleteUuid] = useState("");

    const [totalDeleted, SetTotalDeleted] = useState(1);

    const [SelectAllInfo, SetSelectAllInfo] = useState("Select All");

    const { RangePicker } = DatePicker;

    const [userAuth, setUserAuth] = useState(false);
    const [reset2faLoading, setReset2faLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [rangePickerValue, setRangePickerValue] = useState(null);
    const [rangePickerValueFrom, setRangePickerValueFrom] = useState("");
    const [rangePickerValueTo, setRangePickerValueTo] = useState("");
    const [userGroupFilterValues, setUserGroupFilterValues] = useState([]);
    const [roleFilterValues, setRoleFilterValues] = useState([]);
    const [checkboxRoleChanged, setCheckboxRoleChanged] = useState(false);
    const [isMobileModalOpenAction, setIsMobileModalOpenAction] = useState(false);
    const [isMobileModalOpenFilter, setIsMobileModalOpenFilter] = useState(false);
    const [isMobileModalOpenEdit, setIsMobileModalOpenEdit] = useState(false);
    const [isActionDisable, setIsActionDisable] = useState(false);
    const [isViewMobileModalOpen, setIsViewMobileModalOpen] = useState(false);
    const [currentPageUser, setCurrentPageUser] = useState();
    const [loading, setLoading] = useState(false);

    const { users, setUsers } = useMetadata();

    const [viewRecords, setViewRecords] = useState({
        userEmail: "",
        userName: "",
        approved: "",
        formattedApproved: ""
    });
    const [mobileModalData, setMobileModalData] = useState({
        name: "",
        userNames: "",
        isActionDisable: false,
        record: "",
        label: "",
        color: ""
    });
    const currentPage = 1;
    const [current, setCurrent] = useState(1);
    const PasswordInput = useRef(null);

    const toggleExpansion = (key, record) => {
        setOpenUserListFilter(false);

        if (expandededitusers.includes(key)) {
            setExpandedEditUsers(expandededitusers.filter((user) => user !== key));
        } else {
            setExpandedEditUsers([key]);
        }
    };

    const updateCheckedGroupItem = (key, records) => {
        if (records) {
            setCheckedGroupItems(prevCheckedItems => {
                const updatedItems = prevCheckedItems.filter(item => item.key !== key);

                return updatedItems;
            });
            setCheckedGroupItems(prevCheckedItems => {
                const updatedItems = [...prevCheckedItems];

                records.forEach(record => {
                    const option = record;
                    updatedItems.push({ option, key });
                });

                return updatedItems;
            });
        }
    };
    const onUserGroupChange = (selectedUserGroup, uuid) => {
        setUserGroup((prevUserGroup) => ({
            ...prevUserGroup,
            [uuid]: selectedUserGroup,
        }));
    };

    const listCognitoUsers = async () => {
        try {
            setLoading(true);
            await listFilterTenantUser(searchfullname, rangePickerValueFrom, rangePickerValueTo, userGroupFilterValues, roleFilterValues);
            setLoading(false);
            return true;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleTableCheckboxChange = (key, uuid, event) => {
        const currentPageData = userData.slice((current - 1) * tablePageSize, current * tablePageSize);
            const currentuser = userData.find(user => user.username  === username.toLowerCase());

            const userIsInAdminGroups = currentuser.user_roles.some(group => group === "customer_admin");

            const filteredUsers = currentPageData.filter(user => {
                const currentuser = user.username.toLowerCase() === username.toLowerCase();

                let userIsNotCustomerAdmin = false;
                if(!userIsInAdminGroups) {
                    userIsNotCustomerAdmin = user.user_roles.some(group => group === "customer_admin");
                }
                return !currentuser && !userIsNotCustomerAdmin;
            });
        let count = selectAllUserListCount;
        let countAll = filteredUsers.length;
        if (event.target.checked) {
            setSelectedUserListKeys(prevSelectedKeys => [...prevSelectedKeys, { key, uuid }]);
            setSelectAllUserListCount(count += 1);
            setCurrentPageUser(countAll);
        } else {
            setSelectedUserListKeys(prevSelectedKeys =>
                prevSelectedKeys.filter(selectedItem => selectedItem.key !== key)
            );
            setSelectAllUserListCount(count -= 1);
        }
    };

    const handleDropdownCheckbox = (key, option) => {
        updateCheckedGroupItem(key, [option]);
    };
   let filteredRolesOptionsList = groupsRoles;
   if(!isCustomerAdmin) {
       filteredRolesOptionsList = groupsRoles.filter(role => role.value !== SolutionManifest.Roles.CustomerAdmin);
   }

   const handleMoreDrpdownClick = (item) => {
        // check the text of the dropdown
        if (item == "Reset 2FA") {
            showVerifyIdentityModal();
        } else if (item == "more") {
            // console.log("more")
        }
   }

   const handleModalVerifyPassword = async () => {
        try {
            setReset2faLoading(true);
            const authenticate = await reAuthenticate(username, PasswordInput.current.value);
            if (authenticate.statusCode == 200) {
                SetDisplayMessage(Localization.Modal.Reset2fa.Desc1.replace(/\${username}/g, `<strong>${mobileModalData.name}</strong>`))
                setUserAuth(true);
                setReset2faLoading(false);
            }
        } catch (error) {
            console.error("Authentication failed: ", error);
            setReset2faLoading(false);
            setPasswordInvalid(true);
        }
   }

   const handleModalReset2fa = async () => {
        try {
            setReset2faLoading(true);
            const prefix = `-${subdomainName}`;
            const handleResetMFAForUser = await resetMFAForUser(mobileModalData.userNames + prefix, mobileModalData.userPoolID, mobileModalData.name, mobileModalData.uuid);

            if(handleResetMFAForUser) {
                const sendReset2faEmail = await UsersService.sendReset2faEmail(mobileModalData.email, fullname, tenantUuid, subdomainName);

                if(sendReset2faEmail.statusCode === 200) {
                    resetModal();
                    updateToastMessage(`2FA reset successfully`, "success");
                }
            }
        } catch (error) {
            console.error("Failed in resetting the 2fa:", error);
            updateToastMessage(`2FA reset error: ${error}`, "error");
        }
   }

    const resetModal = () => {
        SetDisplayMessage(``);
        setUserAuth(false);
        setShowPassword(false);
        setReset2faLoading(true);
        SetModalShow(false);
    };

    const showVerifyIdentityModal = () => {
        SetDisplayMessage(``)
        SetModalType("Reset2fa");
        SetModalShow(true);
    }

    const handleInputChange = (event) => {
        setReset2faLoading(event.target.value.length > 0 ? false : true);
    };

    const handleInputKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleModalVerifyPassword();
        }
    };

    const renderGenericPassword = () => {
        return(<>
            <div className="modal-form-group main">
                <label htmlFor="currentpassword" className={`${passwordInvalid ? 'warning-label' : ''}`}>Current password</label>
                <input
                    id="currentpassword"
                    className={`form-control ${passwordInvalid ? 'warning-input' : ''}`}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter current password"
                    onKeyDown={handleInputKeyPress}
                    onChange={handleInputChange}
                    ref={PasswordInput}
                ></input>
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-password-icon"
                >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>
            </div>
            {passwordInvalid ? (
            <div className="modal-form-group reenterpassword-group">
                <p className="reenterpassword-warning"><WarningRoundedIcon />Invalid password</p>
            </div>) : null}
        </>)
    };

    const dropdownMenu = (records, key) => {
        /*let filteredRolesOptionsList = groupsRoles;
        if(!isCustomerAdmin) {
            filteredRolesOptionsList = groupsRoles.filter(role => role.value !== SolutionManifest.Roles.CustomerAdmin);
        }*/
        return (
            <div className='ant-dropdown-menu'>
                {
                    filteredRolesOptionsList.map(role => {
                        return (
                        <div className='ant-dropdown-menu-item'
                            key={records.key+role.value}>
                            <Form.Check
                                type='radio'
                                id={role.value}
                                label={role.label}
                                checked={checkedGroupItems.some(item => item.option === role.value && item.key === key)}
                                onChange={() => handleDropdownCheckbox(key, role.value)}
                            />
                        </div>)
                    })
                }
                {
                    checkedGroupItems.length > 0 &&
                        <div className='ant-dropdown-menu-note'>
                            {
                                checkedGroupItems.some(item => item.option === "customer_admin" && item.key === key) &&
                                    <span>{Localization.Settings.Roles.CustomerAdmin}</span>
                            }
                            {
                                checkedGroupItems.some(item => item.option === "asset_owner" && item.key === key) &&
                                    <span>{Localization.Settings.Roles.AssetOwner}</span>
                            }
                            {
                                checkedGroupItems.some(item => item.option === "end_user" && item.key === key) &&
                                    <span>{Localization.Settings.Roles.EndUser}</span>
                            }
                        </div>
                }
                <div className='ant-dropdown-menu-divider'>
                    <hr />
                </div>
                <div className='ant-dropdown-menu-clear-all userlistClear'>
                    <a href={SolutionManifest.Roles.Url} target='_blank'>Learn about user roles</a>
                </div>
            </div>
        );
    };

    function isButtonDisabled(record, module) {
        let isDisabled = false;
        const isCurrentUser = record.key === username;
        const isUserCustomerAdmin = record.user_roles.some(group => group === SolutionManifest.Roles.CustomerAdmin);

        if(isCurrentUser) {
            if (module === "delete") {
                isDisabled = true;
            } else if (module === "select") {
                isDisabled = record.key === username;
            }
        } else if(isCustomerAdmin) {
            isDisabled = isCurrentUser;
        } else if(isCustomerAdmin) {
            isDisabled = isCustomerAdmin;
        } else {
            isDisabled = isUserCustomerAdmin;
        }

        return isDisabled;
    }
    const [sortOrder, setSortOrder] = useState(true); // State to track sorting order
    const [sortOrderMobile, setSortOrderMobile] = useState('ascend');
        if(sortOrder){
            userData.sort((a, b) => {
            const nameA = a?.fullname || '';
            const nameB = b?.fullname || '';
            return nameA.localeCompare(nameB);
        });
        }else{
            userData.sort((a, b) => {
                const nameA = a?.fullname || '';
                const nameB = b?.fullname || '';
                return nameB.localeCompare(nameA);
            });
        }
    const handleMobileSortBy = (value) => {
        const newSortOrder = value === 1 ? 'ascend' : 'descend';
        setSortOrderMobile(newSortOrder);
    };
    const handleDesktopSortBy = (pagination) => {
        if(Object.keys(pagination).length === 0){
            setSortOrder(!sortOrder);
        }else if(current !== pagination.current){
            return
        }else{
            setSortOrder(!sortOrder);
            setSelectAllUserList(false);
            setSelectedUserListKeys([]);
            setSelectAllUserListCount(0);
        }
    };

    const tableColumns = [
        {
            title: 'Full Name',
            key: 'name',
            defaultSortOrder: 'ascend',
            className: isTablet ? 'mobile-col-name' : null,
            render: (record) => {
                const nameAttribute = record?.fullname;
                const recordUsername = record?.username ? record?.username : null;
                const uuidvalue = record?.uuid;

                return (
                    <>
                        <div className='ant-table-custom-cell'>
                            <span>
                                <Checkbox
                                    className={`tablecheckbox ${recordUsername === username ? 'tablecheckbox-disable':''}`}
                                    key={record.key}
                                    id={record.key}
                                    checked={
                                        record.key === username
                                            ? false
                                            : selectedUserListKeys.map(item => item.key).includes(record.key)
                                    }
                                    onChange={event => (recordUsername !== username && handleTableCheckboxChange(record.key, uuidvalue, event))}
                                    disabled={isButtonDisabled(record, "select")}
                                >
                                </Checkbox>
                            </span>
                            <span className='truncated-span' onClick={() => document.getElementById(record.key).click()}>
                                {nameAttribute ? nameAttribute : '-'}
                            </span>
                        </div>
                    </>
                );
            },
            sorter: (a, b) => {
                const nameA = a?.fullname || '';
                const nameB = b?.fullname || '';
                return nameA.localeCompare(nameB);
            },
            ...(isTablet ? { sortOrder: sortOrder ? 'ascend' : 'descend' } : {}),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Role',
            dataIndex: 'user_roles',
            key: 'groups',
            className: 'col-role',
            render: (record, rowData) => {
                return (
                    <div className='ant-tag-container'>
                        {expandededitusers.includes(rowData.key) ? (
                            <>
                                <Dropdown
                                    dropdownRender={() => dropdownMenu(record, rowData.key)}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                >
                                    <div className='ant-dropdown-select'>
                                        <div className='ant-tag-container'>
                                            {checkedGroupItems
                                                .filter(role => role.key === rowData.key)
                                                .map(role => {
                                                const groupOption = groupsRoles.find(option => option.value === role.option);
                                                const label = groupOption ? groupOption.label : role.option;
                                                const color = groupOption ? groupOption.color : "";

                                                if(!groupOption) {
                                                    return;
                                                };

                                                return (
                                                    <Tag
                                                        key={role.option}
                                                        className={
                                                            color === 'green' ? 'ant-dropdown-select-tag green' :
                                                            color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                                            color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                                            color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                                            color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                                            'custom-select-checkbox-tag'
                                                        }
                                                    >
                                                        {label}
                                                    </Tag>
                                                );
                                            })}
                                        </div>
                                        <ArrowIcon className="ant-dropdown-arrow"/>
                                    </div>
                                </Dropdown>
                            </>
                        ) : (
                            <>
                            {record.map(group => {
                                const groupOption = groupsRoles.find(option => option.value === group);
                                const label = groupOption ? groupOption.label : group;
                                const color = groupOption ? groupOption.color : "";

                                if(!groupOption) {
                                    return;
                                };

                                return (
                                    <Tag
                                        key={group}
                                        className={
                                            color === 'green' ? 'ant-dropdown-select-tag green' :
                                            color === 'blue' ? 'ant-dropdown-select-tag blue' :
                                            color === 'pink' ? 'ant-dropdown-select-tag pink' :
                                            color === 'purple' ? 'ant-dropdown-select-tag purple' :
                                            color === 'orange' ? 'ant-dropdown-select-tag orange' :
                                            'custom-select-checkbox-tag'
                                        }
                                    >
                                        {label}
                                    </Tag>
                                    );
                                })}
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            className: isTablet ? 'mobile-col-actions' : null,
            render: (text, record) => {
                const uuid = record.uuid ? record.uuid : '';
                const name = record?.fullname ? record?.fullname : '';
                const username = record?.username ? record?.username : '';
                const email = record?.email ? record?.email : '';
                const colors = [];
                const labels = [];
                const joinedRoles = record?.user_roles.length > 0 ? record?.user_roles.filter(group => {
                    const filterGroups = groupsRoles.filter(item => group.includes(item.value));
                    return filterGroups.length > 0;
                }).map(group => {
                    const groupOption = groupsRoles.find(option => option.value === group);
                    const label = groupOption ? groupOption.label : group;
                    const color = groupOption ? groupOption.color : "";
                    colors.push(color);
                    labels.push(label);
                    return label;
                }).join(', ') : [];

                const isDisabled = uuid === userUuid;

                if(isTablet){
                    return(
                    <button className="mobile-more-vertical" onClick={() => handleMobileModal(name, isDisabled, record, labels, colors, uuid, joinedRoles, userPoolID)}>
                        <CustomIcons variant="more" />
                    </button>
                    );
                }else{
                    if(uuid === userUuid) {
                        return (
                        <div className='buttons-container'>
                            <button
                                className='icon-button'
                                disabled={true}
                            >
                                <CreateRoundedIcon />
                            </button>
                            <button
                                className='icon-button'
                                disabled={true}
                            >
                                <CustomIcons variant="trash" className="custom-icon" />
                            </button>
                            <button
                                className='icon-button'
                                disabled={true}
                            >
                                <CustomIcons variant="more" className="custom-icon" />
                            </button>
                            <button className={`icon-button ${userListExpandedRows.includes(record.key) ? 'expanded' : ''}`} onClick={() => handleExpand(record.key)}>
                                <CustomIcons variant="arrow" directtion="down"  />
                            </button>
                        </div>);
                    };
                    return (
                        <div className='actions_groups_btns'>
                            {expandededitusers.includes(record.key) ? (
                                <div className='buttons-container cancel-save'>
                                    <Button variant="secondary" onClick={() => {toggleExpansion(record.key);}} size="sm">
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={() => handleSaveUser(username, record.key, record.user_roles[0], name)} disabled={saveLoading} size="sm">
                                        Save
                                        {
                                            saveLoading &&
                                                <SpinnerIcon />
                                        }
                                    </Button>
                                </div>
                            ) : (
                                <div className='buttons-container'>
                                    <button
                                        className='icon-button'
                                        onClick={() => {
                                            updateCheckedGroupItem(record.key, record.user_roles);
                                            toggleExpansion(record.key);
                                        }}
                                        disabled={isButtonDisabled(record, "edit")}
                                    >
                                        <CreateRoundedIcon />
                                    </button>
                                    <button
                                        className='icon-button'
                                        onClick={() => handleDeleteUser(record.username, uuid, name, joinedRoles)}
                                        disabled={isButtonDisabled(record, "delete")}
                                    >
                                        <CustomIcons variant="trash" className="custom-icon" />
                                    </button>

                                    <ButtonWithDropDown listItems={["Reset 2FA"]} handleItemClick={(item) => {
                                        setMobileModalData({
                                            ...mobileModalData,
                                            name: name,
                                            email: email,
                                            userNames: record.username,
                                            isActionDisable: isDisabled,
                                            record: record,
                                            label: labels,
                                            color: colors,
                                            uuid: uuid,
                                            joinedRoles: joinedRoles,
                                            userPoolID: userPoolID,
                                        });
                                        handleMoreDrpdownClick(item, name, userPoolID);
                                        }}>
                                        <CustomIcons variant="more" className="custom-icon" />
                                    </ButtonWithDropDown>
                                    <button className={`icon-button ${userListExpandedRows.includes(record.key) ? 'expanded' : ''}`} onClick={() => handleExpand(record.key)}>
                                        <CustomIcons variant="arrow" directtion="down"  />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                }
            },
        },
    ];
    const mobileTable = () => {
        if (isTablet) {
            return tableColumns.filter(column => column.title !== 'Role');
        }
        return tableColumns;
    }
    const handleMobileModal = (name, isDisabled, record, label, color, uuid, joinedRoles, userPoolID) => {
        setIsMobileModalOpenAction(true);
        setIsActionDisable(isDisabled);
        setMobileModalData({
            ...mobileModalData,
            name: name,
            email: record.email,
            userNames: record.username,
            isActionDisable: isDisabled,
            record: record,
            label: label,
            color: color,
            uuid: uuid,
            joinedRoles: joinedRoles,
            userPoolID: userPoolID,
        });
        expandableRowRender(record);
    }
    const handleViewModal = () => {
        setIsViewMobileModalOpen(true);
    }
    const handleEditModal = (record) => {
        setIsMobileModalOpenEdit(true);
        setIsMobileModalOpenAction(false);
        updateCheckedGroupItem(mobileModalData.record.key, mobileModalData.record.user_roles)

    }
    const handleMobileModalClose = () => {
        setIsMobileModalOpenAction(false);
        setIsMobileModalOpenFilter(false);
        setIsViewMobileModalOpen(false)
        setIsMobileModalOpenEdit(false);
    }

    const handleExpand = (key) => {
        setUserListExpandedRows((prevExpandedRows) => {
            if (prevExpandedRows.includes(key)) {
                return prevExpandedRows.filter((rowKey) => rowKey !== key);
            } else {
                return [...prevExpandedRows, key];
            }
        });
    };

    const expandableRowRender = (record) => {
        let formattedApproveDate = "";
        let approveDate = "";
        if (record.approve_date) {
            approveDate = new Date(record.approve_date);

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const day = approveDate.getDate().toString().padStart(2, '0');
            const month = months[approveDate.getMonth()];
            const year = approveDate.getFullYear();
            const hours = approveDate.getHours().toString().padStart(2, '0');
            const minutes = approveDate.getMinutes().toString().padStart(2, '0');
            const seconds = approveDate.getSeconds().toString().padStart(2, '0');

            formattedApproveDate = `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
        }
        if(isTablet){
            setViewRecords({
                ...viewRecords,
                userEmail: record.email ? record.email : '-',
                userName: record.username ? record.username : '-',
                approved: record.authorizer_name ? record.authorizer_name : '',
                formattedApproved: formattedApproveDate ? formattedApproveDate : '-'
            });
        }
        if (userListExpandedRows.includes(record.key)) {
            return (
                <>
                    <div className='ant-table-custom-expanded-cell'>
                        <div className='paragraph-1 mb-0'>
                            <span><CustomIcons variant="email" /> {record.email ? record.email : '-'}</span>
                            <span><AccountCircleRoundedIcon /> {record.username ? record.username : '-'}</span>
                        </div>
                        <div className='paragraph-1 mb-0'>
                            <span className='font-bold'><strong>Approved by {record.authorizer_name ? record.authorizer_name : ''}</strong></span>
                            <span>{formattedApproveDate ? formattedApproveDate : '-'}</span>
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };

    // Handler Save Success
    const handleSaveUserSuccess = async (uuid) => {
        const successListCognitoUsers = await listCognitoUsers();

        if(successListCognitoUsers) {
            toggleExpansion(uuid);
            setSaveLoading(false);
            updateToastMessage(`User role updated successfully`, "success");
        }
    };

    const handleSaveUser = async (username, uuid, currentGroups, name) => {
        setSaveLoading(true);

        const user_group_value = userGroup[uuid];
        const groupvalue = [];
        const rolesCombination = SolutionManifest.RolesCombination;
        const user = userData.find(user => user.key === uuid);
        const oldRoles = user.user_roles.join(', ');

        for (const item of checkedGroupItems) {
          if (item.key === uuid) {
            groupvalue.push(item.option);
          }
        }

        for (const combinationObj of rolesCombination) {
            const combination = combinationObj.combination;

            // Check if the combination is a subset of groupvalue
            if (combination.every(role => groupvalue.includes(role))) {
                // Add the roles to groupvalue array
                groupvalue.push(combinationObj.roles);
            }
        }

        const usersArray = [
            {
                username: username,
                uuid: uuid,
                user_group: user_group_value ? user_group_value : "",
                roles: groupvalue
            }
        ];

        // Check if group with currentGroup is the same
        if(groupvalue[0] === currentGroups) {
            setSaveLoading(false);
            toggleExpansion(uuid);
            return;
        }

        const bodyData = {
            uuid: tenantUuid,
            status: "update", //require
            users: usersArray,
            currentUser: userUuid,
            name: name,
            oldRoles: oldRoles
        };

        const usersResult = await UsersService.userSettings(bodyData);

        if(usersResult.code === 200) {
            let notificationContent = '';

            if (user_group_value && groupvalue.length > 0) {
                notificationContent = "User group and role";
            } else if (user_group_value) {
                notificationContent = "User group";
            } else if (groupvalue.length > 0) {
                notificationContent = "User role";
            }
            //create notification
            await NotificationService.createNotification(tenantUuid, user.uuid, "role change", userUuid, oldRoles, usersArray[0].roles[0])

            const userRequest = await UsersService.listUserRequest(tenantUuid);

            if(userRequest.code === 200) {
                props.updateUserRequestPending(userRequest.response.pending);
                props.updateUserRequestApproved(userRequest.response.approved);
                props.updateUserRequestRejected(userRequest.response.rejected);
                setIsMobileModalOpenEdit(false);
                handleSaveUserSuccess(uuid);
            }
        } else {
            setSaveLoading(false);
            updateToastMessage(`Error - ${usersResult.code}`, "error");
        }
    };

    // Handler Delete Success
    const handleDeleteSingleUserSuccess = async () => {
        const successListCognitoUsers = await listCognitoUsers();

        if(successListCognitoUsers) {
            updateToastMessage(`1 user deleted`, "success");
        }
    };

    // Handler Delete Success
    const handleDeleteMultipleUserSuccess = async (totalDeleted) => {
        const successListCognitoUsers = await listCognitoUsers();

        if(successListCognitoUsers) {
            updateToastMessage(`${totalDeleted} user${totalDeleted > 1 ? 's' : ''} deleted`, "success");
        }
    };

    const handleModalClose = () => {
        setPasswordInvalid(false);
        SetModalShow(false);
        SetModalDeleteUsername("");
        SetModalDeleteUuid("");
    };

    // Handle Modal Delete
    const handleModalAccept = async () => {
        let usersArray = [];
        let usersResult;
        SetModalLoading(true);
        if(modaldeleteusername) {
            const user = userData.find(user => user.key === modaldeleteuuid);
            const roles = user.user_roles.join(', ');
            const fullname = user.fullname || '';
            const prefix = `-${subdomainName}`;
            usersArray = [
                {
                    username: modaldeleteusername + prefix,
                    uuid: modaldeleteuuid,
                    name: fullname,
                    roles: roles
                }
            ];

            if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                usersResult =  await StripeService.stripeAdditionalUser(customerId, tenantUuid, usersArray, false, userUuid);

                if(usersResult.statusCode === 200) {
                    SetModalDeleteUsername("");
                    SetModalDeleteUuid("");
                    SetModalLoading(false);
                    SetModalShow(false);
                    setSelectAllUserListCount(0);
                    handleDeleteSingleUserSuccess();
                }
            } else {
                const bodyData = {
                    uuid: tenantUuid,
                    status: "delete",
                    users: usersArray,
                    currentUser: userUuid
                };
                usersResult = await UsersService.userSettings(bodyData);

                if(usersResult.code === 200) {
                    SetModalDeleteUsername("");
                    SetModalDeleteUuid("");
                    SetModalLoading(false);
                    SetModalShow(false);
                    setSelectAllUserListCount(0);
                    handleDeleteSingleUserSuccess();
                }
            }
        }
        else {
            deleteBulkList.forEach(item => {
                const user = userData.find(user => user.key === item.entrykey);
                const roles = user.user_roles.join(', ');
                const fullname = user?.fullname || '';
                const prefix = `-${subdomainName}`;
                if (item.entryusername === username) {
                  return;
                }
                usersArray.push({
                    username: item.entryusername + prefix,
                    uuid: item.entrykey,
                    name: fullname,
                    roles: roles
                });
            });

            if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                usersResult = await StripeService.stripeAdditionalUser(customerId, tenantUuid, usersArray, false, userUuid);

                if(usersResult.statusCode === 200) {
                    SetModalDeleteUsername("");
                    SetModalDeleteUuid("");
                    SetModalLoading(false);
                    SetModalShow(false);
                    setSelectAllUserListCount(0);
                    handleDeleteMultipleUserSuccess(totalDeleted);
                }
            } else {
                const bodyData = {
                    uuid: tenantUuid,
                    status: "delete",
                    users: usersArray,
                    currentUser: userUuid
                };
                usersResult = await UsersService.userSettings(bodyData);

                if(usersResult.code === 200) {
                    SetModalDeleteUsername("");
                    SetModalDeleteUuid("");
                    SetModalLoading(false);
                    SetModalShow(false);
                    setSelectAllUserListCount(0);
                    handleDeleteMultipleUserSuccess(totalDeleted);
                }
            }
        }
    };

    const handleDeleteUser = async (username, uuid, fullname, roles) => {
        SetTotalDeleted(1);
        SetModalShow(true);
        SetModalType("userlistdelete");
        SetInfoMessage(`<li key=${uuid}><strong>${fullname}</strong> (${roles})</li>`);
        SetModalDeleteUsername(username);
        SetModalDeleteUuid(uuid);
        setIsMobileModalOpenAction(false);

        if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
            props.updatePaidPlan(null);
            SetDisplayMessage(isTablet ? Localization.Settings.UserList.MobileDeleteSingleUserPayment.replace(/\${NameDeleted}/g, `<strong>${fullname}</strong> (${roles})`) : Localization.Settings.UserList.DeleteSingleUserPayment);

            const stripePaidPlan = await StripeService.stripePaidPlan(customerId, tenantUuid);

            if(stripePaidPlan.code === 200) {
                props.updatePaidPlan(stripePaidPlan.response);

                const updatedUserCount = users - 1;
                setUsers(updatedUserCount);
            }
        } else {
            SetDisplayMessage(isTablet ? Localization.Settings.UserList.MobileDeleteSingleUser.replace(/\${NameDeleted}/g, `<strong>${fullname}</strong> (${roles})`) : Localization.Settings.UserList.DeleteSingleUser);
        }
    };
    const handleDeleteBulkUser = async () => {
        if(selectedUserListKeys.length > 0) {
            SetModalShow(true);
            SetModalType("userlistdelete");
            SetModalDeleteUsername("");
            setDeleteBulkList([]);

            let formattedString = '';
            let totalDeleted = 0;

            selectedUserListKeys.forEach(selectedKey => {
                if (selectedKey.key === username) {
                  return;
                }
                const entry = Object.values(userData).find(item => item.key === selectedKey.key);

                const entryusername = entry.username;
                const entrykey = selectedKey.key;
                setDeleteBulkList(prevCheckedItems => {
                    const updatedItems = [...prevCheckedItems];
                    updatedItems.push({ entryusername, entrykey });
                    return updatedItems;
                    }
                );

                if (entry) {
                  const groupNames = entry.user_roles.map(group => {
                    const groupLabel = groupsRoles.find(option => option.value === group)?.label;
                    return groupLabel ? groupLabel : "" || group;
                  }).join(', ');
                  if (entry?.fullname) {
                        formattedString += isTablet ? `<strong>${entry?.fullname}</strong> (${groupNames})` : `<li key=${selectedKey.key}><strong>${entry?.fullname}</strong> (${groupNames})<br/></li>`;
                    } else {
                        formattedString += `<li key=${selectedKey.key}><strong>${selectedKey.key}</strong> (${groupNames})<br/></li>`;
                    }
                  totalDeleted += 1;
                }
            });

            SetTotalDeleted(totalDeleted);
            SetInfoMessage(formattedString);

            let modifiedMessage;

            if(subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                props.updatePaidPlan(null);
                modifiedMessage = isTablet ? Localization.Settings.UserList.MobileDeleteSingleUserPayment.replace(/\${NameDeleted}/g, formattedString) : Localization.Settings.UserList.DeleteSingleUserPayment;
                if(totalDeleted > 1) {
                    modifiedMessage = isTablet ? Localization.Settings.UserList.MobileDeleteBulkUserPayment.replace(/\${totalDeleted}/g, totalDeleted) : Localization.Settings.UserList.DeleteBulkUserPayment.replace(/\${totalDeleted}/g, totalDeleted);
                }

                const stripePaidPlan = await StripeService.stripePaidPlan(customerId, tenantUuid);

                if(stripePaidPlan.code === 200) {
                    props.updatePaidPlan(stripePaidPlan.response);

                    // Update users count
                    const updatedUserCount = users - totalDeleted;
                    setUsers(updatedUserCount);
                }
            } else {
                modifiedMessage = isTablet ? Localization.Settings.UserList.MobileDeleteSingleUser.replace(/\${NameDeleted}/g, formattedString) : Localization.Settings.UserList.DeleteSingleUser;
                if(totalDeleted > 1) {
                    modifiedMessage = isTablet ? Localization.Settings.UserList.MobileDeleteBulkUser.replace(/\${totalDeleted}/g, totalDeleted) : Localization.Settings.UserList.DeleteBulkUsers.replace(/\${totalDeleted}/g, totalDeleted);
                }

                // Update users count
                const updatedUserCount = users - totalDeleted;
                setUsers(updatedUserCount);
            }
            SetDisplayMessage(modifiedMessage);
        }
        else {
            updateToastMessage("Select at least one user to delete", "error");
        }
    };

    const onChangeFilterName = async (name) => {
        if (!modalShow && allowSearchChange) {
            setSearchFullname(name);
        }
    };

    const [tablePagination, SetTablePagination] = useState("");
    const handleTableChange = (pagination, filters, sorter) => {
        let startindex = pagination * tablePageSize - tablePageSize + 1;
        let endindex = startindex + tablePageSize - 1;
        endindex = totalUsers < endindex ? totalUsers:endindex;
        SetTablePagination(`Showing ${startindex}-${endindex} of ${totalUsers} Users`);
        setSelectAllUserList(false);
        setSelectedUserListKeys([]);
        setSelectAllUserListCount(0);
        setCurrent(pagination);
        setSelectAllUserList(false);
    };

    const handleSelectAllChange = event => {
        setSelectAllUserList(event.target.checked);
    };

    const onClickFilters = () => {
        setOpenUserListFilter(!openUserListFilter);
    };

    const handleFilterDate = (value) => {
        setRangePickerValue(value);

        if(!value) {
            setRangePickerValueFrom(null);
            setRangePickerValueTo(null);
            return;
        }
        setRangePickerValueFrom(value[0].valueOf());
        setRangePickerValueTo(value[1].valueOf());
    };

    const handleApplyFilter = () => {
        listCognitoUsers();
        handleMobileModalClose();
        setSelectAllUserList(false);
        setSelectedUserListKeys([]);
        setSelectAllUserListCount(0);
        setSortOrder(sortOrderMobile === 'ascend' ? true : false);

        if(roleFilterValues.length > 0 && rangePickerValue != null){
            setCountFilter(2);
        }else if(roleFilterValues.length > 0 || rangePickerValue != null){
            setCountFilter(1);
        }else{
            setCountFilter(0);
        }
    };

    const handleClearAllFilter = async () => {
        try {
            setCheckboxRoleChanged(false);
            setRangePickerValue(null);
            setRangePickerValueFrom("");
            setRangePickerValueTo("");
            setUserGroupFilterValues([]);
            setCustomSelectMultipleClearAll(true);
            setRoleFilterValues([]);
            setCountFilter(0);
            handleMobileModalClose();
            setSortOrder(true);
            setSortOrderMobile('ascend');
            setSelectAllUserList(false);
            setSelectedUserListKeys([]);
            setSelectAllUserListCount(0);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFilterUserGroup = (value) => {
        setUserGroupFilterValues(value);
    };

    const [countFilter, setCountFilter] = useState();
    const handleRoleCheckboxChange = (event) => {
        const selectedRole = event.target.id;
        if (event.target.checked) {
            setRoleFilterValues(prevRoles => [...prevRoles, selectedRole]);
        } else {
            setRoleFilterValues(prevRoles => prevRoles.filter(role => role !== selectedRole));
        }
        setCheckboxRoleChanged(true);
    };

    const updateCustomSelectMultipleClearAll = (value) => {
        setCustomSelectMultipleClearAll(value);
    };

    const UserListSkeleton = () => {
        return(<>
            <div className='user-list-table-skeleton'>
                <Skeleton width="196px" height="24px"/>
                <div className={`${isTablet ? 'd-none' : null}`}>
                    <div>
                        Full Name
                    </div>
                    <div>
                        Role
                    </div>
                    <div>
                        Actions
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
            </div>
        </>)
    };

    useEffect(() => {
        if (!checkboxRoleChanged) {
            listCognitoUsers();
        }

        setCheckboxRoleChanged(true);
    }, [roleFilterValues, currentPage, checkboxRoleChanged]);

    useEffect(() => {
        listCognitoUsers();
        setCheckboxRoleChanged(true);
    }, []);

    useEffect(() => {
        const endindex = users < 10 ? users : 10;
        SetTablePagination(`Showing ${1}-${endindex} of ${users} ${users > 1 ? 'users' : 'user'}`);
    }, [userData]);

    useEffect(() => {
        listCognitoUsers();
    }, [searchfullname]);

    useEffect(() => {
        if (selectAllUserList) {
            const currentPageData = userData.slice((current - 1) * tablePageSize, current * tablePageSize);
            const currentuser = userData.find(user => user.username === username.toLowerCase());

            const userIsInAdminGroups = currentuser.user_roles.some(group => group === "customer_admin");

            const filteredUsers = currentPageData.filter(user => {
                const currentuser = user.username.toLowerCase() === username.toLowerCase();

                let userIsNotCustomerAdmin = false;
                if(!userIsInAdminGroups) {
                    userIsNotCustomerAdmin = user.user_roles.some(group => group === "customer_admin");
                }
                return !currentuser && !userIsNotCustomerAdmin;
            });

            let count = filteredUsers.length;
            const allSelected = currentPageUser === selectAllUserListCount;
            setSelectedUserListKeys(filteredUsers);
            SetSelectAllInfo(`${allSelected ? 'All' : count} selected`);
            setSelectAllUserListCount(count);
            setCurrentPageUser(count);
        } else {
            setSelectedUserListKeys([]);
            SetSelectAllInfo("Select all");
            setSelectAllUserListCount(0);
        }
    }, [selectAllUserList]);

    useEffect(() => {
        const allSelected = currentPageUser === selectAllUserListCount;
        if(selectAllUserListCount > 0) {
            SetSelectAllInfo(`${allSelected ? 'All' : selectAllUserListCount} selected`);
            if(allSelected){
                setSelectAllUserList(true);
            }
        }
        else {
            setSelectAllUserList(false);
            SetSelectAllInfo("Select all");
        }
    }, [selectAllUserListCount]);

    return (
        <>
            <div className="form-container user-list">
                <div className="form-group">
                    {!isTablet && <h2 className="title-text">User List</h2> }
                    {isTablet ?
                        <div className='mobile-setting-bar'>
                            <div className='mobile-setting-filter'>
                                <Button
                                    onClick={() => { setIsMobileModalOpenFilter(true);}}
                                    variant="default" className={openUserListFilter ? "icon-button icon-button-text icon-button-left selected" : "icon-button icon-button-text icon-button-left"}>
                                    <h3 className='filters-h3'>{countFilter ? `Filters (${countFilter})` : 'Filters'}</h3>
                                    <span className='d-flex align-items-center'>
                                    {isMobileModalOpenFilter ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                    </span>
                                </Button>
                            </div>
                            <div className="form-group form-search">
                                <SearchIcon />
                                <input
                                    id="search-full-name"
                                    className="form-control"
                                    type="text"
                                    value={searchfullname}
                                    placeholder="Search full name"
                                    onChange={(e) => {
                                        onChangeFilterName(e.target.value);
                                    }}
                                    onFocus={() => {
                                        setAllowSearchChange(true);
                                    }}
                                    onBlur={() => {
                                        setAllowSearchChange(false);
                                    }}
                                ></input>
                            </div>
                            {
                            isMobileModalOpenFilter && (
                                    <MobileModal title="Filters" onClose={handleMobileModalClose} withBtn={true}>
                                        <div className="mobile-modal-sort">
                                            <div className="sort-title">
                                                <p className="paragraph-1">Sort By</p>
                                            </div>
                                            <div className="sort-data">
                                                <input
                                                    type="radio"
                                                    id="full-name-A-Z"
                                                    name="sort"
                                                    value={1}
                                                    checked={sortOrderMobile === 'ascend'}
                                                    onChange={() => handleMobileSortBy(1)}
                                                />
                                                <label htmlFor="full-name-A-Z" className="paragraph-1">Full Name: A-Z</label>
                                            </div>
                                            <div className="sort-data">
                                                <input
                                                    type="radio"
                                                    id="full-name-Z-A"
                                                    name="sort"
                                                    value={2}
                                                    checked={sortOrderMobile === 'descend'}
                                                    onChange={() => handleMobileSortBy(2)}
                                                />
                                                <label htmlFor="full-name-Z-A" className="paragraph-1">Full Name: Z-A</label>
                                            </div>
                                        </div>
                                        <div className='mobile-modal-filter'>
                                <div className='row asset-filter-container'>
                                    <div className='col-3'>
                                        <div className='rangepicker-container'>
                                            <p className='filter-title'>Date Approved</p>
                                            <div className='mobile-rangepicker-container'>
                                            <label>Date Approved</label>
                                            <RangePicker
                                                value={rangePickerValue}
                                                size='8'
                                                format="DD-MM-YYYY"
                                                placeholder={['DD/MM/YY', 'DD/MM/YY']}
                                                className='date-component'
                                                onChange={(value) => handleFilterDate(value)}
                                                clearIcon={<CustomIcons className='rangepicker-close-icon' variant="cancel" />}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 mobile-role'>
                                        <p className='paragraph-1 font-bold'>Role</p>
                                        <div className='mobile-role-container'>
                                                {groupsRoles.slice(0, 2).map((role, index) => (
                                                    <Form.Check
                                                        className='mobile-role-checkbox-filter'
                                                        key={index}
                                                        type='checkbox'
                                                        id={role.value}
                                                        label={role.label}
                                                        onChange={handleRoleCheckboxChange}
                                                        checked={roleFilterValues.includes(role.value)}
                                                    />
                                                ))}
                                                {groupsRoles.slice(2).map((role, index) => (
                                                    <Form.Check
                                                        className='mobile-role-checkbox-filter'
                                                        key={index}
                                                        type='checkbox'
                                                        id={role.value}
                                                        label={role.label}
                                                        onChange={handleRoleCheckboxChange}
                                                        checked={roleFilterValues.includes(role.value)}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                                        <div className="mobile-modal-filter">
                                            <div className='asset-filter-top'>
                                                <Button variant='secondary' onClick={() => handleClearAllFilter()}>Clear All</Button>
                                                <Button variant='primary' onClick={() => handleApplyFilter()}>Apply</Button>
                                            </div>
                                        </div>
                                    </MobileModal>
                            )
                        }
                        {selectAllUserListCount > 0 &&
                                <div className='mobile-check-delete'>
                                    <div className='mobile-user-check'>
                                        {users > 1 && (
                                        <Checkbox checked={selectAllUserList} onChange={handleSelectAllChange} className={selectAllUserListCount === 0 ? 'selectall_checkbox' : currentPageUser === selectAllUserListCount ? 'selectall_checkbox' : 'selectall_checkbox some-checkbox-filled'}>
                                            {SelectAllInfo}
                                        </Checkbox>
                                        )}
                                    </div>
                                    <div className='mobile-user-bulk-delete'>
                                        {users > 1 && (
                                            <Button
                                                onClick={handleDeleteBulkUser}
                                                variant="default" className="icon-button icon-button-text icon-button-left">
                                                <CustomIcons variant="trash" className="custom-icon" />
                                            </Button>
                                            )}
                                    </div>
                                </div>
                        }
                        </div>
                    : (
                        <div className='user-list-settings-bar'>
                            <div className='user-request-settings-bar-left'>
                                {users > 1 && (
                                    <Checkbox checked={selectAllUserList} onChange={handleSelectAllChange} className={selectAllUserListCount === 0 ? 'selectall_checkbox' : currentPageUser === selectAllUserListCount ? 'selectall_checkbox' : 'selectall_checkbox some-checkbox-filled'}>
                                        {SelectAllInfo}
                                    </Checkbox>
                                )}
                            </div>
                            <div className='user-request-settings-bar-right'>
                                    <div className="form-group form-search">
                                        <SearchIcon />
                                        <input
                                            id="search-full-name"
                                            className="form-control"
                                            value={searchfullname}
                                            type="text"
                                            placeholder="Search full name"
                                            onChange={(e) => {
                                                onChangeFilterName(e.target.value);
                                            }}
                                            onFocus={() => {
                                                setAllowSearchChange(true);
                                            }}
                                            onBlur={() => {
                                                setAllowSearchChange(false);
                                            }}
                                        ></input>
                                    </div>
                                    <Button
                                        onClick={onClickFilters}
                                        variant="default" className={openUserListFilter ? "icon-button icon-button-text icon-button-left selected" : "icon-button icon-button-text icon-button-left"}>
                                        Filters {countFilter && `(${countFilter})`}
                                        <span style={{marginLeft: '4px', display: 'inline-block' }}>
                                        {openUserListFilter ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                        </span>
                                    </Button>
                                    {users > 1 && (
                                    <Button
                                        onClick={handleDeleteBulkUser}
                                        variant="default" className="icon-button icon-button-text icon-button-left">
                                        <CustomIcons variant="trash" className="custom-icon" />Delete
                                    </Button>
                                    )}
                                </div>
                            </div>
                    )}
                </div>
                {openUserListFilter &&
                    <div className='user-request-filter'>
                        <div className='user-request-filter-button-group'>
                            <Button className='clear-all-button' variant='secondary' onClick={() => handleClearAllFilter()}>Clear All</Button>
                            <Button className='apply-filter-button' variant='primary' onClick={() => handleApplyFilter()}>Apply Filters</Button>
                        </div>
                        <div className='user-request-filter-options'>
                            <div className='row'>
                                <div className='col-6 col-xxl-4 filter-options-rangepicker-container'>
                                    <p className='paragraph-1 font-bold'>Date Approved</p>
                                    <label>Date Approved</label>
                                    <RangePicker
                                        value={rangePickerValue}
                                        size='8'
                                        format="DD-MM-YYYY"
                                        placeholder={['DD/MM/YY', 'DD/MM/YY']}
                                        className='date-component'
                                        onChange={(value) => handleFilterDate(value)}
                                        clearIcon={<CustomIcons className='rangepicker-close-icon' variant="cancel" />}
                                    />
                                </div>

                                <div className='col-6 col-xxl-6'>
                                    <p className='paragraph-1 font-bold mb-225'>Role</p>
                                    <div className='row'>
                                        <div className='col-5'>
                                            {groupsRoles.slice(0, 2).map((role, index) => (
                                                <Form.Check
                                                    className='role-checkbox-filter'
                                                    key={index}
                                                    type='checkbox'
                                                    id={role.value}
                                                    label={role.label}
                                                    onChange={handleRoleCheckboxChange}
                                                    checked={roleFilterValues.includes(role.value)}
                                                />
                                            ))}
                                        </div>
                                        <div className='col-5'>
                                            {groupsRoles.slice(2).map((role, index) => (
                                                <Form.Check
                                                    className='role-checkbox-filter'
                                                    key={index}
                                                    type='checkbox'
                                                    id={role.value}
                                                    label={role.label}
                                                    onChange={handleRoleCheckboxChange}
                                                    checked={roleFilterValues.includes(role.value)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { (users > 0 && !loading) ? (
                    <>
                        <div className="form-group tablePagination">
                            <span className="table-pagination">{tablePagination}</span>
                        </div>
                        <div className="form-group user-list-table-list user-list-table">
                            <Table
                                className='custom-ant-table'
                                showQuickJumper
                                expandable={{
                                    expandedRowRender: expandableRowRender,
                                    expandedRowKeys: userListExpandedRows,
                                    onExpand: (expanded, record) => handleExpand(record.key),
                                }}
                                pagination={users > tablePageSize ? {
                                    showSizeChanger: false,
                                    showQuickJumper: true,
                                    position: ['none', "bottomCenter"],
                                    tablePageSize: tablePageSize,
                                    onChange: handleTableChange,
                                }: false}
                                showExpandColumn={false}
                                columns={isTablet ? mobileTable() : tableColumns}
                                dataSource={userData}
                                rowKey={(record) => record.key}
                                onChange={handleDesktopSortBy}
                            />
                        </div>
                    </>
                ) : (
                    !loading ?
                        <div className='nousersfound'>
                            {
                                searchfullname
                                ?
                                    <>
                                        <p className='font-bold'>No users found for “{searchfullname}”</p>
                                        <ul>
                                            <li>Check and ensure your spelling is correct</li>
                                            <li>Try a different search</li>
                                        </ul>
                                    </>
                                :
                                    <>
                                        <p className='font-bold'>No results found</p>
                                        <ul>
                                            <li>Check and ensure your inputs and selections are valid</li>
                                            <li>Try inputting and/or selecting other valid Filters options</li>
                                        </ul>
                                    </>
                            }
                        </div>
                    :   <UserListSkeleton />)
                }
                {
                                isMobileModalOpenAction && (
                                            <MobileModal title={mobileModalData.name} onClose={handleMobileModalClose}>
                                                <div className="mobile-modal-more-vertical">
                                                    <Button size='sm' className="btn-row-action" onClick={() => { handleViewModal()}} variant='link'>
                                                    <RemoveRedEyeIcon />
                                                        <h3>View</h3>
                                                    </Button>
                                                    <Button className={`btn-row-action icon-button ${mobileModalData.isActionDisable ? 'd-none': ''}`} onClick={() => { handleEditModal()}} size='sm' variant='link'>
                                                            <>
                                                            <CreateRoundedIcon />
                                                                <h3>Edit</h3>
                                                            </>
                                                    </Button>
                                                    <Button className={`btn-row-action icon-button ${mobileModalData.isActionDisable ? 'd-none': ''}`} onClick={() => handleDeleteUser(mobileModalData.userNames, mobileModalData.uuid, mobileModalData.name, mobileModalData.joinedRoles)} size='sm' variant='link'>
                                                            <>
                                                            <CustomIcons variant="trash" className="custom-icon" />
                                                                <h3>Delete</h3>
                                                            </>
                                                    </Button>
                                                    <Button className={`btn-row-action icon-button ${mobileModalData.isActionDisable ? 'd-none': ''}`} onClick={() => {showVerifyIdentityModal(); setIsMobileModalOpenAction(false);}} size='sm' variant='link'>
                                                            <>
                                                            <CustomIcons variant="lockReset" className="custom-icon" />
                                                                <h3>Reset 2FA</h3>
                                                            </>
                                                    </Button>
                                                </div>
                                            </MobileModal>
                                )
                            }
                            {isViewMobileModalOpen &&
                                 <ViewMobileModal title={'View'} onClose={handleMobileModalClose}>
                                    <div className='name-role'>
                                        <h1>{mobileModalData.name}</h1>
                                        <div className='ant-tag-container'>
                                            <Tag
                                                    className={
                                                        mobileModalData.color[0] === 'green' ? 'ant-dropdown-select-tag green' :
                                                        mobileModalData.color[0] === 'blue' ? 'ant-dropdown-select-tag blue' :
                                                        mobileModalData.color[0] === 'pink' ? 'ant-dropdown-select-tag pink' :
                                                        mobileModalData.color[0]=== 'purple' ? 'ant-dropdown-select-tag purple' :
                                                        mobileModalData.color[0] === 'orange' ? 'ant-dropdown-select-tag orange' :
                                                        'custom-select-checkbox-tag'
                                                    }
                                                >
                                                    <label>{mobileModalData.label[0]}</label>
                                                </Tag>
                                        </div>
                                    </div>
                                    <div className='email-username'>
                                        <p className='paragraph-1'><CustomIcons variant="email" /> <span>{viewRecords.userEmail}</span></p>
                                        <p className='paragraph-1'><AccountCircleRoundedIcon /><span>{viewRecords.userName}</span></p>
                                    </div>
                                    <div className='approve-details'>
                                        <p className='paragraph-1 approved-name'>Approved by {viewRecords.approved}</p>
                                        <p className='paragraph-1'>{viewRecords.formattedApproved}</p>
                                     </div>
                                </ViewMobileModal>
                            }
                            {
                                isMobileModalOpenEdit && (
                                            <MobileModal title={'Edit user role'} onClose={handleMobileModalClose} withBtn={true}>
                                                <div className="mobile-modal-more-vertical">
                                                <div className='mobile-modal-sort'>
                                                    {
                                                        filteredRolesOptionsList.map((role, i) => {
                                                            const roleArr =[Localization.Settings.Roles.CustomerAdmin, Localization.Settings.Roles.AssetOwner, Localization.Settings.Roles.EndUser]
                                                            return (
                                                            <div className='sort-data'
                                                                key={mobileModalData.record.key+role.value}>
                                                                <Form.Check
                                                                    className='ps-0'
                                                                    type='radio'
                                                                    id={role.value}
                                                                    label=''
                                                                    checked={checkedGroupItems.some(item => item.option === role.value && item.key === mobileModalData.record.key)}
                                                                    onChange={() => handleDropdownCheckbox(mobileModalData.record.key, role.value)}
                                                                />
                                                                <div className='role-desc-container' onClick={() => document.getElementById(role.value).click()}>
                                                                    <span className='paragraph-1'>{role.label}</span>
                                                                    <span className='role-desc'>{roleArr[i]}</span>
                                                                </div>
                                                            </div>)
                                                        })
                                                    }
                                                </div>
                                                </div>
                                                <div className="mobile-modal-filter">
                                                        <div className='asset-filter-top'>
                                                            <Button variant='secondary' onClick={() => setIsMobileModalOpenEdit(false)}>Cancel</Button>
                                                            <Button variant="primary" onClick={() => handleSaveUser(mobileModalData.userNames, mobileModalData.record.key, mobileModalData.record.user_roles[0], mobileModalData.name)} disabled={saveLoading} size="sm">
                                                                Save
                                                                {
                                                                    saveLoading &&
                                                                        <SpinnerIcon />
                                                                }
                                                            </Button>
                                                        </div>
                                                    </div>
                                            </MobileModal>
                                     )
                                }
            </div>
            <GenericModal
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                handleModalAccept={handleModalAccept}
                handleModalVerifyPassword={handleModalVerifyPassword}
                handleModalReset2FA={handleModalReset2fa}
                modalType={modalType}
                infoMessage={infoMessage}
                displayMessage={displayMessage}
                modalLoading={modalLoading}
                userAuth={userAuth}
                reset2faLoading={reset2faLoading}
                resetModal={resetModal}
            >
                {!userAuth && renderGenericPassword()}
            </GenericModal>
        </>
    );
};

export default UserList;