import { useState, useRef, useEffect, useContext } from 'react';
import { sanitizeInput } from '../../shared/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getStoreItem, createStore, setStoreItem } from '../../../store';
import { SessionContext } from '../../shared/context/session-provider';
import { ApiContext } from '../../shared/context/api-state';
import { UploadContext } from '../../shared/context/upload-provider';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@material-ui/core/Popper';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AssetService from '../../../services/api/assetsService';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';
import ArrowIcon from '../../icons/arrow';
import CancelIcon from '../../icons/cancel';
import Localization from '../../shared/localization';
import AddIcon from '../../icons/add';
import CustomIcons from '../../shared/components/custom-icons';
import {IsDesktop} from '../../shared/utils'
import { ShareContext } from '../../shared/context/share-state';
import { useMetadata } from '../../shared/context/meta-provider';
const PopperComponent = (props) => {
    const { anchorEl, children, ...restProps } = props;
    const [newCollectionError, setNewCollectionError] = useState("");
    const [newCollection, setNewCollection] = useState("");
    const [charLimitShow, setCharLimitShow] = useState(false);
    const [charLimitReached, setCharLimitReached] = useState(false);
    const [collectionShowCreate, setCollectionShowCreate] = useState(false);
    const [collectionCreating, setCollectionCreating] = useState(false);
    const [newCollectionLimit, setNewCollectionLimit] = useState(0);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);

    // Refresh MetaData
    const { refreshMetadata } = useMetadata();
    
    // Share Context
    const { 
        updateStorage,setMaxStorageSize
    } = useContext(ShareContext);
   
    const newCollectionCharLimit = 50;
    const inputRef = useRef(null);

    const newCollectionCreate = async () => {
        setCollectionCreating(true);
        setCharLimitReached(false);

        if(newCollection === '') {
            setNewCollectionError('empty');
            setCollectionCreating(false);
        } else {
            setNewCollectionError('');
            const bodyCollection = {
                "input": {
                    "name": newCollection,
                    "tenantUuid": tenantUuid,
                    "userUuid": userUuid
                }
            }

            try {
                const createCollection = await AssetService.createCollection(bodyCollection, {}, tenantUuid);
                setCollectionCreating(false);
                setNewCollection("");
                const resultData = {
                    ...createCollection,
                    checked: true,
                    mixed: false
                };
                
                const newItem = {
                    "uuid": resultData.uuid,
                    "name": resultData.name,
                    "tenantUuid": resultData.tenantUuid,
                    "timestamp": Date.now(), // Current timestamp
                    "checked": false,
                    "userUuid": createCollection.userUuid,
                    "assets": []
                };
                const storeItem = await getStoreItem('all-collections', createStore('get-collections', 'all-collections'));
                storeItem.push(newItem);

                await setStoreItem('all-collections', storeItem, createStore('get-collections', 'all-collections'));
                props.createcollectionandaddasset(createCollection.name, createCollection.uuid);
                props.updatecollectiondata(resultData);
                props.dropdownref.current.querySelector('.MuiAutocomplete-listbox').scrollTop = 0;                

                const getAssetStorage = await AssetService.getAssetStorage(tenantUuid);

                if(getAssetStorage.statusCode === 200) {
                    updateStorage(getAssetStorage.response.storageSize, getAssetStorage.response.percentage, getAssetStorage.response.remainingSize);
                    setMaxStorageSize(getAssetStorage.response.maxStorageSize);
                }
                // Refresh Meta Data
                refreshMetadata();
            } catch (error) {
                await inputRef.current.focus();
                setCollectionCreating(false);
                setNewCollectionError('duplicated');
                console.error(`fail to create collection ${newCollection}`);
            }
        }
    };

    const newCollectionShowCreate = async (e) => {
        setNewCollection("");        
        setNewCollectionError("");
        setNewCollectionLimit(0);
        await setCollectionShowCreate(true);
        await inputRef.current.focus();
    };

    const newCollectionChange = (char) => {
        setNewCollectionError("")
        setCharLimitReached(false);

        const regex = /[.<>'":;[\]{}|\\+=/?,]/g;
        const sanitizedValue = sanitizeInput(regex, char.target.value);
        setNewCollection(sanitizedValue);
        setNewCollectionLimit(sanitizedValue.length);

        if(newCollectionCharLimit === sanitizedValue.length) {
            setCharLimitReached(true);
        }
    };

    const newCollectionBlur = () => setCharLimitShow(false);

    return (
        <Popper {...restProps}
            ref={props.dropdownref}
            open={collectionShowCreate ? collectionShowCreate : props.open}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal={true}
            className='upload-collection-popper'
        >
            {children}
            <hr />
            {
                collectionShowCreate
                ?
                    <>
                        <div className='upload-collection-new'>
                            <div className='upload-collection-new-input-group'>
                                <Form.Control
                                    ref={inputRef}
                                    type="text"
                                    className={newCollectionError === 'empty' || newCollectionError === 'duplicated' ? 'upload-collection-new-input red-border' : 'upload-collection-new-input'}
                                    id="CreateCollection"
                                    as="input"
                                    aria-describedby="NewCollectionError"
                                    value={newCollection}
                                    maxLength={newCollectionCharLimit}
                                    placeholder={Localization.Collection.NewCollectionName}
                                    onChange={(e) => newCollectionChange(e)}
                                    onBlur={(e) => newCollectionBlur(e)}
                                    onFocus={(e) => setCharLimitShow(true)}
                                />
                                {
                                    // charLimitShow &&
                                        <div className='upload-char-limit'>
                                            <span>
                                                {
                                                    charLimitReached
                                                    ?
                                                        Localization.Upload.Offcanvas.Progress.CharLimitReach
                                                    :
                                                        <>
                                                            {
                                                                newCollectionError === 'empty' &&
                                                                    <Form.Text className='error' id="NewCollectionError">
                                                                        <FontAwesomeIcon icon={faTriangleExclamation} />
                                                                        {Localization.Alerts.NewCollectionNameError}
                                                                    </Form.Text>
                                                            }
                                                            {
                                                                newCollectionError === 'duplicated' &&
                                                                    <Form.Text className='error' id="NewCollectionError">
                                                                        <FontAwesomeIcon icon={faTriangleExclamation} />
                                                                        {Localization.Alerts.NewCollectionNameDuplication}
                                                                    </Form.Text>
                                                            }
                                                        </>
                                                }
                                            </span>
                                            <span>
                                                {
                                                    `${newCollectionLimit}/${newCollectionCharLimit}`
                                                }
                                            </span>
                                        </div>
                                }
                            </div>
                            <div className='upload-collection-new-btn-group upload-collection-btn'>
                                <button className='btn btn-secondary' onClick={() => setCollectionShowCreate(false)}>{Localization.Collection.NewCollectionSecondaryBtn}</button>
                                <button className={collectionCreating ? 'btn btn-primary disabled' : 'btn btn-primary'} onClick={() => newCollectionCreate()}>{Localization.Collection.NewCollectionPrimaryBtn}{collectionCreating && <Spinner variant='gray' />}</button>
                            </div>
                        </div>

                    </>
                :
                <button className='upload-collection-create' onClick={(event) => newCollectionShowCreate(event)}>{ Localization.Collection.CreateNewCollection }</button>
            }
        </Popper>
    );
};

const UploadChipsSecondary = (props) => {
    const [tooltipCollection, setTooltipCollection] = useState(0);

    const handleMouseCollectionEnter = (collections) => {
        const process = props.processRef.current;
        const checkCollection = props.checkedFiles.filter(file => {
            const fileCollection = file.collection ? file.collection : [];

            if(fileCollection) {
                return fileCollection.includes(collections.target.dataset.key);
            }

            return [];
        });

        if(checkCollection) {
            checkCollection.forEach(collection => {
                if(process.querySelector('#' + collection.id)) {
                    process.querySelector('#' + collection.id).classList.add('highlight');
                }
            });
            setTooltipCollection(checkCollection.length);
            collections.target.classList.add('hover');
        }
    };

    const handleMouseCollectionLeave = (collections) => {
        const process = props.processRef.current;

        const checkTag = props.checkedFiles.filter(file => {
            const fileCollections = file.collection ? file.collection : [];

            if(fileCollections) {
                return fileCollections.includes(collections.target.dataset.key);
            }

            return [];
        });

        if(checkTag) {
            checkTag.forEach(collections => {
                if(process.querySelector('#' + collections.id)) {
                    process.querySelector('#' + collections.id).classList.remove('highlight');
                }
            });
            setTooltipCollection(0);
            collections.target.classList.remove('hover');
        }
    };

    return(
        <>
            {
                props.collectionMultipleSelection.map(collection => {
                    return <button className="chips-secondary" data-tooltip={`${tooltipCollection} of ${props.checkedFiles.length} files`} key={collection.uuid} data-key={collection.uuid} onMouseEnter={handleMouseCollectionEnter} onMouseLeave={handleMouseCollectionLeave} onClick={(e) => props.addCollection(e)}><span>{collection.name}</span><span><AddIcon /></span></button>;
                })
            }
        </>
    );
};

const UploadCollection = (props)=> {
    // const [collection, setCollection] = useState([]);
    // const [collectionKey, setCollectionKey] = useState(0);
    // const [collectionAsset, setCollectionAsset] = useState([]);
    // const [collectionMultipleSelection, setCollectionMultipleSelection] = useState([]);
    const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
    const [autocompleteDisabled, setAutocompleteDisabled] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const noExistingCollection = [{
        name: `${Localization.Alerts.noExistingCollection}`
    }];
    const isDesktop = IsDesktop();
    // Api Context
    const { getTenantCollection } = useContext(ApiContext);

    //Upload Context
    const { updateActiveSetting,
        updateFileCollection,
        collection,
        collectionKey,
        collectionAsset,
        collectionMultipleSelection,
        updateCollection,
        updateCollectionKey,
        updateCollectionAsset,
        updateCollectionMultipleSelection,
        collectionDescRef,
        collectionSameRef } = useContext(UploadContext);

    const filterAutoCompleteOptions = (options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        const filterOptions = options.filter((option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        if(filterOptions.length === 0) {
            const noCollectionFound = [{
                name: `${Localization.Alerts.NoCollectionFound}`
            }];

            return noCollectionFound;
        }

        return filterOptions;
    };

    const removeAllCollection = () => {
        updateFileCollection([], props.checkedFiles);
        updateCollection(prevCollection => {
            return prevCollection.map(item => {
                return {
                    ...item,
                    checked: false, // Update the desired property
                    mixed: false
                };
              });
        });
        updateCollectionAsset([]);
        updateCollectionMultipleSelection([]);
    };
    const addAllCollection = () => {        
        const collectionKeys = collectionMultipleSelection.map(item => item.uuid);
        const process = props.processRef.current;

        const checkCollection = props.checkedFiles.filter(file => {
            const fileCollection = file.collection ? file.collection : [];

            if(fileCollection) {
                collectionKeys.forEach(key => {
                    return fileCollection.includes(key);
                })
            }

            return [];
        });

        props.checkedFiles.forEach((checked) => {
            const attributeCollectionArr = checked.collection ? checked.collection : [];
            const newCheckedFileArr = [checked];

            collectionKeys.forEach(key => {
                for(let l = 0; l < attributeCollectionArr.length; l++) {
                    if (attributeCollectionArr[l] === key) {
                        attributeCollectionArr.splice(l, 1);
                    }
                }
                attributeCollectionArr.unshift(key);
            })
            updateFileCollection(attributeCollectionArr, newCheckedFileArr);
        });

        if(checkCollection.length > 0) {
            checkCollection.forEach(collection => {
                process.querySelector('#' + collection.id).classList.remove('highlight');
            });
        }

        const collection = props.checkedFiles.map(item => item.collection ? item.collection : []);
        mergeCollectionArrays(collection);

        updateCollection(prevCollection => {
            return prevCollection.map(item => {
                if (item.uuid === collectionKey) {
                  return {
                    ...item,
                    checked: true, // Update the desired property
                    mixed: false
                  };
                }
                return item;
              });
        });
    };

    const createCollectionAndAddAsset = (name, uuid) => {
        const collectionArray = collectionAsset;
        collectionArray.unshift({name: name, uuid: uuid});
        props.checkedFiles.forEach((checked) => {
            const attributeCollectionArr = checked.collection ? checked.collection : [];
            const newCheckedFileArr = [checked];

            for(let l = 0; l < attributeCollectionArr.length; l++) {
                if (attributeCollectionArr[l] === uuid) {
                    attributeCollectionArr.splice(l, 1);
                }
            }

            attributeCollectionArr.unshift(uuid);
            updateFileCollection(attributeCollectionArr, newCheckedFileArr);
        });
        updateCollectionAsset(collectionArray);
    }

    const filterCollectionByUUIDs = (data, uuids) => {
        const filteredArray = data.filter(obj => uuids.includes(obj.uuid));
        const modifiedArray = filteredArray.map(obj => {
          const { checked, ...rest } = obj;
          return rest;
        });
        return modifiedArray;
    };

    const updateCollectionChecked = (data, uuids) => {
        const modifiedCollection = data.map(obj => {
          if (uuids.includes(obj.uuid)) {
            return {
              ...obj,
              checked: true
            };
          }
          return obj;
        });
        return modifiedCollection;
    };

    const updateCollectionMixed = (data, uuids) => {
        const modifiedCollection = data.map(obj => {
          if (uuids.includes(obj.uuid)) {
            return {
              ...obj,
              checked: false,
              mixed: true
            };
          }
          return obj;
        });
        return modifiedCollection;
    };

    const updateCollectionData = (data) => {
        const collectionData = collection;
        collectionData.unshift(data);
        updateCollection(collectionData);
        updateCollectionKey(prevKey => prevKey + 1);
    };

    const mergeCollectionArrays = (arr) => {
        const merged = [].concat(...arr);
        const duplicates = new Set(arr[0]);

        for (let i = 1; i < arr.length; i++) {
            const subarray = arr[i];
            const subarraySet = new Set(subarray);

            for (const string of duplicates) {
                if (!subarraySet.has(string)) {
                    duplicates.delete(string);
                }
            }
        }

        if (Array.from(duplicates).length > 0) {
            const uniqueDuplicates = merged.filter(item => Array.from(duplicates).indexOf(item) === -1);
            const uniqueMerged = [...new Set(uniqueDuplicates)];
            const filterMergedData = filterCollectionByUUIDs(collection, uniqueMerged);
            const filterDuplicatesData = filterCollectionByUUIDs(collection, Array.from(duplicates));
            const updatedCollection = updateCollectionMixed(collection, uniqueMerged);
            updateCollectionAsset(filterDuplicatesData);
            updateCollectionMultipleSelection(filterMergedData);
            updateCollection(updatedCollection);
            return duplicates && uniqueMerged;
        } else {
            const uniqueMerged = [...new Set(merged)];
            const filterData = filterCollectionByUUIDs(collection, uniqueMerged);
            const updatedCollection = updateCollectionMixed(collection, uniqueMerged);
            updateCollectionMultipleSelection(filterData);
            updateCollection(updatedCollection);
            return merged;
        }
    };

    const collectionSelectOptions = (e) => {
        const checkbox = e.target.querySelector('input[type="checkbox"]');
        const checkboxMixed = e.target.querySelector('.form-check-input-mixed');
        let collectionArray = collectionAsset;

        if (checkbox) {
            setScrollPosition(dropdownRef.current.querySelector('.MuiAutocomplete-listbox').scrollTop);
            if(!checkboxMixed) {
                updateCollection(prevCollection => {
                    return prevCollection.map(item => {
                        if (item.uuid === checkbox.id) {
                          return {
                            ...item,
                            checked: !item.checked, // Update the desired property
                            mixed: false
                          };
                        }
                        return item;
                    });
                });
            } else {
                updateCollection(prevCollection => {
                    return prevCollection.map(item => {
                        if (item.uuid === checkbox.id) {
                          return {
                            ...item,
                            checked: true, // Update the desired property
                            mixed: false
                          };
                        }
                        return item;
                    });
                });
            }

            if(!checkbox.checked) {
                addCollection(checkbox.id);
                collectionArray.unshift({name: checkbox.dataset.name, uuid: checkbox.id});
            } else {
                removeCollection(checkbox.id);
                collectionArray = collectionAsset.filter(asset => asset.uuid !== checkbox.id);
            }
            updateCollectionAsset(collectionArray);
        }
    };

    const removeCollection = (data) => {
        const collectionKey = data.target ? data.target.closest('button').dataset.key : data;
        const collectionArray = collectionAsset.filter(asset => asset.uuid !== collectionKey);

        props.checkedFiles.forEach((checked) => {
            const attributeCollectionArr = checked.collection ? checked.collection : [];
            const newCheckedFileArr = [checked];
            const filteredCollectionArray = attributeCollectionArr.filter(item => item !== collectionKey);
            updateFileCollection(filteredCollectionArray, newCheckedFileArr);
        });

        updateCollectionAsset(collectionArray);
        updateCollection(prevCollection => {
            return prevCollection.map(item => {
                if (item.uuid === collectionKey) {
                  return {
                    ...item,
                    checked: false, // Update the desired property
                    mixed: false
                  };
                }
                return item;
            });
        });
    };

    const addCollection = (data) => {
        const collectionKey = data.target ? data.target.closest('button').dataset.key : data;
        const process = props.processRef.current;

        const checkCollection = props.checkedFiles.filter(file => {
            const fileCollection = file.collection ? file.collection : [];

            if(fileCollection) {
                return fileCollection.includes(collectionKey);
            }

            return [];
        });

        props.checkedFiles.forEach((checked) => {
            const attributeCollectionArr = checked.collection ? checked.collection : [];
            const newCheckedFileArr = [checked];

            for(let l = 0; l < attributeCollectionArr.length; l++) {
                if (attributeCollectionArr[l] === collectionKey) {
                    attributeCollectionArr.splice(l, 1);
                }
            }

            attributeCollectionArr.unshift(collectionKey);
            updateFileCollection(attributeCollectionArr, newCheckedFileArr);
        });

        if(checkCollection.length > 0) {
            checkCollection.forEach(collection => {
                process.querySelector('#' + collection.id).classList.remove('highlight');
            });
        }

        const collection = props.checkedFiles.map(item => item.collection ? item.collection : []);
        mergeCollectionArrays(collection);

        updateCollection(prevCollection => {
            return prevCollection.map(item => {
                if (item.uuid === collectionKey) {
                  return {
                    ...item,
                    checked: true, // Update the desired property
                    mixed: false
                  };
                }
                return item;
              });
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(collectionSameRef.current) {
                if (
                    inputRef.current &&
                    dropdownRef.current &&
                    !inputRef.current.contains(event.target) &&
                    !dropdownRef.current.contains(event.target) &&
                    !collectionSameRef.current.contains(event.target)
                ) {
                    setIsAutocompleteOpen(false);
                }
            } else if(collectionDescRef.current) {
                if (
                    inputRef.current &&
                    dropdownRef.current &&
                    !inputRef.current.contains(event.target) &&
                    !dropdownRef.current.contains(event.target) &&
                    !collectionDescRef.current.contains(event.target)
                ) {
                    setIsAutocompleteOpen(false);
                }
            } else {
                if (
                    inputRef.current &&
                    dropdownRef.current &&
                    !inputRef.current.contains(event.target) &&
                    !dropdownRef.current.contains(event.target)
                ) {
                    setIsAutocompleteOpen(false);
                }
            }
        };

        const getCollection = async () => {
            return await getStoreItem('all-collections', createStore('get-collections', 'all-collections')).then(async storeItem => {
                if(storeItem) {
                    storeItem.sort((a, b) => a.name.localeCompare(b.name));
                    setAutocompleteDisabled(false);
                    updateCollection(storeItem);
                } else {
                    await getTenantCollection().then((res) => {
                        setAutocompleteDisabled(false);

                        if(res && res.length > 0) {
                            const collectionData = res.map(item => ({
                                ...item,
                                checked: false
                            }));
                            const sortCollection = _.sortBy(collectionData, [function(o) { return o.name; }]);
                            updateCollection(sortCollection);
                        } else {
                            updateCollection([]);
                        }
                    }).catch((e) => {console.error(`fail to get collection`)});
                }
            });
        };

        getCollection();
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(dropdownRef.current) {
            dropdownRef.current.querySelector('.MuiAutocomplete-listbox').scrollTop = scrollPosition;
        }
    }, [collection]);

    useEffect(() => {
        updateCollectionAsset([]);
        updateCollectionMultipleSelection([]);
        if(props.checkedFiles.length > 0) {
            if(props.checkedFiles.length > 1) {
                const checkedCollection = props.checkedFiles.map(item => item.collection ? item.collection : []);
                mergeCollectionArrays(checkedCollection);
            } else {
                const collectionReset = collection.map(item => ({
                    ...item,
                    checked: false,
                    mixed: false
                }));
                const collectionArray = props.checkedFiles[0].collection ? props.checkedFiles[0].collection : [];
                const filteredData = filterCollectionByUUIDs(collectionReset, collectionArray);
                const updatedCollection = updateCollectionChecked(collectionReset, collectionArray);
                updateCollectionAsset(filteredData);
                updateCollection(updatedCollection);
            }
        }
    }, [props.checkedFiles]);

    return(
        <>
            <div className="upload-collection-wrapper">
                <div className='upload-offcanvas-header'>
                    <div>
                    <h3 className='mb-0'><button className='icon-button mobile-back-button' onClick={() => updateActiveSetting("")}><CustomIcons variant="arrow" direction="back"/></button> {Localization.Collection.Name}</h3>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={() => updateActiveSetting("")}><CancelIcon /></button>
                        </div>
                    </div>
                    <p className='paragraph-1'>{ props.checkedFiles.length > 0 ? `${props.checkedFiles.length} ${props.checkedFiles.length > 1 ? 'files' : 'file'} selected` : Localization.Collection.NoFilesSelected}</p>
                </div>
                {
                    props.checkedFiles.length > 0
                    ?
                        <>
                            <div className='upload-collection-added'>
                                <div className='upload-collection-added-header'>
                                    {
                                        (collectionAsset.length > 0) &&
                                            <div className='upload-collection-clear-all'>
                                                <button className='icon-button icon-button-text' onClick={() => removeAllCollection()}>
                                                    <CancelIcon /> { Localization.Collection.ClearAll}
                                                </button>
                                            </div>
                                    }
                                    {
                                        collectionMultipleSelection.length > 0
                                        ?
                                            <>
                                                {
                                                    collectionAsset.length > 0 && 
                                                    <>
                                                     <p className='paragraph-1 collection-header w-100'>{ props.checkedFiles.length > 1 ? Localization.Collection.CollectionHeader : "File is in the following collection(s):" }</p>
                                                    <div className='upload-collection-added-desc' ref={collectionDescRef}>
                                                        {
                                                            collectionAsset.map(collection => {
                                                                return <button className="chips-primary" key={collection.uuid} data-key={collection.uuid} ><span>{collection.name}</span><span onClick={(e) => removeCollection(e)}><CancelIcon /></span></button>;
                                                            })
                                                            // Localization.Collection.NoCollectionDesc.map((collection) => <p className='paragraph-1 mb-0'>{collection}</p>)
                                                        }
                                                    </div>
                                                    </>
                                                }
                                                <div className='upload-collection-add-all'>
                                                    <button className='icon-button icon-button-text' onClick={() => addAllCollection()}>
                                                        <AddIcon /> { Localization.Collection.AddAll}
                                                    </button>
                                                </div>
                                                <div className='upload-collection-same' ref={collectionSameRef}>
                                                    <div className='upload-collection-same-desc'>
                                                        <p className='paragraph-1'>{ Localization.Collection.SomeFileCollection }</p>
                                                    </div>
                                                    <div className='upload-tag-tagging-cont'>
                                                        <UploadChipsSecondary
                                                            processRef={props.processRef}
                                                            collectionMultipleSelection={collectionMultipleSelection}
                                                            checkedFiles={props.checkedFiles}
                                                            addCollection={addCollection} />
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            collectionAsset.length === 0
                                            ?
                                                <>
                                                    <p className='paragraph-1 collection-header'>{ props.checkedFiles.length > 1 ? Localization.Collection.CollectionHeader : "File is in the following collection(s):" }</p>
                                                    <div className='upload-collection-none'>
                                                        {
                                                            Localization.Collection.NoCollectionDesc.map((collection) => <p className='paragraph-1 mb-0' key={collection}>{collection}</p>)
                                                        }
                                                    </div>
                                                </>
                                            :
                                            <>
                                              <p className='paragraph-1 collection-header w-100'>{ props.checkedFiles.length > 1 ? Localization.Collection.CollectionHeader : "File is in the following collection(s):" }</p>
                                                <div className='upload-collection-added-desc' ref={collectionDescRef}>
                                                    {
                                                        collectionAsset.map(collection => {
                                                            return <button className="chips-primary" key={collection.uuid} data-key={collection.uuid}><span>{collection.name}</span><span onClick={(e) => removeCollection(e)}><CancelIcon /></span></button>;
                                                        })
                                                    }
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className='upload-offcanvas-form upload-offcanvas-form-collection'>
                                <div>
                                    {
                                        autocompleteDisabled &&
                                            <Spinner className='collection-spinner'/>
                                    }
                                    <Autocomplete
                                        key={collectionKey}
                                        style={{marginTop: '24px'}}
                                        options={collection.length > 0 ? collection : noExistingCollection}
                                        getOptionLabel={(option) => option.name}
                                        PopperComponent={(props) => <PopperComponent {...props} placement="top-start" open={isAutocompleteOpen} dropdownref={dropdownRef} updatecollectiondata={updateCollectionData} createcollectionandaddasset={createCollectionAndAddAsset} />}
                                        open={isAutocompleteOpen}
                                        disablePortal
                                        filterOptions={filterAutoCompleteOptions}
                                        autoFocus
                                        position="top"
                                        disabled={autocompleteDisabled}
                                        renderInput={(params) => (
                                            <TextField ref={inputRef} {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        !isDesktop ?
                                                        <button className='border-0' onClick={()=> setIsAutocompleteOpen(!isAutocompleteOpen)} style={{backgroundColor: 'transparent'}}><ArrowIcon /></button>
                                                        : <ArrowIcon />
                                                    )
                                                }}
                                                placeholder="Search or create a collection"
                                                onClick={() => {
                                                    if(dropdownRef.current) {
                                                        dropdownRef.current.querySelector('.MuiAutocomplete-listbox').scrollTop = scrollPosition;
                                                    }
                                                }}

                                                onFocus={() => setIsAutocompleteOpen(true)}
                                            />
                                        )}
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option.name, inputValue, { insideWords: true });
                                            const parts = parse(option.name, matches);
                                            const maxCollectionLength = Math.max(option.name.length);
                                            const isMatch = collectionAsset.some(asset => asset.name === option.name);

                                            if (isMatch) {
                                                option.checked = true;
                                            }

                                            if(option.name === `${Localization.Alerts.NoCollectionFound}` || option.name === `${Localization.Alerts.noExistingCollection}`) {
                                                return (
                                                    <>
                                                        <li {...props} disabled={true} style={{justifyContent: "center", pointerEvents: "none", padding: "0px", minHeight: !isDesktop ? 'auto' : ''}}>
                                                            <p className='paragraph-1 mb-0 spanish-gray--color'>{option.name}</p>
                                                        </li>
                                                    </>
                                                );
                                            }

                                            return (
                                                <>
                                                    <li {...props} className="form-poppper-item" onClick={collectionSelectOptions} style={{width: maxCollectionLength > 40 ? 'max-content' : ''}}>
                                                        <div className='d-flex flex-row'>
                                                            <Form.Check
                                                                className={option.mixed && 'form-check-input-mixed'}
                                                                type='checkbox'
                                                                data-name={option.name}
                                                                id={option.uuid}
                                                                defaultChecked={option.checked}
                                                            />
                                                            <span>{option.name}</span>
                                                            {/* {parts.map((part, index) => (
                                                                <span
                                                                    key={index}
                                                                    style={{
                                                                        color: 'black',
                                                                        whiteSpace: 'pre-wrap'
                                                                    }}
                                                                >
                                                                    {part.text}
                                                                </span>
                                                            ))} */}
                                                            <hr />
                                                        </div>
                                                    </li>
                                                </>
                                            );
                                        }}
                                    />
                                    <Form.Label className='collection-label'>Collection</Form.Label>
                                </div>
                            </div>
                        </>
                    :
                    <div className='upload-offcanvas-no-files'>
                        { Localization.Collection.SelectAFile }
                    </div>
                }
            </div>
        </>
    );
}

export default UploadCollection;