import { useState, useEffect, useContext, useRef } from 'react';
import { throttle } from 'lodash';
import { SessionContext } from '../../shared/context/session-provider';
import { ShareContext } from '../../shared/context/share-state';
import { ApiContext } from '../../shared/context/api-state';
import { black, IsTablet } from '../../shared/utils';
import { useDropzone } from 'react-dropzone';
import { UploadContext } from '../../shared/context/upload-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import AssetService from '../../../services/api/assetsService';
import _ from 'lodash';
import Table from 'react-bootstrap/Table';
import CustomIcons from '../../shared/components/custom-icons';
import GridViewIcon from '../../icons/grid-view';
import AssetCard from '../asset-card';
import AssetCardSkeleton from '../asset-card-skeleton';
import AssetFilter from '../asset-filter';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SettingNavbar from '../../shared/components/setting-navbar';
import MobileFilter from '../../shared/components/mobile-filter';
import MobileType from '../../shared/components/mobile-type';
import Skeleton from 'react-loading-skeleton';
import UploadIcon from '../../icons/upload';
import CropRoundedIcon from '../../icons/crop';
import ImageCropperModal from '../../shared/components/image-cropper-modal';

const SearchResultList = (props) => {
    // Account Context
    const { tenantUuid } = useContext(SessionContext);

    // Placeholder Skeleton Length
    const placeholderSkeleton = 4;

    const [data, setData] = useState([]);
    const [from, setFrom] = useState(0);

    const { selectedOption, selectedKeywords } = props;

    const { imageSimilarSearch }= useContext(ApiContext);

    const { mobileView,
        setMobileView,
        displayFilters,
        setDisplayFilters,
        clearFilters,
        view,
        setView,
        setFilterState,
        isMobileModalTypeOpen,
        isMobileModalFilterOpen,
        sortMobile,
        setSortByMobile,
        filters,
        currentPage,
        setCurrentPage,
        totalPages,
        loading,
        setLoading,
        fetching,
        setIsFetching,
        numFilters,
        type,
        isFilter,
        PAGE_SIZE,
        onClickFilters,
        getData,
        displaySearchItems,
        setDisplaySearchItems,
        minFileSize,
        maxFileSize,
        allSearchItems,
        setAllSearchItems,
        setType,
        sort,
        setFilters,
        setTotalPages,
        pdFrom,
        pdTo,
        resetDefaultState,
        resetState,
        handleMobileModalTypeOpen,
        handleMobileModalFilterOpen,
        applyFilters,
        setIsSearch,
        imageSearchUrl,
        isImageSearch,
        setIsImageSearchModalOpen,
        setIsImageSearchHeaderModalOpen,
        imageBase64,
        setImageBase64,
        imgSrc,
        setImgSrc,
        setImageSearchTotal,
        setTextSearchTotal,
     } = useContext(ShareContext);

     const { uploadImageFileData } = useContext(UploadContext);

    const [sortFilenameOrder, setSortFilenameOrder] = useState('asc');
    const [sortTypeOrder, setSortTypeOrder] = useState('asc');
    const [sortSizeOrder, setSortSizeOrder] = useState('asc');
    const [isCropping, setIsCropping] = useState(false);
    const imageCropperRef = useRef(null);

    const isTablet = IsTablet();

    const imageinputRef = useRef();


    function capitalizeEachWord(string) {
        if (!string) return string;
        return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    const searchAssets = async (filterType) => {
        let minFileSizeInBytes = null;
        let maxFileSizeInBytes = null;

        if (minFileSize != null) {
            minFileSizeInBytes = minFileSize * 1048576;
        }

        if (maxFileSize != null) {
            maxFileSizeInBytes = maxFileSize * 1048576;
        }

        let filterAssets = {
            group: tenantUuid,
            pageSize: 1000,
            query: selectedKeywords === undefined ? "" : capitalizeEachWord(selectedKeywords),
            types: filterType?.changeType ? filterType?.type : selectedOption || type,
            publishedDates: filterType?.clearFilter ? filterType?.publishedDates : [pdFrom, pdTo],
            modifiedDates: filterType?.clearFilter ? filterType?.mmodifiedDates : [null, null],
            minFileSize: filterType?.clearFilter ? filterType?.minFileSize : minFileSizeInBytes != null && minFileSizeInBytes > 0 ? minFileSizeInBytes : 0,
            maxFileSize: filterType?.clearFilter ? filterType?.maxFileSize : maxFileSizeInBytes != null && maxFileSizeInBytes > 0 ? maxFileSizeInBytes : undefined,
            sort: isTablet ? filterType?.clearFilter ? 1 : sortMobile : sort,
            sorting: filterType?.clearFilter ? 'desc' : sort === 1 ? 'desc' : 'asc',
            sortby: "timestamp",
            type: selectedOption || type
        };

        await AssetService.filterAssets(filterAssets).then((data) => {
            const assets = data.hits;
            const { total: allAssets, image = 0, document = 0, audio = 0, video = 0 } = data.typeCounts;        
            setTextSearchTotal({ allAssets, image, document, audio, video });
            setFilters(filterAssets);
            setAllSearchItems(assets ? assets : []);
            setData(assets ? assets : []);
            let pageData = getData(assets ? assets : [], filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setFrom(data.nextFrom);
            setTotalPages(pageData ? pageData.totalPages : 0);
            setDisplaySearchItems(pageData ? pageData.data : []);
            setIsFetching(false);
            setIsSearch(true);
            setLoading(false);
        });
    };

    const imageSimilarAnalyse = async (tenantUuid, imageBase64, filterType) => {
        let minFileSizeInBytes = null;
        let maxFileSizeInBytes = null;

        if (minFileSize != null) {
            minFileSizeInBytes = minFileSize * 1048576;
        }

        if (maxFileSize != null) {
            maxFileSizeInBytes = maxFileSize * 1048576;
        }

        let filterAssets = {
            group: tenantUuid,
            pageSize: PAGE_SIZE,
            types: filterType?.changeType ? filterType?.type : type,
            publishedDates: filterType?.clearFilter ? filterType?.publishedDates : [pdFrom, pdTo],
            minFileSize: filterType?.clearFilter ? filterType?.minFileSize : minFileSizeInBytes,
            maxFileSize: filterType?.clearFilter ? filterType?.maxFileSize : maxFileSizeInBytes,
            sort: isTablet ? filterType?.clearFilter ? 1 : sortMobile : sort
        };

        await imageSimilarSearch(tenantUuid, imageBase64, filterAssets).then((data) => {
            setType("image");
            const assets = data.response.hits;
            setIsImageSearchModalOpen(false);
            setIsImageSearchHeaderModalOpen(false);
            setImageSearchTotal({ image : data.response.total || 0});            
            setFilters(filterAssets);
            setAllSearchItems(assets ? assets : []);
            setData(assets ? assets : []);
            let pageData = getData(assets ? assets : [], filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setFrom(data.nextFrom);
            setTotalPages(pageData ? pageData.totalPages : 0);
            setDisplaySearchItems(pageData ? pageData.data : []);
            setIsFetching(false);
            setIsSearch(true);
            setLoading(false);
        });
    };

    const handleScroll = throttle(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;

        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= totalPages) {
                return;
            }
            loadItems();
        }
    }, 200);

    const loadItems = () => {
        if (fetching) {
            return;
        }

        setIsFetching(true);

        let page = currentPage + 1;

        if (page > PAGE_SIZE) {
            setIsFetching(false);
            return;
        }
        if (!imgSrc && !selectedKeywords) {
            return;
        }
        let pageData = getData(allSearchItems ,filters, page, PAGE_SIZE);
        setCurrentPage(page);
        setDisplaySearchItems([...displaySearchItems, ...pageData.data]);
        setIsFetching(false);

        return;
    };

    const changeView = (view) => {
        setView(view);
    };

    const handleSortByMobile = (value) => {
        setSortByMobile(value);
    }

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return <AssetCardSkeleton key={index}/>;
        });
    };

    const sortByFileName = () => {
        setSortFilenameOrder(sortFilenameOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const nameA = a.basename.toLowerCase();
            const nameB = b.basename.toLowerCase();

            return sortFilenameOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setDisplaySearchItems(sortedAsset);
    }

    const sortByType = () => {
        setSortTypeOrder(sortTypeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const typeA = a.type.toLowerCase();
            const typeB = b.type.toLowerCase();

            return sortTypeOrder === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        });

        setDisplaySearchItems(sortedAsset);
    }

    const sortBySize = () => {
        setSortSizeOrder(sortSizeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const sizeA = a.fileSize;
            const sizeB = b.fileSize;

            return sortSizeOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
        });

        setDisplaySearchItems(sortedAsset);
    }

    const renderPlaceholderSkeletonListView = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return(<div className='asset-card-skeleton-list-view col-12'>
                    <div>
                        <div className='skeleton-list-view'>
                            <div>
                                <Skeleton height={60} />
                            </div>
                            <div>
                                <Skeleton height={60} />
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            );
        });
    };

    const onDrop = async (files) => {
        setLoading(true);
        const response = await uploadImageFileData(files);

        if (!response.message) {
          if (typeof response.base64 === 'string') {
            // Call setImgSrc with the Base64 string
            setImageBase64(response.base64.split(',')[1]);
            setImgSrc(response.base64);
            imageSimilarAnalyse(tenantUuid, response.base64.split(',')[1], filters)
          }
        } else {
            updateToastMessage(`${response.message}`,"error");
        }

        setLoading(false);
    };

    const { updateToastMessage } = useContext(GlobalContext);

    const { getInputProps } = useDropzone({ onDrop,
        accept: {
          'image/png': [],
          'image/jpeg': [],
          'image/bmp': [],
          'image/tiff': []
        } });

    const handleImageSearchClick = () => {
        imageinputRef.current.click();
    }

    const handleCroppedImage = (url, base64) => {
      setImgSrc(url);
      setImageBase64(base64.split(',')[1]);
      imageSimilarAnalyse(tenantUuid, base64.split(',')[1])
      setIsCropping(false);
    };

    const handleImageCropClick = async () => {
      setIsCropping(true);
    }

    const handleCropCancelClick = async () => {
      setIsCropping(false);
    }

    const handleCropSaveClick = async () => {
      await imageCropperRef.current.makeClientCrop(); // Trigger the crop complete logic
      setIsCropping(false);
    };

    const handleSortBy = async (sortby) => {
        let minFileSizeInBytes = null;
        let maxFileSizeInBytes = null;

        if (minFileSize != null) {
            minFileSizeInBytes = minFileSize * 1048576;
        }

        if (maxFileSize != null) {
            maxFileSizeInBytes = maxFileSize * 1048576;
        }
        
        let filterAssets = {
            group: tenantUuid,
            pageSize: 1000,
            query: selectedKeywords === undefined ? "" : capitalizeEachWord(selectedKeywords),
            types: filters?.changeType ? filters?.type : selectedOption || type,
            publishedDates: filters?.clearFilter ? filters?.publishedDates : [pdFrom, pdTo],
            modifiedDates: filters?.clearFilter ? filters?.mmodifiedDates : [null, null],
            minFileSize: filters?.clearFilter ? filters?.minFileSize : minFileSizeInBytes != null && minFileSizeInBytes > 0 ? minFileSizeInBytes : 0,
            maxFileSize: filters?.clearFilter ? filters?.maxFileSize : maxFileSizeInBytes != null && maxFileSizeInBytes > 0 ? maxFileSizeInBytes : undefined,
            sort: isTablet ? filters?.clearFilter ? 1 : sortMobile : sort,
            sorting: filters?.clearFilter ? 'desc' : sort === 1 ? 'desc' : 'asc',
            sortby: "timestamp",
            type: selectedOption || type
        };

        if(sortby === "image") {
    
            await imageSimilarSearch(tenantUuid, imageBase64, filterAssets).then((data) => {
                setType("image");
                const assets = data.response.hits;
                setImageSearchTotal({ image : data.response.total || 0}); 
                setFilters(filterAssets);
                setAllSearchItems(assets ? assets : []);
                setData(assets ? assets : []);
                let pageData = getData(assets ? assets : [], filterAssets, 1, PAGE_SIZE);
                setCurrentPage(1);
                setFrom(data.nextFrom);
                setTotalPages(pageData ? pageData.totalPages : 0);
                setDisplaySearchItems(pageData ? pageData.data : []);
                setIsFetching(false);
                setIsSearch(true);
                setLoading(false);
            });
        } else if (sortby === "text") {
            await AssetService.filterAssets(filterAssets).then((data) => {
                const assets = data.hits;
                const { total: allAssets, image = 0, document = 0, audio = 0, video = 0 } = data.typeCounts;        
                setTextSearchTotal({ allAssets, image, document, audio, video });
                setFilters(filterAssets);
                setAllSearchItems(assets ? assets : []);
                setData(assets ? assets : []);
                let pageData = getData(assets ? assets : [], filterAssets, 1, PAGE_SIZE);
                setCurrentPage(1);
                setFrom(data.nextFrom);
                setTotalPages(pageData ? pageData.totalPages : 0);
                setDisplaySearchItems(pageData ? pageData.data : []);
                setIsFetching(false);
                setIsSearch(true);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        // Check if data has at least one item
        if (data && data.length > 0 && loading) {
          setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        resetDefaultState();

        if (!selectedKeywords && !isImageSearch) {
            setLoading(false);
            return;
        }

        const homeTab = document.getElementById('menu-tabs-id-tab-search');
        if (homeTab) {
            homeTab.className = homeTab.className.replace(' active', '')
        }
          setType(selectedOption === undefined?"":selectedOption);
    }, [selectedOption, selectedKeywords, sort, imgSrc, imageSearchUrl]);

    useEffect(() => {
        setIsImageSearchModalOpen(false);
        setIsImageSearchHeaderModalOpen(false);
        if(!loading && !isImageSearch) {
            searchAssets(filters);
        }

        if(!loading && isImageSearch) {
            imageSimilarAnalyse(tenantUuid, imageBase64, filters)
        }
    }, [resetState]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <div className="container search-result-list">
            <section className="pt-5 pb-5">
                <h3 className='search-title'>
                    {imgSrc && selectedKeywords.length === 0 ? (
                        "Image analysis"
                    ) : (
                        "Search Results"
                    )}
                </h3>
                <input
                    className="upload-inputs d-none"
                    {...getInputProps()}
                    type="file"
                    accept="image/png, image/jpeg, image/bmp, image/tiff"
                    capture="environment"
                    ref={imageinputRef}
                />
                {!loading && !imageSearchUrl && imgSrc && selectedKeywords.length === 0 && (
                <>
                    <div className='search-image-container'>
                        <img src={imgSrc} alt="My File" />
                    </div>
                    <div className='search-image-action-container'>
                        <a href="#" onClick={handleImageCropClick}><CropRoundedIcon color={black} /></a>
                        <a href="#" onClick={handleImageSearchClick}><UploadIcon color={black} /></a>
                    </div>
                </>
                )}
                {imageSearchUrl && selectedKeywords.length === 0 && (
                <>
                    <div className='search-image-container'>
                        <img src={imageSearchUrl} alt="My File" />
                    </div>
                    <div className='search-image-action-container'>
                        <a href="#" onClick={handleImageCropClick}><CropRoundedIcon color={black} /></a>
                        <a href="#" onClick={handleImageSearchClick}><UploadIcon color={black} /></a>
                    </div>
                </>
                )}
                <h2 className="h2-mb-48 search-keyword">{selectedKeywords}</h2>
                <div className="row asset-view-container">
                    <div className="mobile-type-active-container" onClick={() => { handleMobileModalTypeOpen(); setDisplayFilters(false); setFilterState(false); }}>
                        <h3 id="mobile-type-active">
                            { type === '' && 'All Assets' }
                            { type === 'image' && 'Images' }
                            { type === 'video' && 'Videos' }
                            { type === 'document' && 'Documents' }
                            { type === 'audio' && 'Audio' }
                        </h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-filter-container" onClick={() => { handleMobileModalFilterOpen(); onClickFilters(); }}>
                        <h3>Filters {numFilters !== 0 && <span style={{color: "black !important"}}>{`(${numFilters})`}</span>}</h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-view-container">
                        <div className={`mobile-view-icon ${mobileView ? "hide" : "show"}`} onClick={() => { setMobileView(true); changeView('grid'); }}><GridViewIcon /></div>
                        <div className={`mobile-view-icon ${mobileView ? "show" : "hide"}`} onClick={() => { setMobileView(false); changeView('row'); }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                            </svg>
                        </div>
                    </div>
                    {
                        !loading ? <SettingNavbar searchResult={selectedKeywords} handleSortBy={handleSortBy} /> : null
                    }
                </div>
            </section>
            {
                displayFilters && !isTablet &&
                    <section className={view === 'row' ? 'pb-5' : 'pb-5'}>
                        <AssetFilter clear={clearFilters} applyFilters={applyFilters} show={displayFilters} searchResult={selectedKeywords} imgSrc={imgSrc}/>
                    </section>
            }
            <section>
                { view === 'row'? <div className='assets-container'>
                    {
                        !loading ? displaySearchItems.length > 0 &&
                            <Table className="table-assets">
                                <thead>
                                    <tr className='table-dark'>
                                        <th scope="col">
                                        </th>
                                        <th scope="col" className='paragraph-1' onClick={sortByFileName}>
                                            Filename
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col" onClick={sortByType}>
                                            Type
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col" onClick={sortBySize}>
                                            Size
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading ? displaySearchItems?.length > 0 && (displaySearchItems && displaySearchItems?.length > 0) && displaySearchItems.map(file => {
                                            let assetFile = {
                                                uuid: file.uuid,
                                                basename: file.basename,
                                                timestamp: file.timestamp,
                                                type: file.type,
                                                size: file.fileSize,
                                                bookmarks:file.bookmarks,
                                                mime: file.mime,
                                                overallStatus: file.overallStatus
                                            }
                                            return (<AssetCard
                                                displayItems={displaySearchItems}
                                                view={view}
                                                key={assetFile.uuid}
                                                bookmarks={assetFile.bookmarks}
                                                file={assetFile}
                                                menuTabKey={props.menuTabKey}
                                                handleViewAssetOpen={props.handleViewAssetOpen}
                                                handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                        }) :
                                        renderPlaceholderSkeleton()
                                    }
                                </tbody>
                            </Table> : renderPlaceholderSkeletonListView()
                    }
                    {!loading && (displaySearchItems?.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) &&
                        <tr>
                            <div className='no-results'>
                                <p>No results found for "{selectedKeywords}"</p>
                                <ul>
                                    <li>Check and ensure your spelling is correct</li>
                                    <li>Try using different keywords</li>
                                    <li>Keep your search query simple</li>
                                </ul>
                            </div>
                        </tr>
                    }
                </div> : <></> }
                { view === 'grid'?
                    <div className="assets-container g-3">
                        <div className='row'>
                            {!loading && (displaySearchItems.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) && !isFilter &&
                                <>
                                    <div className='no-results'>
                                        {selectedKeywords ? (
                                            <>
                                            <p>No results found for "{selectedKeywords}"</p>
                                            <ul>
                                                <li>Check and ensure your spelling is correct</li>
                                                <li>Try using different keywords</li>
                                                <li>Keep your search query simple</li>
                                            </ul>
                                            </>
                                        ) : (
                                            <>
                                            <p>No results found</p>
                                            <ul>
                                                <li>Ensure your image is clear</li>
                                                <li>Try searching using a different image</li>
                                            </ul>
                                            </>
                                        )}
                                    </div>
                                </>
                            }
                            {
                                !loading && (displaySearchItems.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) && isFilter &&
                                    <>
                                        <div className='no-cards-found'>
                                            <p className='font-bold'>No results found</p>
                                            <ul>
                                                <li>Check and ensure your inputs and selections are valid</li>
                                                <li>Try inputting and/or selecting other valid Filters options</li>
                                            </ul>
                                        </div>
                                    </>
                            }
                            {
                                !loading ? displaySearchItems.length > 0 && (displaySearchItems && displaySearchItems?.length > 0) && displaySearchItems.map(file => {
                                    let assetFile = {
                                        uuid: file.uuid,
                                        basename: file.basename,
                                        timestamp: file.timestamp,
                                        type: file.type,
                                        size: file.fileSize,
                                        bookmarks:file.bookmarks,
                                        mime: file.mime,
                                        overallStatus: file.overallStatus
                                    }

                                    return (
                                        <AssetCard
                                            displayItems={displaySearchItems}
                                            view={view}
                                            key={assetFile.uuid}
                                            bookmarks={assetFile.bookmarks}
                                            file={assetFile}
                                            menuTabKey={props.menuTabKey}
                                            handleViewAssetOpen={props.handleViewAssetOpen}
                                            handleRemoveFileModal={props.handleRemoveFileModal}
                                        />
                                    )
                                }) : renderPlaceholderSkeleton()
                            }
                        </div>

                    </div>
                : <></> }
            </section>

            {
                isMobileModalTypeOpen && isTablet && (
                    <MobileType searchResult={selectedKeywords}/>
                )
            }
            {
                isMobileModalFilterOpen && isTablet && (
                    <MobileFilter hasSort={true} searchResult={selectedKeywords}/>
                )
            }
            {
                isCropping && (
                    <>
                        <ImageCropperModal
                            src={imgSrc}
                            modalShow={isCropping}
                            onCropComplete={handleCroppedImage}
                            handleCropCancelClick={handleCropCancelClick}
                            handleCropSaveClick={handleCropSaveClick}
                            imageCropperRef={imageCropperRef}
                        />
                    </>
                )
            }
        </div>
    )
}

export default SearchResultList;