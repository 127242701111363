import Skeleton from 'react-loading-skeleton';

const BookmarkCardSkeleton = ({view}) => {
    return(
        <div className="bookmark-card-skeleton">
            <div>
                {
                    view === 'single' ?
                    <>
                        <div>
                            <div className="ratio ratio-16x9">
                                <Skeleton />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <div className="ratio ratio-16x9">
                                <Skeleton />
                            </div>
                        </div>
                        <div>
                            <div className="ratio ratio-16x9">
                                <Skeleton />
                            </div>
                            <div className="ratio ratio-16x9">
                                <Skeleton />
                            </div>
                        </div>
                    </>
                }
            </div>
            <Skeleton width="50%" height="23px" className="mb-3 mt-3"/>
        </div>
    );
};

export default BookmarkCardSkeleton;