
import { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { IsMobile } from '../../../utils';
import { SessionContext } from '../../../context/session-provider';
import { ShareContext } from '../../../context/share-state';
import { GlobalContext } from '../../../../shared/context/global-provider';
import Localization from '../../../localization';
import Chips from '../../chips';
import Skeleton from 'react-loading-skeleton';
import DOMPurify from 'dompurify'
import StripeService from '../../../../../services/api/stripeService';
import SolutionManifest from '../../../../../solution-manifest';
import CustomInput from '../../forms/custom-input';
import SpinnerIcon from '../../../../icons/spinner';
import UsersService from '../../../../../services/api/usersService';
import TooltipComponent from '../../tooltip';
import CustomIcons from '../../custom-icons';
import MobileModal from '../../mobile-modal';

const DisplayPlan = (props) => {
    // Tenant Uuid
    const { tenantUuid, userUuid } = useContext(SessionContext);

    // Total Users
    const { totalUsers } = useContext(ShareContext);

    // Subscription
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const [cancelSubscriptionButton, setCancelSubscriptionButton] = useState(true);
    const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);
    const amountDueToolTipContent = (
        <div style={{ whiteSpace: 'pre-line' }}>      
            {`${Localization.Plan.PaidPlan.Tooltip.AmountDue}`}    
        </div>
    );

    // Extent Free Trial
    const [extentFreeTrialLoading, setExtentFreeTrialLoading] = useState(false);
    const { updateToastMessage } = useContext(GlobalContext);
    const isMobile = IsMobile();

    // Mobile Modal Tooltip
    const [ismobileModalTooltipOpen, setIsMobileModalTooltipOpen] = useState(false);

    // Mobile Modal Type
    const [mobileModalType, setMobileModalType] = useState("");

    // Mobile Modal Type
    const [mobileModalContent, setMobileModalContent] = useState("");

    const handleCancelSubscription = async () => {
        setCancelSubscriptionLoading(true);
        setCancelSubscriptionButton(true);
        const stripeCancelSubscription = await StripeService.stripeCancelSubscription(props.customerId, tenantUuid, userUuid);

        if(stripeCancelSubscription.code === 200) {
            props.updatePaidPlan(null);
            setCancelSubscription(false);
            updateToastMessage('Subscription plan cancelled','success');
            const stripePaidPlan = await StripeService.stripePaidPlan(props.customerId, tenantUuid);

            if(stripePaidPlan.code === 200) {
                props.updatePaidPlan(stripePaidPlan.response);
            }
        }
    };

    const handleFreeTrialExtension = async (tenantUuid) => {

        setExtentFreeTrialLoading(true);

        const extentFreeTrial = await UsersService.extentFreeTrial(tenantUuid, userUuid);

        if(extentFreeTrial.code === 200) {
            props.updateFreeTrialPlan(null);
            const freeTrial = await UsersService.freeTrial(tenantUuid);

            if (freeTrial.code === 200) {
                props.updateFreeTrialPlan(freeTrial.response);
                updateToastMessage(Localization.Plan.FreeTrial.TrialExtensionSuccess, 'success');
            }
        };

        setExtentFreeTrialLoading(false);

        if(extentFreeTrial.code === Localization.Plan.FreeTrial.TrialExtensionError.name) {
            updateToastMessage(Localization.Plan.FreeTrial.TrialExtensionError.error, 'error');
        };
    };

    const handleMobileModalType = async (type, content) => {
        setMobileModalType(type);
        setMobileModalContent(content);
        handleMobileModalTooltipOpen();
    };

    const handleMobileModalTooltipOpen = () => {
        setIsMobileModalTooltipOpen(true);
    }

    const handleMobileModalTooltipClose = () => {
        setIsMobileModalTooltipOpen(false);
    }

    return(<>
        <div className='settings-plan-details'>
                {
                    // Free Trial
                    props.subscriptionPlan === SolutionManifest.SubscriptionPlan.FreeTrialId &&
                        <>
                            <div className='settings-plan-details-title'>
                                <h2>{Localization.Plan.FreeTrial.Title}</h2> <Chips text="Active"/>
                            </div>
                            <div className='settings-plan-details-plan'>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                            <>
                                                <span>{Localization.Plan.FreeTrial.StartDate}</span>
                                                <span>{props.planFreeTrial.start_trial_time}</span>
                                            </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }

                                </div>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                        <>
                                            <span>{Localization.Plan.FreeTrial.EndDate}</span>
                                            <span>{props.planFreeTrial.end_trial_time}</span>
                                        </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }
                                </div>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                        <>
                                            {
                                                props.planFreeTrial?.trial_days_remaining === 0
                                                ?
                                                    <span>{Localization.Plan.FreeTrial.TimeRemaining}</span>
                                                :
                                                    <span>{Localization.Plan.FreeTrial.DaysRemaining}</span>
                                            }
                                            <span>
                                                {
                                                    props.planFreeTrial.trial_days_remaining === 0
                                                    ?
                                                        props.planFreeTrial.trial_time_remaining
                                                    :
                                                        props.planFreeTrial.trial_days_remaining
                                                }
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }
                                </div>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                        <>
                                            <span>{Localization.Plan.FreeTrial.TrialExtension}</span>
                                            <span>{props.planFreeTrial.trial_extension}</span>
                                        </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }
                                </div>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                        <>
                                            <span>{Localization.Plan.FreeTrial.NumberUsers}</span>
                                            <span>{totalUsers}</span>
                                        </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }
                                </div>
                                <div>
                                    {
                                        props.planFreeTrial
                                        ?
                                        <>
                                            <span>{Localization.Plan.FreeTrial.BillingAmount}</span>
                                            <span>{Localization.Plan.FreeTrial.FreeTrialAmount}</span>
                                        </>
                                        :
                                        <>
                                            <span><Skeleton width="40%" height="24px"/></span>
                                            <span><Skeleton width="50%" height="24px"/></span>
                                        </>
                                    }
                                </div>
                            </div>
                            <p className='paragraph-1'>
                                {
                                            props.planFreeTrial && props.planFreeTrial.trial_extension > 0
                                            ?
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(isMobile ? Localization.Plan.FreeTrial.MobileDesc : Localization.Plan.FreeTrial.Desc)}}></span>
                                            :
                                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(isMobile ? Localization.Plan.FreeTrial.MobileTrialDesc : Localization.Plan.FreeTrial.TrialDesc)}}></span>

                                }
                            </p>
                            {
                                props.planFreeTrial && props.planFreeTrial.trial_extension > 0 &&
                                    <Button variant="primary" className="btn-sm" disabled={extentFreeTrialLoading} onClick={() => handleFreeTrialExtension(tenantUuid)}>
                                        {Localization.Plan.FreeTrial.Btn}
                                        {
                                            extentFreeTrialLoading &&
                                                <SpinnerIcon />
                                        }
                                    </Button>
                            }
                        </>
                }
                {
                    // Paid Plan
                    props.subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                        <>
                            <div className='settings-plan-details-title'>
                                {
                                    props.planPaidPlan?.planName
                                    ?
                                        <>
                                            <h2>{props.planPaidPlan?.planName}</h2> {props.planPaidPlan?.status === 'active' ? <Chips text="Active"/> : <Chips text="Expiring" colorClass="red"/>}
                                        </>
                                    :
                                        <Skeleton width="250px" height="32px" style={{ marginBottom: "28px"}}/>
                                }
                            </div>
                            <div className={props.planPaidPlan && props.planPaidPlan?.status === 'active' ? 'settings-plan-details-plan-cont active' : 'settings-plan-details-plan-cont expiring'}>
                                <div className='settings-plan-details-plan'>
                                    <div>
                                        {
                                            props.planPaidPlan
                                            ?
                                                <>
                                                    <span>{Localization.Plan.PaidPlan.SubscriptionTerm}</span>
                                                    <span>{isMobile ? 'Monthly' : Localization.Plan.PaidPlan.MonthlyTerm}</span>
                                                </>
                                            :
                                                <>
                                                    <span><Skeleton width="40%" height="24px"/></span>
                                                    <span><Skeleton width="50%" height="24px"/></span>
                                                </>
                                        }
                                    </div>
                                    <div>
                                        {
                                            props.planPaidPlan
                                            ?
                                                <>
                                                    <span>{Localization.Plan.PaidPlan.StartDate}</span>
                                                    <span>{props.planPaidPlan.startDate}</span>
                                                </>
                                            :
                                                <>
                                                    <span><Skeleton width="40%" height="24px"/></span>
                                                    <span><Skeleton width="50%" height="24px"/></span>
                                                </>
                                        }
                                    </div>
                                    <div>
                                        {
                                            props.planPaidPlan
                                            ?
                                                props.planPaidPlan.status === 'active'
                                                ?
                                                    <>
                                                        <span>{Localization.Plan.PaidPlan.RenewalDate}</span>
                                                        <span>{props.planPaidPlan.renewalDate}</span>
                                                    </>
                                                :
                                                    <>
                                                        <span>{Localization.Plan.PaidPlan.ExpiryDate}</span>
                                                        <span>{props.planPaidPlan.subscriptionEnd}</span>
                                                    </>
                                            :
                                                <>
                                                    <span><Skeleton width="40%" height="24px"/></span>
                                                    <span><Skeleton width="50%" height="24px"/></span>
                                                </>
                                        }
                                    </div>
                                    <div>
                                        {
                                            props.planPaidPlan
                                            ?
                                            <>
                                                <span>{Localization.Plan.PaidPlan.NumberUsers}</span>
                                                <span>
                                                    {
                                                        props.planPaidPlan
                                                        ?
                                                            totalUsers
                                                        :
                                                            <Skeleton width="50%" height="24px"/>
                                                    }
                                                </span>
                                            </>
                                            :
                                                <>
                                                    <span><Skeleton width="40%" height="24px"/></span>
                                                    <span><Skeleton width="50%" height="24px"/></span>
                                                </>
                                        }
                                    </div>
                                    <div>
                                        {
                                            props.planPaidPlan
                                            ?
                                                props.planPaidPlan.status === 'active'
                                                ?
                                                    <>
                                                        <span>{Localization.Plan.PaidPlan.BillingAmount}</span>
                                                        <span>{props.planPaidPlan.amount}</span>
                                                    </>
                                                :
                                                    <>
                                                        <span>{Localization.Plan.PaidPlan.CancellationDate}</span>
                                                        <span>{props.planPaidPlan.cancelAt}</span>
                                                    </>
                                            :
                                                <>
                                                    <span><Skeleton width="40%" height="24px"/></span>
                                                    <span><Skeleton width="50%" height="24px"/></span>
                                                </>
                                        }
                                    </div>
                                    {
                                        props.planPaidPlan
                                        ?
                                            (props.planPaidPlan.status === 'active' )
                                            ?
                                                <div>
                                                    <span>{Localization.Plan.PaidPlan.Credit}{isMobile ? <a className='tooltip-component' onClick={() => handleMobileModalType(Localization.Plan.PaidPlan.Credit,Localization.Plan.PaidPlan.Tooltip.Credit)}><CustomIcons variant='help'/></a> : <a className='tooltip-component' data-tooltip-id="credit-tooltip"><CustomIcons variant='help'/></a>}</span>
                                                    <span>{props.planPaidPlan.credit}</span>
                                                </div>
                                            :
                                                null
                                        :
                                            <div>
                                                <span><Skeleton width="40%" height="24px"/></span>
                                                <span><Skeleton width="50%" height="24px"/></span>
                                            </div>

                                    }
                                    {
                                        props.planPaidPlan
                                        ?
                                            (props.planPaidPlan.status === 'active')
                                            ?
                                                <div>
                                                    <span>{Localization.Plan.PaidPlan.AmountDueModal}{isMobile ? <a className='tooltip-component' onClick={() => handleMobileModalType(Localization.Plan.PaidPlan.AmountDueModal,Localization.Plan.PaidPlan.Tooltip.AmountDue)}><CustomIcons variant='help'/></a> : <a className='tooltip-component' data-tooltip-id="amountdue-tooltip"><CustomIcons variant='help'/></a>}</span>
                                                    <span>{props.planPaidPlan.amountdue}</span>
                                                </div>
                                            :
                                                null
                                        :
                                            <div>
                                                <span><Skeleton width="40%" height="24px"/></span>
                                                <span><Skeleton width="50%" height="24px"/></span>
                                            </div>

                                    }
                                </div>
                                {
                                    props.planPaidPlan
                                    ?
                                        props.planPaidPlan.status === 'active'
                                        ?
                                            <div className={`settings-plan-details-plan-desc active ${cancelSubscription ? 'd-none' : null}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.PaidPlan.SubscriptionDesc.replace(/\${renewalDate}/g, props.planPaidPlan?.renewalDate))}}></div>
                                        :
                                            <div className={`settings-plan-details-plan-desc expiring`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.PaidPlan.SubscriptionExpiryDesc.replace(/\${expiryDate}/g, props.planPaidPlan?.subscriptionEnd))}}></div>
                                    :
                                        <Skeleton width="100%" height="24px"/>
                                }
                            </div>
                            {
                                props.planPaidPlan && props.planPaidPlan.status === 'active' && !cancelSubscription &&
                                    <button className='text-button cancel-subscription' onClick={() => setCancelSubscription(!cancelSubscription)}>{Localization.Plan.PaidPlan.CancelSubscription.Title}</button>
                            }
                            {
                                props.planPaidPlan
                                ?
                                    cancelSubscription &&
                                        <div className='settings-plan-details-cancel-subscription'>
                                            <h3>{Localization.Plan.PaidPlan.CancelSubscription.Title}</h3>
                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.PaidPlan.CancelSubscription.Desc.replace(/\${expiryDate}/g, props.planPaidPlan?.subscriptionEnd ?? props.planPaidPlan?.renewalDate))}}></p>
                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.PaidPlan.CancelSubscription.CancelMySubscription)}}></p>
                                            <CustomInput
                                                handleCustomInputChange={(event) => event.target.value === Localization.Plan.PaidPlan.CancelSubscription.Input.Placeholder ? setCancelSubscriptionButton(false) : setCancelSubscriptionButton(true)}
                                                label={Localization.Plan.PaidPlan.CancelSubscription.Input.Label}
                                                placeholder={Localization.Plan.PaidPlan.CancelSubscription.Input.Placeholder}
                                                type="text"
                                                validated
                                            />
                                            <div className='button-group'>
                                                <Button variant='secondary' className="btn-sm" disabled={cancelSubscriptionLoading} onClick={() => (setCancelSubscription(false), setCancelSubscriptionButton(true)) }>{Localization.Plan.PaidPlan.CancelSubscription.Button.Secondary}</Button>
                                                <Button variant='primary' className="btn-sm" disabled={cancelSubscriptionButton} onClick={handleCancelSubscription}>{Localization.Plan.PaidPlan.CancelSubscription.Button.Primary}
                                                    {
                                                        cancelSubscriptionLoading &&
                                                            <SpinnerIcon />
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                :
                                    <Skeleton width="100%" height="24px"/>
                            }
                            <TooltipComponent id="credit-tooltip" content={Localization.Plan.PaidPlan.Tooltip.Credit} placement="right"/>
                            <TooltipComponent id="amountdue-tooltip" content={amountDueToolTipContent} placement="right"/>
                        </>

                }
                {
                    ismobileModalTooltipOpen &&
                        <MobileModal title={mobileModalType} onClose={handleMobileModalTooltipClose}>
                            <div className='mobile-modal-tooltip'>
                                { mobileModalContent }
                                <div className='mobile-modal-tooltip-button'>
                                    <Button variant='primary' onClick={handleMobileModalTooltipClose}>{ Localization.Plan.PaidPlan.Tooltip.MobileBtn }</Button>
                                </div>
                            </div>
                        </MobileModal>
                }
        </div>
    </>);
};

export default DisplayPlan;
