import React from 'react';
import { IsMobile } from '../../utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ImageCropper from '../image-cropper';

const ImageCropperModal = (props) => {
    const isMobile = IsMobile();

    return(
        <Modal show={props.modalShow} onHide={props.handleModalClose} className='image-cropper-modal'>
            <Modal.Body>
                <div className='image-cropper-section'>
                    <ImageCropper ref={props.imageCropperRef} src={props.src} onCropComplete={props.onCropComplete} />
                </div>
                <div className='image-cropper-footer'>
                    <Button variant='secondary' onClick={props.handleCropCancelClick}>Cancel</Button>
                    <Button variant='primary' onClick={props.handleCropSaveClick}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ImageCropperModal;