import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShareContext } from '../../context/share-state';
import { ApiContext } from '../../context/api-state';
import { SessionContext } from '../../context/session-provider';
import path from '../../routePaths';

const RelatedImages = (props) => {
    if(!props.assetsEdit && props.relatedImages.length === 0){ 
        return null;}
    return (        
        <div className='related-images-wrapper related-images'>
            <h2 className="title-heading">Related images</h2>
            <div className="image-card-container">
                {props.relatedImages.map(file => {
                    let assetFile = {
                        uuid: file.uuid,
                        basename: file.basename,
                        timestamp: file.timestamp,
                        type: file.type,
                        size: file.fileSize,
                        mime: file.mime,
                        overallStatus: file.overallStatus
                    };
                    return (
                        <RelatedImagesCard 
                            displayItems={props.relatedImages} 
                            key={file.uuid} 
                            file={assetFile} 
                        />
                    );
                })}
            </div>
        </div>
    );
};

const RelatedImagesCard = ({ file }) => {
    const { updateMenuTabKey } = useContext(ShareContext);
    const pathPrefix = "/";
    const navigate = useNavigate();

    const [imageUrl, setImageUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileUUID, setFileUUID] = useState(null);
    const [status, setStatus] = useState(null);

    const { getAssetsById } = useContext(ApiContext);
    const { tenantUuid } = useContext(SessionContext);

    useEffect(() => {
        if (file.overallStatus === 'COMPLETED') {
            getAssetsById(file.uuid, tenantUuid).then(asset => {
                if (asset) {
                    setFileUUID(asset.uuid);
                    setStatus(asset.overallStatus);
                    if (asset.type === 'image') {
                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setFileName(asset.key);
                    }
                } else {
                    setImageUrl(null);
                }
            });
        } else {
            setStatus(file.overallStatus);
        }
    }, [file, getAssetsById, tenantUuid]);

    const handleViewAsset = () => {
        updateMenuTabKey(`${path.ASSET}${pathPrefix}${fileUUID}`);
        navigate(`${pathPrefix}${path.ASSET}${pathPrefix}${fileUUID}`);
    };

    const truncateFirstText = (fulltext) => {
        return fulltext.slice(0, -4 + (get_file_extension(fulltext).length * -1)); // Remove the last 3 or 4 characters and the extension
    };

    const get_file_extension = (filename) => {
        const parts = filename.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : null;
    };

    const truncateEndText = (fulltext) => {
        const extension = "." + get_file_extension(fulltext);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    };

    const Card = () => {
        return (
            <>
                {status !== 'COMPLETED' ? null : (
                    <div className="image-card" onClick={() => handleViewAsset(fileUUID)}>
                        <img src={imageUrl} alt="related" />
                        <div className="image-card-filename">
                            <div className='title-container'>
                                <div className="first-text">{truncateFirstText(fileName)}</div>
                                <div className="end-text">{truncateEndText(fileName)}</div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return <Card />;
};

export default RelatedImages;
