import React from 'react';
import { useState, useContext } from "react";
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ShareContext } from '../../../context/share-state';

const CustomCheckbox = ({ bookmarkKey, label, validated, disabled, customCheck}) => {
    const [checkedName, setCheckedName] = useState(customCheck);
    const { 
        allBookmark, 
        selectedAssetUuid, 
        setSelectedBookmarkName, 
        setDeselectedBookmarkName } = useContext(ShareContext);

    const handleCustomCheckbox = (event) => {
        const isChecked = event.target.checked;
        setCheckedName(isChecked);
    
        // Set selection into array
        setSelectedBookmarkName(prevState => {
            if (isChecked) {
                // Add the label if checked
                const updatedState = new Set([...prevState, label]);
                
                // Remove the label from deselected list if it exists
                setDeselectedBookmarkName(prevDeselected => {
                    const deselectedBookmarks = new Set(prevDeselected);
                    const lowercaseDeselectedBookmarks = new Set([...deselectedBookmarks].map(item => item.toLowerCase()));

                    if (lowercaseDeselectedBookmarks.has(label.toLowerCase())) {
                        deselectedBookmarks.delete([...deselectedBookmarks].find(item => item.toLowerCase() === label.toLowerCase()));
                    }

                    return [...deselectedBookmarks];
                });

                return [...updatedState];
            } else {
                // Remove the label if existing bookmark was unchecked
                const updatedState = prevState.filter(item => item.toLowerCase() !== label.toLowerCase());

                const bookmarkAsset = allBookmark
                    ?.find(bookmark => bookmark.name.toLowerCase() === label.toLowerCase())
                    ?.assets?.some(asset => asset.uuid === selectedAssetUuid);

                if (bookmarkAsset) {
                    // Save the deselected label into deselectedBookmarkName
                    setDeselectedBookmarkName((prevDeselected) => {
                        const deselectedBookmarks = new Set([...prevDeselected, label]);
                        return [...deselectedBookmarks];
                    });
                }

                return updatedState;
            }
        });
    };
    
    return(
        <>
            <div className={validated ? "form-group" : "form-group form-error"} key={bookmarkKey} >
                <Form.Check
                    key={bookmarkKey}
                    onChange={handleCustomCheckbox}
                    type='checkbox'
                    checked={checkedName}
                    id={label}
                    label={label}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
    bookmarkKey: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validated: PropTypes.bool,
    disabled: PropTypes.bool
};


