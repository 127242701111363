import { useState, useEffect, useContext, useRef } from 'react';
import { Nav, Tab, Badge, Button } from 'react-bootstrap';
import { IsMobile, IsTablet } from '../../shared/utils';
import { SessionContext } from '../../shared/context/session-provider';
import { useLocation, useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { AdditionalUserOffcanvasProvider } from '../../shared/context/additional-user-offcanvas-provider';
import { ShareContext } from '../../shared/context/share-state';
import { getStoreItem, setStoreItem, createStore, getAllStoreItems } from '../../../store';
import ArrowIcon from "../../icons/arrow";
import UserRequest from '../user-request';
import UserList from '../user-list';
import UsersService from '../../../services/api/usersService';
import Invoices from '../invoices';
import Plan from '../plan';
import AuditTrail from '../audit-trail';
import StripeService from "../../../services/api/stripeService";
import SolutionManifest from '../../../solution-manifest';
import NotificationBanner from '../../shared/components/notification-banner';
import CustomIcons from '../../shared/components/custom-icons';
import DOMPurify from 'dompurify';
import Localization from '../../shared/localization';
import path from '../../shared/routePaths';
import MyAssets from '../my-assets';
import MobileModal from '../../shared/components/mobile-modal';

const SettingsView = () => {
    const { setUserListExpandedRows,
        setUserRequestExpandedRowKeys,
        setSelectAllUserList,
        setSelectedUserListKeys,
        setSelectAllRequestChecked,
        setCheckedRequestUser,
        setSelectAllUserListCount,
        setOpenRequestFilter,
        setOpenUserListFilter,
        isMobileModalOpen,
        handleMobileModalOpen,
        handleMobileModalClose,
        resetDefaultState,
        planPaidPlan,
        setPlanPaidPlan,
        planFreeTrial,
        setPlanFreeTrial,
        invoices,
        setInvoices,
        paymentMethods,
        setPaymentMethods,
        productPlan,
        setProductPlan,
        userRequestPending,
        setUserRequestPending,
        userRequestApproved,
        setUserRequestApproved,
        userRequestRejected,
        setUserRequestRejected,
        setTabKey,
        setView,
        clearFilters,
        originalData,
        setDisplayMyAssetItems,
        setFilterSearchData,
        setSearchQuery,
        setActiveType,
        setCurrentPage,
        tablePageSize,
        setTablePendingData,
        tablePendingData,
        setDropdownMenuCheckedRole,
        categoryType,
        setSelectedCategory,
        setApplyCategory,
        setFilteredCategoryData,
        setDays,
        setAuditSelectedOption,
        setAuditExpandedRowKeys
    } = useContext(ShareContext);

    // Session Context
    const { tenantUuid,
        isCustomerAdmin,
        isUserAdmin,
        isAssetOwner,
        subscriptionPlan,
        userRoles,
        productUrl,
        customerId,
        setCustomerId } = useContext(SessionContext);

    // Notification Banner
    const [notificationBannerShow, setNotificationBannerShow] = useState(false);
    const [notificationBannerContent, setNotificationBannerContent] = useState("");
    const [notificationBannerVariant, setNotificationBannerVariant] = useState("");
    const pathPrefix = '/';

    const [isUsers, setIsUsers] = useState("");
    const [isPlanManagement, setIsPlanManagement] = useState("");
    const [userPendingCount, setUserPendingCount] = useState("");
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    // Url
    const location = useLocation();
    const navigate = useNavigate();

    // Tab
    const [activeTab, setActiveTab] = useState("");

    //Nav Link Ref
    const navLinkUserList = useRef();
    const navLinkUserRequest = useRef();
    const navLinkMyAssets = useRef();
    const navLinkPlan = useRef();
    const navLinkInvoices = useRef();
    const navLinkAuditTrail = useRef();

    const updateNotificationBanner = (notificationBannerShow, notificationBannerContent, notificationBannerVariant) => {
        setNotificationBannerShow(notificationBannerShow);
        setNotificationBannerContent(notificationBannerContent);
        setNotificationBannerVariant(notificationBannerVariant);
    };

    const updateInvoices = (invoice) => {
        setInvoices(invoice);
    };

    const updateUserRequestPending = (newUserRequest) => {
        setUserRequestPending(newUserRequest);
    };

    const updateUserRequestApproved = (newUserRequest) => {
        setUserRequestApproved(newUserRequest);
    };

    const updateUserRequestRejected = (newUserRequest) => {
        setUserRequestRejected(newUserRequest);
    };

    const listPaymentMethods = async (customer_id) => {
        const stripeGetPaymentMethods = await StripeService.stripeGetPaymentMethods(customer_id, tenantUuid);
        if(stripeGetPaymentMethods.code === 200) {
            setPaymentMethods(stripeGetPaymentMethods.response);

            return stripeGetPaymentMethods;
        }
    };

    const updatePaymentMethods = (newPaymentMethods) => {
        setPaymentMethods(newPaymentMethods);
    }

    const updateFreeTrialPlan = (newPlan) => {
        setPlanFreeTrial(newPlan);
    };

    const updatePaidPlan = (newPlan) => {
        setPlanPaidPlan(newPlan);
    };

    const checkStore = async (startingAfter = null) => {
        const checkFirstInvoice = await StripeService.stripeListInvoice(customerId, tenantUuid, 1, startingAfter);

        if(checkFirstInvoice.code === 200) {
            const getInvoice = await getStoreItem(checkFirstInvoice.response[0].invoice_id, createStore('invoices', 'all-invoices'));

            if(getInvoice) {
                return true;
            }

            return false;
        }
    };

    const fetchNextInvoices = async (startingAfter = null) => {
        StripeService.stripeListInvoice(customerId, tenantUuid, tablePageSize * 2, startingAfter).then(stripeInvoices => {
            if (stripeInvoices.code === 200) {
                setInvoices(prevInvoices => {
                    const allInvoices = [...prevInvoices, ...stripeInvoices.response];

                    allInvoices.forEach((invoice) => {
                        setStoreItem(invoice.invoice_id, invoice, createStore('invoices', 'all-invoices'));
                    });

                    allInvoices.sort((a, b) => {
                        const dateA = new Date(a.issue_date);
                        const dateB = new Date(b.issue_date);
                        return dateB - dateA;
                    });
                    return allInvoices;
                });

                if(stripeInvoices.hasMore === true) {
                    fetchNextInvoices(stripeInvoices.startingAfter);
                }
            }
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const activeTab = queryParams.get('tab');

        // Check if references are defined before accessing properties
        if (
            navLinkUserList?.current ||
            navLinkMyAssets?.current ||
            navLinkPlan?.current ||
            navLinkUserRequest?.current ||
            navLinkInvoices?.current ||
            navLinkAuditTrail?.current
        ) {
            // Define a function to handle tab click
            const handleTabClick = (tabRef, tabKey) => {
                if (tabRef.current && (tabRef.current.getAttribute('data-rr-ui-event-key') === activeTab)) {
                    tabRef.current.click();
                }
            };

            // Use the function to handle tab clicks
            handleTabClick(navLinkUserList, 'user-list');
            handleTabClick(navLinkMyAssets, 'my-assets');
            handleTabClick(navLinkPlan, 'plan');
            handleTabClick(navLinkUserRequest, 'user-request');
            handleTabClick(navLinkInvoices, 'invoices');
            handleTabClick(navLinkAuditTrail, 'audit-trail');
        }

        // Set active tab based on query parameter
        if (isCustomerAdmin) {
            const validTabs = ['user-list', 'user-request', 'my-assets', 'plan', 'invoices', 'audit-trail'];
            setActiveTab(validTabs.includes(activeTab) ? activeTab : 'user-list');
        } else if (isAssetOwner) {
            setActiveTab(activeTab === 'my-assets' ? activeTab : 'my-assets');
        } else {
            setActiveTab('user-list');
        }
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let stripeCustomerId = customerId;

                resetDefaultState();

                if((userRoles && subscriptionPlan && tenantUuid && !stripeCustomerId) && (userRoles.includes(SolutionManifest.Roles.CustomerAdmin) && subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId)) {
                    const stripeId = await StripeService.stripeGetId(tenantUuid);

                    if(stripeId.code === 200) {
                        stripeCustomerId = stripeId.response.customer_id;
                        setCustomerId(stripeId.response.customer_id);
                    }
                }

                // Fetch user requests
                if ((activeTab === 'user-list' || activeTab === 'user-request') && (!userRequestPending.length > 0 ||
                    !userRequestApproved.length > 0 ||
                    !userRequestRejected.length > 0)) {
                    UsersService.listUserRequest(tenantUuid).then(userRequest => {
                        if (userRequest.code === 200) {
                            setUserRequestPending(userRequest.response.pending);
                            setUserRequestApproved(userRequest.response.approved);
                            setUserRequestRejected(userRequest.response.rejected);
                        }
                    })
                }

                if (isCustomerAdmin) {
                    if (stripeCustomerId && subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId) {
                        // Fetch paid plan
                        if (activeTab === 'plan') {
                            StripeService.stripePaidPlan(stripeCustomerId, tenantUuid).then(stripePaidPlan => {
                                if (stripePaidPlan.code === 200) {
                                    setPlanPaidPlan(stripePaidPlan.response);
                                }
                            });
                        }

                        // Fetch invoices
                        if (activeTab === 'invoices') {
                            const store = await checkStore();
                            if(store) {
                                const allInvoices = await getAllStoreItems(createStore('invoices', 'all-invoices'));
                                allInvoices.sort((a, b) => {
                                    const dateA = new Date(a.issue_date);
                                    const dateB = new Date(b.issue_date);
                                    return dateB - dateA;
                                });
                                setInvoices(allInvoices);
                            } else {
                                fetchNextInvoices();
                            }
                        }

                        // Fetch payment methods
                        if (activeTab === 'plan') {
                            listPaymentMethods(stripeCustomerId);
                        }
                    }

                    // Fetch free trial
                    if (subscriptionPlan === SolutionManifest.SubscriptionPlan.FreeTrialId && !planFreeTrial) {
                         // Fetch product plan

                        if(activeTab === 'plan') {
                            await StripeService.stripeListProductPlan().then(stripeListProductPlan => {
                                if (stripeListProductPlan.code === 200) {
                                    setProductPlan(stripeListProductPlan.data[0]);
                                }
                            })

                            UsersService.freeTrial(tenantUuid).then(freeTrial => {
                                if (freeTrial.code === 200) {
                                    setPlanFreeTrial(freeTrial.response);
                                    const trial_days_remaining = freeTrial.response.trial_days_remaining;
                                    const trial_extension = freeTrial.response.trial_extension;
                                    const shouldShowError = trial_days_remaining <= 3;

                                    const generateNotificationContent = (type, linkText, linkUrl, linkTarget) => {
                                        const trial_time_remaining = freeTrial.response.trial_time_remaining;
                                        const errorVariant = shouldShowError ? <CustomIcons variant="error" color="#D80029" /> : null;

                                        return (
                                            <div>
                                                {errorVariant}
                                                <span className="desktop-notification-content-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                                                    Localization.Plan.FreeTrial.NotificationBanner[type].replace(
                                                        /\${trial_days_remaining}/g,
                                                        trial_days_remaining === 0 ? `<strong>${trial_time_remaining}</strong>` : `<strong>${trial_days_remaining} days</strong>`
                                                    )
                                                )}}></span>
                                                <span className="mobile-notification-content-text">
                                                    Free trial ends in <strong>{trial_days_remaining === 0 ? `${trial_time_remaining}` : trial_days_remaining === 1 ? `${trial_days_remaining} day` :`${trial_days_remaining} days`}</strong>
                                                </span>
                                                {linkText && <a href={linkUrl} target={linkTarget} className="btn btn-primary btn-sm">{linkText}</a>}
                                            </div>
                                        );
                                    };

                                    let notificationContent;

                                    if (isCustomerAdmin) {
                                        if (trial_extension === 1) {
                                            notificationContent = generateNotificationContent('CustomerAdmin', 'Manage plan', `${pathPrefix + path.SETTINGS}?tab=plan`);
                                        } else {
                                            notificationContent = generateNotificationContent('Extended', 'Register', productUrl, '_blank');
                                        }
                                    } else {
                                        notificationContent = generateNotificationContent('User');
                                    }

                                    updateNotificationBanner(true, notificationContent, shouldShowError ? 'error' : undefined);
                                }
                            });
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [activeTab]);

    const handleNavLinkClick = (eventKey) => {
        setUserListExpandedRows([]);
        setUserRequestExpandedRowKeys([]);
        setSelectAllUserList(false);
        setSelectedUserListKeys([]);
        setSelectAllUserListCount(0);
        setSelectAllRequestChecked(false);
        setCheckedRequestUser([]);
        setOpenRequestFilter(false);
        setOpenUserListFilter(false);
        setIsUsers(eventKey);
        setIsPlanManagement(eventKey);
        setUserRequestPending([]);
        handleMobileModalClose();
        setView('grid');
        clearFilters();
        setFilterSearchData([]);
        setSearchQuery("");
        setActiveType("all");
        setDisplayMyAssetItems(originalData);
        setCurrentPage(1);
        setTabKey('pending');
        setSelectedCategory(categoryType);
        setApplyCategory(categoryType);
        setFilteredCategoryData([]);
        setDays(30);
        setAuditSelectedOption(1);
        setAuditExpandedRowKeys([]);
        setUserRequestPending(() => {
            const newState = userRequestPending;
            return newState;
        });

        const updatedTableData = tablePendingData.map(item => ({
            ...item,
            checked: false
        }));
        setTablePendingData(updatedTableData);
        setDropdownMenuCheckedRole({});

        // Set Active Tab
        if (isCustomerAdmin) {
            const validTabs = ['user-list', 'user-request', 'my-assets', 'plan', 'invoices', 'audit-trail'];
            setActiveTab(validTabs.includes(eventKey) ? eventKey : 'user-list');
        } else if (isAssetOwner) {
            setActiveTab(eventKey === 'my-assets' ? eventKey : 'my-assets');
        } else {
            setActiveTab(eventKey);
        }
        const route = `${pathPrefix + path.SETTINGS}?tab=${eventKey}`;
        navigate(route, { replace: true });
    };

    return (
        activeTab &&
            <>
                <section className="continer-fluid settings-view-wrapper d-flex align-items-center">
                    <div className="container">
                        <Tab.Container className="container" id="user-tabs" defaultActiveKey={activeTab}>
                            <div className="row setting-row">
                                <div className="col-sm-2">
                                    <div className={`settings-group`}>
                                        <div className="form-container">
                                            <div className="form-group settings-buttons">

                                                {(isCustomerAdmin || isUserAdmin) && (
                                                        <>
                                                            {isTablet && (activeTab === "user-list" || activeTab === "user-request") &&
                                                                <>
                                                                    <div className='mobile-title-container'>
                                                                        <h3 className='mobile-title-sub-text'>User Management</h3>
                                                                        <Button
                                                                            onClick={() => { handleMobileModalOpen(); }}
                                                                            variant="default">
                                                                            {activeTab === "user-list" ? (isUsers === "user-request" ? "User Request" : "User List") : (isUsers === "user-list" ? "User List" : "User Request")}
                                                                            <span className='d-flex align-items-center'>
                                                                            {isMobileModalOpen ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                                                            </span>
                                                                        </Button>
                                                                    </div>
                                                                    {
                                                                        isMobileModalOpen && (
                                                                                    <MobileModal title={'User Management'} onClose={handleMobileModalClose}>
                                                                                        <div className="mobile-modal-more-vertical">
                                                                                            <Nav variant="pills" className="flex-column" style={{ transform: 'translate(-7px, 4px)' }}>
                                                                                                {/* <Nav.Item>
                                                                                                    <Nav.Link className="paragraph-1" eventKey="user-group">User Group</Nav.Link>
                                                                                                </Nav.Item> */}
                                                                                                <Nav.Item>
                                                                                                    <Nav.Link className="paragraph-1" eventKey="user-list" onClick={() => handleNavLinkClick("user-list")}>User List</Nav.Link>
                                                                                                </Nav.Item>
                                                                                                <Nav.Item>
                                                                                                    <Nav.Link className="paragraph-1" eventKey="user-request" onClick={() => handleNavLinkClick("user-request")}>User Request {userRequestPending.length > 0 && <Badge>{userRequestPending.length > 10 ? '10+' : userRequestPending.length}</Badge>}</Nav.Link>
                                                                                                </Nav.Item>
                                                                                            </Nav>
                                                                                        </div>
                                                                                    </MobileModal>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                            <div className={`user-management ${isTablet && 'd-none'}`}>
                                                                <p className="title-sub-text">User Management</p>
                                                                <Nav variant="pills" className="flex-column" style={{ transform: 'translate(-7px, 4px)' }}>
                                                                    {/* <Nav.Item>
                                                                        <Nav.Link className="paragraph-1" eventKey="user-group">User Group</Nav.Link>
                                                                    </Nav.Item> */}
                                                                    <Nav.Item>
                                                                        <Nav.Link className="paragraph-1" eventKey="user-list" ref={navLinkUserList} onClick={() => handleNavLinkClick("user-list")}>User List</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link className="paragraph-1" eventKey="user-request" ref={navLinkUserRequest} onClick={() => handleNavLinkClick("user-request")}>User Request {userRequestPending.length > 0 && <Badge>{userRequestPending.length > 10 ? '10+' : userRequestPending.length}</Badge>}</Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                            </div>

                                                        </>
                                                )}
                                                {(isCustomerAdmin || isUserAdmin || isAssetOwner) && (
                                                     <>
                                                        {isTablet && activeTab === "my-assets" &&
                                                            <div className='mobile-title-container'>
                                                                <h3 className='mobile-title-sub-text'>Asset Management</h3>
                                                                <h2>My Assets</h2>
                                                            </div>
                                                        }
                                                        <div className={`asset-management ${isTablet && 'd-none'}`}>
                                                            <p className="title-sub-text">Asset Management</p>
                                                            <Nav variant="pills" className="flex-column" style={{ transform: 'translate(-7px, 4px)' }}>
                                                                <Nav.Item>
                                                                    <Nav.Link className="paragraph-1" eventKey="my-assets" ref={navLinkMyAssets} onClick={() => handleNavLinkClick("my-assets")}>My Assets</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                      </>
                                                )}
                                                {(isCustomerAdmin) && (
                                                    <>
                                                    {isTablet && (activeTab === "plan" || activeTab === "invoices" || activeTab === "audit-trail") &&
                                                            <>
                                                                <div className='mobile-title-container'>
                                                                    <h3 className='mobile-title-sub-text'>Plan Management</h3>
                                                                    {subscriptionPlan === "freeTrial" ?
                                                                        <h2>Plan</h2>
                                                                    :
                                                                    <>
                                                                    <Button
                                                                        onClick={() => { handleMobileModalOpen(); }}
                                                                        variant="default">
                                                                         {activeTab === "plan" ? (isPlanManagement === "invoices" ? "Invoices" : isPlanManagement === "audit-trail" ? "Audit Trail" : "Plan") : activeTab === "invoices" ? (isPlanManagement === "plan" ? "Plan" : isPlanManagement === "audit-trail" ? "Audit Trail" : "Invoices") : (isPlanManagement === "plan" ? "Plan" : isPlanManagement === "invoices" ? "Invoices" : "Audit Trail")}
                                                                        <span className='d-flex align-items-center'>
                                                                        {isMobileModalOpen ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                                                        </span>
                                                                    </Button>
                                                                    </>
                                                                    }
                                                                </div>
                                                                {
                                                                    isMobileModalOpen && (
                                                                                <MobileModal title={'Plan Management'} onClose={handleMobileModalClose}>
                                                                                    <div className="mobile-modal-more-vertical">
                                                                                    <Nav variant="pills" className="flex-column" style={{ transform: 'translate(-7px, 4px)' }}>
                                                                                            <Nav.Item>
                                                                                                <Nav.Link className="paragraph-1" eventKey="plan" onClick={() => handleNavLinkClick("plan")}>Plan</Nav.Link>
                                                                                            </Nav.Item>
                                                                                            {
                                                                                                subscriptionPlan === "paidPlan" &&
                                                                                                <>
                                                                                                    <Nav.Item>
                                                                                                        <Nav.Link className="paragraph-1" eventKey="invoices" onClick={() => handleNavLinkClick("invoices")}>Invoices</Nav.Link>
                                                                                                    </Nav.Item>
                                                                                                </>
                                                                                            }
                                                                                            <Nav.Item>
                                                                                               <Nav.Link className="paragraph-1" eventKey="audit-trail" onClick={() => handleNavLinkClick("audit-trail")}>Audit Trail</Nav.Link>
                                                                                           </Nav.Item>
                                                                                        </Nav>
                                                                                    </div>
                                                                                </MobileModal>
                                                                    )
                                                                }
                                                            </>
                                                        }
                                                        <div className={`plan-management ${isTablet && 'd-none'}`}>
                                                            <p className="title-sub-text">Plan Management</p>
                                                            <Nav variant="pills" className="flex-column" style={{ transform: 'translate(-7px, 4px)' }}>
                                                                <Nav.Item>
                                                                    <Nav.Link className="paragraph-1" eventKey="plan" ref={navLinkPlan} onClick={() => handleNavLinkClick("plan")}>Plan</Nav.Link>
                                                                </Nav.Item>
                                                                {
                                                                    subscriptionPlan === "paidPlan" &&
                                                                    <>
                                                                        <Nav.Item>
                                                                            <Nav.Link className="paragraph-1" eventKey="invoices" ref={navLinkInvoices} onClick={() => handleNavLinkClick("invoices")}>Invoices</Nav.Link>
                                                                        </Nav.Item>
                                                                    </>
                                                                }
                                                                <Nav.Item>
                                                                    <Nav.Link className="paragraph-1" eventKey="audit-trail" ref={navLinkAuditTrail} onClick={() => handleNavLinkClick("audit-trail")}>Audit Trail</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10">
                                    <div className={`list-group`}>
                                        <Tab.Content>
                                            {(isCustomerAdmin || isUserAdmin || isAssetOwner) && (
                                            <>
                                                {/* <Tab.Pane eventKey="user-group">
                                                    <UserGroup />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="user-list">
                                                    <UserList
                                                        updatePaidPlan={updatePaidPlan}
                                                        updateUserRequestPending={updateUserRequestPending}
                                                        updateUserRequestApproved={updateUserRequestApproved}
                                                        updateUserRequestRejected={updateUserRequestRejected}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="user-request">
                                                    <AdditionalUserOffcanvasProvider>
                                                        <UserRequest
                                                            userRequestPending={userRequestPending}
                                                            userRequestApproved={userRequestApproved}
                                                            userRequestRejected={userRequestRejected}
                                                            updateUserRequestPending={updateUserRequestPending}
                                                            updateUserRequestApproved={updateUserRequestApproved}
                                                            updateUserRequestRejected={updateUserRequestRejected}
                                                            updatePaidPlan={updatePaidPlan}
                                                            updateInvoices={updateInvoices}
                                                        />
                                                    </AdditionalUserOffcanvasProvider>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="my-assets">
                                                    <MyAssets />
                                                </Tab.Pane>
                                                {
                                                    isCustomerAdmin &&
                                                        <Tab.Pane eventKey="plan">
                                                            <Plan
                                                                updateFreeTrialPlan={updateFreeTrialPlan}
                                                                updatePaidPlan={updatePaidPlan}
                                                                customerId={customerId}
                                                                subscriptionPlan={subscriptionPlan}
                                                                planFreeTrial={planFreeTrial}
                                                                productPlan={productPlan}
                                                                planPaidPlan={planPaidPlan}
                                                                paymentMethods={paymentMethods}
                                                                listPaymentMethods={listPaymentMethods}
                                                                updatePaymentMethods={updatePaymentMethods} />
                                                        </Tab.Pane>
                                                }
                                                {
                                                    isCustomerAdmin && subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                                                    <>
                                                        <Tab.Pane eventKey="invoices">
                                                            <Invoices invoices={invoices} />
                                                        </Tab.Pane>
                                                    </>
                                                }
                                                {
                                                    isCustomerAdmin &&
                                                    <>
                                                        <Tab.Pane eventKey="audit-trail">
                                                            <AuditTrail />
                                                        </Tab.Pane>
                                                    </>
                                                }
                                            </>
                                            )}
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </ Tab.Container>
                    </div>
                </section>
                {
                    (isUserAdmin && userRoles.length === 1) &&
                        notificationBannerShow &&
                            <NotificationBanner content={notificationBannerContent} variant={notificationBannerVariant}/>
                }
            </>

    )
}

export default SettingsView;