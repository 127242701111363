import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoJS = (props) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const {options, onReady} = props;

    useEffect(() => {
      // Make sure Video.js player is only initialized once
      if (videoRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = videoRef.current;

        const player = videojs(videoElement, options, () => {
          onReady && onReady(player);
        });
      }
    }, []);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (
      <div data-vjs-player>
        <video ref={videoRef} className="custom-vjs video-js vjs-big-play-centered" muted={options.muted} preload="auto" playsInline>
          <source src={options.sources.src} type={options.sources.type} />
        </video>
      </div>
    );
}

export default VideoJS;