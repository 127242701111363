import React, { createContext, useState, useEffect, useContext } from "react";
import metaService from "../../../../services/api/metaService";
import { SessionContext } from "../session-provider";

const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
    const [assets, setAssets] = useState([]);
    const [collections, setCollections] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);
    const [users, setUsers] = useState([]);
    const [userBookmarks, setUserBookmarks] = useState([]);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);

    const fetchMetadata = async () => {
        const metaResponse = await metaService.getMetaData(tenantUuid);

        const assets = {
            allAssets: metaResponse.response.allAssets || 0,
            image: metaResponse.response.image || 0,
            video: metaResponse.response.video || 0,
            document: metaResponse.response.document || 0,
            audio: metaResponse.response.audio || 0
        }
        setAssets(assets);
        setCollections(metaResponse.response.collections || 0);
        setBookmarks(metaResponse.response.bookmarks || 0);
        setUsers(metaResponse.response.users || 0);
        setUserBookmarks(metaResponse.response.userBookmarks[userUuid] || 0);
    };

    const refreshMetadata = async () => {
        await fetchMetadata();
    };

    useEffect(() => {
        fetchMetadata();
    }, []);

    return (
        <MetadataContext.Provider value={{ assets, setAssets, collections, bookmarks, setBookmarks, users, setUsers, setCollections, userBookmarks, setUserBookmarks, refreshMetadata }}>
            {children}
        </MetadataContext.Provider>
    );
};

export const useMetadata = () => {
    const context = useContext(MetadataContext);
    if (!context) throw new Error("useMetadata must be used within MetadataProvider");
    return context;
};
