import { useState, useEffect, useRef, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { sanitizeInput } from '../../../utils';
import { ApiContext } from '../../../context/api-state';
import { SessionContext } from '../../../context/session-provider';
import { getStoreItem, removeStoreItem, setStoreItem, createStore } from '../../../../../store';
import { GlobalContext } from '../../../context/global-provider';
import { IsTablet } from '../../../utils';
import { ShareContext } from '../../../context/share-state';
import Localization from '../../../localization';
import "react-loading-skeleton/dist/skeleton.css";
import CustomIcons from "../../custom-icons";
import AssetService from '../../../../../services/api/assetsService';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import MovieIcon from '@mui/icons-material/Movie';
import VideoJS from '../../../videojs';
import DocumentPdf from "../../document-pdf"
import Skeleton from 'react-loading-skeleton';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMetadata } from '../../../context/meta-provider';

const EditAssets = (props) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [textFocus, setTextFocus] = useState(false);
    const [TextAreaFocus, setTextAreaFocus] = useState(false);
    const [TextAreaFocusDes, setTextAreaFocusDes] = useState(false);
    const [tagName, setTagName] = useState("");
    const [tagNameChar, setTagNameChar] = useState(0);
    const [filesName, setFilesName] = useState("");
    const [newCollection, setNewCollection] = useState("");
    const [newCollectionError, setNewCollectionError] = useState("");
    const [collectionCreating, setCollectionCreating] = useState(false);
    const [filesDesc, setFilesDesc] = useState("");
    const [filesDescChar, setFilesDescChar] = useState(0);
    const [charLimit, setCharLimit] = useState("");
    const [filesNameChar, setFilesNameChar] = useState(0);
    const [tenantData, setTenantData] = useState("");
    const [collectionShowCreate, setCollectionShowCreate] = useState(false);
    const [newCollectionLimit, setNewCollectionLimit] = useState(0);
    const [currentAsset, setCurrentAsset] = useState("");
    const [assignTags, setAssignTags] = useState("");
    const [aiSmartTags, setAiSmartTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getTenantCollection, getAssetCollections } = useContext(ApiContext);
    const { updateToastMessage } = useContext(GlobalContext);
    const { tenantUuid, userUuid } = useContext(SessionContext);
    const { setDisplayCollectionItems,
            editCollections,
            setEditCollections,
            removedCollections,
            setRemovedCollections,
            assetCollections } = useContext(ShareContext);
    const [searchQuery, setSearchQuery] = useState('');
    const inputRef = useRef(null);
    const inputRefCollection = useRef(null);
    const dropdownRef = useRef(null);
    const nameCharLimit = 256;
    const descCharLimit = 300;
    const newCollectionCharLimit = 50;
    const tagCharLimit = 50;
    
    // Refresh MetaData
    const { setCollections } = useMetadata();

    const isTablet = IsTablet();
    const navigate = useNavigate();
    const pathPrefix = "/";

    const filesNameCharLimitsChange = (char) => {
        setFilesNameChar(char.target.value.length);
        setFilesName(char.target.value);

    };
    const filesNameCharLimitsBlur = (char) => {
        setCharLimit("");
        if (char.target.value.length === 0) {
            setFilesName(currentAsset ? currentAsset.name : props.name);
            setFilesNameChar(currentAsset ? currentAsset.name.length : props.name.length);
        }
    }
    const charLimitsShow = (e, type) => setCharLimit(type);

    const filesDescCharLimitsChange = (char) => {
        setFilesDescChar(char.target.value.length);
        setFilesDesc(char.target.value);
    }

    const filesDescCharLimitsBlur = () => setCharLimit("");

    const newCollectionChange = (char) => {
        const regex = /[.<>'":;[\]{}|\\+=/?,]/g;
        const sanitizedValue = sanitizeInput(regex, char.target.value);
        setNewCollection(sanitizedValue);
        setNewCollectionLimit(sanitizedValue.length);
        setNewCollectionError("");
    };

    const handleDropdown = () => {
        setOpenDropdown(true);
    };
    const handleDropdownArrow = () => {
        setOpenDropdown(!openDropdown);
        if (!openDropdown) {
            inputRefCollection.current.focus();
        }
    };
    useEffect(() => {
        if (openDropdown && dropdownRef.current) {
          dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [openDropdown]);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenDropdown(false);
            setCollectionShowCreate(false);
            setNewCollection("");
            setNewCollectionLimit(0);
            setNewCollectionError("");
        }
    };
    const checkCollections = (label) => {
        setEditCollections((prevCollections) => {
            const isChecked = Array.isArray(prevCollections) && prevCollections.some((editLabel) => editLabel.uuid === label.uuid);
            if (isChecked) {
                addRemovedCollection(label);
                return prevCollections.filter((editLabel) => editLabel.uuid !== label.uuid);
            } else {
                return [...prevCollections, label];
            }
        });
        setOpenDropdown(true);
        inputRefCollection.current.focus();
        setSearchQuery('');
    };
    const addRemovedCollection = (newlabelCollection) => {
        setRemovedCollections(prevCollections => {
            // Check if newlabelCollection matches any assetCollection by name or uuid
            const existsInAssetCollections = assetCollections.some(asset => 
                asset.name === newlabelCollection.name || asset.uuid === newlabelCollection.uuid
            );
    
            if (existsInAssetCollections) {
                return [...prevCollections, newlabelCollection];
            }
            // If not included, return the previous collections unchanged
            return prevCollections;
        });
    };
    const newCollectionCreate = async () => {
        setCollectionCreating(true);

        if (newCollection === '') {
            setNewCollectionError('empty');
            setCollectionCreating(false);
        } else {
            setNewCollectionError('');
            setNewCollectionLimit(0);
            const bodyCollection = {
                "input": {
                    "name": newCollection,
                    "tenantUuid": tenantUuid,
                    "userUuid": userUuid
                }
            }
            try {
                const createCollection = await AssetService.createCollection(bodyCollection, {}, tenantUuid);
                setCollectionCreating(false);
                setNewCollection("");
                setTenantData((prevCollections) => {
                    // Check if the label with the same uuid already exists
                    const isDuplicated = prevCollections.some(label => label.name === createCollection.name);
                    // If not duplicated, add the updated label to the state
                    if (!isDuplicated) {
                        setEditCollections((prevEditCollections) => {
                            const updatedLabel = { name: createCollection.name, uuid: createCollection.uuid };
                            // Update editCollections state
                            const updatedEditCollections = [...prevEditCollections, updatedLabel];
                            // Update tenantData state
                            setTenantData([...prevCollections, updatedLabel]);

                            return updatedEditCollections;

                        });
                    }
                    // If duplicated, return the unchanged array
                    return prevCollections;
                });                
                setCollections(prev => prev + 1);
            } catch (error) {
                await inputRef.current.focus();
                setCollectionCreating(false);
                setNewCollectionError('duplicated');
                console.error(`fail to create collection ${newCollection}`);
            }
        }
    };

    const isAssetCollections = async (collectionUuid) => {
        await getStoreItem(collectionUuid, createStore('collections', 'asset-collections'));
    }

    const save = async () => {
        const newCollections = Array.isArray(editCollections) && editCollections.map((editLabel) => editLabel.uuid);
        const fileKey = props.fileKey;
        const parts = fileKey.split('.');
        const fileExtension = parts[parts.length - 1];
        const bodyAsset = {
            "uuid": tenantUuid,
            "useruuid": userUuid,
            "assetuuid": props.assetUuid,
            "basename": filesName,
            "description": filesDesc ? filesDesc : '',
            "collections": newCollections ? newCollections : [],
            "tags": assignTags ? assignTags.join(",") : "",
            "filename": fileKey
        }

        try {
            const response = await AssetService.updateAsset(bodyAsset, tenantUuid);
            if (response.statusCode === 200) {
                updateToastMessage(`Edits saved`, 'success');
                props.handleSaveEdit(false);
                getStoreItem(props.assetUuid)
                    .then(async (currentAsset) => {
                        currentAsset.name = filesName;
                        currentAsset.desc = filesDesc;
                        currentAsset.key = filesName + '.' + fileExtension;
                        currentAsset.collections = editCollections;
                        currentAsset.tags = assignTags;
                        setStoreItem(props.assetUuid, currentAsset);
                        props.handleUpdatedAsset(currentAsset);
                        setCurrentAsset(currentAsset);
                        setFilesDesc(currentAsset.desc);
                        
                        //Get collectionUuid
                        const location = window.location.href;
                        const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
                        const collectionUuid = location.match(uuidRegex);
                        
                        if(!(await isAssetCollections(collectionUuid[0]))) {
                            await getAssetCollections();
                        }
                        
                        if(editCollections.length > 0) {                            
                            editCollections.forEach((collection) => {
                                setStoreItem(collection.uuid, createStore('collections', 'asset-collections'));
                            });
                            
                            // Update assets collections from state if current collections is not found on current assets
                            setDisplayCollectionItems(prevCollectionItems => {

                                if (!prevCollectionItems) {
                                    prevCollectionItems = [];
                                }
                                
                                // Remove collections from store if current collections is not found on current assets
                                const checkCollections = editCollections.filter((collection) => collection.uuid === collectionUuid[0]);

                                //if current collections is not found on current assets
                                if(!checkCollections.length > 0) {
                                    const updatedCollectionItems = prevCollectionItems.filter((item) => {
                                        if(item.uuid === currentAsset.uuid) {
                                            return;
                                        }
                                        return item
                                    })

                                    return updatedCollectionItems;
                                }
                                return prevCollectionItems;
                            });
                        } 

                        if(removedCollections && removedCollections.length > 0) {
                            for(let collection of removedCollections) {
                                removeStoreItem(collection.uuid, createStore('collections', 'asset-collections'));
                            }
                        }                        
                        setRemovedCollections([]);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                updateToastMessage(`Not saved`, 'error');
            }
        } catch (error) {
            console.error("Error fetching asset storage:", error);
        }
    };

    const customBorderFilename = {
        border: TextAreaFocus ? '1px solid black' : '',
        padding: '0.625rem 0.313rem 0.625rem 0'
    };
    const customBorderDesc = {
        border: TextAreaFocusDes ? '1px solid black' : '',
        padding: '0.625rem 0.313rem 0.625rem 0'
    };
    const customBorderTag = {
        border: textFocus ? '1px solid black' : ''
    };

    // Tag Logic
    const tagChange = (char) => {
        const input = char.target;
        const cursorPosition = input.selectionStart;
        const regex = /[.<>'":;[\]{}|\\+=/?]/g;
        const sanitizedValue = sanitizeInput(regex, char.target.value);
        const words = sanitizedValue.split(" ");
        const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        const properCaseValue = formattedWords.join(" ");
        setTagNameChar(char.target.value.length);
        setTagName(properCaseValue.replace(/[,]/g, ""));

        setTimeout(() => {
            input.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);

        if(properCaseValue.indexOf(',') !== -1 && tagName) {
            setAssignTags((prevAssignTags) => {
                // Check if the tag is already present in assignTags
                if (!prevAssignTags.includes(tagName)) {
                    // Add the tag to assignTags if not already present
                    const updatedAssignTags = [...prevAssignTags, tagName];
                    // Remove the tag from aiSmartTags if present
                    if (aiSmartTags.includes(tagName)) {
                        setAiSmartTags(prevAiSmartTags => prevAiSmartTags.filter(item => item !== tagName));
                    }
                    return updatedAssignTags;
                }
                return [...prevAssignTags]; // If already present, return previous state
            });
            setTagName("");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && tagName) {
            setAssignTags((prevAssignTags) => {
                // Check if the tag is already present in assignTags
                if (!prevAssignTags.includes(tagName)) {
                    // Add the tag to assignTags if not already present
                    const updatedAssignTags = [...prevAssignTags, tagName];
                    // Remove the tag from aiSmartTags if present
                    if (aiSmartTags.includes(tagName)) {
                        setAiSmartTags(prevAiSmartTags => prevAiSmartTags.filter(item => item !== tagName));
                    }
                    return updatedAssignTags;
                }
                return [...prevAssignTags]; // If already present, return previous state
            });
            setTagName("");
        }
    };

    const removeAllTag = () => {
        setAssignTags("");
        setAiSmartTags(props?.aiSmartTags ? props.aiSmartTags : []);
    };

    const removeTag = (tag) => {
        setAssignTags((prevAssignTags) => {
            const updatedAssignTags = prevAssignTags.filter(item => item !== tag);

            if (props?.aiSmartTags && props?.aiSmartTags.includes(tag)) {
                // Add the removed tag back to aiSmartTags
                setAiSmartTags(prevAiSmartTags => {
                    // Combine previous aiSmartTags with the removed tag
                    const combinedArray = [...prevAiSmartTags, tag];

                    // Convert the combinedArray to a Set to remove duplicates
                    const uniqueSet = new Set(combinedArray);

                    // Convert the Set back to an array
                    return [...uniqueSet];
                });
            }

            return updatedAssignTags;
        });
    };

    const addTag = (tag) => {
        setAssignTags((prevAssignTags) => {
             // Check if the tag is already present in assignTags
            if (!prevAssignTags.includes(tag)) {
                // Add the tag to assignTags if not already present
                return [...prevAssignTags, tag];
            }
            return prevAssignTags; // If already present, return previous state
        });
        setAiSmartTags((prevAiSmartTags) => {
            // Remove the tag from aiSmartTags if present
            return prevAiSmartTags.filter(item => item !== tag);
        });
    };

    const addAllTag = () => {
        setAssignTags((prevAssignTags) => {
            const combinedArray = [...prevAssignTags, ...aiSmartTags];

            const uniqueSet = new Set(combinedArray);

            return[...uniqueSet];
        });

        setAiSmartTags([]);
    };

    useEffect(() => {
        const checkAssignTagsArr = (assignTags, currentAsset) => {
            if(assignTags && currentAsset) {
                if (assignTags.length !== currentAsset.length) {
                    return false;
                }

                // Check each element
                for (let i = 0; i < assignTags.length; i++) {
                    if (assignTags[i] !== currentAsset[i]) {
                        return false;
                    }
                }
            }

            // If all elements are equal, arrays are equal
            return true;
        };
        setSearchQuery('');
        if(!props.isAssetEdit){
            if (filesName === '' || filesName !== currentAsset.name || filesName !== props.name) {
                setFilesName(currentAsset ? currentAsset.name : props.name);
                setFilesNameChar(props.name?.length || null);
            }
            if (filesDesc === '' || filesDesc !== currentAsset.desc || filesDesc !== props.desc) {
                setFilesDesc(currentAsset ? currentAsset.desc : props.desc === undefined ? '': props.desc);
            }
            if (editCollections.length === 0 || editCollections !== currentAsset.collections || editCollections !== props.collections) {
                setEditCollections(currentAsset ? currentAsset.collections : props.collections);
            }
            if (assignTags.length === 0 || checkAssignTagsArr(assignTags, currentAsset.tags) || checkAssignTagsArr(assignTags, props.tags)) {
                setAssignTags(currentAsset ? currentAsset.tags : props?.tags ? props?.tags : []);
                setAiSmartTags(currentAsset ? props?.aiSmartTags ? props?.aiSmartTags.filter(item => !currentAsset.tags.includes(item)) : currentAsset.tags : props?.aiSmartTags ? props?.tags ? props?.aiSmartTags.filter(item => !props?.tags.includes(item)) : [] : []);
            }
        }
        if (props.isSaveAssetEdit) {
            save();
        }
    }, [props.isSaveAssetEdit, props.isAssetEdit]);

    useEffect(() => {
        getTenantCollection().then(result => {
            setTenantData(result);
            setLoading(false);
        })
            .catch(error => {
                console.error('Error fetching tenant collection:', error);
            });
        setEditCollections(props.collections);
        setFilesName(props.name);
        setFilesDesc(props.desc);
        setFilesNameChar(filesName.replace(/\.[^/.]+$/, "").length);
        setAssignTags(props?.tags ? props?.tags : []);
        setAiSmartTags(props?.aiSmartTags ? props?.tags ? props?.aiSmartTags.filter(item => !props?.tags.includes(item)) : [] : []);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filtered array based on search query
    const filteredTenantData = Array.isArray(tenantData) && tenantData.filter(label =>
        label.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
      const handleCollectionKeyDown = (event) => {
        if(searchQuery === ''){
            if (event.key === 'Backspace') {
                setEditCollections((previousArr) => (previousArr.slice(0, -1)));
            }
        }
    };

    return (
        <>
            <div className={`form-container ${!props.isAssetEdit ? 'd-none' : 'edit-asset-container'}`}>
                {!props.isMobile &&
                    <h3>{Localization.Search.Edit.Title}</h3>
                }
                {props.isMobile &&
                <>
                      <div className="col-lg-8 col-md-12" style={{marginBottom: props.fileData.type === 'document' ? '56px' : null}}>
                        <h3>{Localization.Search.Edit.Title}</h3>
                      {
                          props.fileData.type === 'image' ?
                              <div className='ratio ratio-4x3'>
                                  <div className={props.assetsHover ? "view-asset-image view-asset-image-visible" : "view-asset-image"} onMouseEnter={() => {}} onMouseLeave={() => {}}>
                                      <img
                                          alt={ props.fileData.name }
                                          src={ props.fileData.url }
                                      />
                                      <div className="view-asset-image-enlarge">
                                          <div className="view-asset-enlarge" onClick={() => props.updateFSLightBox(props.fileData.url, !props.FSLightbox, 'image', props.fileData)} >
                                              <ZoomOutMapRoundedIcon />
                                          </div>
                                      </div>
                                  </div>
                              </div> :
                          props.fileData.type === 'video' ?
                              <div className='ratio ratio-4x3'>
                                  {
                                      props.fileData.mime === 'video/x-msvideo'
                                      ?
                                          <div className="view-asset-icon"><MovieIcon /></div>
                                      :
                                          <div className="view-asset-video">
                                              <VideoJS options={props.videoJsOptions} onReady={props.handlePlayerReady} />
                                              <div className="view-asset-video-enlarge">
                                                  {/* <div className="view-asset-enlarge" onClick={() => props.updateFSLightBox(props.fileData.url, !props.FSLightbox, 'video', props.fileData)} >
                                                      <ZoomOutMapRoundedIcon />
                                                  </div> */}
                                              </div>
                                          </div>
                                  }

                              </div> :
                          props.fileData.type === 'document' ?
                              <div className='ratio ratio-4x3'>
                                  <div className="view-asset-document">
                                      <DocumentPdf fileURL={props.fileData.url} />
                                  </div>
                              </div> :
                          props.fileData.type === 'audio' ?
                              <div className='ratio ratio-4x3'>
                                  <div className="view-asset-icon">
                                      <FontAwesomeIcon icon={faFileAudio} onClick={props.handleAudioClick}/>
                                      <audio ref={props.audioRef} loop controls>
                                          <source src={props.fileData.url} type="audio/mpeg" />
                                          Your browser does not support the audio tag.
                                      </audio>
                                  </div>
                              </div> : <Skeleton height={`100%`}/>

                      }
                  </div>
                  </>
                }
                <div className="form-group">
                    <div className='form-control' style={customBorderFilename}>
                        <Form.Control
                            as="textarea"
                            id="Filename"
                            aria-describedby="fileNameDesc"
                            maxLength={nameCharLimit}
                            value={filesName}
                            onChange={(e) => filesNameCharLimitsChange(e)}
                            onBlur={(e) => {
                                filesNameCharLimitsBlur(e, filesName)
                                setTextAreaFocus(false)
                            }}
                            onFocus={(e) => {
                                charLimitsShow(e, "name");
                                setTextAreaFocus(true)
                            }}
                            placeholder="Type here"
                        />
                    </div>
                    <Form.Label style={TextAreaFocus ? { color: 'black' } : {}} htmlFor="Filename">*Filename</Form.Label>
                    {
                        charLimit === "name" &&
                        <div className="d-flex flex-row justify-content-between">
                            <div className='edit-char-limit'>
                                <span>
                                    {
                                        filesNameChar >= nameCharLimit &&
                                        "Character limit reached"
                                    }
                                </span>
                                <span>
                                    {
                                        `${filesNameChar}/${nameCharLimit}`
                                    }
                                </span>
                            </div>
                        </div>
                    }
                </div>

                <div className="form-group">
                    <div className='form-control' style={customBorderDesc}>
                        <Form.Control
                            id="Description"
                            as="textarea"
                            placeholder="Type here"
                            aria-describedby="fileDescError"
                            value={filesDesc}
                            maxLength={descCharLimit}
                            onChange={(e) => filesDescCharLimitsChange(e)}
                            onBlur={(e) => {
                                filesDescCharLimitsBlur(e);
                                setTextAreaFocusDes(false)
                            }}
                            onFocus={(e) => {
                                charLimitsShow(e, "desc");
                                setTextAreaFocusDes(true);
                                setFilesDescChar(filesDesc ? filesDesc.length : 0);
                            }}
                        />
                    </div>
                    <Form.Label style={TextAreaFocusDes ? { color: 'black' } : {}} htmlFor="Description">Description</Form.Label>
                    {
                        charLimit === "desc" &&
                        <>
                            <div className='edit-char-limit'>
                                <span>
                                    {
                                        descCharLimit === filesDescChar &&
                                        `${Localization.Upload.Offcanvas.Progress.CharLimitReach}`
                                    }
                                </span>
                                <span>
                                    {
                                        `${filesDescChar}/${descCharLimit}`
                                    }
                                </span>
                            </div>
                        </>
                    }
                </div>

                <div className="form-group">
                    <Form.Control
                        type="text"
                        as="input"
                        id="tagName"
                        placeholder={Localization.Tag.TagPlaceholder}
                        aria-describedby="tagName"
                        maxLength={tagCharLimit}
                        value={tagName}
                        onChange={(e) => tagChange(e)}
                        onBlur={(e) => {
                            setCharLimit("");
                            setTextFocus(false);
                        }}
                        onKeyDown={handleKeyDown}
                        onFocus={(e) => {
                            charLimitsShow(e, 'tag')
                            setTextFocus(true)
                        }}
                        style={customBorderTag}
                    />
                    <Form.Label style={textFocus ? { color: 'black' } : {}} htmlFor="tagName">Tag</Form.Label>
                    {
                        charLimit === 'tag' &&
                            <div className='view-asset-edit-tag-limit-cont'>
                                {tagNameChar >= tagCharLimit && (
                                    <div className='view-asset-edit-tag-limit'>
                                        Character limit reached
                                    </div>
                                )}
                                <div className='view-asset-edit-tag-limit-right'>
                                    {`${tagNameChar}/${tagCharLimit}`}
                                </div>
                            </div>
                    }
                    <div className='view-asset-edit-tag'>
                        {
                            (isTablet && (!assignTags.length > 0 || !aiSmartTags.length > 0)) &&
                                <hr/>
                        }
                        {
                            assignTags && assignTags.length > 0 &&
                                <>
                                    <div className='view-asset-edit-tag-clear-all'>
                                        <button className='icon-button' onClick={() => removeAllTag()}>
                                            <CustomIcons variant='cancel' /> <span className="clear-all-text" >{ Localization.Tag.ClearAll}</span>
                                        </button>
                                    </div>
                                    <div className='view-asset-edit-tag-desc'>
                                        <span className='paragraph-1'>{ Localization.Search.View.TagDesc }</span>
                                    </div>
                                    <div className='view-asset-edit-tag-cont'>
                                        {
                                            assignTags
                                            .sort((a, b) => a.localeCompare(b))
                                            .map(tag => {
                                                return <button className="chips-primary" key={tag} data-key={tag}><span>{tag}</span><span onClick={(e) => removeTag(tag)}><CancelIcon /></span></button>;
                                            })
                                        }
                                    </div>
                                </>

                        }
                        {
                            aiSmartTags && aiSmartTags.length > 0 &&
                                <div className='view-asset-edit-tag-add-all'>
                                    <button className='icon-button' onClick={() => addAllTag()}>
                                        <CustomIcons variant='cancel' /> <span className="add-all-text" >{ Localization.Tag.AddAll}</span>
                                    </button>
                                </div>
                        }
                        {
                            aiSmartTags && aiSmartTags.length > 0 &&
                                <div className='view-asset-edit-ai-smart-tag'>
                                    <div className='view-asset-edit-tag-desc'>
                                        <span className='paragraph-1'>{ Localization.Search.View.AiSmartTag }</span>
                                    </div>
                                    <div className='view-asset-edit-tag-ai-smart-tag-cont'>
                                        {
                                            aiSmartTags
                                                .filter(tag => typeof tag === 'string' && tag !== "")
                                                .sort((a, b) => a.localeCompare(b))
                                                .map(tag => {
                                                return (
                                                    <button className="chips-secondary" key={tag} data-key={tag} onClick={(e) => addTag(tag)}>
                                                        <span>{tag}</span>
                                                        <span><CustomIcons variant='add'/></span>
                                                    </button>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>


                <div className="form-group" ref={dropdownRef}>
                    {<div className='form-control collection-dropdown-select d-flex justify-content-between' style={{border: openDropdown ? '1px solid black': ''}}>
                        {/* <div className='text-truncate' style={{ color: editCollections.length > 0 ? 'black' : '#989595' }}>
                            {editCollections.length === 0 ? "Select a collection" : Array.isArray(editCollections) && editCollections.map(item => (<StyledTag label={item.name} />))}
                        </div> */}
                         <div className='create-collection-tag'  onClick={handleDropdown}>
                        {editCollections.length > 0 && Array.isArray(editCollections) && editCollections.map(item => (
                        <div className='d-flex' key={item.name} data-key={item.name}>
                            <div className="chips-primary" key={item.name}>
                                <span>{item.name}</span>
                                <button onClick={(event) => { event.stopPropagation(); checkCollections(item); }}><CustomIcons variant='cancel' /></button>
                            </div>
                        </div>
                        ))}
                        <input type='text' ref={inputRefCollection} onChange={handleSearch} style={{display: openDropdown || editCollections.length === 0 ? 'block': 'none'}} onKeyDown={handleCollectionKeyDown} placeholder={editCollections.length === 0 ? "Search or select a collection" : undefined} value={searchQuery}/>
                        </div>
                        <button className='border-0 bg-transparent' onClick={handleDropdownArrow}><CustomIcons variant='arrow' direction='down' className={`ant-dropdown-arrow ${openDropdown ? 'rotate-arrow': ''}`} style={{transform: !openDropdown ? 'rotate(180deg)' : ''}} /></button>
                    </div>}
                    <div className={`ant-dropdown ${!openDropdown ? 'd-none' : ''}`}>
                        <div className={`ant-dropdown-menu`}>
                            <ul className={`${editCollections.length === 0 && tenantData.length === 0 ? 'no-collections' : ''}`}>
                                {loading ? (
                                    <div className="d-flex justify-content-center gap-2 align-items-center w-100 h-100">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        <span>Loading...</span>
                                    </div>
                                ) : editCollections.length === 0 && tenantData.length === 0 ? (
                                    <li className="d-flex justify-content-center gap-2 align-items-center w-100 h-100">
                                        <p className="paragraph-1 mb-0 spanish-gray--color">
                                            {Localization.Alerts.noExistingCollection}
                                        </p>
                                    </li>
                                ) : filteredTenantData.length === 0 ? <div><p className="paragraph-1 mb-0 spanish-gray--color text-center">{Localization.Alerts.NoCollectionFound}</p></div> :
                                    (Array.isArray(filteredTenantData) &&
                                    filteredTenantData
                                            .sort((a, b) => {
                                                const aChecked = Array.isArray(editCollections) && editCollections.some((editLabel) => editLabel.uuid === a.uuid);
                                                const bChecked = Array.isArray(editCollections) && editCollections.some((editLabel) => editLabel.uuid === b.uuid);
                                                if (aChecked && !bChecked) {
                                                    return -1;
                                                } else if (!aChecked && bChecked) {
                                                    return 1;
                                                }
                                                return a.name.localeCompare(b.name);
                                            })
                                            .map((label, i) => {
                                                const isChecked = Array.isArray(editCollections) && editCollections.some((editLabel) => editLabel.uuid === label.uuid)
                                                const maxCollectionLength = Math.max(label.name.length);
                                                return (
                                                    <li className="ant-dropdown-menu-item" data-key={label.uuid} key={label.uuid} style={{width: maxCollectionLength > 40 ? 'max-content' : ''}}>
                                                        <Form.Check
                                                            className={label.mixed && 'form-check-input-mixed'}
                                                            type='checkbox'
                                                            data-name={label.name}
                                                            id={label.uuid}
                                                            checked={isChecked}
                                                            label={label.name}
                                                            onChange={() => checkCollections(label)}
                                                        />
                                                    </li>
                                                )
                                            })

                                    )
                                }
                            </ul>
                            <div className='ant-dropdown-menu-divider'>
                                <hr />
                            </div>
                            <div className={`${!collectionShowCreate ? 'd-none' : ''}`}>
                                <div className='edit-collection-new-input-group mt-3'>
                                    <Form.Control
                                        ref={inputRef}
                                        type="text"
                                        id="CreateCollection"
                                        className={newCollectionError === 'empty' || newCollectionError === 'duplicated' ? 'create-collection red-border' : 'create-collection'}
                                        as="input"
                                        value={newCollection}
                                        aria-describedby="NewCollectionError"
                                        onChange={(e) => newCollectionChange(e)}
                                        maxLength={newCollectionCharLimit}
                                        placeholder={Localization.Collection.NewCollectionName}
                                    />
                                    {
                                        <div className='edit-char-limit'>
                                            {
                                                newCollectionError === 'empty' &&
                                                <Form.Text className='error' id="NewCollectionError">
                                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                                    {Localization.Alerts.NewCollectionNameError}
                                                </Form.Text>
                                            }
                                            {
                                                newCollectionError === 'duplicated' &&
                                                <Form.Text className='error' id="NewCollectionError">
                                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                                    {Localization.Alerts.NewCollectionNameDuplication}
                                                </Form.Text>
                                            }
                                            <span>
                                                {
                                                    newCollectionLimit === newCollectionCharLimit &&
                                                    `${Localization.Upload.Offcanvas.Progress.CharLimitReach}`
                                                }
                                            </span>
                                            <span>
                                                {
                                                    `${newCollectionLimit}/${newCollectionCharLimit}`
                                                }
                                            </span>
                                        </div>
                                    }
                                </div>
                                <div className='upload-collection-new-btn-group'>
                                    <button className='btn btn-secondary' onClick={() => setCollectionShowCreate(false)}>{Localization.Collection.NewCollectionSecondaryBtn}</button>
                                    <button className={collectionCreating ? 'btn btn-primary disabled' : 'btn btn-primary'} onClick={() => newCollectionCreate()}>{Localization.Collection.NewCollectionPrimaryBtn}{collectionCreating && <Spinner variant='gray' />}</button>
                                </div>
                            </div>
                            <div className={`ant-dropdown-menu-clear-all userlistClear ${!collectionShowCreate ? '' : 'd-none'}`}>
                                <button
                                    className='font-weight-bold'
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        cursor: 'pointer',
                                        fontWeight: '700',
                                    }}
                                    onClick={() => setCollectionShowCreate(true)}
                                >
                                    Create New Collection
                                </button>
                            </div>
                        </div>
                    </div>
                    <Form.Label className='collection-label'>Collection</Form.Label>
                </div>
            </div >
        </>
    );
}
export default EditAssets;