import React from 'react';
import { spanishGray, black } from '../../utils';
import PropTypes from 'prop-types';
import FilterIcon from '../../../icons/filter-icon';
import SortIcon from '../../../icons/faSort';
import CancelIcon from '../../../icons/cancel';
import TickIcon from '../../../icons/tick';
import InfoIcon from '../../../icons/info';
import IosShare from '../../../icons/iosShare';
import LinkIcon from '../../../icons/link';
import ImageIcon from '../../../icons/image';
import GridViewIcon from '../../../icons/grid-view';
import ListViewIcon from '../../../icons/list-view';
import EditIcon from '../../../icons/edit';
import DownloadIcon from '../../../icons/download';
import DownloadingRoundedIcon from '../../../icons/downloading';
import CommentsIcon from '../../../icons/comments';
import CollectionIcon from '../../../icons/collection';
import CloseIcon from '../../../icons/close';
import CameraIcon from '../../../icons/camera';
import BuildingIcon from '../../../icons/building';
import ArrowIcon from '../../../icons/arrow';
import AudioIcon from '../../../icons/audio';
import BookmarkIcon from '../../../icons/bookmark';
import AddIcon from '../../../icons/add';   
import DuplicateRoundedIcon from '../../../icons/duplicate';
import EmailIcon from '../../../icons/email';
import ErrorIcon from '../../../icons/error';
import GifIcon from '../../../icons/gif';
import MinusIcon from '../../../icons/minus';
import MoreIcon from '../../../icons/more';
import MovieIcon from '../../../icons/movie';
import MusicIcon from '../../../icons/music';
import NotificationIcon from '../../../icons/notification';
import PauseIcon from '../../../icons/pause';
import PeopleIcon from '../../../icons/people';
import PlayIcon from '../../../icons/play';
import ReplayIcon from '../../../icons/replay';
import ReportIcon from '../../../icons/report';
import RestoreIcon from '../../../icons/restore';
import SaveIcon from '../../../icons/save';
import SearchIcon from '../../../icons/search';
import SettingsIcon from '../../../icons/settings';
import ShareIcon from '../../../icons/share';
import SpinnerIcon from '../../../icons/spinner';
import StopIcon from '../../../icons/stop';
import TagIcon from '../../../icons/tag';
import UndoIcon from '../../../icons/undo';
import UploadIcon from '../../../icons/upload';
import UserIcon from '../../../icons/user';
import VisibilityIcon from '../../../icons/visibility';
import WarningIcon from '../../../icons/warning';
import ZoomIcon from '../../../icons/zoom';
import LogoutIcon from '../../../icons/logout';
import FileAudioIcon from '../../../icons/faFileAudio';
import FilePdfIcon from '../../../icons/faFilePdf';
import FileWordIcon from '../../../icons/faFileWord';
import TrashIcon from '../../../icons/trash';
import CheckIcon from '../../../icons/check';
import LockResetIcon from '../../../icons/lock-reset';
import UserCircle from '../../../icons/user-circle';
import AccountCog from '../../../icons/account-cog';
import HelpIcon from '../../../icons/help';
import FilterCenterFocus from '../../../icons/filtercenterfocus';

const CustomIcons = ({filled, variant, color, direction, arrowDouble, size, className, muted, visible }) => {
    const colorVal = color || black;

    return(
        <>
            {
                variant === 'help'
                ?
                    <HelpIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'check'
                ?
                    <CheckIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'filter'
                ?
                    <FilterIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'tick'
                ?
                    <TickIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'faFileAudio'
                ?
                    <FileAudioIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'faFilePdf'
                ?
                    <FilePdfIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'faFileWord'
                ?
                    <FileWordIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'faSort'
                ?
                    <SortIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'cancel'
                ?
                    <CancelIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'info'
                ?
                    <InfoIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'iosShare'
                ?
                    <IosShare color={colorVal} size={size} className={className}/>
                :
                variant === 'link'
                ?
                    <LinkIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'image'
                ?
                    <ImageIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'grid'
                ?
                    <GridViewIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'list'
                ?
                    <ListViewIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'edit'
                ?
                    <EditIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'download'
                ?
                    <DownloadIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'downloading'
                ?
                    <DownloadingRoundedIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'comments'
                ?
                    <CommentsIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'collection'
                ?
                    <CollectionIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'close'
                ?
                    <CloseIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'camera'
                ?
                    <CameraIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'building'
                ?
                    <BuildingIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'arrow'
                ?
                    <ArrowIcon direction={direction} size={size} double={arrowDouble} color={colorVal} className={className}/>
                :
                variant === 'audio'
                ?
                    <AudioIcon color={colorVal} size={size} className={className} muted={muted}/>
                :
                variant === 'bookmark'
                ?
                    <BookmarkIcon filled={filled} color={colorVal} size={size} className={className}/>
                :
                variant === 'add'
                ?
                    <AddIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'email'
                ?
                    <EmailIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'error'
                ?
                    <ErrorIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'gif'
                ?
                    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg" color={colorVal} size={size} className={className}>
                        <path d="M7.02273 3C6.92898 2.71307 6.8054 2.45597 6.65199 2.22869C6.50142 1.99858 6.32102 1.80256 6.1108 1.64062C5.90341 1.47869 5.66761 1.35511 5.40341 1.26989C5.1392 1.18466 4.84943 1.14205 4.53409 1.14205C4.01705 1.14205 3.54688 1.27557 3.12358 1.54261C2.70028 1.80966 2.36364 2.20312 2.11364 2.72301C1.86364 3.2429 1.73864 3.88068 1.73864 4.63636C1.73864 5.39205 1.86506 6.02983 2.1179 6.54972C2.37074 7.0696 2.71307 7.46307 3.14489 7.73011C3.5767 7.99716 4.0625 8.13068 4.60227 8.13068C5.10227 8.13068 5.54261 8.02415 5.9233 7.81108C6.30682 7.59517 6.60511 7.29119 6.81818 6.89915C7.03409 6.50426 7.14205 6.03977 7.14205 5.50568L7.46591 5.57386H4.84091V4.63636H8.16477V5.57386C8.16477 6.29261 8.01136 6.91761 7.70455 7.44886C7.40057 7.98011 6.98011 8.39205 6.44318 8.68466C5.90909 8.97443 5.29545 9.11932 4.60227 9.11932C3.82955 9.11932 3.15057 8.9375 2.56534 8.57386C1.98295 8.21023 1.52841 7.69318 1.2017 7.02273C0.877841 6.35227 0.715909 5.55682 0.715909 4.63636C0.715909 3.94602 0.808239 3.32528 0.992898 2.77415C1.1804 2.22017 1.4446 1.74858 1.78551 1.35938C2.12642 0.97017 2.52983 0.671875 2.99574 0.464489C3.46165 0.257102 3.97443 0.153409 4.53409 0.153409C4.99432 0.153409 5.4233 0.223011 5.82102 0.362216C6.22159 0.498579 6.57812 0.693181 6.89062 0.946022C7.20597 1.19602 7.46875 1.49574 7.67898 1.84517C7.8892 2.19176 8.03409 2.5767 8.11364 3H7.02273ZM11.0316 0.272727V9H9.97479V0.272727H11.0316ZM13.1506 9V0.272727H18.3835V1.21023H14.2074V4.15909H17.9915V5.09659H14.2074V9H13.1506Z" fill="white"/>
                    </svg>
                :
                variant === 'minus'
                ?
                    <MinusIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'more'
                ?
                    <MoreIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'movie'
                ?
                    <MovieIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'music'
                ?
                    <MusicIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'notification'
                ?
                    <NotificationIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'pause'
                ?
                    <PauseIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'people'
                ?
                    <PeopleIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'play'
                ?
                    <PlayIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'replay'
                ?
                    <ReplayIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'report'
                ?
                    <ReportIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'restore'
                ?
                    <RestoreIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'save'
                ?
                    <SaveIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'search'
                ?
                    <SearchIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'settings'
                ?
                    <SettingsIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'share'
                ?
                    <ShareIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'spinner'
                ?
                    <SpinnerIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'stop'
                ?
                    <StopIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'tag'
                ?
                    <TagIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'undo'
                ?
                    <UndoIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'upload'
                ?
                    <UploadIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'user'
                ?
                    <UserIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'visibility'
                ?
                    <VisibilityIcon color={colorVal} size={size} className={className} visible={visible}/>
                :
                variant === 'warning'
                ?
                    <WarningIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'zoom'
                ?
                    <ZoomIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'logout'
                ?
                    <LogoutIcon color={colorVal} size={size} className={className} />
                :
                variant === 'trash'
                ?
                    <TrashIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'lockReset'
                ?
                    <LockResetIcon color={colorVal} size={size} className={className}/>
                :
                variant === 'userCircle'
                ?
                    <UserCircle color={colorVal} size={size} className={className}/>
                :
                variant === 'accountCog'
                ?
                    <AccountCog color={colorVal} size={size} className={className}/>
                :
                variant === 'filtercenterfocus'
                ?
                    <FilterCenterFocus color={colorVal} size={size} className={className}/>
                :
                variant === 'duplicate'
                ?
                    <DuplicateRoundedIcon color={colorVal} size={size} className={className}/>
                :
                    null
            }
        </>
    )
}

export default CustomIcons;

CustomIcons.propTypes = {
    arrowDouble: PropTypes.bool,
    muted: PropTypes.bool,
    visible: PropTypes.bool,
    direction: PropTypes.oneOf([
        'up',
        'down',
        'left',
        'right',
        'expand',
        'back'
    ]),
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large']),
    variant: PropTypes.oneOf([
        'downloading',
        'iosShare',
        'check',
        'trash',
        'filter',
        'logout',
        'faSort',
        'faFileAudio',
        'faFilePdf',
        'faFileWord',
        'cancel',
        'tick',
        'info',
        'link',
        'image',
        'grid',
        'list',
        'edit',
        'download',
        'duplicate',
        'comments',
        'collection',
        'close',
        'camera',
        'building',
        'arrow',
        'audio',
        'bookmark',
        'add',
        'email',
        'error',
        'gif',
        'minus',
        'more',
        'movie',
        'music',
        'notification',
        'pause',
        'people',
        'play',
        'replay',
        'report',
        'restore',
        'save',
        'search',
        'settings',
        'share',
        'spinner',
        'stop',
        'tag',
        'undo',
        'upload',
        'user',
        'visibility',
        'warning',
        'zoom',
        'filtercenterfocus',
        'accountCog',
        'userCircle',
        "help"
    ])
};

CustomIcons.defaultProps = {
    arrowDouble: false,
    muted: false,
    visible: false,
    size: 'medium'
}