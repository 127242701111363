import React from 'react';
import PropTypes from 'prop-types';
import CustomIcons from '../../custom-icons';
import Localization from '../../../localization';
import { useContext, useRef, useState } from 'react';
import { ShareContext } from '../../../context/share-state';

const CustomInput = ({ type, label, placeholder, validated, disabled, charlimit, handleCustomInputChange }) => {
    const { setNewCreatedBookmark, editBookmarkError, allBookmark } = useContext(ShareContext);
    const inputRef = useRef();
    const [charCount, setCharCount] = useState(0);
    const [ bookmarkTitleOnfocus, setBookmarkTitleOnfocus] = useState(true);

    const charCounter = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 30) {
            setCharCount(newValue.length);
            setNewCreatedBookmark(newValue);
        } else {
            setCharCount(30);
            setNewCreatedBookmark(newValue.slice(0, 30));
        }

        if (handleCustomInputChange) {
            handleCustomInputChange(event);
        }
    }

    const handleKeyDown = (event) => {
        if (charCount >= 30 && event.key !== 'Backspace') {
          event.preventDefault();
        }
    };
        
    return(
        <>
            <div className={`form-group input ${allBookmark.length > 0 ? '': 'single'} ${editBookmarkError === 'duplicated'? 'form-error' : ''}`}>
                <label htmlFor={label}>{label}</label>
                <input
                    id={label}
                    type={type}
                    ref= {inputRef}
                    className="form-control"
                    placeholder={placeholder}
                    onChange={charCounter}
                    onKeyDown={handleKeyDown}
                    disabled={disabled}
                    onFocus={(e) => {
                        setBookmarkTitleOnfocus(true);
                   }}
                   onBlur={(e) => {
                        setBookmarkTitleOnfocus(false);
                   }}
                   autoFocus
                ></input>
                {charlimit &&
                    <div className={`input-counter-container ${bookmarkTitleOnfocus || editBookmarkError? '' : 'hidden'}`}>
                        {editBookmarkError === 'duplicated' &&
                            <div className={`error-label`}>
                                <CustomIcons variant="error"/>
                                <p>{Localization.BookMark.Modal.Alert.TitleExist}</p>
                            </div>
                        }
                        {editBookmarkError !== 'duplicated' && <p className={`limit-label ${charCount >= 30 ? '' : 'hidden'}`}>Character limit reached</p>}
                        <p className={`counter-label`}>{charCount}/30</p>
                    </div>
                }
            </div>
        </>
    );
};

export default CustomInput;

CustomInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password']),
    validated: PropTypes.bool,
    disabled: PropTypes.bool,
    charlimit: PropTypes.bool
};

