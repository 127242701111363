import ServiceUtil from '../util/serviceUtil.js';
import ENDPOINTS from '../util/endpoints';

class SearchService {
    static async Search(filter, callback, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.Search, filter, '', tenantUuid, callback);
    }

    static async searchInDocument(docId, query, callback, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', `${ENDPOINTS.Search}/${docId}`, query, '', tenantUuid, callback);
    }
    
    static async textSearch(data, tenantUuid) {
        let params = { uuid: data.tenantUuid, pagesize: data.pageSize, query: data.query, labels: data.labels, token: undefined, from: data.from } ;
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.TextSearch, params, '', tenantUuid);
    }
}

export default SearchService;