import path from "../../routePaths";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createStore, getStoreItem } from '../../../../store';
import { formatText } from '../../utils';
import { ApiContext } from "../../context/api-state";
import { SessionContext } from "../../context/session-provider";
import { ShareContext } from "../../context/share-state";

const ViewAssetsHooks = (props) => {
    const [fileData, setFileData] = useState({});
    const [fetchedFileData, setFetchedFileData] = useState(false);
    const [currentUUID, setCurrentUUID] = useState(null);
    const [nextUUID, setNextUUID] = useState(null);
    const [prevUUID, setPrevUUID] = useState(null);
    const [viewAssetsShow, setViewAssetsShow] = useState(false);
    const [viewAssetBtnDisable, setViewAssetBtnDisable] = useState(false);
    const [displayItems, setDisplayItems] = useState(null);
    const { getAssetsById } = useContext(ApiContext);
    const { tenantUuid } = useContext(SessionContext);
    const { updateMenuTabKey, prevMenuTabKey } = useContext(ShareContext);

    //Navigation
    const navigate = useNavigate();
    const pathPrefix = "/";

    const getSmartLabelData = (analysisResults) => {
        let analysis;

        if (analysisResults) {
          if (analysisResults.Labels)
            analysis = analysisResults.Labels;
          else {
            analysis = Object.entries(analysisResults).map(([Name, Json]) => ({ Name, Json }));
          }
        }

        return analysis;
    };

    const getDimensionsFromUrl = (url, asset) => {
        return new Promise((resolve, reject) => {
            if(formatText(asset.type) === 'Image') {
                const img = new Image();
                img.onload = () => {
                  const { width, height } = img;
                  resolve({ width, height });
                };
                img.onerror = reject;
                img.src = url;
            } else if(formatText(asset.type) === 'Video') {
                const video = document.createElement('video');
                video.onloadedmetadata = () => {
                    const { videoWidth: width, videoHeight: height } = video;
                    const frameRate = video.webkitDecodedFrameRate || 30;
                    resolve({ width, height, frameRate });
                };
                video.onerror = reject;
                video.src = url;
                video.load();
            }
        });
    };

    const getFileData = async (fileUUID) => {
        try {
            const storeItem = await getStoreItem(fileUUID, createStore('asset', 'asset-asset'));

            if(storeItem) {
                const url = URL.createObjectURL(storeItem.ingestBlob);
                const data = { ...storeItem, url };
                setFileData(data);
                setViewAssetBtnDisable(false);
                setViewAssetsShow(true);
                setFetchedFileData(true);
                return;
            }

            getAssetsById(fileUUID, tenantUuid).then((asset) => {
                const url = URL.createObjectURL(asset.ingestBlob);
                const data = { ...asset, url };
                setFileData(data);
                setViewAssetBtnDisable(false);
                setViewAssetsShow(true);
                setFetchedFileData(true);
            });
            return;
        } catch (error) {
            // Handle error
            console.error('getFileData error:', error);
            return false;
        }
    };

    const handleViewAssetOpen = async (fileUUID, displayItems) => {
        setViewAssetsShow(true);
        setFileData({});

        setFetchedFileData(false);
        await getFileData(fileUUID);

        let next = null;
        let prev = null;

        if(displayItems && displayItems.length > 0) {
            setDisplayItems(displayItems);
            setCurrentUUID(fileUUID);
            displayItems.map((file, index, elements) => {
                if(file.uuid === fileUUID) {
                    // Get the next and previous items
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    
                    // Update state value
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    
                    // Set local variables for next and previous UUIDs
                    next = nextItem ? nextItem.uuid : null;
                    prev = prevItem ? prevItem.uuid : null;
                    
                    // Return early once the current item is found
                    return elements;
                }
                return elements;
            });

            setFetchedFileData(true);

            let assetData = {
                nextUUID: next,
                prevUUID: prev,
                fileUUID: fileUUID,
                displayItems: displayItems
            }
            updateMenuTabKey(`${path.ASSET}${pathPrefix}${fileUUID}`); 
            navigate(`${pathPrefix}${path.ASSET}${pathPrefix}${fileUUID}`, {replace: true, state: assetData});
        }
    };

    const handleViewPrev = async () => {
        if(prevUUID) {
            setFileData({});
            setFetchedFileData(false);
            setViewAssetBtnDisable(true);
            
            await getFileData(prevUUID);
            setCurrentUUID(prevUUID);
            
            let next = null;
            let prev = null;

            displayItems.map((file, index, elements) => {
                if(file.uuid === prevUUID) {
                    // Get the next and previous items
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    
                    // Set to state
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    
                    // Set local variables for next and previous UUIDs
                    next = nextItem ? nextItem.uuid : null;
                    prev = prevItem ? prevItem.uuid : null;
                    
                    // Return early once the current item is found
                    return elements;
                }
                return elements;
            });


            let assetData = {
                nextUUID: next,
                prevUUID: prev,
                fileUUID: prevUUID,
                displayItems: displayItems
            }
            updateMenuTabKey(`${path.ASSET}${pathPrefix}${prevUUID}`); 
            navigate(`${pathPrefix}${path.ASSET}${pathPrefix}${prevUUID}`, {replace: true, state: assetData});
            setFetchedFileData(true);
        }
    };

    const handleViewNext = async () => {
        if(nextUUID) {
            setFileData({});
            setFetchedFileData(false);
            setViewAssetBtnDisable(true);

            await getFileData(nextUUID);
            setCurrentUUID(nextUUID);
            
            let next = null;
            let prev = null;

            displayItems.map((file, index, elements) => {
                if(file.uuid === nextUUID) {
                    // Get the next and previous items
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    
                    // Update state value
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    
                    // Set local variables for next and previous UUIDs
                    next = nextItem ? nextItem.uuid : null;
                    prev = prevItem ? prevItem.uuid : null;
                    
                    // Return early once the current item is found
                    return elements;
                }
                return elements;
            });

            let assetData = {
                nextUUID: next,
                prevUUID: prev,
                fileUUID: nextUUID,
                displayItems: displayItems
            }
            updateMenuTabKey(`${path.ASSET}${pathPrefix}${nextUUID}`); 
            navigate(`${pathPrefix}${path.ASSET}${pathPrefix}${nextUUID}`, {replace: true, state: assetData});
        }
    };

    const handleViewClose = () => {
        if(prevMenuTabKey) {
            navigate(pathPrefix + prevMenuTabKey.current, {replace: true});
            updateMenuTabKey(prevMenuTabKey.current);

            if(prevMenuTabKey.current === path.SETTINGS) {
                navigate(pathPrefix + prevMenuTabKey.current + '?tab=my-assets', {replace: true});        
                updateMenuTabKey(prevMenuTabKey.current);
            }
        } else {
            navigate(pathPrefix + path.EXPLORE, {replace: true});
            updateMenuTabKey(path.EXPLORE);
        }

        setFileData({});
        setFetchedFileData(false);
        setViewAssetsShow(false);        
    };

    return {
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        currentUUID,
        nextUUID,
        prevUUID,
        viewAssetsShow,
        viewAssetBtnDisable
    };
};

export default ViewAssetsHooks;