/* eslint-disable no-undef */
import { useState } from "react";
import { CognitoRefreshToken, CognitoAccessToken,CognitoIdToken, CognitoUserSession  } from "amazon-cognito-identity-js";
import { createContext } from "react";
import SolutionManifest from '../../../../solution-manifest';
import IotSubscriber from '../../../../services/api/IotSubscriber';
import AssetService from "../../../../services/api/assetsService";
import S3Utils from "../../../../services/util/s3utils";
import StripeService from "../../../../services/api/stripeService";
const SessionContext = createContext();

const Session = (props) => {
    const [companyName, setCompanyName] = useState(null);
    const [username, setUsername] = useState(null);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(null);
    const [isEndUser, setIsEndUser] = useState(null);
    const [isUserAdmin, setIsUserAdmin] = useState(null);
    const [isAssetOwner, setIsAssetOwner] = useState(null);
    const [checkAuthenticated, setCheckAuthenticated] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [hasSubdomain, setHasSubdomain] = useState(null);
    const [tenantUuid, setTenantUuid] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [userPoolID, setUserPoolID] = useState(null);
    const [clientID, setClientID] = useState(null);
    const [identityPoolID, setIdentityPoolID] = useState(null);
    const [productUrl, setProductUrl] = useState(null);
    const [subdomainName, setSubdomainName] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [userPool, setUserPool] = useState(null);
    const [groupsRoles, setGroupsRoles] = useState(null);
    const [userRoles, setUserRoles] = useState(null);
    const [email, setEmail] = useState(null);
    const [fullname, setFullname] = useState(null);
    const [userUuid, setUserUuid] = useState(null);
    const [initials, setInitials] = useState(null);
    const [profileImgUrl, setProfileImgUrl] = useState("");

    const setProfilePic = async (bucket, folderPrefix) => {
      try {
          const response = await S3Utils.getObject(bucket, folderPrefix);

          let imageBlob = new Blob([response.Body.buffer], {
              type: response.ContentType,
          });

          let url = URL.createObjectURL(imageBlob);

          setProfileImgUrl(url);

          return url;
      } catch (error) {
          // Handle the error here
          console.error('An error occurred while setting the profile picture:', error);
      }
    };

    const loadProfilePic = async (username, tenantUuid) => {
        try {
            let folderName = `${SolutionManifest.UserProfile.FolderPath}${tenantUuid}/${username}/`;
            let bucket = SolutionManifest.UserProfile.Bucket;

            await AssetService.listFilesInFolder(bucket, folderName).then((fileNames) => {
                if (fileNames.length > 0) {
                    return setProfilePic(bucket, folderName + fileNames[0]);
                }
            });
        } catch (error) {
          //console.error('An error occurred while loading the profile pic:', error);
        }
    };

    const setAwsConfiguration = async(user, cognitoData, sessionParams) => {
      const idToken = user.getSignInUserSession().getIdToken();
      const cognitoUserPoolID = cognitoData ? cognitoData.id : userPoolID
      const endpoint = `cognito-idp.${SolutionManifest.Region}.amazonaws.com/${cognitoUserPoolID}`;

      const params = {
        IdentityPoolId: identityPoolID ? identityPoolID : sessionParams.identitypool_id,
        Logins: {
          [endpoint]: idToken.getJwtToken(),
        },
      };

      const identityCredentials = new AWS.CognitoIdentityCredentials(params, {
        region: SolutionManifest.Region,
      });

      await identityCredentials.getPromise();
      AWS.config.credentials = identityCredentials;
      AWS.config.region = SolutionManifest.Region;
    };

    const handleRefreshError = (err, sessionParams) => {
      setCompanyName(sessionParams.company_name);
      setGroupsRoles(sessionParams.groups_roles);
      setSubdomainName(sessionParams.subdomain_name);
      setUserPoolID(sessionParams.userpool_id);
      setClientID(sessionParams.client_id);
      setTenantUuid(sessionParams.uuid);
      setUserPool(sessionParams.cognito_userpool);
      setIdentityPoolID(sessionParams.identitypool_id);
      setCheckAuthenticated(false);
      setIsAuthenticated(false);
      console.error(err);
    };

    const updateLocalStorageWithSessionTokens = (session, signInUserSession) => {
      localStorage.setItem('accessToken', signInUserSession ? signInUserSession.accessToken.jwtToken : session.getAccessToken().getJwtToken());
      localStorage.setItem('idToken', signInUserSession ? signInUserSession.idToken.jwtToken : session.getIdToken().getJwtToken());
      localStorage.setItem('refreshToken', signInUserSession ? signInUserSession.refreshToken.token : session.getRefreshToken().getToken());
    };

    const setupProfileInformation = async (session, cognitoData, sessionParams) => {
      await setAwsConfiguration(session.user, cognitoData, sessionParams);
      await IotSubscriber.attachIot();
      await IotSubscriber.getSingleton().connect(session.user);
      await loadProfilePic(session['preferred_username'], tenantUuid ? tenantUuid : sessionParams ? sessionParams.uuid : session['custom:tenantUuid']);
      const initials = session['preferred_username']
        .split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('');

        setInitials(initials);
        setFullname(session.name);
        setEmail(session.email);
        setIsCustomerAdmin(session.user_roles ? session.user_roles.includes(SolutionManifest.Roles.CustomerAdmin) : false);
        setIsUserAdmin(session.user_roles ? session.user_roles.includes(SolutionManifest.Roles.UserAdmin) : false);
        setIsEndUser(session.user_roles ? session.user_roles.includes(SolutionManifest.Roles.EndUser) : false);
        setIsAssetOwner(session.user_roles ? session.user_roles.includes(SolutionManifest.Roles.AssetOwner) : false);
        setSubscriptionPlan(session['custom:subscriptionPlan']);
        setUsername(session['preferred_username']);
        setUserRoles(session.user_roles);
        setGroupsRoles(groupsRoles ? groupsRoles : sessionParams?.groups_roles);
        setSubdomainName(subdomainName ? subdomainName : sessionParams?.subdomain_name);
        setUserPoolID(userPoolID ? userPoolID : sessionParams?.userpool_id);
        setClientID(clientID ? clientID : sessionParams?.client_id);
        setTenantUuid(tenantUuid ? tenantUuid : sessionParams?.uuid);
        setUserPool(userPool ? userPool : sessionParams?.cognito_userpool);
        setIdentityPoolID(identityPoolID ? identityPoolID : sessionParams?.identitypool_id);
        setCompanyName(companyName ? companyName : sessionParams?.company_name);
        setCheckAuthenticated(true);
        setIsAuthenticated(true);
        setUserUuid(session['custom:uuid']);

        if(!customerId && session.user_roles && session.user_roles.includes(SolutionManifest.Roles.CustomerAdmin) && session['custom:subscriptionPlan'] === SolutionManifest.SubscriptionPlan.PaidPlanId) {
          const stripeId = await StripeService.stripeGetId(tenantUuid ? tenantUuid : sessionParams?.uuid);

          if(stripeId.code === 200) {
            setCustomerId(stripeId.response.customer_id);
          }
        }
    };

    const handleRefreshSuccess = async (session, cognitoData, sessionParams) => {
        updateLocalStorageWithSessionTokens(session);

        getSession(cognitoData)
            .then(async (session) => {
                await setupProfileInformation(session, cognitoData, sessionParams)
            })
            .catch((err) => handleRefreshError(err, sessionParams));
    };

    const checkAndRefreshToken = async () => {
      if (cognitoUser != null) {
        cognitoUser.getSession(function(err, session) {
          if (err) {
              console.log(err);
              return;
          }

          // Get expiration time of the current access token
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
          const tokenExpirationTime = session.getAccessToken().getExpiration();

          // Check if token is going to expire in the next 5 minutes (300 seconds)
          if (tokenExpirationTime - currentTime < 300) {
              console.log("Token is about to expire, refreshing...");

              // Refresh the session using the refresh token
              cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
                  if (err) {
                      console.log("Error refreshing session:", err);
                  } else {
                      console.log("Token refreshed successfully!");
                      // Update session storage or use the new tokens
                      localStorage.setItem('accessToken', newSession.getAccessToken().getJwtToken());
                      localStorage.setItem('idToken', newSession.getIdToken().getJwtToken());
                      localStorage.setItem('refreshToken', newSession.getRefreshToken().getToken());
                  }
              });
          }
        });
      }
    }

    const refreshSession = async (cognitoUser, cognitoData, sessionParams) => {
        const refreshToken = new CognitoRefreshToken({ RefreshToken: localStorage.getItem('refreshToken') });

        try {
          await cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              handleRefreshError(err, sessionParams);
            } else {
              handleRefreshSuccess(session, cognitoData, sessionParams);
            }
          });
        } catch (err) {
          handleRefreshError(err, sessionParams);
        }
    };

    const getSession = async (cognitoData) => {
      return await new Promise((resolve, reject) => {
        const user = cognitoData ? cognitoData.pool.getCurrentUser() : userPool?.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject();
            } else {

              const attributes = await new Promise((resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results = {};

                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }

                    resolve(results);
                  }
                });
              });

              function decodeJwtPayload(token) {
                const payload = token.split('.')[1];
                return JSON.parse(atob(payload));
              }

              const idToken = session.idToken;
              const decodedPayload = decodeJwtPayload(idToken.jwtToken);
              const user_roles = decodedPayload['cognito:groups'];

              resolve({ user, ...session, ...attributes, user_roles });
            }
          });
        } else {
          reject();
        }
      });
    };

    const HandlerBackToTop = () => {
      //when in Desktop view
      const loginLeftContElement = document.querySelector('.login-header');
      loginLeftContElement.scrollIntoView();

      //Mobile View
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    return (
      <SessionContext.Provider value={{
          updateLocalStorageWithSessionTokens,
          setupProfileInformation,
          customerId,
          setCustomerId,
          isAssetOwner,
          setIsAssetOwner,
          isEndUser,
          setIsEndUser,
          companyName,
          setCompanyName,
          userRoles,
          setUserRoles,
          loadProfilePic,
          profileImgUrl,
          initials,
          setInitials,
          setEmail,
          fullname,
          setFullname,
          setUserUuid,
          userUuid,
          email,
          getSession,
          username,
          setUsername,
          isCustomerAdmin,
          setIsCustomerAdmin,
          isUserAdmin,
          setIsUserAdmin,
          groupsRoles,
          setGroupsRoles,
          userPool,
          setUserPool,
          subscriptionPlan,
          setSubscriptionPlan,
          subdomainName,
          setSubdomainName,
          productUrl,
          setProductUrl,
          identityPoolID,
          setIdentityPoolID,
          clientID,
          setClientID,
          tenantUuid,
          setTenantUuid,
          userPoolID,
          setUserPoolID,
          checkAuthenticated,
          isAuthenticated,
          HandlerBackToTop,
          setCheckAuthenticated,
          setIsAuthenticated,
          refreshSession,
          handleRefreshError,
          hasSubdomain,
          setHasSubdomain,
          setAwsConfiguration,
          checkAndRefreshToken
        }}>
            {props.children}
        </SessionContext.Provider>
    );
};

export { Session, SessionContext };
