import { useState, useEffect, useRef, useContext } from 'react';
import { setStoreItem, createStore, removeStoreItem, getStoreItem } from '../../../store';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ApiContext } from '../../shared/context/api-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileAudio, faFile, faFileExcel, faFilePowerpoint, faHouseChimneyWindow } from '@fortawesome/free-solid-svg-icons';
import { createAuditBody, formatTime } from '../../shared/utils';
import { SessionContext } from '../../shared/context/session-provider';
import { GlobalContext } from '../../shared/context/global-provider';
import { ShareContext } from '../../shared/context/share-state';
import { IsTablet } from '../../shared/utils';
import { useMetadata } from '../../shared/context/meta-provider';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MovieIcon from '@mui/icons-material/Movie';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import S3Utils from '../../../services/util/s3utils';
import AssetService from '../../../services/api/assetsService';
import ImageIcon from '../../icons/image';
import DocumentIcon from '../../icons/document';
import AssetCardSkeleton from '../asset-card-skeleton';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import SpinnerIcon from '../../icons/spinner';
import CustomIcons from '../../shared/components/custom-icons';
import MobileModal from '../../shared/components/mobile-modal';
import GenericModal from '../../shared/components/generic-modal';
import auditTrailService from '../../../services/api/auditTrailService';
import SvgComponent from '../../shared/components/svg-view';
import SolutionManifest from '../../../solution-manifest';
import path from '../../shared/routePaths';

const AssetCard = ({file, handleViewAssetOpen, handleRemoveFileModal, menuTabKey, view, displayItems}) => {
    const vidRef= useRef();
    const audioRef = useRef();
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileUUID, setFileUUID] = useState(null);
    const [mime, setMime] = useState(null);
    const [duration, setDuration] = useState(null);
    const [status, setStatus] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [isOpenBookMarkModal, setIsOpenBookMarkModal] = useState(false);
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
    const  [isToastVisible, setIsToastVisible ] = useState(false);

    const [playVideo, setPlayVideo] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [videoContentType, setVideoContentType] = useState(null);

    const [displayGif, setDisplayGif] = useState(false);
    const [gifUrl, setGifUrl] = useState(null);

    const [audioContentType, setAudioContentType] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [playAudio, setPlayAudio] = useState(false);

    const [mute, setMute] = useState(true);
    const [key, setKey] = useState(null);
    let toastQueue = [];

    //Check isTablet?
    const isTablet = IsTablet();

    let cardView = view == null? 'card' : view;

    const { getAssetsById, getBookmarks } = useContext(ApiContext);
    const { tenantUuid, userUuid, subdomainName } = useContext(SessionContext);
    const { updateToastMessage } = useContext(GlobalContext);
    const { bookmarks, setBookmarks, userBookmarks, setUserBookmarks } = useMetadata();
    const {
        allBookmark,
        setAllBookmark,
        selectedAssetUuid,
        setSelectedAssetUuid,
        newCreatedBookmark,
        selectedBookmarkName,
        setSelectedBookmarkName,
        deselectedBookmarkName,
        setDeselectedBookmarkName,
        setEditBookmarkError,
        isCreateSelected,
        setIsCreateSelected,
        setNewCreatedBookmark,
        setCreateBookmarkLoading,
        setDisplayBookmarkAssets,
        STANDARD_PAGE_SIZE } = useContext(ShareContext);

    let formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const showToast = (message, type) => {
        toastQueue.push({ message: message, type: type });
    };

    const displayNextToast = () => {
        if (toastQueue.length > 0) {
            setIsToastVisible(true);
            const { message, type } = toastQueue.shift();
            updateToastMessage(message, type);

            // Assuming updateToastMessage displays the toast for 3 seconds
            setTimeout(() => {
                setIsToastVisible(false);
                displayNextToast();
            }, 3000);
        }
    };

    const renderDocumentInnerBody = (mime, imageUrl) => {
        if (imageUrl) {
            return <img alt={fileName} src={ imageUrl } loading="lazy"/>
        }
        if(mime === 'application/pdf') {
            return  <FontAwesomeIcon className="svg-icon" icon={faFilePdf} />;
        }
        if(mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mime === 'application/vnd.ms-excel') {
            return  <FontAwesomeIcon className="svg-icon" icon={faFileExcel} />;
        }
        if(mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mime === 'application/msword') {
            return  <FontAwesomeIcon className="svg-icon" icon={faFileWord} />;
        }
        if(mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || mime === 'application/vnd.ms-powerpoint') {
            return  <FontAwesomeIcon className="svg-icon" icon={faFilePowerpoint} />;
        }
        return <FontAwesomeIcon className="svg-icon" icon={faFile} />;
    }

    const download = () => {
        setDownloading(true);
        AssetService.getAssetById(file.uuid, tenantUuid).then(asset => {
            S3Utils.getObject(asset.bucket, asset.key).then(response => {
                let fileBlob = new Blob([response.Body.buffer], {
                    type: response.ContentType,
                });
                downloadBlob(fileBlob, `${asset.basename}.${asset.key.split('.').pop()}`, true);
                setDownloading(false);
            });
        });
    }

    const sendEmail = (fileUUID) => {
        const url = `https://${subdomainName}.${SolutionManifest.DomainName}/${path.ASSET}/${fileUUID}`;

        const defaultMessage = `Check out this asset on elko:\n${url}`;

        // Construct the mailto link
        const mailtoLink = `mailto:?body=${encodeURIComponent(defaultMessage)}`;

        // Open the default email app
        window.location.href = mailtoLink;
    };

    const copyLink = (fileUUID) => {
        const url = `https://${subdomainName}.${SolutionManifest.DomainName}/${path.ASSET}/${fileUUID}`;

        navigator.clipboard.writeText(url);

        setIsMobileModalOpen(false);
        updateToastMessage('Link copied','success');
    }

    const setAddBookmark = (fileUUID) => {
        setIsOpenBookMarkModal(true);
        setSelectedAssetUuid(fileUUID);
        onVideoMouseLeave();

        // initialize values of bookmarks that exist for fileUuid
        const initialLabels = allBookmark
            .filter(bookmark => bookmark.bookmarkAssets.some(asset => asset.assetUuid === fileUUID))
            .filter(bookmark => bookmark?.assets?.some(asset => asset.uuid === fileUUID))
            .map(bookmark => bookmark.name);

        const initialBookmarkOfFileUuid = new Set([...initialLabels]);
        setSelectedBookmarkName([...initialBookmarkOfFileUuid]);
    }

    const saveBookmark = async () => {
        let promises = [];

        // For POST
        if (newCreatedBookmark?.length > 0) {
            promises.push(createBookmark(newCreatedBookmark));
        }

        // For PUT
        const infoOfSelected = allBookmark.filter(bookmark => selectedBookmarkName.includes(bookmark.name));
        const newlyAddedBookmark = infoOfSelected.filter(bookmark =>
            bookmark.assets.every(asset => asset.uuid !== selectedAssetUuid)
        );
        if (newlyAddedBookmark?.length > 0 && !newCreatedBookmark) {
            promises.push(updateBookmarks(selectedBookmarkName));
        }

        // For DELETE
        if (deselectedBookmarkName?.length > 0 && !newCreatedBookmark) {
            promises.push(deleteBookmarks(deselectedBookmarkName));
        }

        try {
            await Promise.all(promises)
                .then(() => {
                    displayNextToast();
                    setDeselectedBookmarkName([]);
                    setSelectedBookmarkName([]);
                    setNewCreatedBookmark(null);
                })
                .catch((error) => {
                    throw new Error(error)
                });

        } catch (error) {
            console.error('Error in saveBookmark:', error.stack);
        }
    };

    const createBookmark = async (bookmarkName) => {
        try {
            setCreateBookmarkLoading(true);

            let body = {
                name: bookmarkName,
                tenantUuid: tenantUuid,
                userUuid: userUuid,
                timestamp: Date.now(),
                bookmarkAssets: [
                    {
                        assetUuid: selectedAssetUuid,
                        timestamp: Date.now()
                    }
                ]
            };

            const result =  await AssetService.createNewBookmark(body, tenantUuid);
           if(result?.statusCode !== 200) {
                console.error(result.response);
                setCreateBookmarkLoading(false);
                setEditBookmarkError('duplicated');
           } else {
                setUserBookmarks(userBookmarks + 1)
                setEditBookmarkError('');
                setCreateBookmarkLoading(false);
                setBookmarks(bookmarks + 1);

                //Add form indexdb and filter in allbookmark
                await getBookmarks(bookmarkName, 'bookmark', null, STANDARD_PAGE_SIZE);

                //Show toast for new folder
                if (newCreatedBookmark !== null) {
                    showToast(`Bookmarked to ${newCreatedBookmark}`, 'success');
                }

                // Close the modal
                setIsCreateSelected(false);
                setIsOpenBookMarkModal(false);
           }
        } catch (error) {
            console.error('Error in POST bookmark:', error.message);
            updateToastMessage(`${error.message}`,'error');
        }
    };

    const updateBookmarks = async (bookmarkNames) => {
        try {
            let body = bookmarkNames.reduce((acc, name) => {
                acc[name] = {
                    uuid: allBookmark.find(bookmark => bookmark.name === name)?.uuid,
                    name: name,
                    tenantUuid: tenantUuid,
                    userUuid: userUuid,
                    bookmarkAssets: [
                        {
                            assetUuid: selectedAssetUuid,
                            timestamp: Date.now()
                        }
                    ],
                    timestamp: Date.now()
                };
                return acc;
            }, {});
            const result = await AssetService.updateSetBookmark(body, tenantUuid);

            if(result?.statusCode !== 200) {
                console.error(JSON.stringify(result));
            } else {
                // Set in allbookmarks
                for (const bookmark of bookmarkNames) {
                    await getBookmarks(bookmark, 'bookmark', null, STANDARD_PAGE_SIZE);
                }

                // Show toast message
                    // Retrieve the newly selected from the selectedBookmarkName
                    const infoOfSelected = allBookmark.filter(bookmark => selectedBookmarkName.includes(bookmark.name));
                    const newlyAddedBookmark = infoOfSelected.filter(bookmark =>
                        bookmark.bookmarkAssets.every(asset => asset.assetUuid !== selectedAssetUuid)
                    );

                    if (newlyAddedBookmark.length === 1) {
                        showToast(`Bookmarked to ${newlyAddedBookmark[0].name}`, 'success');
                    }

                    if (newlyAddedBookmark.length > 1 && newCreatedBookmark === null) {
                        showToast(`Bookmarked to ${newlyAddedBookmark.length} folders`, 'success');
                    }

                // Close the modal
                setIsOpenBookMarkModal(false);
            }

        } catch (error) {
            console.error('Error on PUT bookmark:', error);
            updateToastMessage(`${error.message}`,'error');
        }
    };

    const deleteBookmarks = async (bookmarkNames) => {
        try {
            // Construct the body for deletion based on bookmarkNames
            let body = bookmarkNames.reduce((acc, name) => {
                const bookmark = allBookmark.find(b => b.name === name);
                if (bookmark) {
                    acc[name] = {
                        uuid: bookmark.uuid,
                        name: name,
                        tenantUuid: tenantUuid,
                        userUuid: userUuid,
                        bookmarkAssets: [
                            {
                                assetUuid: selectedAssetUuid,
                            }
                        ],
                        timestamp: Date.now()
                    };
                }
                return acc;
            }, {});

            // Call delete API
            const result = await AssetService.deleteSetBookmark(body, tenantUuid);

            if (result?.statusCode !== 200) {
                console.error(result);
                return;
            }

            // Remove bookmarks from IndexedDB and other locations
            const getRemovedBookmarks = allBookmark.filter(bookmark =>
                bookmarkNames.includes(bookmark.name)
            );

            for( const bookmark of getRemovedBookmarks) {
                await removeStoreItem(bookmark.uuid, createStore('bookmarks','asset-bookmarks'));
            }

            // Filter out assets from bookmarks in allBookmark
            const filteredBookmarks = allBookmark.map(bookmark => {
                if (bookmarkNames.includes(bookmark.name)) {
                    const updatedBookmarkAssets = bookmark.bookmarkAssets.filter(asset => asset.assetUuid !== selectedAssetUuid);
                    const updatedAssets = bookmark.assets.filter(asset => asset.uuid !== selectedAssetUuid);
                    return { ...bookmark, bookmarkAssets: updatedBookmarkAssets, assets: updatedAssets };
                }
                return bookmark;
            });

            if(menuTabKey.includes(path.BOOKMARK)) {
                const filteredDisplayItems = displayItems.filter(asset => asset.uuid !== selectedAssetUuid);
                setDisplayBookmarkAssets(filteredDisplayItems);
            }

            setAllBookmark(filteredBookmarks);

            // Show Toast Message
            if (deselectedBookmarkName?.length === 1) {
                showToast(`Removed from ${deselectedBookmarkName[0]}`, 'success');
            } else if (deselectedBookmarkName?.length > 1) {
                showToast(`Removed from ${deselectedBookmarkName.length} folders`, 'success');
            }

            // Close the modal
            setIsOpenBookMarkModal(false);

        } catch (error) {
            console.error('Error on DELETE bookmark:', error.stack);
            updateToastMessage(`${error.message}`, 'error');
        }
    };


    const downloadBlob = async (blob, name) => {
        if (
          window.navigator &&
          window.navigator.msSaveOrOpenBlob
        ) return window.navigator.msSaveOrOpenBlob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = name;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        if(tenantUuid && userUuid) {
            // Add download asset activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "asset", "download", { files: [name] });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }

    const onVideoMouseEnter = () => {
        if (vidRef.current) {
            setPlayVideo(true);

            const handleTimeUpdate = () => {
                if (vidRef.current) {
                    try {
                        const currentTime = vidRef.current.currentTime;
                        const formattedRemainingTime = formatTime(vidRef.current.duration - currentTime);
                        setDuration(formattedRemainingTime);
                    } catch (e) {
                        console.error("Error updating time:", e);
                    }
                }
            };

            // Get the current time of the video
            vidRef.current.addEventListener("timeupdate", handleTimeUpdate);

            try {
                vidRef.current.play();
            } catch (e) {
                console.error("Error playing video:", e);
            }

            // Cleanup function to remove the event listener
            return () => {
                if (vidRef.current) {
                    vidRef.current.removeEventListener("timeupdate", handleTimeUpdate);
                }
            };
        }
    };

    const onVideoMouseLeave = () => {
        if (vidRef.current) {
            setPlayVideo(false);

            const handleTimeUpdate = () => {
                if (vidRef.current) {
                    try {
                        const duration = vidRef.current.duration;
                        const formattedDuration = formatTime(duration);
                        setDuration(formattedDuration);

                    } catch (e) {
                        console.error("Error updating time:", e);
                    }
                }
            };

            // Get the current time of the video
            vidRef.current.addEventListener("timeupdate",handleTimeUpdate);
            try {
                vidRef.current.pause();
                vidRef.current.currentTime = 0;
                setMute(true);
            } catch (e) {
                console.error("Error playing video:", e);
            }

            // Cleanup function to remove the event listener
            return () => {
                if (vidRef.current) {
                    vidRef.current.removeEventListener("timeupdate", handleTimeUpdate);
                }
            };
        }
    }

    const onAudioMouseEnter = () => {
        if (audioRef.current) {
            setPlayAudio(true);

            const handleTimeUpdate = () => {
                try {
                    const currentTime = audioRef.current.currentTime;
                    const formattedRemainingTime = formatTime(audioRef.current.duration - currentTime);
                    setDuration(formattedRemainingTime);
                } catch (e) {
                    console.error("Error updating time:", e);
                }
            }

            // Get the current time of the video
            audioRef.current.addEventListener("timeupdate", handleTimeUpdate);

            try {
                audioRef.current.play();
            } catch (e) {
                console.error("Error playing audio:", e);
            }

            // Cleanup function to remove the event listener
            return () => {
                if (audioRef.current) {
                    audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
                }
            };
        }
    }

    const onAudioMouseLeave = () => {
        if (audioRef.current) {
            setPlayAudio(false);

            const handleTimeUpdate = () => {
                const duration = audioRef.current.duration;
                const formattedDuration = formatTime(duration);
                setDuration(formattedDuration);
            }

            // Get the current time of the audio
            audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);

            try {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setMute(true);
            } catch (e) {
                console.error("Error playing audio:", e);
            }

            // Cleanup function to remove the event listener
            return () => {
                if (audioRef.current) {
                    audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
                }
            };
        }
    }

    const onGifMouseEnter = () => {
        if(!gifUrl) {
            return;
        }
        setDisplayGif(true);
    }

    const onGifMouseLeave = () => {
        if(!gifUrl) {
            return;
        }
        setDisplayGif(false);
    }

    const onPressMute = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (file.type == 'video') {
            setMute(!mute);
            vidRef.current.muted = mute;
        }
        if (file.type == 'audio') {
            setMute(!mute);
            audioRef.current.muted = mute;
        }
    }

    useEffect(() => {
        if(file.overallStatus === 'COMPLETED') {
            const getAssetByIdFunc = async () => {
                const asset = await getAssetsById(file.uuid, tenantUuid)

                if(asset) {
                    setFileUUID(asset.uuid);
                    setKey(asset.key);
                    setStatus(asset.overallStatus);

                    if (asset.type === 'image') {
                        if (asset.mime === 'image/gif') {
                            const proxyurl = URL.createObjectURL(asset.proxyBlob);
                            const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                            setImageUrl(proxyurl);
                            setGifUrl(ingestUrl);
                            setFileName(asset.key);
                            setLoading(false);

                            return;
                        }

                        if (asset.mime === 'image/svg+xml') {
                            const reader = new FileReader();
                            reader.onloadend = function() {
                                const svgString = reader.result;
                                setImageUrl(svgString);
                            }
                            reader.readAsText(asset.ingestBlob);
                            setFileName(asset.key);
                            setLoading(false);

                            return;
                        }

                        if((asset.mime === 'image/tiff') ||
                           ((asset.mime === 'image/jpeg') && (['tiff','TIF','tif'].includes(asset.key.split('.').pop())))) {
                            try {
                                const proxyurl = URL.createObjectURL(asset.proxyBlob);
                                setImageUrl(proxyurl);
                                setFileName(asset.key);
                                setLoading(false);

                                return;
                              } catch (error) {
                                console.error('Error fetching and converting image:', error);
                              }
                        }

                        const url = URL.createObjectURL(asset.ingestBlob);
                        setImageUrl(url);
                        setFileName(asset.key);
                        setLoading(false);
                    }

                    if (asset.type === 'document') {
                        const excludeFileTypes = SolutionManifest.documentExcludeFileTypes;
                        if (!excludeFileTypes.includes(asset.mime)) {
                            const url = URL.createObjectURL(asset.proxyBlob);
                            setImageUrl(url);
                        }
                        setFileName(asset.key);
                        setMime(asset.mime);
                        setLoading(false);
                    }

                    if (asset.type === 'video') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                        const proxyUrl = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(proxyUrl);
                        setVideoBlob(ingestUrl);
                        setVideoContentType(asset.mime);
                        setFileName(asset.key);
                        setDuration(asset.duration);
                        setLoading(false);
                    }

                    if (asset.type === 'audio') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);

                        setMime(asset.mime);
                        setFileName(asset.key);
                        setAudioContentType(asset.mime);
                        setAudioUrl(ingestUrl);
                        setDuration(asset.duration);
                        setLoading(false);
                    }
                } else {
                    setImageUrl(null);
                    setLoading(false);
                }
            };
            getAssetByIdFunc();
        } else {
            setStatus(file.overallStatus);
            setLoading(false);
        };
    }, [file]);

    // for the first part of the long text
    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -4 + (get_file_extension(fulltext).length * -1)); // Remove the last 3 or 4 characters and the extension
        return truncatedFulltext;
    }

    const get_file_extension = (filename) => {
        const parts = filename.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1];
        } else {
            return null;
        }
    };

    // for the end part of the long text
    const truncateEndText = (fulltext) => {
        const extension = "." + get_file_extension(fulltext);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    }

    const handleMobileModalClose = () => {
        if(isCreateSelected) {
            setNewCreatedBookmark(null);
            setEditBookmarkError('');
            setIsCreateSelected(false);
        } else {
            setIsMobileModalOpen(false);
            setIsCreateSelected(false);
            setDeselectedBookmarkName([]);
            setSelectedBookmarkName([]);
            setNewCreatedBookmark(null);
            setEditBookmarkError('');
        }
    }

    const handleBookMarkModalClose = () => {
        if(isCreateSelected) {
            setNewCreatedBookmark(null);
            setEditBookmarkError('');
            setIsCreateSelected(false);
        } else {
            setIsOpenBookMarkModal(!isOpenBookMarkModal);
            setIsCreateSelected(false);
            setDeselectedBookmarkName([]);
            setSelectedBookmarkName([]);
            setNewCreatedBookmark(null);
            setEditBookmarkError('');
        }
    }

    const Card = () => {
        return (<>
            {
                loading
                ?
                    <AssetCardSkeleton />
                :
                    status !== 'COMPLETED' ?
                        null
                    :
                        <div className={isTablet ? 'asset-card-wrapper col-md-4' : 'asset-card-wrapper col-md-3'} onMouseEnter={file.type === 'audio' ? onAudioMouseEnter : file.type === 'video' ? onVideoMouseEnter : null} onMouseLeave={file.type === 'audio' ? onAudioMouseLeave : file.type === 'video' ? onVideoMouseLeave : null}>
                            <div className='asset-card-container border'>
                                <div className={`asset-card-header ${file.type === 'image' && 'header-image' } ${file.type === 'video' ||  file.type === 'audio' ? 'header-video' : ''}`}>
                                    {
                                        fileName !== null &&
                                        <div className="grid-view-title-container">
                                            <div className="first-text">{truncateFirstText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                            <div className="end-text">{truncateEndText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                        </div>
                                    }
                                </div>
                                <div
                                    onClick={() => handleViewAssetOpen(fileUUID, displayItems)}
                                    className={loading ? 'asset-card-body asset-card-body-loading' : 'asset-card-body'}>
                                    {
                                        imageUrl &&
                                                file.type === 'image' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                            <div
                                                                key={fileUUID}
                                                                onMouseEnter={onGifMouseEnter}
                                                                onMouseLeave={onGifMouseLeave}
                                                                className='ratio ratio-4x3'
                                                            >
                                                                {
                                                                    fileName && (fileName.endsWith('.JPEG') || fileName.endsWith('.jpeg') || fileName.endsWith('.JPG') || fileName.endsWith('.jpg') ||
                                                                                 fileName.endsWith('.png') || fileName.endsWith('.bmp') ||
                                                                                 fileName.endsWith('.TIF') || fileName.endsWith('.tif') || fileName.endsWith('.TIFF') || fileName.endsWith('.tiff')) &&
                                                                        <img
                                                                            style={{ display: displayGif? 'none': 'block' }}
                                                                            alt={ fileName }
                                                                            src={ imageUrl }
                                                                            loading="lazy"
                                                                        />
                                                                }
                                                                {
                                                                    fileName && (fileName.endsWith('.svg')) &&
                                                                        <SvgComponent svgString={imageUrl} />
                                                                }
                                                                {
                                                                    fileName && fileName.endsWith('.gif') &&
                                                                        <>
                                                                            <img
                                                                                alt={ fileName }
                                                                                src={ gifUrl }
                                                                                loading="lazy"
                                                                            />
                                                                            <div className='movie-footer'>
                                                                                {(fileName && fileName.endsWith('.gif')) && <div><CustomIcons className='my-svg' variant='gif' color="white"/></div>}
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    (fileName && (fileName.endsWith('.jpg') || fileName.endsWith('.JPG')) || fileName && (fileName.endsWith('.jpeg') || fileName.endsWith('.JPEG'))) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && (fileName.endsWith('.jpg') || fileName.endsWith('.JPG')) || fileName && (fileName.endsWith('.jpeg') || fileName.endsWith('.JPEG'))) && <div><ImageIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    fileName && fileName.endsWith('.png') &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.png')) && <div><ImageIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    (fileName && fileName.endsWith('.svg') || fileName && (fileName.endsWith('.tif') || fileName.endsWith('.TIF')) || fileName && (fileName.endsWith('.tiff') || fileName.endsWith('.TIFF'))) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.svg') || fileName && (fileName.endsWith('.tif') || fileName.endsWith('.TIF')) || fileName && (fileName.endsWith('.tiff') || fileName.endsWith('.TIFF'))) && <div><ImageIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'document' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>

                                                            <div
                                                                key={fileUUID}
                                                                className='ratio ratio-4x3'
                                                            >
                                                                {renderDocumentInnerBody(mime, imageUrl)}
                                                                {
                                                                    fileName && fileName.endsWith('.pdf') &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.pdf')) && <div><DocumentIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    fileName && (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && (fileName.endsWith('.xlsx') || fileName.endsWith('.xls'))) && <div><DocumentIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    fileName && (fileName.endsWith('.docx') || fileName.endsWith('.doc')) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && (fileName.endsWith('.docx') || fileName.endsWith('.doc'))) && <div><DocumentIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    fileName && (fileName.endsWith('.pptx') || fileName.endsWith('.ppt')) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && (fileName.endsWith('.pptx') || fileName.endsWith('.ppt'))) && <div><DocumentIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'video' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                            <div
                                                                // onMouseEnter={onVideoMouseEnter}
                                                                // onMouseLeave={onVideoMouseLeave}
                                                                key={fileUUID}
                                                                className='ratio ratio-4x3'
                                                            >
                                                                <video ref={ vidRef } muted={mute} playsInline>
                                                                    <source src={videoBlob} type="video/mp4"/>
                                                                </video>
                                                                <div className='volumn-icon-section' onClick={(e) => onPressMute(e)}
                                                                    style={{ display: playVideo? 'block': 'none' }} >
                                                                    {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                                                </div>
                                                                <div className='movie-footer'>
                                                                    <div>
                                                                        <MovieIcon className='my-svg' color='white' />
                                                                    </div>
                                                                    <p>{duration}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'audio' ?
                                                    <div className='w-100 h-100'>
                                                            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                                <div
                                                                    // onMouseEnter={onAudioMouseEnter}
                                                                    // onMouseLeave={onAudioMouseLeave}
                                                                    key={fileUUID}
                                                                    className='ratio ratio-4x3'
                                                                >
                                                                    <div className='svg-icon'>
                                                                        <FontAwesomeIcon className='svg-icon' icon={faFileAudio} />
                                                                        <audio muted={mute} ref={audioRef}>
                                                                            <source src={audioUrl} type={audioContentType}/>
                                                                        </audio>
                                                                    </div>
                                                                    <div className='volumn-icon-section' onClick={(e) => onPressMute(e)}
                                                                            style={{ display: playAudio ? 'inline': 'none' }} >
                                                                        {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                                                    </div>
                                                                    <div className='movie-footer'>
                                                                        <div>
                                                                            <AudiotrackRoundedIcon className='my-svg' color='white' />
                                                                        </div>
                                                                        <p>{duration}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                    <div>
                                                    </div>
                                    }
                                    </div>
                                    <div className='asset-card-footer'>
                                        <div className="d-grid">
                                            <ButtonGroup className="d-flex">
                                                {downloading ? <CustomIcons variant="spinner" />
                                                    : <Button className='icon-button' variant='link' onClick={() => download()}><CustomIcons variant="download" /></Button>}
                                            </ButtonGroup>
                                        </div>
                                        <div className="d-grid">
                                            <ButtonGroup className="d-flex">
                                                <Button className='icon-button' variant='link' onClick={() => copyLink(fileUUID)}><CustomIcons variant="link" /></Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="d-grid">
                                            <ButtonGroup className="d-flex">
                                                <Button className='icon-button' variant='link' onClick={() => sendEmail(fileUUID)}><CustomIcons variant="email" /></Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="d-grid">
                                            <ButtonGroup className="d-flex">
                                                <Button className='icon-button' variant='link' onClick={() => setAddBookmark(fileUUID)}><CustomIcons filled={allBookmark.some(bookmark => bookmark.assets.some(asset => asset.uuid === fileUUID)) || Boolean(menuTabKey?.includes(path.BOOKMARK) && true)} variant="bookmark" />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                            </div>
                            {
                                <GenericModal
                                    modalType="addBookMark"
                                    className="bookmark-modal"
                                    modalShow={isOpenBookMarkModal}
                                    saveBookmark={saveBookmark}
                                    isCreateSelected={isCreateSelected}
                                    handleModalClose={handleBookMarkModalClose}
                                    handleBookMarkModalClose={handleBookMarkModalClose}
                                />
                            }
                        </div>
            }
        </>);
    }

    const Row = () => {
        return (<>
            {
                loading
                ?
                    <AssetCardSkeleton view="row"/>
                :
                    status !== 'COMPLETED' ?
                        null
                    :
                        <tr>
                            <td className='thumbnail' onClick={() => handleViewAssetOpen(fileUUID, displayItems)}>
                            {
                                imageUrl &&
                                        file.type === 'image' ?
                                        (
                                            fileName && fileName.endsWith('.svg') ?
                                                <SvgComponent svgString={imageUrl} />
                                            :
                                                <img alt={fileName} src={imageUrl} loading="lazy"/>
                                        ):
                                        file.type === 'document' ?
                                            <div>
                                                {renderDocumentInnerBody(mime, imageUrl)}
                                            </div> :
                                        file.type === 'video' ?
                                            <div>
                                                {imageUrl !== null && <div>
                                                    <img
                                                    alt={ fileName }
                                                    src={ imageUrl } loading="lazy"/>
                                                </div>}
                                                {imageUrl === null &&
                                                    <div className='svg-icon'>
                                                        <MovieIcon fontSize='large' />
                                                    </div>}
                                            </div> :
                                        file.type === 'audio' ?
                                        <div className='row-sound-icon-container'>
                                            <FontAwesomeIcon className='svg-icon' icon={faFileAudio} />
                                        </div> :
                                        <div>
                                        </div>
                                }
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    <div className="list-view-title-container">
                                        {/* <div className="first-text">{truncateFirstText(fileName.basename)}</div>
                                        <div className="end-text">{truncateEndText(fileName.basename)}</div> */}
                                        <div className="first-text">{truncateFirstText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                        <div className="end-text">{truncateEndText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                    </div>
                                    <div className="mobile-type-and-size-container">
                                        <span className="mobile-type">
                                            {file.type[0].toUpperCase() + file.type.slice(1, file.type.length)} {file.mime === 'image/gif' ? '(GIF)' : ''}
                                        </span>
                                        <span className="mobile-size">{formatBytes(file.size)}</span>
                                    </div>
                                </div>
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    {file.type[0].toUpperCase() + file.type.slice(1, file.type.length)} {file.mime === 'image/gif' ? '(GIF)' : ''}
                                </div>
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    {formatBytes(file.size)}
                                </div>
                            </td>
                            <td className='text-left button-group'>
                                <div className="td-assets">
                                    <div className="mobile-more-vertical" onClick={() => setIsMobileModalOpen(true)} style={{ transform: "rotate(90deg)" }}>
                                        <CustomIcons variant="more" />
                                    </div>
                                    <Button size='sm' className="btn-row-action" onClick={() => handleViewAssetOpen(fileUUID, displayItems)} variant='link'><RemoveRedEyeIcon /></Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => copyLink(fileUUID)} variant='link'>
                                        {<CustomIcons variant="link" />}
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => sendEmail(fileUUID)} variant='link'>
                                        {<CustomIcons variant="email" />}
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => download()} variant='link'>
                                        {downloading && <SpinnerIcon />}
                                        {!downloading && <CustomIcons variant="download" />}
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => setAddBookmark(fileUUID)} variant='link'>
                                        {<CustomIcons filled={allBookmark.some(bookmark => bookmark.assets.some(asset => asset.uuid === fileUUID)) || Boolean(menuTabKey?.includes(path.BOOKMARK) && true)} variant="bookmark" />
                                        }
                                    </Button>
                                </div>
                            </td>
                            {
                                isTablet &&
                                    <hr />
                            }
                        </tr>
            }
            {
                isMobileModalOpen && (
                    <tr>
                        <td className='modal-container'>
                            <MobileModal titleWithExtension={fileName} onClose={handleMobileModalClose}>
                                <div className="mobile-modal-more-vertical">
                                    <Button size='sm' className="btn-row-action" onClick={() => { handleMobileModalClose(); handleViewAssetOpen(fileUUID, displayItems); }} variant='link'>
                                        <RemoveRedEyeIcon />
                                        <h3>View</h3>
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => download()} variant='link'>
                                        {downloading && <SpinnerIcon />}
                                        {!downloading &&
                                            <>
                                                <CustomIcons variant="download" />
                                                <h3>Download</h3>
                                            </>
                                        }
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => copyLink(fileUUID)} variant='link'>
                                        <CustomIcons variant="link" />
                                        <h3>Copy link</h3>
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => sendEmail(fileUUID)} variant='link'>
                                        <CustomIcons variant="email" />
                                        <h3>Email</h3>
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => {setAddBookmark(fileUUID); setIsMobileModalOpen(false)}} variant='link'>
                                        <CustomIcons variant="bookmark" />
                                        <h3>Bookmark</h3>
                                    </Button>
                                </div>
                            </MobileModal>
                        </td>
                    </tr>
                )
            }
            { isOpenBookMarkModal &&
                <GenericModal
                    modalType="addBookMark"
                    className="bookmark-modal"
                    saveBookmark={saveBookmark}
                    modalShow={isOpenBookMarkModal}
                    isCreateSelected={isCreateSelected}
                    handleModalClose={handleBookMarkModalClose}
                    handleBookMarkModalClose={handleBookMarkModalClose}
                />
            }
        </>);
    }

    return cardView === "row"? Row() : Card();
}

export default AssetCard;