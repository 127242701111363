import { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileAudio, faFileExcel, faFilePowerpoint, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { convertPdfToImage } from '../../shared/utils';
import { UploadContext } from '../../shared/context/upload-provider';
import { Button } from 'react-bootstrap';
import { IsMobile, IsDesktop, IsXLG_To_MDTablet } from '../../shared/utils';
import { Checkbox } from 'antd';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Localization from '../../shared/localization';
import CustomIcons from '../../shared/components/custom-icons';
import MobileModal from '../../shared/components/mobile-modal';
import ImageIcon from '../../icons/image';
import DocumentIcon from '../../icons/document';
import MovieIcon from '@mui/icons-material/Movie';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import SpinnerIcon from '../../icons/spinner';
import * as UTIF from 'utif';

GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.js';

const UploadCard = ({onClickRemove, file, isSelected, onSelectedChange, onSelectedClick, disableDelete, FSLightbox, updateFSLightBox, progressState}) => {
    const [muted, setMuted] = useState(true);
    const [pdfFileImage, setPDFFileImage] = useState("");
    const [currentFileTime, setCurrentFileTime] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [MuteVisible, setMuteVisible] = useState(false);
    const [pdfLoading, setPDFLoading] = useState(false);
    const [isMobileModalOpenAction, setIsMobileModalOpenAction] = useState(false);
    const isMobile = IsMobile();
    const isMdTablet = IsXLG_To_MDTablet();
    const isDesktop = IsDesktop();

    const { videoRef,
        audioRef,
        imageRef,
        pdfRef,
        activeSettingView} = useContext(UploadContext);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    }

    const updatepdfLoading = () => setPDFLoading(true);

    const updatepdfImg = (src) => setPDFFileImage(src);

    useEffect(() => {
        if(videoRef.current[file.id] && file.type === 'video') {
            videoRef.current[file.id].addEventListener("loadedmetadata", function() {
                const duration = videoRef.current[file.id].duration;
                const formattedDuration = formatTime(duration);

                setCurrentFileTime(formattedDuration);
            });
        } else if(audioRef.current[file.id] && file.type === 'audio') {
            audioRef.current[file.id].addEventListener("loadedmetadata", function() {
                const duration = audioRef.current[file.id].duration;
                const formattedDuration = formatTime(duration);

                setCurrentFileTime(formattedDuration);
            });
        } else if(pdfRef.current[file.id]) {
            var reader = new FileReader();

            reader.onload = function (event) {
                var pdfData = event.target.result;
                convertPdfToImage(pdfData, getDocument, updatepdfLoading, updatepdfImg);
            };

            reader.readAsArrayBuffer(file.object);
        }
    }, [file]);

    const handleMouseEnter = () => {
        if(disableDelete){
            return;
        }
        setMuteVisible(true);

        if(videoRef.current[file.id] !== null && file.type === 'video') {
            if(muted) {
                videoRef.current[file.id].muted = true;
            } else {
                videoRef.current[file.id].muted = false;
            }
            videoRef.current[file.id].addEventListener("timeupdate", function() {
                // Get the current time of the video
                const currentTime = videoRef.current[file.id].currentTime;
                const formattedRemainingTime = formatTime(videoRef.current[file.id].duration - currentTime);

                setCurrentFileTime(formattedRemainingTime);
            });
            videoRef.current[file.id].play();
        } else if(audioRef.current[file.id] !== null && file.type === 'audio') {
            if(muted) {
                audioRef.current[file.id].muted = true;
            } else {
                audioRef.current[file.id].muted = false;
            }
            audioRef.current[file.id].addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const currentTime = audioRef.current[file.id].currentTime;
                const formattedRemainingTime = formatTime(audioRef.current[file.id].duration - currentTime);

                setCurrentFileTime(formattedRemainingTime);
            });
            audioRef.current[file.id].play();
        }
    };

    const handleMouseLeave = () => {
        setMuteVisible(false);
        setMuted(true);

        if(videoRef.current[file.id] !== null && file.type === 'video') {
            videoRef.current[file.id].currentTime = 0;
            videoRef.current[file.id].pause();

            videoRef.current[file.id].addEventListener("timeupdate", function() {
                // Get the current time of the video
                const duration = videoRef.current[file.id].duration;
                const formattedDuration = formatTime(duration);

                setCurrentFileTime(formattedDuration);
            });
        } else if(audioRef.current[file.id] !== null && file.type === 'audio') {
            audioRef.current[file.id].currentTime = 0;
            audioRef.current[file.id].pause();

            audioRef.current[file.id].addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const duration = audioRef.current[file.id].duration;
                const formattedDuration = formatTime(duration);

                setCurrentFileTime(formattedDuration);
            });
        }
    };

    const handleMouseClick = () => {
        const isVideo = file.type === 'video';
        const isAudio = file.type === 'audio';
        const mediaRef = isVideo ? videoRef.current[file.id] : (isAudio ? audioRef.current[file.id] : null);

        if (mediaRef !== null) {
            const isCurrentlyMuted = !muted;
            setMuted(isCurrentlyMuted);
            mediaRef.muted = isCurrentlyMuted;
            mediaRef.play();
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const get_file_extension = (filename) => {
        const parts = filename.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1];
        } else {
            return null;
        }
    };

    // for the first part of the long text
    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -4 + (get_file_extension(fulltext).length * -1)); // Remove the last 3 or 4 characters and the extension

    return truncatedFulltext;
    }

    // for the end part of the long text
    const truncateEndText = (fulltext) => {
        const extension = "." + get_file_extension(fulltext);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    }

    const renderUploadContent = () => {
        return (
            <>
                <div className='upload-card-body'>
                        <div className='ratio ratio-4x3'>
                            {
                                file.type === "image" && (file.name.endsWith('.tif') || file.name.endsWith('.tiff') ) ?
                                    <div
                                        style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}}
                                        className='upload-card-image upload-card-image-visible'>
                                        <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                        <img
                                            ref={el => {
                                                if (el) {
                                                    const reader = new FileReader();
                                                    reader.onload = function(e) {
                                                        const buffer = e.target.result;
                                                        const ifds = UTIF.decode(buffer);
                                                        if (ifds.length > 0) {
                                                            UTIF.decodeImage(buffer, ifds[0]);
                                                            const rgba = UTIF.toRGBA8(ifds[0]);
                                                            const canvas = document.createElement('canvas');
                                                            canvas.width = ifds[0].width;
                                                            canvas.height = ifds[0].height;
                                                            const ctx = canvas.getContext('2d');
                                                            const imgData = ctx.createImageData(canvas.width, canvas.height);
                                                            imgData.data.set(rgba);
                                                            ctx.putImageData(imgData, 0, 0);
                                                            el.src = canvas.toDataURL();
                                                        }
                                                    };
                                                    reader.readAsArrayBuffer(file.object);
                                                }
                                            }}
                                            alt={ file.name }
                                        />
                                    </div> :
                                file.type === 'image'
                                ?
                                        <div
                                            style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}}
                                            className='upload-card-image upload-card-image-visible'>
                                            {/* <div className="upload-card-zoom-2" style={{background: `url(${URL.createObjectURL(file.object)})`}}></div> */}
                                            <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                            <img
                                                ref={el => imageRef.current[file.id] = el}
                                                alt={ file.name }
                                                src={ URL.createObjectURL(file.object) }

                                            />
                                            <div className="upload-card-image-icon">
                                                <ImageIcon color="#ffffff" />
                                            </div>
                                        </div>
                                :
                                file.type === 'gif'
                                ?
                                        <div
                                            style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}}
                                            className='upload-card-image upload-card-image-visible'>
                                            {/* <div className="upload-card-zoom-2" style={{background: `url(${URL.createObjectURL(file.object)})`}}></div> */}
                                            <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                            {/*<div className="upload-card-bottom-container">
                                                <div className='upload-card-zoom upload-card-zoom-visible'
                                                    onClick={() => updateFSLightBox(URL.createObjectURL(file.object),
                                                        !FSLightbox, 'image', file)}
                                                >
                                                    <CustomIcons variant='zoom' />
                                                </div>
                                                    </div>*/}
                                            <img
                                                ref={el => imageRef.current[file.id] = el}
                                                alt={ file.name }
                                                src={ URL.createObjectURL(file.object) }

                                            />
                                            <div className="upload-card-image-icon">
                                                <CustomIcons variant='gif' color="white"/>
                                            </div>
                                        </div>
                                :
                                file.type === 'pdf' ?
                                    <div style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}} className={pdfLoading ? 'upload-card-pdf upload-card-pdf-visible upload-card-pdf-loaded' : 'upload-card-pdf'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                        <img
                                            ref={ el => pdfRef.current[file.id] = el }
                                            alt={ file.name }
                                            src={ pdfFileImage }
                                        />
                                        <div className='upload-card-icon'>
                                            <FontAwesomeIcon icon={faFilePdf} />
                                        </div>
                                        <div className="upload-card-image-icon">
                                            <DocumentIcon color="#ffffff" />
                                        </div>
                                    </div>
                                :
                                file.type === "document" ?
                                    <div style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}} className='upload-card-icon' >
                                        <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                        { (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) && <FontAwesomeIcon icon={faFileExcel} /> }
                                        { (file.name.endsWith('.doc') || file.name.endsWith('.docx')) && <FontAwesomeIcon icon={faFileWord} /> }
                                        { (file.name.endsWith('.ppt') || file.name.endsWith('.pptx')) && <FontAwesomeIcon icon={faFilePowerpoint} /> }
                                    </div> :
                                file.type === "video" ?
                                    <div style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}} className='upload-card-video'>
                                        <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                        {!disableDelete && (
                                            <div className={isDesktop ? MuteVisible ? 'upload-card-video-mute upload-card-video-mute-visible': 'upload-card-video-mute' :'d-none'} onClick={handleMouseClick}>
                                                {muted ? <CustomIcons variant='audio' muted={muted}/> : <CustomIcons variant='audio' muted={muted} />}
                                            </div>
                                        )}
                                        <div className='upload-card-video-duration' onClick={handleMouseClick}>
                                            <span>{currentFileTime}</span>
                                        </div>

                                        <video ref={el => videoRef.current[file.id] = el} playsInline loop>
                                            <source src={URL.createObjectURL(file.object)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="upload-card-image-icon">
                                            <MovieIcon color="#ffffff" />
                                        </div>
                                    </div> :
                                file.type === "audio" ?
                                    <div style={disableDelete || file.state === 'UPLOADING' ? {cursor: 'initial'} : {}} className='upload-card-audio'>
                                        <div className="upload-card-click-box" data-checked={isSelected} onClick={onSelectedClick}></div>
                                        <div className="upload-card-bottom-container-audio">
                                            {!disableDelete && (
                                            <div className={MuteVisible ? 'upload-card-audio-mute upload-card-audio-mute-visible' : 'upload-card-audio-mute'} onClick={handleMouseClick}>
                                                {muted ? <CustomIcons variant='audio' muted={muted} /> : <CustomIcons variant='audio' muted={muted} />}
                                            </div>
                                            )}

                                            <div className='upload-card-audio-duration' onClick={handleMouseClick}>
                                                <span>{currentFileTime}</span>
                                            </div>
                                        </div>
                                        <div className='upload-card-icon'>
                                            <FontAwesomeIcon icon={faFileAudio} />
                                        </div>
                                        <audio ref={el => audioRef.current[file.id] = el} loop>
                                            <source src={URL.createObjectURL(file.object)} type="audio/mpeg" />
                                            Your browser does not support the audio tag.
                                        </audio>
                                        <div className="upload-card-image-icon">
                                            <AudiotrackRoundedIcon color="#ffffff" />
                                        </div>
                                    </div> :
                                    <div className='upload-card-icon' >
                                        <QuestionMarkIcon className='upload-card-icon' />
                                    </div>

                            }
                        </div>
                </div>
                <div className={`upload-card-footer ${activeSettingView !== 'list' ? 'upload-footer-hover' : ''}`}>
                    {
                        file.type === 'image' ||
                        file.type === 'gif' ||
                        file.type === 'pdf' ||
                        file.type === "document" ?
                            <>
                                <div style={{width: '100%'}} className="grid-view-title-container">
                                    <div className="first-text">{truncateFirstText(file.name)}</div>
                                    <div className="end-text">{truncateEndText(file.name)}</div>
                                </div>
                                <div className="list-view-title-container">
                                    <div className='filename-container text-truncate'>
                                        <div className="first-text">{truncateFirstText(file.name)}</div>
                                        <div className="end-text">{truncateEndText(file.name)}</div>
                                    </div>
                                    {isMobile &&
                                        <div className='card-type-text'>
                                            {
                                                file.type === 'image'
                                                ?
                                                    Localization.Upload.Type.Image
                                                :
                                                    file.type === 'gif'
                                                ?
                                                    Localization.Upload.Type.Gif
                                                :
                                                    file.type === 'pdf' ||
                                                    file.type === "document"
                                                ?
                                                    Localization.Upload.Type.Doc
                                                :
                                                    file.type === "video"
                                                ?
                                                    Localization.Upload.Type.Video
                                                :
                                                    file.type === "audio"
                                                ?
                                                    Localization.Upload.Type.Audio
                                                : null
                                            }
                                        </div>}
                                </div>
                            </> : null
                    }
                    {
                        file.type === "video" ||
                        file.type === "audio" ?
                            <>
                                <div  style={{width: '63%'}} className="grid-view-title-container">
                                    <div className="first-text">{truncateFirstText(file.name)}</div>
                                    <div className="end-text">{truncateEndText(file.name)}</div>
                                </div>
                                <div className="list-view-title-container">
                                    <div className='filename-container text-truncate'>
                                        <div className="first-text">{truncateFirstText(file.name)}</div>
                                        <div className="end-text">{truncateEndText(file.name)}</div>
                                    </div>
                                    {isMobile &&
                                        <div className='card-type-text'>
                                            {
                                                file.type === 'image'
                                                ?
                                                    Localization.Upload.Type.Image
                                                :
                                                    file.type === 'gif'
                                                ?
                                                    Localization.Upload.Type.Gif
                                                :
                                                    file.type === 'pdf' ||
                                                    file.type === "document"
                                                ?
                                                    Localization.Upload.Type.Doc
                                                :
                                                    file.type === "video"
                                                ?
                                                    Localization.Upload.Type.Video
                                                :
                                                    file.type === "audio"
                                                ?
                                                    Localization.Upload.Type.Audio
                                                : null
                                            }
                                        </div>}
                                </div>
                            </> : null
                    }
                </div>
            </>
        )
    }
    return(
        <div className="upload-card-wrapper" id={file.id} onMouseEnter={isDesktop ? handleMouseEnter : null} onMouseLeave={isDesktop ? handleMouseLeave : null}>
            <div className={isSelected ? "upload-card-cont upload-card-cont-checked" : "upload-card-cont"}>
                <div className={'upload-card-header' + (activeSettingView === 'list' ? ' upload-card-header-list' : '')}>
                    {
                        file.state === 'UPLOADING'
                        ?
                            <div className='upload-card-process'>
                                <SpinnerIcon /> {!isDesktop ? activeSettingView === 'grid' ? Localization.Upload.Uploading : '' : Localization.Upload.Uploading}
                            </div>
                        :
                        file.state === 'ANALYSING'
                        ?
                            <div className='upload-card-process'>
                                <SpinnerIcon /> {activeSettingView === 'grid' ? Localization.Upload.Analysing : ''}
                            </div>
                        :
                        file.state === 'ERROR'
                        ?
                            <div className='upload-card-error'>
                                <ReportProblemIcon/> {!isDesktop ? activeSettingView === 'grid' ? 'Failed' : '' : Localization.Upload.Error}
                            </div>
                        :
                        file.state === 'COMPLETED'
                        ?
                            <div className='upload-card-completed'>
                               <CheckCircleIcon/> {!isDesktop ? activeSettingView === 'grid' ? Localization.Upload.Completed : '' : Localization.Upload.Completed}
                            </div>
                        :
                            <div className={ file.type === 'audio' ? 'upload-card-checkbox without-zoom' : 'upload-card-checkbox' }>
                                <Checkbox
                                    id={file.id}
                                    onChange={onSelectedChange}
                                    checked={isSelected}
                                />
                                {
                                    activeSettingView === 'grid' &&
                                    <>
                                        {
                                            file.type === 'image' || file.type === 'gif' ?
                                                <div className="upload-card-bottom-container" >
                                                    <div className='upload-card-zoom upload-card-zoom-visible'
                                                        onClick={() => updateFSLightBox(URL.createObjectURL(file.object),
                                                            !FSLightbox, 'image', file)}
                                                    >
                                                        <CustomIcons variant='zoom' size="medium" />
                                                    </div>
                                                </div>
                                            : file.type === 'pdf' ?
                                                <div className="upload-card-bottom-container">
                                                    <div className='upload-card-zoom upload-card-zoom-visible' onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'custom', file, onDocumentLoadSuccess, numPages)}>
                                                        <CustomIcons variant='zoom' />
                                                    </div>
                                                </div>
                                            : file.type === 'video' ?
                                                <div className="upload-card-bottom-container">
                                                    <div className='upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'video', file)}>
                                                        <CustomIcons variant='zoom' />
                                                    </div>
                                                </div>
                                            : file.type === 'audio' ?
                                                <div className="upload-card-bottom-container">
                                                    <div className='upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'audio', file)}>
                                                        <CustomIcons variant='zoom' />
                                                    </div>
                                                </div>
                                            : null
                                        }
                                        <button className='icon-button upload-card-delete' onClick={() => onClickRemove(file) }>
                                            <CustomIcons variant='trash' color="#000000" />
                                        </button>
                                    </>
                                }

                            </div>
                    }
                </div>
                {activeSettingView === 'list' ? (
                    <div className="d-flex flex-row flex-1" data-checked={!isDesktop ? isSelected : null} onClick={!isDesktop ? onSelectedClick : null}>
                        {renderUploadContent()}
                    </div>
                ) : renderUploadContent()}
                <div className='upload-card-type'>
                    {
                        file.type === 'image'
                        ?
                            Localization.Upload.Type.Image
                        :
                            file.type === 'gif'
                        ?
                            Localization.Upload.Type.Gif
                        :
                            file.type === 'pdf' ||
                            file.type === "document"
                        ?
                            Localization.Upload.Type.Doc
                        :
                            file.type === "video"
                        ?
                            Localization.Upload.Type.Video
                        :
                            file.type === "audio"
                        ?
                            Localization.Upload.Type.Audio
                        : null
                    }
                </div>
                {
                    file.attributes.termsofuse &&
                        <div className='upload-card-termsofuse'>
                            { file.attributes.termsofuse }
                        </div>
                }
                {
                    disableDelete
                    ?
                        file.state === 'UPLOADING'
                        ?
                            <div className='upload-card-process'>
                                <SpinnerIcon /> {!isDesktop ? activeSettingView === 'grid' ? Localization.Upload.Uploading : '' : Localization.Upload.Uploading}
                            </div>
                        :
                        file.state === 'ANALYSING'
                        ?
                            <div className='upload-card-process'>
                                <SpinnerIcon /> {!isDesktop ? activeSettingView === 'grid' ? Localization.Upload.Analysing : '' : Localization.Upload.Analysing}
                            </div>
                        :
                        file.state === 'ERROR'
                        ?
                            <div className='upload-card-error'>
                                <ReportProblemIcon/> {!isDesktop ? activeSettingView === 'grid' ?  Localization.Upload.Error : '' : Localization.Upload.Error}
                            </div>
                        :
                        file.state === 'COMPLETED'
                        ?
                            <div className='upload-card-completed upload-card-completed-list'>
                                <CheckCircleIcon/> {!isDesktop ? activeSettingView === 'grid' ? Localization.Upload.Completed : '' : (!isMdTablet? Localization.Upload.Completed : '')}
                            </div>
                        :  <> 
                        <div className='upload-card-delete'>
                        {isDesktop &&
                            file.type === 'image' || file.type === 'gif' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'
                                    onClick={() => updateFSLightBox(URL.createObjectURL(file.object),
                                        !FSLightbox, 'image', file)}
                                >
                                    <CustomIcons variant='zoom' size="medium" />
                                </div>
                            : file.type === 'pdf' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible' onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'custom', file, onDocumentLoadSuccess, numPages)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : file.type === 'video' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'video', file)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : file.type === 'audio' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'audio', file)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : null
                        }
                        {isDesktop &&
                            <button className='icon-button' onClick={() => onClickRemove(file) }>
                                <CustomIcons variant='trash' />
                            </button>
                        }
                    </div>
                       { activeSettingView === 'list' && !isDesktop && !progressState &&
                       <div className='upload-card-more-button'>
                           <button className="mobile-more-vertical" onClick={() => setIsMobileModalOpenAction(true)}/* onClick={() => handleMobileModal(name, isDisabled, record, labels, colors, uuid, joinedRoles)} */>
                               <CustomIcons variant="more" />
                           </button>
                       </div>
                       }
                       </>
                    :<>
                    <div className='upload-card-delete'>
                        {isDesktop &&
                            file.type === 'image' || file.type === 'gif' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'
                                    onClick={() => updateFSLightBox(URL.createObjectURL(file.object),
                                        !FSLightbox, 'image', file)}
                                >
                                    <CustomIcons variant='zoom' size="medium" />
                                </div>
                            : file.type === 'pdf' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible' onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'custom', file, onDocumentLoadSuccess, numPages)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : file.type === 'video' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'video', file)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : file.type === 'audio' ?
                                <div className='icon-button upload-card-zoom upload-card-zoom-visible'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'audio', file)}>
                                    <CustomIcons variant='zoom' />
                                </div>
                            : null
                        }
                        {isDesktop &&
                            <button className='icon-button' onClick={() => onClickRemove(file) }>
                                <CustomIcons variant='trash' />
                            </button>
                        }
                    </div>
                      { activeSettingView === 'list' && !isDesktop && !progressState &&
                      <div className='upload-card-more-button'>
                          <button className="mobile-more-vertical" onClick={() => setIsMobileModalOpenAction(true)}/* onClick={() => handleMobileModal(name, isDisabled, record, labels, colors, uuid, joinedRoles)} */>
                              <CustomIcons variant="more" />
                          </button>
                      </div>
                      }
                    </>
                }
                   {
                isMobileModalOpenAction && (
                    <MobileModal isCapitalize={true} titleWithExtension={file.name} onClose={() => setIsMobileModalOpenAction(false)}>
                        <div className="mobile-modal-more-vertical">
                            {
                            file.type === 'image' || file.type === 'gif' ?
                                <Button size='sm' className="btn-row-action" variant='link'
                                    onClick={() => updateFSLightBox(URL.createObjectURL(file.object),
                                        !FSLightbox, 'image', file)}
                                >
                                    <CustomIcons variant='zoom' size="medium" />
                                    <h3>Expand</h3>
                                </Button>
                            : file.type === 'pdf' ?
                                <Button size='sm' className="btn-row-action" variant='link' onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'custom', file, onDocumentLoadSuccess, numPages)}>
                                    <CustomIcons variant='zoom' />
                                    <h3>Expand</h3>
                                </Button>
                            : file.type === 'video' ?
                                <Button size='sm' className="btn-row-action" variant='link'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'video')}>
                                    <CustomIcons variant='zoom' />
                                    <h3>Expand</h3>
                                </Button>
                            : file.type === 'audio' ?
                                <Button size='sm' className="btn-row-action" variant='link'  onClick={() => updateFSLightBox(URL.createObjectURL(file.object), !FSLightbox, 'audio')}>
                                    <CustomIcons variant='zoom' />
                                    <h3>Expand</h3>
                                </Button>
                            : null
                        }
                            <Button className={`btn-row-action icon-button`} onClick={() => {onClickRemove(file); setIsMobileModalOpenAction(false)}} size='sm' variant='link'>
                                    <>
                                    <CustomIcons variant="trash" className="custom-icon" />
                                        <h3>Delete</h3>
                                    </>
                            </Button>
                        </div>
                    </MobileModal>
                )
            }
            </div>
            <hr/>
        </div>
    );
}

export default UploadCard;