import PropTypes from 'prop-types';

const SvgComponent = ({ svgString }) => {
    return (
        <span className='svg-container' dangerouslySetInnerHTML={{ __html: svgString }} />
    );
};

export default SvgComponent;

SvgComponent.propTypes = {
    svgString: PropTypes.string,
};
